import React from 'react';
import BannerPage from '../components/Banner';

const BannerManagement = () => {
  return (
    <>
      <BannerPage />
    </>
  );
};

export default BannerManagement;
