import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/user';
import orderReducer from './reducers/order';
import categoryReducer from './reducers/category';
import subcategoryReducer from './reducers/subcategory';

const store = configureStore({
  reducer: {
    user: userReducer,
    order: orderReducer,
    category: categoryReducer,
    subcategory: subcategoryReducer,
  },
});

export default store;
