import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { PRODUCT_TYPES } from '../common/constants';
import Loader from '../common/UI/Loader';
import SubcategoryFormPage from '../components/CategoryPage/SubCategorySection/form';
import { getAllSubcategory } from '../store/reducers/subcategory';
import { getProductOptions } from '../utils/productHelper';

const addSubcategory = () => {
  const { subcategoryArr, loading } = useSelector((state) => state.subcategory);
  const dispatch = useDispatch();
  const [subcategoryEditInfo, setSubcategoryEditInfo] = useState({});
  const [params] = useSearchParams();
  const id = params.get('id');

  useEffect(() => {
    if (id && !subcategoryArr) {
      dispatch(getAllSubcategory());
    }
    window.onbeforeunload = () => '';
  }, []);

  useEffect(() => {
    if (id && subcategoryArr?.length) {
      let subcategory = subcategoryArr.find((el) => el?._id === id);
      const image =
        typeof subcategory?.image == 'string'
          ? JSON.parse(subcategory?.image)
          : subcategory?.image;
      const productOptionsList = getProductOptions(
        subcategory?.recommended_product,
      );
      const question_answer = subcategory?.question_answer?.map((el) => {
        el = { ...el?.questionAnswer, _id: el?._id };
        return el;
      });
      setSubcategoryEditInfo({
        ...subcategory,
        prodType: PRODUCT_TYPES.find(
          (option) => option.value == subcategory?.category_id?.product_type,
        ),
        categoryName: {
          label: subcategory?.category_id?.name,
          value: subcategory?.category_id?.slug,
          categoryId: subcategory?.category_id?._id,
        },
        photo: {
          PrevUrl: image?.location || null,
          imgData: image,
        },
        subcategoryName: subcategory?.name,
        subcategoryId: subcategory?._id,
        recommendedProduct: productOptionsList,
        question_answer: question_answer,
      });
    }
  }, [subcategoryArr]);

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <SubcategoryFormPage subcategoryEditInfo={subcategoryEditInfo} />
      )}
    </>
  );
};

export default addSubcategory;
