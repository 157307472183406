import * as network from './network';

// Banner Apis
const getAllBanner = (params) => {
  return network.get(`banner`, params, true);
};

const getBannersOnSearch = (params) => {
  return network.get(`banner/search`, params, true);
};

const addBanner = (payload) => {
  return network.post(`banner/add`, payload, true);
};

const updateBanner = (id, payload) => {
  return network.put(`banner/update/${id}`, payload, true);
};

const deleteBanner = (id) => {
  return network.delet(`banner/${id}`, true);
};

const bannerRelocation = (payload) => {
  return network.post(`banner/relocation`, payload, true);
};

export default {
  getAllBanner,
  getBannersOnSearch,
  addBanner,
  updateBanner,
  deleteBanner,
  bannerRelocation,
};
