import React, { useState } from 'react';
import AddAndEditModal from '../../../common/UI/CustomModal/addAndEditModal';
import CustomInput from '../../../common/FormComponents/Input';
import { Button } from 'react-bootstrap';
import services from '../../../api/api';
import { useNavigate } from 'react-router-dom';

const WeightEditModal = ({ weightObj, toggle, show }) => {
  const navigate = useNavigate();

  const [weight, setWeight] = useState(weightObj.weight);

  const onSubmit = () => {
    if (weightObj?.updateHeightWeight) {
      const payload = {
        id: weightObj.order_items_id,
        updateHeightWeight: true,
        weight: {
          answer: weight,
          answerInKgs: weight,
          answerInPound: weight * 2.2,
          answerInStone: weight * 0.1575,
        },
      };
      services
        .updateHeightWeight(payload)
        .then((res) => {
          navigate(0);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const payload = {
        order_item_id: weightObj.order_items_id,
        answer: weight,
        question: weightObj.question,
      };
      services
        .updateWeightStatistic(payload)
        .then((res) => {
          navigate(0);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <AddAndEditModal
        handleToggle={() => toggle()}
        show={show}
        handleAction={() => onSubmit()}
        action="Update"
      >
        <label className="form-check-label mb-2 fs-6" htmlFor="email">
          Weight<span className="text-danger">*</span>
        </label>
        <input
          className="form-control shadow-none border"
          id="email"
          type="email"
          onChange={(e) => {
            setWeight(e.target.value);
          }}
          value={weight}
        />
      </AddAndEditModal>
    </>
  );
};
export default WeightEditModal;
