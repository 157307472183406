import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import services from '../../api/api';

const TextEditor = (props) => {
  const {
    onEditorChange,
    value,
    editorHeight,
    className,
    style,
    isDisabled,
    ...restProp
  } = props;
  return (
    <div className={className} style={style}>
      <Editor
        apiKey="olvo9xapc1lfyi6rq6kfc2o9o3dhv35urx88j82chqld8g1t"
        // onInit={(evt, editor) => console.log('editor', editor)}
        onEditorChange={onEditorChange}
        value={value}
        init={{
          height: editorHeight,
          // setup: () => {
          //   console.log('set up called');
          // },
          menubar: false,
          plugins:
            'hr lists table fullscreen emoticons preview searchreplace insertdatetime directionality charmap link autolink image media',
          toolbar:
            'undo redo selectall link image media|' +
            'bold italic underline strikethrough  |' +
            'blocks fontfamily fontsize |' +
            'forecolor backcolor |' +
            'alignleft aligncenter alignright alignjustify |' +
            'outdent indent ' +
            'numlist bullist checklist |' +
            'charmap emoticons searchreplace |' +
            'subscript superscript hr |' +
            'removeformat |' +
            'table insertdatetime |' +
            'fullscreen PrintReorder print preview |' +
            // 'a11ycheck permanentpen formatpainter casechange' +
            'ltr rtl',
          branding: false,
          images_upload_handler: async (blobInfo, progress) => {
            return new Promise((resolve, reject) => {
              const formData = new FormData();
              formData.append('photos', blobInfo.blob(), blobInfo.filename());

              services
                .uploadImage(formData)
                .then((res) => {
                  if (res.data.data.photos.length && res.data.data.photos[0]) {
                    progress(100);
                    resolve(res.data.data.photos[0].location);
                  } else {
                    reject('Failed to retrieve image URL.');
                  }
                })
                .catch((err) => {
                  console.log(err);
                  reject('Image upload failed. Please try again.');
                });
            });
          },
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          ...restProp,
        }}
        disabled={isDisabled}
      />
    </div>
  );
};

export default TextEditor;
