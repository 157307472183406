import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import ReactTextareaAutosize from 'react-textarea-autosize';
import CustomDropdown from '../../common/UI/Dropdown';
import {
  booleanOptions,
  booleanRestrictOptions,
  getItemStyle,
  getListStyleSelected,
  inputBooleanQuestionTypeWithGP,
  questionType,
  reorder,
} from '../../utils/productHelper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import services from '../../api/api';
import DeleteConfirmationModal from '../../common/UI/CustomModal/deleteModal';
import { cloneDeep } from 'lodash';
import { toastSuccess, toastError } from '../../common/UI/Toasts';
import deleteIcon from '../../assets/images/trash.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faListUl,
  faMinusCircle,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { faOptinMonster, faPalfed } from '@fortawesome/free-brands-svg-icons';
import {
  gpAddressSubQuestionIDAndKeys,
  gpAddressSubQuestionIDs,
  staticIDs,
} from '../../common/constants';
import TextEditor from '../../common/TextEditor';
import QuestionOptionModal from './questionOptionModal';

const GeneralHealthQuestions = () => {
  const [genQuestions, setGenQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteGeneralQuestion, setDeleteGeneralQuestion] = useState();
  const [deleteGeneralQuestionId, setDeleteGeneralQuestionId] = useState();
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [selectedQuestionsIndex, setSelectedQuestionsIndex] = useState(null);

  useEffect(() => {
    setLoading(true);
    getGenQuestions();
  }, []);

  const getGenQuestions = () => {
    services
      .getAllGenQuestions()
      .then((res) => {
        setGenQuestions(
          res?.data?.data
            ?.map((el) => {
              return {
                _id: el._id,
                question: el.question,
                order: el.order,
                questionType: el?.question?.questionType,
                preferredAnswer: el?.question?.preferredAnswer,
              };
            })
            .sort(function (a, b) {
              if (a.order < b.order) {
                return -1;
              }
              if (a.order > b.order) {
                return 1;
              }
              return 0;
            }),
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const emptyQuestion = {
    question: {
      isHTML: false,
      question: '',
      questionType: { label: 'Input', value: 'input' },
      preferredAnswer: {
        value: '',
        label: '',
      },
      subQuestions: [],
      isAddSubQuestionsDisabled: true,
      isAnswerDisabled: false,
    },
    order: genQuestions.length + 1,
  };

  const handleAddQuestion = () => {
    const question_answer = genQuestions;
    let questionsClone = cloneDeep(question_answer);
    questionsClone.push(emptyQuestion);
    setGenQuestions(questionsClone);
  };
  const handleAddSubQuestion = (index) => {
    let questionsClone = cloneDeep(genQuestions);
    if (!questionsClone[index].question.subQuestions) {
      questionsClone[index].question.subQuestions = [];
    }
    questionsClone[index].question.subQuestions.push({
      question: '',
      answer: '',
      questionType: { label: 'Input', value: 'input' },
      selectedAnswer: null,
      isAnswerDisabled: true,
      isHTML: false,
    });
    setGenQuestions(questionsClone);
  };

  const handleChangeForQuestion = (name, value, index) => {
    const question_answer = genQuestions;
    let questionsClone = cloneDeep(question_answer);
    questionsClone[index]['question'][name] = value;
    if (name === 'questionType') {
      // handling of "isAddSubQuestionsDisabled" prop
      if (value.value === 'boolean' || value.value === 'select') {
        questionsClone[index]['question'].isAddSubQuestionsDisabled = false;
      } else {
        questionsClone[index]['question'].isAddSubQuestionsDisabled = true;
        // empty the sub question if there were any
        questionsClone[index].question.subQuestions = [];
      }
    }
    if (name === 'questionType' && value.value === 'boolean') {
      questionsClone[index]['question'].isAnswerDisabled = false;
    } else if (name === 'questionType' && value.value !== 'boolean') {
      questionsClone[index]['question'].isAnswerDisabled = true;
      questionsClone[index]['question'].preferredAnswer = null;
    }

    setGenQuestions(questionsClone);
  };

  const handleChangeForSubQuestion = (name, value, subIndex, index) => {
    let questionsClone = cloneDeep(genQuestions);
    let subQuestionClone = cloneDeep(
      questionsClone[index]?.question?.subQuestions,
    );
    subQuestionClone[subIndex][name] = value;

    if (name === 'questionType' && value.value === 'boolean') {
      subQuestionClone[subIndex].isAnswerDisabled = false;
    } else if (name === 'questionType' && value.value !== 'boolean') {
      subQuestionClone[subIndex].isAnswerDisabled = true;
      subQuestionClone[subIndex].preferredAnswer = null;
    }

    questionsClone[index].question.subQuestions = subQuestionClone;
    setGenQuestions(questionsClone);
  };

  const onDragEndQuestion = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const question_answer = genQuestions;
      let questionsReordered = reorder(
        question_answer,
        source.index,
        destination.index,
      );

      questionsReordered = questionsReordered.map((el, index) => {
        return {
          ...el,
          order: index + 1,
        };
      });
      setGenQuestions(questionsReordered);
    }
  };

  const handleSubmit = () => {
    let payload = cloneDeep(genQuestions);

    const selectedSubAnsData = [];
    let checkQueExist = false;
    payload.forEach((el) => {
      if (!el.question.question) {
        checkQueExist = true;
      }
      el.question.subQuestions.forEach((el) => {
        if (!el.question || !el.selectedAnswer || !el.questionType)
          selectedSubAnsData.push(el.selectedAnswer);
      });
    });
    selectedSubAnsData.length || checkQueExist ? (
      <>
        {selectedSubAnsData.length &&
          toastError('Please fill all details of sub-questions!')}
        {checkQueExist && toastError('Question field cannot be blank')}
      </>
    ) : (
      services
        .submitGenQuestions({ question: payload })
        .then((res) => {
          toastSuccess('General questions updated successfully');
          getGenQuestions();
        })
        .catch((err) => {
          console.log('error', err);
        })
    );
  };

  const handleDelete = (el, index) => {
    if (el._id) {
      setDeleteGeneralQuestionId(el._id);
      setDeleteModalShow(true);
    } else {
      let questions = genQuestions;
      questions.splice(index, 1);
      questions = questions.map((el, index) => {
        return {
          ...el,
          order: index + 1,
        };
      });
      setGenQuestions(questions);
    }
  };

  const handleDeleteSubQuestion = (subIndex, index) => {
    let questionsClone = cloneDeep(genQuestions);
    let subQuestionClone = cloneDeep(
      questionsClone[index]?.question?.subQuestions,
    );
    subQuestionClone.splice(subIndex, 1);
    questionsClone[index].question.subQuestions = subQuestionClone;
    setGenQuestions(questionsClone);
  };

  const toggleDeleteModal = () => {
    setDeleteModalShow(false);
    setDeleteGeneralQuestionId(null);
    setDeleteGeneralQuestion(null);
  };

  const deleteModalAction = () => {
    setDeleteModalShow(false);
    deleteQuestion();
  };

  const deleteQuestion = () => {
    services
      .deleteGenQuestion(deleteGeneralQuestionId)
      .then((res) => {
        toastSuccess('General Question Deleted Successfully');
        setDeleteGeneralQuestionId(null);
        getGenQuestions();
      })
      .catch((err) => {
        console.log('error', err);
        toastError(err.message);
        setDeleteGeneralQuestionId(null);
      });
  };

  const handleAddOption = () => {
    const genQuestionsClone = [...genQuestions];
    genQuestions[selectedQuestionsIndex].question?.options
      ? genQuestionsClone[selectedQuestionsIndex].question.options.push('')
      : (genQuestionsClone[selectedQuestionsIndex].question.options = ['']);
    setGenQuestions(genQuestionsClone);
  };

  const handleChangeForOptions = (index, value) => {
    const genQuestionsClone = [...genQuestions];
    if (index === false) {
      // change for is Single
      genQuestionsClone[selectedQuestionsIndex].question.isSingle = value;
    } else {
      genQuestionsClone[selectedQuestionsIndex].question.options[index] = value;
    }
    setGenQuestions(genQuestionsClone);
  };

  const handleDeleteForOptions = (index) => {
    const genQuestionsClone = [...genQuestions];
    const options = genQuestionsClone[
      selectedQuestionsIndex
    ].question.options.filter((el, i) => i != index);
    genQuestionsClone[selectedQuestionsIndex].question.options = options;
    setGenQuestions(genQuestionsClone);
  };

  const getText = (html) => {
    var divContainer = document.createElement('div');
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || '';
  };
  return (
    <>
      {deleteModalShow && (
        <DeleteConfirmationModal
          name={deleteGeneralQuestion}
          module={'General Question'}
          show={deleteModalShow}
          handleToggle={toggleDeleteModal}
          handleDelete={deleteModalAction}
        />
      )}
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <div
                    className="col-md-12 d-flex"
                    style={{ justifyContent: 'space-between' }}
                  >
                    <h3>General Health Questions</h3>
                    <div className="d-flex flex-column align-items-center">
                      <Button
                        className="btn-primary"
                        onClick={handleAddQuestion}
                        style={{
                          width: 'max-content',
                        }}
                      >
                        + Add
                      </Button>{' '}
                      <span>Height weight questions are static</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7">Questions</div>
                  <div className="col-md-2">Answer Type</div>
                  <div className="col-md-2">Answer</div>
                  <div className="col-md-1">Action</div>
                </div>
                <DragDropContext onDragEnd={onDragEndQuestion} className="w-75">
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyleSelected(snapshot.isDraggingOver)}
                      >
                        {genQuestions?.map((el, index) => {
                          const question = getText(el?.question?.question);
                          const isDisabled = el?._id === staticIDs.gp_question;
                          return (
                            <Draggable
                              key={`main${index}`}
                              draggableId={`main${index}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className="question_wrapper"
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                  style={{
                                    ...getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                      el?._id,
                                    ),
                                  }}
                                >
                                  <div
                                    key={`main${index}`}
                                    className={`row m-2 ${
                                      el?._id === staticIDs?.weight ||
                                      el?._id === staticIDs?.height ||
                                      el?._id === staticIDs?.gp_question
                                        ? 'static_question'
                                        : ''
                                    }`}
                                  >
                                    <div
                                      className="col-md-1 d-flex align-items-center justify-content-center"
                                      style={{ width: '1px' }}
                                    >
                                      <input
                                        type="checkbox"
                                        name="isHTML"
                                        onChange={(e) =>
                                          !el?.question?.isHTML &&
                                          handleChangeForQuestion(
                                            e.target.name,
                                            e.target.checked,
                                            index,
                                          )
                                        }
                                        checked={el?.question?.isHTML}
                                        disabled={isDisabled}
                                      />
                                    </div>
                                    <div className={`col-md-6`}>
                                      {el?._id === staticIDs?.weight ||
                                      el?._id === staticIDs?.height ||
                                      el?._id === staticIDs?.gp_question ? (
                                        <span className="static_question--label">
                                          {staticIDs.height === el._id
                                            ? 'Height'
                                            : staticIDs.weight === el._id
                                            ? 'Weight'
                                            : 'GP Address'}
                                        </span>
                                      ) : null}
                                      {el?.question?.isHTML ? (
                                        <TextEditor
                                          name="question"
                                          value={el?.question?.question}
                                          onEditorChange={(value) => {
                                            handleChangeForQuestion(
                                              'question',
                                              value,
                                              index,
                                            );
                                          }}
                                          editorHeight="30vh"
                                        />
                                      ) : (
                                        <ReactTextareaAutosize
                                          name="question"
                                          type="text"
                                          className="form-control form_input"
                                          placeholder=""
                                          onChange={(e) =>
                                            handleChangeForQuestion(
                                              e.target.name,
                                              e.target.value,
                                              index,
                                            )
                                          }
                                          value={el?.question?.question}
                                          minRows={1}
                                        />
                                      )}
                                    </div>
                                    <div className="col-md-2">
                                      <CustomDropdown
                                        options={questionType}
                                        isSearchable={false}
                                        height={'44px'}
                                        className={'me-3'}
                                        containerWidth={'95%'}
                                        placeholderMarginTop={'-5px'}
                                        placeholder={'Select'}
                                        placeholderColor={'#9aa5ad'}
                                        value={el?.question?.questionType}
                                        border={'1 px solid #9aa5ad'}
                                        onChange={(value) => {
                                          handleChangeForQuestion(
                                            'questionType',
                                            value,
                                            index,
                                          );
                                        }}
                                        isDisabled={
                                          el?._id === staticIDs?.weight ||
                                          el?._id === staticIDs?.height ||
                                          el?._id === staticIDs?.gp_question
                                        }
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      <CustomDropdown
                                        border={'1px solid #9aa5ad'}
                                        name="preferredAnswer"
                                        height="44px"
                                        onChange={(value) =>
                                          handleChangeForQuestion(
                                            'preferredAnswer',
                                            value,
                                            index,
                                          )
                                        }
                                        isDisabled={
                                          el?.question?.isAnswerDisabled ||
                                          el?._id === staticIDs?.weight ||
                                          el?._id === staticIDs?.height ||
                                          el?._id === staticIDs?.gp_question
                                        }
                                        className={'me-3'}
                                        containerWidth={'95%'}
                                        placeholderMarginTop={'-5px'}
                                        placeholder={'Select'}
                                        placeholderColor={'#9aa5ad'}
                                        value={el?.question?.preferredAnswer}
                                        options={booleanRestrictOptions}
                                      />
                                    </div>
                                    {!(
                                      el?._id === staticIDs?.weight ||
                                      el?._id === staticIDs?.height ||
                                      el?._id === staticIDs?.gp_question
                                    ) ? (
                                      <div className="col-md-1 d-flex align-items-center">
                                        <FontAwesomeIcon
                                          className="pt-1 d-block m-2"
                                          style={{ pointerEvents: 'auto' }}
                                          onClick={() =>
                                            handleDelete(el, index)
                                          }
                                          icon={faTrash}
                                          size="lg"
                                          color={'#088fff'}
                                        />
                                        <span
                                          className="m-2 pt-1 d-block"
                                          style={{
                                            width: '50px',
                                            cursor: `${
                                              el?.question
                                                ?.isAddSubQuestionsDisabled
                                                ? 'not-allowed'
                                                : 'pointer'
                                            }`,
                                          }}
                                          onClick={() => {
                                            if (
                                              el?.question
                                                ?.isAddSubQuestionsDisabled
                                            )
                                              return;
                                            handleAddSubQuestion(index);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPlusCircle}
                                            size="lg"
                                            color={'#088fff'}
                                          />
                                        </span>
                                        {el?.question?.questionType?.value ===
                                        'select' ? (
                                          <span
                                            className="m-2 pt-1 d-block"
                                            style={{
                                              width: '50px',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              setShowOptionsModal(
                                                !showOptionsModal,
                                              );
                                              setSelectedQuestionsIndex(index);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faListUl}
                                              size="lg"
                                              color={'#088fff'}
                                            />
                                          </span>
                                        ) : null}
                                      </div>
                                    ) : null}
                                  </div>
                                  {el?.question?.subQuestions?.length
                                    ? el?.question?.subQuestions.map(
                                        (elem, subIndex) => {
                                          const question = getText(
                                            elem?.question,
                                          );
                                          const labelValuePairOptions = el
                                            ?.question?.options
                                            ? el.question.options.map((el) => {
                                                return {
                                                  label: el,
                                                  value: el,
                                                };
                                              })
                                            : [];
                                          const isDisabled =
                                            gpAddressSubQuestionIDs.includes(
                                              elem?._id,
                                            );
                                          return (
                                            <div
                                              key={`sub${subIndex}`}
                                              className={`row sub_question mb-2 ${
                                                isDisabled
                                                  ? 'static_question'
                                                  : ''
                                              }`}
                                            >
                                              <div
                                                className="col-md-1 d-flex align-items-center justify-content-center"
                                                style={{ width: '1px' }}
                                              >
                                                <input
                                                  type="checkbox"
                                                  name="isHTML"
                                                  onChange={(e) =>
                                                    !elem?.isHTML &&
                                                    handleChangeForSubQuestion(
                                                      e.target.name,
                                                      e.target.checked,
                                                      subIndex,
                                                      index,
                                                    )
                                                  }
                                                  checked={elem?.isHTML}
                                                  disabled={isDisabled}
                                                />
                                              </div>
                                              <div className="col-md-2">
                                                <div className="d-flex align-items-center">
                                                  <span className="m-2">
                                                    If{' '}
                                                  </span>
                                                  <CustomDropdown
                                                    name="questionType"
                                                    height="44px"
                                                    className="question_dropdown"
                                                    onChange={(value) =>
                                                      handleChangeForSubQuestion(
                                                        'selectedAnswer',
                                                        value,
                                                        subIndex,
                                                        index,
                                                      )
                                                    }
                                                    value={elem?.selectedAnswer}
                                                    options={
                                                      el?.question.questionType
                                                        ?.value === 'boolean'
                                                        ? booleanOptions
                                                        : el?.question
                                                            ?.questionType
                                                            ?.value === 'select'
                                                        ? labelValuePairOptions
                                                        : null
                                                    }
                                                    controlStyles={{
                                                      width: '127.931px',
                                                      textAlign: 'center',
                                                    }}
                                                    isDisabled={isDisabled}
                                                  />
                                                  <span className="m-2">:</span>
                                                </div>
                                              </div>
                                              <div className={`col-md-4`}>
                                                {isDisabled ? (
                                                  <span className="static_question--label">
                                                    {
                                                      gpAddressSubQuestionIDAndKeys[
                                                        elem._id
                                                      ]
                                                    }
                                                  </span>
                                                ) : null}
                                                {elem?.isHTML ? (
                                                  <TextEditor
                                                    name="question"
                                                    value={elem?.question}
                                                    onEditorChange={(value) => {
                                                      handleChangeForSubQuestion(
                                                        'question',
                                                        value,
                                                        subIndex,
                                                        index,
                                                      );
                                                    }}
                                                    editorHeight="30vh"
                                                  />
                                                ) : (
                                                  <ReactTextareaAutosize
                                                    name="question"
                                                    type="text"
                                                    className="form-control form_input"
                                                    placeholder=""
                                                    onChange={(e) =>
                                                      handleChangeForSubQuestion(
                                                        e.target.name,
                                                        e.target.value,
                                                        subIndex,
                                                        index,
                                                      )
                                                    }
                                                    value={elem?.question}
                                                    minRows={1}
                                                  />
                                                )}
                                              </div>
                                              <div className="col-md-2">
                                                <CustomDropdown
                                                  name="questionType"
                                                  height="44px"
                                                  className="question_dropdown"
                                                  onChange={(value) =>
                                                    handleChangeForSubQuestion(
                                                      'questionType',
                                                      value,
                                                      subIndex,
                                                      index,
                                                    )
                                                  }
                                                  value={elem?.questionType}
                                                  options={
                                                    inputBooleanQuestionTypeWithGP
                                                  }
                                                  isDisabled={isDisabled}
                                                />
                                              </div>
                                              <div className="col-md-2">
                                                <CustomDropdown
                                                  name="preferredAnswer"
                                                  height="44px"
                                                  onChange={(value) =>
                                                    handleChangeForSubQuestion(
                                                      'preferredAnswer',
                                                      value,
                                                      subIndex,
                                                      index,
                                                    )
                                                  }
                                                  isDisabled={
                                                    elem?.isAnswerDisabled
                                                  }
                                                  className="question_dropdown"
                                                  value={elem?.preferredAnswer}
                                                  options={
                                                    booleanRestrictOptions
                                                  }
                                                />
                                              </div>
                                              {!isDisabled && (
                                                <div className="col-md-1 d-flex">
                                                  <FontAwesomeIcon
                                                    className="pt-1 d-block m-2"
                                                    onClick={() =>
                                                      handleDeleteSubQuestion(
                                                        subIndex,
                                                        index,
                                                      )
                                                    }
                                                    icon={faMinusCircle}
                                                    size="lg"
                                                    color={'#088fff'}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          );
                                        },
                                      )
                                    : null}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                {/*  */}
              </div>
            </div>
            <div className="d-flex" style={{ justifyContent: 'end' }}>
              <button
                onClick={() => handleSubmit()}
                type="button"
                className="btn btn-success my-2 me-2"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {showOptionsModal ? (
        <QuestionOptionModal
          handleChange={handleChangeForOptions}
          show={showOptionsModal}
          toggle={() => {
            if (showOptionsModal) {
              setShowOptionsModal(false);
              setSelectedQuestionsIndex(null);
            } else {
              setShowOptionsModal(true);
            }
          }}
          isSingle={
            genQuestions[selectedQuestionsIndex].question?.isSingle || false
          }
          addOptions={handleAddOption}
          deleteOption={handleDeleteForOptions}
          options={genQuestions[selectedQuestionsIndex].question?.options}
        />
      ) : null}
    </>
  );
};

export default GeneralHealthQuestions;
