import * as network from './network';

// Keyword Apis
const getAllKeyword = (params) => {
  return network.get(`related-keyword`, params, true);
};

const getKeywordsOnSearch = (params) => {
  return network.get(`related-keyword/search`, params, true);
};

const addKeyword = (payload) => {
  return network.post(`related-keyword`, payload, true);
};

const updateKeyword = (id, payload) => {
  return network.put(`related-keyword/${id}`, payload, true);
};

const deleteKeyword = (id) => {
  return network.delet(`related-keyword/${id}`, true);
};
export default {
  getAllKeyword,
  getKeywordsOnSearch,
  addKeyword,
  updateKeyword,
  deleteKeyword,
};
