import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import CustomInput from '../../../common/FormComponents/Input';
import CustomReactSelect from '../../../common/FormComponents/ReactSelect';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import services from '../../../api/api';
import {
  getErrorMessage,
  getFormDataFromObject,
  imageDimentionValidator,
  regex,
} from '../../../utils/helpers';
import { toastError, toastSuccess } from '../../../common/UI/Toasts';
import { DIMENSION, PRODUCT_TYPES } from '../../../common/constants';
import ImageInput from '../../../common/FormComponents/ImageInput';
import TextError from '../../../common/FormComponents/TextError';
import plusBlueIcon from '../../../assets/images/plus-blue.svg';
import deleteIcon from '../../../assets/images/trash.svg';
import { useDispatch } from 'react-redux';
import { getAllCategory } from '../../../store/reducers/category';

const validationSchema = Yup.object({
  prodType: Yup.object()
    .nullable()
    .required(getErrorMessage('select-required', 'Product Type')),
  categoryName: Yup.string().required(
    getErrorMessage('required', 'Category Name'),
  ),
  photo: Yup.array()
    .nullable()
    .min(1, getErrorMessage('required', 'image file')),
});

const CategoryFormPage = (props) => {
  const { categoryEditInfo } = props;
  const [params] = useSearchParams();
  const id = params.get('id');
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const onSubmit = (values, onSubmitProps) => {
    let photoValue = values.photo?.imgData
      ? values.photo.imgData instanceof File
        ? values.photo.imgData
        : null
      : null;

    let payload = {
      name: values.categoryName,
      product_type: values.prodType?.value,
    };
    if (photoValue) {
      payload['image'] = photoValue;
    }
    payload = getFormDataFromObject(payload);
    onSubmitProps.setSubmitting(true);
    Object.keys(categoryEditInfo).length
      ? services
          .updateCategory(categoryEditInfo.categoryId, payload)
          .then((res) => {
            navigate(`/category`);
            dispatch(getAllCategory());
            onSubmitProps.setSubmitting(false);
            toastSuccess('Category Updated Successfully');
          })
          .catch((err) => {
            console.log('error', err);
            onSubmitProps.setSubmitting(false);
            onSubmitProps.setFieldError(
              'categoryName',
              'This category already exists',
            );
          })
      : services
          .addCategory(payload)
          .then((res) => {
            onSubmitProps.setSubmitting(false);
            onSubmitProps.resetForm();
            dispatch(getAllCategory());
            toastSuccess('Category Added Successfully');
          })
          .catch((err) => {
            onSubmitProps.setSubmitting(false);
            console.log('error', err);
            onSubmitProps.setFieldError(
              'categoryName',
              'This category already exists',
            );
          });
  };

  return (
    <section className="content_wrapper product_add_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ol className="breadcrumb">
              <li>
                <Link to={'/category'} title="Product Management">
                  Category Management
                </Link>
              </li>
              <li>{id ? 'Edit' : 'Add'} Category</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              <div className="header_top">
                <h3>{id ? 'Edit' : 'Add'} Category</h3>
              </div>
            </div>
          </div>
        </div>
        <Formik
          key={categoryEditInfo?._id}
          initialValues={{
            prodType: categoryEditInfo?.prodType?.value
              ? categoryEditInfo?.prodType
              : categoryEditInfo?.prodType || null,
            categoryName: categoryEditInfo?.categoryName || '',
            photo: categoryEditInfo?.photo?.PrevUrl
              ? categoryEditInfo?.photo
              : [],
          }}
          onSubmit={onSubmit}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {(formik) => {
            const { values, errors, touched } = formik;
            const { setFieldValue, setTouched } = formik;
            return (
              <Form>
                <div className="middle_main_2">
                  <div className="row">
                    <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                      <CustomReactSelect
                        label="Product Type"
                        labelClassname={'form-label mb-3'}
                        name="prodType"
                        id="prodType"
                        className="form-control"
                        placeholderColor="#9aa5ad"
                        height={'50px'}
                        placeholderMarginTop={'-5px'}
                        validationType="required"
                        errorFieldName="Product Type"
                        customSelectBorder={'1px solid #9aa5ad'}
                        options={PRODUCT_TYPES}
                        // value={formik.values.prodType}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                      <CustomInput
                        name="categoryName"
                        id="categoryName"
                        type="text"
                        label="Category Name"
                        labelClassname="form-label"
                        placeholder="Category Name"
                        className="form-control form-input"
                        // value={formik.values.categoryName}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex">
                    <label className="form-label">
                      {' '}
                      Upload Photo<span className="text-danger">*</span> ( w x h
                      : {DIMENSION.category.width} x {DIMENSION.category.height}
                      )
                    </label>
                    <div className="d-flex">
                      <div
                        className="col-2 mb-2"
                        style={{ height: '10rem', width: '11rem' }}
                      >
                        <span className="upload_text">
                          <label style={{ cursor: 'pointer' }}>
                            <img
                              src={plusBlueIcon}
                              width="12"
                              height="12"
                              className="img-fluid custom_radius me-2"
                              alt="plus"
                            />
                            <input
                              style={{ display: 'none' }}
                              type="file"
                              name="photo"
                              accept="image/*"
                              onChange={(e) => {
                                // URL.createObjectURL is giving TypeError without checking length
                                if (e.target.files.length !== 0) {
                                  let imgData = {
                                    PrevUrl: URL.createObjectURL(
                                      e.target.files[0],
                                    ),
                                    imgData: e.target.files[0],
                                  };
                                  let img = new Image();

                                  img.src = imgData.PrevUrl;
                                  img.onload = function () {
                                    const isImageDimention =
                                      imageDimentionValidator.call(
                                        this,
                                        DIMENSION.category.height,
                                        DIMENSION.category.width,
                                      );
                                    if (isImageDimention) {
                                      setFieldValue('photo', imgData);
                                    } else {
                                      toastError(
                                        `Please select photo of size ${DIMENSION.category.width} x ${DIMENSION.category.height}`,
                                      );
                                    }
                                  };
                                }
                              }}
                              onBlur={() => {
                                setTouched({ ...touched, photo: true });
                              }}
                            />
                          </label>
                        </span>
                      </div>
                      {values?.photo?.PrevUrl && (
                        <div className="d-flex">
                          <div className="position-relative hover_effect_main">
                            <img
                              src={values?.photo?.PrevUrl}
                              className="img-fluid custom_radius"
                              style={{ height: '100%', width: '12rem' }}
                            />
                            <div className="hover_effect_child">
                              <button
                                type="button"
                                onClick={() => {
                                  setFieldValue('photo', []);
                                }}
                              >
                                <img
                                  src={deleteIcon}
                                  width="24"
                                  height="18"
                                  alt="trash"
                                  className="mx-2"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        // <div className="col-2 mb-2" style={{ height: '10rem' }}>
                        //   <img
                        //     src={values?.photo?.PrevUrl}
                        //     className="img-fluid custom_radius"
                        //     style={{ height: '100%', width: '12rem' }}
                        //   />
                        // </div>
                      )}
                    </div>

                    {touched['photo'] && errors['photo'] && (
                      <TextError>{errors['photo']}</TextError>
                    )}
                  </div>
                </div>
                <div className="footer_main">
                  <Button
                    type="button"
                    variant="sky"
                    className="my-2 me-2"
                    onClick={() => navigate('/category')}
                  >
                    Back
                  </Button>
                  <Button className="me-2" type="submit">
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

export default CategoryFormPage;
