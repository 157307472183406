import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import services from '../../api/api';

export const fetchUserData = createAsyncThunk('user/fetchUserData', () => {
  return services.getUserData().then((res) => {
    let user = { ...res?.data?.data };
    user.fullName = `${user?.first_name} ${user?.last_name}`;
    return user;
  });
});

const initialState = { userData: {}, loading: false, error: '' };

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.userData = action.payload;
    });
    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.userData = null;
    });
  },
});

export default userSlice.reducer;
