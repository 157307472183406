import * as network from './network';

const getAllFeaturedProducts = (params) => {
  return network.get(`feature-product`, params, true);
};

const deleteFeaturedProduct = (id) => {
  return network.delet(`feature-product/${id}`, true);
};

const addFeatureProduct = (params) => {
  return network.post(`feature-product`, params, true);
};

const updateFeatureProduct = (id, params) => {
  return network.put(`feature-product/${id}`, params, true);
};

export default {
  getAllFeaturedProducts,
  deleteFeaturedProduct,
  addFeatureProduct,
  updateFeatureProduct,
};
