import React from 'react';
import GoogeAdsTable from '../components/googleAds';

const GoogleAds = () => {
  return (
    <>
      <GoogeAdsTable />
    </>
  );
};

export default GoogleAds;
