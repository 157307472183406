import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import SearchAndButtons from '../common/UI/Search&Buttons';
import OrderTable from '../components/Orders/orderTable';
import services from '../api/api';
import { isUserDoctor } from '../utils/helpers';
import deleteIcon from '../assets/images/trash.svg';
import activateIcon from '../assets/images/start.png';
import moment from 'moment';
import { toastSuccess } from '../common/UI/Toasts';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

const PausedSubscriptionManagement = () => {
  const navigate = useNavigate();

  const columns = [
    {
      Header: 'Product Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.product?.name}</span>;
      },
    },
    {
      Header: 'Customer Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>
            {`${row?.original?.user?.first_name} ${row?.original?.user?.last_name}`}
          </span>
        );
      },
    },
    {
      Header: 'Email',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {`${row?.original?.user?.email}`}
          </span>
        );
      },
    },
    {
      Header: 'Subscription Price',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.lastorder[0].total}</span>;
      },
    },
    {
      Header: 'Start Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>{moment(row.original.started_date).format('YYYY-MM-DD')}</span>
        );
      },
    },
    {
      Header: 'Paused Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>{moment(row.original.paused_date).format('YYYY-MM-DD')}</span>
        );
      },
    },
    {
      Header: 'Delivery Service',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.lastorder[0]?.delivery?.title}</span>;
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            {/* <span
              className="me-2"
              onClick={() => handleActivateSubscription(row)}
            >
              <img src={activateIcon} alt="activate" width="34" height="34" />
            </span> */}
            {/* <button className='btn btn-primary px-2 py-1' onClick={() => handleUnPauseSubscription(row)}>Unpause</button> */}
            <span
              className="me-2"
              onClick={() => handleCancelSubscription(row)}
            >
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem('userId');
  const [csvData, setCsvData] = useState([]);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    setLoading(true);
    getOrders(currentPageNo);
  }, [currentPageNo, seeAll, searchVal]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getOrders = (pageNo, value = null) => {
    let params = seeAll
      ? { showall: true }
      : {
        perPage: 10,
        page: pageNo,
      };
    params = {
      ...params,
      paused: true,
    };
    if (searchVal)
      params = {
        ...params,
        search: searchVal,
      };

    services
      .getAllSubscriptions(params)
      .then((res) => {
        let data = res?.data?.data?.subscriptions?.docs;
        setData(data);
        loadCSVdata(data);
        setTotalPage(res?.data?.data?.subscriptions?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const handleOrderSearch = (value) => {
    setSearchVal(value);
  };

  const onSearchDebounce = debounce(handleOrderSearch, 1000);

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  const handleCancelSubscription = (rowData) => {
    services
      .cancelSubscription(rowData?.original?._id)
      .then((res) => {
        toastSuccess('Subscription Cancelled Successfully!!');
        getOrders();
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const handleActivateSubscription = (rowData) => {
    services
      .updateSubscription(rowData?.original?._id, { paused: false })
      .then((res) => {
        toastSuccess('Subscription Paused Successfully!!');
        navigate(0);
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const handleSubscription = (rowData) => {
    services
      .cancelSubscription(rowData?.original?._id)
      .then((res) => {
        toastSuccess('Subscription Cancelled Successfully!!');
        getOrders();
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const handleUnPauseSubscription = (rowData) => {
    services
      .unPauseSubsctiption(rowData?.original?._id)
      .then((res) => {
        toastSuccess('Subscription unpaused successfully!!');
        navigate(0);
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const CSVheaders = [
    { label: 'Product Name', key: 'product_name' },
    { label: 'Customer Name', key: 'customer_name' },
    { label: 'Customer Email', key: 'customer_email' },
    { label: 'Subscription Price', key: 'sub_price' },
    { label: 'Start Date', key: 'start_date' },
    { label: 'End Date', key: 'end_date' },
    { label: 'Delivery Service', key: 'delivery_service' },
    { label: 'Status', key: 'status' },
  ];

  const loadCSVdata = (orderdata) => {
    const modifyorderdata = orderdata?.length
      ? orderdata?.map((el) => {
        return {
          product_name: el?.product?.name,
          customer_name: `${el?.user?.first_name} ${el?.user?.last_name}`,
          customer_email: `${el?.user?.email}`,
          sub_price: el?.lastorder[0]?.total,
          start_date: moment(el?.started_date).format('YYYY-MM-DD'),
          end_date: el?.cancelled_date
            ? moment(el?.cancelled_date).format('YYYY-MM-DD')
            : '-',
          delivery_service: el?.lastorder[0]?.delivery?.title,
          status: 'Cancelled',
        };
      })
      : [];
    setCsvData(modifyorderdata);
  };
  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div
                  className="header_top"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h3>Paused Subscription Management</h3>
                </div>
                <div className="middle_main">
                  <SearchAndButtons
                    onSearchChange={onSearchDebounce}
                    searchPlaceholder={'Search by product and user name...'}
                    buttonName={
                      <CSVLink
                        data={csvData}
                        filename={`orders_${moment().format(
                          'YYYY_MM_DD_HH_mm_ss',
                        )}.xlsx`}
                        target="_blank"
                        headers={CSVheaders}
                        style={{ color: '#fff' }}
                      >
                        Export Orders
                      </CSVLink>
                    }
                    loading={loading}
                    seeAllHandle={seeAllHandle}
                  />
                  {loading ? (
                    <Loader loading={loading} />
                  ) : (
                    <ReactTable
                      title="Subscription Orders"
                      tableColumns={columns}
                      tableData={data}
                      seeAll={seeAll}
                      nextHandle={nextHandle}
                      prevHandle={prevHandle}
                      totalPage={totalPage}
                      pageNo={currentPageNo}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PausedSubscriptionManagement;
