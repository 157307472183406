import React from 'react';
import FeaturedProductsManagement from '../components/FeaturedProducts';
const FeaturedProducts = () => {
  return (
    <>
      <FeaturedProductsManagement />
    </>
  );
};

export default FeaturedProducts;
