import React, { useEffect, useState } from 'react';
import ReactTable from '../../common/UI/ReactTable';
import services from '../../api/api';
import editIcon from '../../assets/images/pencil.svg';
import deleteIcon from '../../assets/images/trash.svg';
import eyeIcon from '../../assets/images/eye.svg';
import searchIcon from '../../assets/images/search-black.svg';
import { PRODUCT_TYPES, PRODUCT_STATUS } from '../../common/constants';
import CustomDropdown from '../../common/FormComponents/Dropdown';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import DeleteConfirmationModal from '../../common/UI/CustomModal/deleteModal';
import api from '../../api/api';
import Loader from '../../common/UI/Loader';
import { compareObjects } from '../../utils/helpers';
import {
  defaultColumnFilter,
  selectColumnFilter,
} from '../../common/UI/ReactTable/tableHelper';
import { toastError, toastSuccess } from '../../common/UI/Toasts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCommentSlash,
  faDownload,
  faInbox,
  faPenAlt,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';

const Products = (props) => {
  const [loadingData, setLoadingData] = useState(false);
  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
  ] = useState(false);
  const [deleteObject, setDeleteObject] = useState({});
  const [seeAll, setSeeAll] = useState(false);
  const [showImported, setShowImported] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [impFile, setImpFile] = useState(null);
  const modifiedProductTypes = [...PRODUCT_TYPES];
  modifiedProductTypes.unshift({
    label: 'All',
    value: 'all',
  });
  const modifiedProductStatus = [...PRODUCT_STATUS];
  modifiedProductStatus.unshift({
    label: 'All',
    value: 'all',
  });
  const [filter, setFilter] = useState({});
  //

  const toggleDeleteModal = (row = null) => {
    if (!showDeleteConfirmationModal) {
      setShowDeleteConfirmationModal(true);
      setDeleteObject(row.original);
    } else {
      setShowDeleteConfirmationModal(false);
    }
  };

  const getFilteredProductData = (filterParams = {}) => {
    if (compareObjects(filter, filterParams)) {
      return;
    }
    if (filterParams.type && filterParams.type === 'all')
      delete filterParams.type;
    setFilter(filterParams);
    let params = {
      page: currentPageNo,
      perPage: 20,
      importByFile: showImported,
      admin: true,
      ...filterParams,
    };
    setLoadingData(true);

    services
      .getProducts(params)
      .then((response) => {
        setData(response?.data?.data?.docs);
        setTotalPage(response?.data?.data?.totalPages);
        setLoadingData(false);
      })
      .catch((err) => {
        console.log(`===> :: err`, err);
        setLoadingData(false);
      });
  };
  //

  const handleProductView = (row) => {
    if (row.original?._id) {
      navigate('/viewproduct/' + row.original._id);
    }
  };

  const columns = [
    {
      Header: 'Product Name',
      accessor: 'name',
      width: '400px',
      Filter: defaultColumnFilter,
      placeholder: 'Product name',
      disableSortBy: true,
    },
    {
      Header: 'Brand',
      placeholder: 'Brand',
      accessor: 'brandName',
      Filter: defaultColumnFilter,
      disableSortBy: true,
      Cell: ({ row }) => {
        return <div>{row.original?.brand?.name}</div>;
      },
    },
    {
      Header: 'Type',
      Filter: (props) =>
        selectColumnFilter(props, { name: 'productType', width: '200px' }),
      placeholder: 'Type',
      options: modifiedProductTypes,
      accessor: 'type',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div>
            {PRODUCT_TYPES.find((el) => el.value === row.original.type).label}
          </div>
        );
      },
    },
    {
      Header: 'Category',
      Filter: defaultColumnFilter,
      accessor: 'categoryName',
      placeholder: 'Category',
      disableSortBy: true,
      Cell: ({ row }) => {
        let categories =
          row.original?.sub_categories &&
          row.original?.sub_categories?.reduce((arr, curr) => {
            if (arr.includes(curr?.category?.name)) return [...arr];
            else {
              return [...arr, curr?.category?.name];
            }
          }, []);

        return (
          <div>
            {categories.map((el) => (
              <div key={el}>{el}</div>
            ))}
          </div>
        );
      },
    },
    {
      Header: 'Sub Category',
      placeholder: 'Sub-Cat',
      Filter: defaultColumnFilter,
      accessor: 'subCategoryName',
      disableSortBy: true,
      Cell: ({ row }) => {
        let subCategories = row.original?.sub_categories;
        return (
          <div>
            {subCategories.map((el) => (
              <div key={el?._id}>{el.name}</div>
            ))}
          </div>
        );
      },
    },
    {
      Header: 'Active',
      Filter: (props) =>
        selectColumnFilter(props, { name: 'status', width: '140px' }),
      placeholder: 'Active',
      options: modifiedProductStatus,
      accessor: 'status',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div>
            {
              PRODUCT_STATUS.find((el) => el.value === row.original.status)
                .label
            }
          </div>
        );
      },
    },

    {
      Header: 'Action',
      accessor: 'action',
      disableSortBy: true,
      Cell: ({ row }) => {
        const params = {
          type: row.original?.type,
          id: row.original?._id,
        };
        return (
          <div className="d-flex align-items-center">
            <span
              className="me-2"
              onClick={() => {
                navigate({
                  pathname: '/addproduct',
                  search: `${createSearchParams(params)}`,
                });
              }}
            >
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span className="me-2" onClick={() => toggleDeleteModal(row)}>
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
            <span onClick={() => handleProductView(row)}>
              <img src={eyeIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];

  const handleDelete = () => {
    api
      .deleteProduct(deleteObject._id)
      .then((res) => {
        toastSuccess('Product deleted successfully!!');
        toggleDeleteModal();
        getProductData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProductData(currentPageNo);
  }, [currentPageNo, seeAll, showImported]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const importProducts = (val) => {
    if (impFile && val.value) {
      const formData = new FormData();
      formData.append('importfile', impFile);
      formData.append('type', val.value);
      if (formData) {
        setLoadingData(true);
        services
          .importBulkProducts(formData)
          .then((response) => {
            setLoadingData(false);
            toastSuccess(`${val.label}s imported sucessfully`);
            setImpFile(null);
            setShowImported(true);
          })
          .catch((err) => {
            console.log(`===> :: err`, err);
            setLoadingData(false);
            toastError('Something went wrong, try after some time');
          });
      }
    } else {
      toastError('Please select product type or select a file');
    }
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getProductData = (page) => {
    setLoadingData(true);
    let params = {
      importByFile: showImported,
      perPage: 20,
      page: page ? page : currentPageNo,
      admin: true,
      ...filter,
      type:
        filter.type?.value && filter.type?.value !== 'all'
          ? filter.type?.value
          : null,
      status:
        filter.status?.value && filter.status?.value !== 'all'
          ? filter.type?.status
          : null,
    };
    if (seeAll) {
      params = { showall: true, ...params };
    }

    services
      .getProducts(params)
      .then((response) => {
        setData(response?.data?.data?.docs);
        setTotalPage(response?.data?.data?.totalPages);
        setLoadingData(false);
      })
      .catch((err) => {
        console.log(`===> :: err`, err);
        setLoadingData(false);
      });
  };

  const handleAddProductChange = (value) => {
    navigate(`/addproduct?type=${value.value}`);
  };

  const handleSeeAll = () => {
    setSeeAll((prev) => !prev);
  };

  const handleShowImported = (event) => {
    setShowImported((prev) => !prev);
  };

  const getProductImportTemplateURL = (val) => {
    if (val.value) {
      services
        .getProductImportTemplate(val.value)
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
            }),
          );
          if (url) {
            let today = new Date();
            let dateTime = `${today.getFullYear()}_${
              today.getMonth() + 1
            }_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${val.value}_import_template_${dateTime}.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
        })
        .catch((err) => {
          console.log(`===> :: err`, err);
        });
    }
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top row m-0">
                  <div className=" col-lg-3 col-md-2 fs-16">
                    <h3>Product Management</h3>
                  </div>
                  <div
                    className="col-lg-9 col-md-10 d-flex mt-2"
                    style={{
                      justifyContent: 'end',
                      alignItems: 'flex-start',
                      flexWrap: 'wrap',
                    }}
                  >
                    <div
                    // className="col-md-6 text-end d-flex"
                    // style={{
                    //   justifyContent: 'end',
                    //   alignItems: 'flex-start',
                    // }}
                    >
                      <div
                        className="d-flex"
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          className=" ms-auto d-flex mx-2"
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <CustomDropdown
                            className="fs-16"
                            placeholder="+ Add"
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            options={PRODUCT_TYPES}
                            controlStyles={{
                              backgroundColor: '#088fff',
                              color: 'white',
                              height: '45px',
                            }}
                            showIndicator="none"
                            containerWidth="190px"
                            isClearable={false}
                            controlShouldRenderValue={false}
                            isSearchable={false}
                            placeholderStyles={{
                              textAlign: 'center',
                              fontSize: '18px',
                            }}
                            onChange={handleAddProductChange}
                          />
                        </div>
                        <div className="form-check mx-2">
                          <input
                            className="form-check-input check_box"
                            type="checkbox"
                            id="flexCheckChecked"
                            onChange={handleSeeAll}
                          />

                          <label
                            className="form-check-label fs-16 checkbox_label"
                            htmlFor="flexCheckChecked"
                          >
                            See All
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <CustomDropdown
                        className=""
                        placeholder={
                          <div className="d-flex justify-content-center align-items-center fs-14">
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="mx-2"
                            />
                            Download Template
                          </div>
                        }
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        options={PRODUCT_TYPES}
                        controlStyles={{
                          backgroundColor: '#088fff',
                          color: 'white',
                          height: '45px',
                        }}
                        showIndicator="none"
                        containerWidth="190px"
                        controlShouldRenderValue={false}
                        isClearable={false}
                        isSearchable={false}
                        placeholderStyles={{
                          textAlign: 'center',
                          fontSize: '22px',
                        }}
                        onChange={getProductImportTemplateURL}
                      />
                    </div>
                    <div className="">
                      <label
                        className="custom_file_upload p-2 text-center mx-2 d-flex align-items-center justify-content-center fs-16"
                        style={{
                          borderRadius: '32px',
                          width: '150px',
                          height: '45px',
                        }}
                      >
                        Choose Template
                        <input
                          type="file"
                          style={{ width: '300px', display: 'none' }}
                          accept=".xlsx"
                          onChange={(event) =>
                            setImpFile(event.target.files[0])
                          }
                        />
                      </label>
                      <div
                        className="form-check-label  checkbox_label mt-2 fs-16"
                        style={{ maxWidth: '200px', wordBreak: 'break-all' }}
                      >
                        {impFile?.name}
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CustomDropdown
                        className="p-0"
                        placeholder={
                          <div className="d-flex justify-content-center align-items-center fs-16">
                            <FontAwesomeIcon icon={faUpload} className="mx-2" />
                            Import Template
                          </div>
                        }
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        options={PRODUCT_TYPES}
                        controlStyles={{
                          backgroundColor: '#088fff',
                          color: 'white',
                          height: '45px',
                        }}
                        showIndicator="none"
                        containerWidth="190px"
                        isClearable={false}
                        controlShouldRenderValue={false}
                        isSearchable={false}
                        placeholderStyles={{
                          textAlign: 'center',
                          fontSize: '22px',
                        }}
                        onChange={importProducts}
                      />
                      <div className="form-check">
                        <input
                          className="form-check-input check_box"
                          type="checkbox"
                          id="importProducts"
                          defaultChecked={showImported}
                          onChange={handleShowImported}
                          style={{
                            cursor: 'pointer',
                            border: '1px solid #19191b',
                            paddingRight: '1rem',
                          }}
                        />
                        <label
                          className="form-check-label fs-15 "
                          htmlFor="importProducts"
                          style={{ paddingLeft: '5px' }}
                        >
                          Show Imported
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="middle_main" style={{ paddingTop: '0px' }}>
                  <div className="row">
                    <ReactTable
                      showConditional={true}
                      tableColumns={columns}
                      tableData={data}
                      seeAll={seeAll}
                      totalPage={totalPage}
                      pageNo={currentPageNo}
                      nextHandle={nextHandle}
                      prevHandle={prevHandle}
                      onChangeSort={getFilteredProductData}
                      loading={loadingData}
                      manualFilters={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeleteConfirmationModal && (
        <DeleteConfirmationModal
          name={deleteObject?.name}
          module={'product'}
          show={showDeleteConfirmationModal}
          handleToggle={toggleDeleteModal}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};

export default Products;
