import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import * as Yup from 'yup';
import moment from 'moment';
import ReactTable from './../common/UI/ReactTable';
import Loader from './../common/UI/Loader';
import SearchAndButtons from './../common/UI/Search&Buttons';
import AddPromotionForm from '../components/BrandPromotion/addPromotionForm';
import api from '../api/api';
import { getErrorMessage, getLabelValuePair } from '../utils/helpers';
import { toastSuccess } from '../common/UI/Toasts';
import editIcon from '../assets/images/pencil.svg';
import deleteIcon from '../assets/images/trash.svg';
import DeleteConfirmationModal from '../common/UI/CustomModal/deleteModal';
import { toastError } from './../common/UI/Toasts/index';

const BrandPromotion = () => {
  const columns = [
    {
      Header: 'Brand',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original.brand.name}</span>;
      },
    },
    {
      Header: 'Percentage Discount',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original.percentage.toFixed(2)}%</span>;
      },
    },
    {
      Header: 'Start Date (YYYY-MM-DD)',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return moment(row.original.start_date).format('YYYY-MM-DD');
      },
    },
    {
      Header: 'End Date (YYYY-MM-DD)',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return moment(row.original.end_date).format('YYYY-MM-DD');
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span
              className="me-2"
              style={{ cursor: 'pointer' }}
              onClick={() => handleEdit(row.original)}
            >
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleDelete(row.original)}
            >
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];
  const initialValues = {
    brand: null,
    pdiscount: '',
    start_date: '',
    end_date: '',
  };

  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showBrandForm, setShowBrandForm] = useState(false);
  const [selectedOptionValue, setSelectedOptionValue] = useState([]);
  const [loadInitialValues, setLoadInitialValues] = useState(null);
  const [delModelShow, setDelModelShow] = useState(false);
  const [deletePromotionBrandName, setDeletePromotionBrandName] =
    useState(null);
  const [deletePromotionBrandId, setDeletePromotionBrandId] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllBrand();
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllBrandPromotions();
  }, [currentPageNo, seeAll, showBrandForm, delModelShow]);

  const validationSchema = Yup.object({
    brand: Yup.object()
      .nullable()
      .required(getErrorMessage('select-required', 'Brand')),
    pdiscount: Yup.number()
      .required(getErrorMessage('required', 'Percentage Discount'))
      .min(0, 'Percentage discount must be greater than or equal to 0')
      .max(100, 'Percentage discount must be less than or equal to 100'),
    start_date: Yup.date()
      .nullable()
      .required(getErrorMessage('select-required', 'Start date')),
    end_date: Yup.date()
      .nullable()
      .required(getErrorMessage('select-required', 'End date')),
  });

  const onSubmit = (values, onSubmitProps) => {
    onSubmitProps.setSubmitting(true);
    if (loadInitialValues?.id) {
      const payload = {
        percentage: values?.pdiscount?.toFixed(2),
        brand_id: values?.brand?.value,
        start_date: moment(values?.start_date).format('YYYY-MM-DD'),
        end_date: moment(values?.end_date).format('YYYY-MM-DD'),
      };
      api
        .updateBrandPromotion(loadInitialValues.id, payload)
        .then(() => {
          onSubmitProps.resetForm();
          onSubmitProps.setSubmitting(false);
          handleAddBrandPromotion(false);
          toastSuccess('Brand Promotion Updated Successfully');
        })
        .catch((err) => {
          onSubmitProps.setSubmitting(false);
          console.log('error', err);
          if (err?.message == 'This Promotion already Exist') {
            toastError('This Brand Promotion already Exist');
          }
        });
    } else {
      const payload = {
        percentage: values?.pdiscount?.toFixed(2),
        brand_id: values?.brand?.value,
        start_date: moment(values?.start_date).format('YYYY-MM-DD'),
        end_date: moment(values?.end_date).format('YYYY-MM-DD'),
      };
      api
        .addBrandPromotion(payload)
        .then(() => {
          onSubmitProps.resetForm();
          onSubmitProps.setSubmitting(false);
          handleAddBrandPromotion(false);
          toastSuccess('Brand Promotion Added Successfully');
        })
        .catch((err) => {
          onSubmitProps.setSubmitting(false);
          console.log('error', err);
          if (err?.message == 'This Promotion already Exist') {
            toastError('This Brand Promotion already Exist');
          }
        });
    }
  };

  const handleEdit = (promotionData) => {
    handleAddBrandPromotion(true);
    setLoadInitialValues({
      id: promotionData?._id,
      brand: {
        label: promotionData?.brand?.name,
        value: promotionData?.brand?._id,
      },
      pdiscount: promotionData?.percentage,
      start_date: new Date(promotionData.start_date),
      end_date: new Date(promotionData.end_date),
    });
  };

  const handleAddBrandPromotion = (isShow) => {
    setLoadInitialValues(null);
    setShowBrandForm(isShow);
  };

  const handleDelete = (promotionData) => {
    setDelModelShow(true);
    setDeletePromotionBrandName(promotionData?.name);
    setDeletePromotionBrandId(promotionData?._id);
  };

  const deleteModalOnHide = () => {
    setDelModelShow(false);
    setDeletePromotionBrandId(null);
    setDeletePromotionBrandName(null);
  };

  const deleteModalOnDelete = () => {
    api
      .deleteBrandPromotion(deletePromotionBrandId)
      .then(() => {
        toastSuccess('Brand Promotion Deleted Successfully');
        setDeletePromotionBrandId(null);
        setDeletePromotionBrandName(null);
        setDelModelShow(false);
      })
      .catch((err) => {
        console.log('error', err);
        setDeletePromotionBrandId(null);
        setDeletePromotionBrandName(null);
      });
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getAllBrand = () => {
    const params = { perPage: 20, page: 1 };
    api
      .getAllBrands(params)
      .then((res) => {
        const response = getLabelValuePair(res.data?.data?.docs, 'name', '_id');
        setSelectedOptionValue(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllBrandPromotions = () => {
    const params = seeAll
      ? { showall: true }
      : { perPage: 10, page: currentPageNo };
    api
      .getAllBrandPromotions(params)
      .then((res) => {
        setData(res.data?.data?.docs);
        setTotalPage(res.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const searchBrandPromotion = (searchkey) => {
    if (searchkey) {
      const params = { showall: true, search: searchkey };
      api
        .searchBrandPromotion(params)
        .then((res) => {
          setData(res.data?.data?.docs);
          setTotalPage(res.data?.data?.totalPages);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      getAllBrandPromotions();
    }
  };

  const getAsyncBrands = async (params) => {
    try {
      let brandParams = { perPage: 20, page: 1, ...params };
      const response = await api.getBrandsOnSearch(brandParams);
      let brandData = response?.data?.data;
      return getLabelValuePair(brandData, 'name', '_id');
    } catch (err) {
      console.log(err);
    }
  };

  const getAsyncOptions = (inputValue, fieldName) => {
    if (fieldName === 'brand') {
      return getAsyncBrands({ search: inputValue });
    }
  };

  const loadOptions = (inputValue, callback, linkedOption) => {
    getAsyncOptions(inputValue, linkedOption).then((results) =>
      callback(results),
    );
    return;
  };

  const debouncedLoadOptions = debounce(loadOptions, 1000);

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  return (
    <div className="content_wrapper all_products_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              {showBrandForm ? (
                <>
                  <div className="header_top">
                    <h3>
                      {loadInitialValues && loadInitialValues.id
                        ? 'Edit'
                        : 'Add'}{' '}
                      Brand Promotion
                    </h3>
                  </div>
                  <div className="middle_main">
                    <AddPromotionForm
                      selectedOptionValue={selectedOptionValue}
                      debouncedLoadOptions={debouncedLoadOptions}
                      initialValues={loadInitialValues || initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                      handleAddBrandPromotion={handleAddBrandPromotion}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="header_top">
                    <h3>Brand Promotion</h3>
                  </div>
                  <div className="middle_main">
                    <SearchAndButtons
                      onSearchChange={searchBrandPromotion}
                      onButtonClick={() => handleAddBrandPromotion(true)}
                      searchPlaceholder={'Search by brand...'}
                      buttonName={'Add Promotion'}
                      seeAllHandle={seeAllHandle}
                    />
                    {loading ? (
                      <Loader loading={loading} />
                    ) : (
                      <ReactTable
                        title="Brand Promotion"
                        tableColumns={columns}
                        tableData={data}
                        seeAll={seeAll}
                        nextHandle={nextHandle}
                        prevHandle={prevHandle}
                        totalPage={totalPage}
                        pageNo={currentPageNo}
                      />
                    )}
                  </div>
                  <DeleteConfirmationModal
                    name={deletePromotionBrandName}
                    module={'Brand Promotion'}
                    show={delModelShow}
                    handleToggle={deleteModalOnHide}
                    handleDelete={deleteModalOnDelete}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandPromotion;
