import * as network from './network';

const getAllBrandPromotions = (params) => {
  return network.get('promotion/brand', params, true);
};

const deleteBrandPromotion = (id) => {
  return network.delet(`promotion/brand/${id}`, true);
};

const addBrandPromotion = (body) => {
  return network.post('promotion/brand', body, true);
};

const updateBrandPromotion = (id, body) => {
  return network.put(`promotion/brand/${id}`, body, true);
};

const searchBrandPromotion = (params) => {
  return network.get('promotion/brand/search', params, true);
};

const getAllProductPromotions = (params) => {
  return network.get('promotion/product', params, true);
};

const updateProductPromotion = (body) => {
  return network.put(`promotion/product`, body, true);
};

const searchProductPromotion = (params) => {
  return network.get('promotion/product/search', params, true);
};

export default {
  getAllBrandPromotions,
  deleteBrandPromotion,
  addBrandPromotion,
  updateBrandPromotion,
  searchBrandPromotion,
  getAllProductPromotions,
  updateProductPromotion,
  searchProductPromotion,
};
