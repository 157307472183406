import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

const ViewSubscriptionAnswer = (props) => {
  const orderData = useSelector((state) => state.order.orderData);
  const { id, variantId } = useParams();
  const dispatch = useDispatch();
  const [thisOrderItem, setThisOrderItem] = useState();
  const [instruction, setInstruction] = useState('');
  const [followUpAnswers, setFollowUpAnswers] = useState(props.followUpAnswer);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {});
  return (
    <>
      <div className="middle_main">
        <div
          className="mt-2"
          style={{
            backgroundColor: '#ffffff',
            padding: '4px',
            borderRadius: '4px',
          }}
        >
          {followUpAnswers?.map((el, index) => {
            return (
              <div
                key={el?._id}
                className="mb-2"
                style={{
                  padding: '7px',
                  backgroundColor: '#e9ecefa3',
                  borderRadius: '4px',
                  color: el?.hasMarkedRestricted ? 'red' : 'black',
                }}
              >
                <div
                  className="d-flex fs-16"
                  style={{
                    borderRadius: '4px',
                    // padding: '0.18rem',
                    background: '#ffffff',
                    justifyContent: 'space-between',
                    fontSize: '20px',
                    fontWeight: '500',
                    borderBottom: '1px solid #ccc',
                  }}
                >
                  <div className="px-2 d-flex">
                    <span>Q)</span>{' '}
                    {el?.isHTML ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: el?.question,
                        }}
                        className="ms-2"
                      />
                    ) : (
                      <span className="ms-2">{el?.question}</span>
                    )}
                  </div>
                </div>
                <div className="ms-3">
                  {el.questionType.value === 'file' ? (
                    <img src={el.answer.location} />
                  ) : el.questionType.value === 'date' ? (
                    `${moment(el?.answer).format('DD/MM/yyyy')}`
                  ) : (
                    el?.answer
                  )}
                </div>
                {/* Sub Questions */}
                {el?.subQuestions &&
                  el?.subQuestions
                    ?.filter((elem) => {
                      if (el?.questionType?.value === 'boolean') {
                        return elem?.selectedAnswer?.value === el?.answer;
                      } else if (el?.questionType?.value === 'select') {
                        return (
                          el?.answer?.length &&
                          el?.answer.includes(elem.selectedAnswer?.value)
                        );
                      }
                    })
                    .map((el, index) => {
                      return (
                        <div
                          key={index}
                          className="ms-5"
                          style={{
                            padding: '5px',
                            color: el?.hasMarkedRestricted ? 'red' : 'black',
                            // backgroundColor: '#ffffff',
                            borderRadius: '4px',
                          }}
                        >
                          <div
                            className="d-flex fs-16"
                            style={{
                              borderRadius: '4px',
                              // padding: '0.18rem',
                              background: '#ffffff',
                              justifyContent: 'space-between',
                              fontSize: '20px',
                              fontWeight: '500',
                              borderBottom: '1px solid #ccc',
                            }}
                          >
                            <div className="px-2 d-flex">
                              <span>Q)</span>
                              {'    '}
                              {el?.isHTML ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: el?.question,
                                  }}
                                  className="ms-2"
                                />
                              ) : (
                                <span className="ms-2">{el?.question}</span>
                              )}
                            </div>
                          </div>
                          <div className="ms-3">
                            {el.questionType.value === 'file' ? (
                              <img src={el?.answer?.location} />
                            ) : el.questionType.value === 'date' ? (
                              `${moment(el?.answer).format('DD/MM/yyyy')}`
                            ) : (
                              el?.answer
                            )}
                          </div>
                        </div>
                      );
                    })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ViewSubscriptionAnswer;
