import React, { useEffect, useState } from 'react';
import AddAndEditModal from '../../../common/UI/CustomModal/addAndEditModal';
import { regex } from '../../../utils/helpers';

const GPEmailModal = ({ show, toggle, email }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [errMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (email !== emailAddress) {
      setEmailAddress(email);
    }
  }, [email]);

  return (
    <AddAndEditModal
      show={show}
      handleToggle={() => toggle()}
      handleAction={() => toggle({ email: emailAddress })}
      action={'Add'}
      module={'GP Email'}
      disabled={errMsg || !emailAddress}
    >
      <label className="form-check-label mb-2 fs-6" htmlFor="email">
        Email<span className="text-danger">*</span>
      </label>
      <input
        className="form-control shadow-none border"
        id="email"
        type="email"
        onChange={(e) => {
          setEmailAddress(e.target.value);
          if (errMsg) setErrorMsg('');
        }}
        onBlur={(e) => {
          const isValid = regex('email').test(e.target.value);
          if (!isValid) {
            setErrorMsg('Please enter valid email.');
          }
        }}
        value={emailAddress}
      />
      {errMsg ? <span className="text-danger fs-13 mx-2">{errMsg}</span> : null}
    </AddAndEditModal>
  );
};

export default GPEmailModal;
