import React, { useState } from 'react';
import RelatedKeyword from '../components/RelatedKeyword';

const relatedKeywordManagement = () => {
  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top mb-3">
                  <h3>Keyword Management</h3>
                </div>
                <div className="middle_main">
                  <RelatedKeyword />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default relatedKeywordManagement;
