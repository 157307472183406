import React, { useState, useEffect } from 'react';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import CustomDropdown from '../common/UI/Dropdown';
import services from '../api/api';
import eyeIcon from '../assets/images/eye.svg';
import deleteIcon from '../assets/images/trash.svg';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import ReactSelect from '../common/FormComponents/ReactSelect';
import { isUserAdmin, isUserDoctor, isUserPharmacist } from '../utils/helpers';
import editIcon from '../assets/images/pencil.svg';

const OldOrdersMeetingList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [createForm, setCreateForm] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem('userId');
  const [csvData, setCsvData] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [clinicianCall, setClinicianCall] = useState(null);
  const [clinicians, setClinicians] = useState(null);
  const [meetOrders, setMeetOrders] = useState(null);
  const [meetOrder, setMeetOrder] = useState(null);
  const [date, setDate] = useState(new Date());
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);

  const dateFormat = (date) => {
    var options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  };

  const handleEdit = (row) => {
    let editInformation = {
      _id: row.original?._id,
      order_id: row?.original?.order_id,
      start_date: row?.original?.start_date,
    };
    console.log(editInformation, 'ifoooo');
    setCreateForm(true);
  };

  const admin_columns = [
    {
      Header: 'Meeting Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.start_date.substring(0, 10)}</span>;
      },
    },
    {
      Header: 'Meeting Time',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.start_date.substring(11, 16)}</span>;
      },
    },
    {
      Header: 'Meeting url',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            onClick={() => {
              window.open(`${row.original?.meet_url}`, '_blank');
            }}
            style={{ cursor: 'pointer' }}
            className="join_meeting_btn"
          >
            Join Now
          </span>
        );
      },
    },
    {
      Header: 'User Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.user_name}</span>;
      },
    },

    {
      Header: 'Assigned Clinician',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        if (row.original.clinician_name) {
          return <span>{row.original?.clinician_name}</span>;
        } else {
          return (
            <CustomDropdown
              className=""
              options={
                clinicians &&
                clinicians
                  .filter((el) => el.role === 'doctor' && el.status)
                  .map((el) => {
                    return {
                      label: `Dr. ${el?.first_name} ${el?.last_name}`,
                      value: {
                        clinician_id: el?._id,
                        clinician_name: `${el?.first_name} ${el?.last_name}`,
                      },
                    };
                  })
              }
              height={'40px'}
              placeholder={'Select'}
              placeholderColor={'#9aa5ad'}
              border={'1 px solid #9aa5ad'}
              containerWidth={'175px'}
              onChange={(value) => {
                assignClinician({
                  id: row.original?._id,
                  value,
                });
              }}
              value={clinicianCall}
            />
          );
        }
      },
    },
    {
      Header: 'Meeting Status',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        if (row.original.status != '') {
          return <span>{row.original.status}</span>;
        } else {
          return (
            <CustomDropdown
              className=""
              options={[
                { label: 'Completed', value: 'Joined' },
                { label: 'Not Joined', value: 'Not Joined' },
                { label: 'Cancelled', value: 'Cancelled' },
              ]}
              height={'40px'}
              placeholder={'Select'}
              placeholderColor={'#9aa5ad'}
              border={'1 px solid #9aa5ad'}
              containerWidth={'175px'}
              onChange={(value) => {
                assignMeetingStatus({
                  id: row.original?._id,
                  value,
                });
              }}
              value={clinicianCall}
            />
          );
        }
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <>
            <span
              onClick={() => {
                window.open(`/vieworder/${row.original.order_id}`, '_blank');
              }}
              style={{ cursor: 'pointer' }}
            >
              <img src={eyeIcon} alt="view" width="24" height="24" />
            </span>
            <span
              onClick={() => cancelMeeting(row)}
              style={{ cursor: 'pointer' }}
            >
              <img src={deleteIcon} alt="view" width="24" height="24" />
            </span>
          </>
        );
      },
    },
  ];

  const clinician_columns = [
    {
      Header: 'Meeting Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.start_date.substring(0, 10)}</span>;
      },
    },
    {
      Header: 'Meeting Time',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.start_date.substring(11, 16)}</span>;
      },
    },
    {
      Header: 'Meeting url',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            onClick={() => {
              window.open(`${row.original?.meet_url}`, '_blank');
            }}
            style={{ cursor: 'pointer' }}
            className="join_meeting_btn"
          >
            Join Now
          </span>
        );
      },
    },
    {
      Header: 'User Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.user_name}</span>;
      },
    },
    {
      Header: 'Meeting Status',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        if (row.original.status != '') {
          return <span>{row.original.status}</span>;
        } else {
          return (
            <CustomDropdown
              className=""
              options={[
                { label: 'Completed', value: 'Joined' },
                { label: 'Not Joined', value: 'Not Joined' },
                { label: 'Cancelled', value: 'Cancelled' },
              ]}
              height={'40px'}
              placeholder={'Select'}
              placeholderColor={'#9aa5ad'}
              border={'1 px solid #9aa5ad'}
              containerWidth={'175px'}
              onChange={(value) => {
                assignMeetingStatus({
                  id: row.original?._id,
                  value,
                });
              }}
              value={clinicianCall}
            />
          );
        }
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <>
            <span
              onClick={() => {
                window.open(`/vieworder/${row.original.order_id}`, '_blank');
              }}
              style={{ cursor: 'pointer' }}
            >
              <img src={eyeIcon} alt="view" width="24" height="24" />
            </span>
            <span
              onClick={() => cancelMeeting(row)}
              style={{ cursor: 'pointer' }}
            >
              <img src={deleteIcon} alt="view" width="24" height="24" />
            </span>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    services
      .getAllOldMeetings()
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });

    if (isUserAdmin()) {
      getCliniciansData();
      getOrdersData();
    }
  }, []);

  const getCliniciansData = () => {
    api
      .getUsers({ showall: true, role: 'pharmacist,doctor,admin' })
      .then((res) => {
        setClinicians(res?.data?.data?.docs);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getOrdersData = () => {
    api
      .getMeetOrders()
      .then((res) => {
        setMeetOrders(res.data.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const assignClinician = (clinicianData) => {
    api
      .assignClinicianMeet(
        {
          clinician_id: clinicianData.value.value.clinician_id,
          clinician_name: clinicianData.value.value.clinician_name,
        },
        clinicianData.id,
      )
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const assignMeetingStatus = (statusData) => {
    api
      .assignClinicianMeet({ status: statusData.value.value }, statusData.id)
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const cancelMeeting = (row) => {
    api
      .assignClinicianMeet({ status: 'Cancelled' }, row.original?._id)
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const selectDate = (date) => {
    setDate(date);
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    services
      .getMeetingSlots([year, month, day].join('-'))
      .then((res) => {
        console.log(res.data.data, 'dataaaaaa');
        setAvailableSlots(res.data.data);
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const scheduleCallApi = () => {
    setButtonClicked(true);
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    const payload = {
      order_id: meetOrder.value,
      meetDateWithTime: selectedSlot,
      user_id: meetOrder.user_id,
    };
    services
      .scheduleMeet(payload)
      .then((res) => {
        if (res.data.message == 'Meet Added') {
          navigate(0);
        }
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="row">
          <div className="col-md-12">
            {' '}
            <div className="container-fluid">
              {createForm ? (
                <>
                  <ol className="breadcrumb">
                    <li>
                      <Link
                        to={'/orders-meeting-list'}
                        title="Completed Meetings List"
                        onClick={() => setCreateForm(false)}
                      >
                        Completed Meetings List
                      </Link>
                    </li>

                    <li>Create a meeting</li>
                    {name === 'viewBanner' && <li>View Details</li>}
                  </ol>
                  <div className="box_main">
                    <div
                      className="header_top"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <h3>Create a Meeting</h3>
                    </div>
                    <div className="col-xl-4 col-md-6 col-lg-6 form-group mt-3">
                      <h6 className="available-text">Select Order</h6>
                      <CustomDropdown
                        className=""
                        options={
                          meetOrders &&
                          meetOrders.map((el) => {
                            return {
                              label: `${el.order_number}`,
                              value: el._id,
                              username: `${el.username}`,
                              user_id: el.user_id,
                            };
                          })
                        }
                        height={'40px'}
                        placeholder={'Select'}
                        placeholderColor={'#9aa5ad'}
                        border={'1 px solid #9aa5ad'}
                        containerWidth={'175px'}
                        onChange={(value) => {
                          setMeetOrder(value);
                        }}
                        value={meetOrder}
                      />
                      {meetOrder ? (
                        <div className="col-xl-12 col-md-6 col-lg-6 form-group mt-3">
                          <p>User Name: {meetOrder.username}</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <h6 className="available-text">Select Date</h6>
                    <ReactDatePicker
                      onChange={(value) => {
                        selectDate(value);
                      }}
                      selected={date}
                      style={{ zIndex: 99 }}
                      className="form-control form-input px-4 date-custom width-100"
                    />
                    <h6 className="available-text">
                      Available slots on {dateFormat(date)}
                    </h6>
                    <div className="slot-section">
                      {availableSlots.map((slot, ind) => {
                        return (
                          <>
                            <div
                              onClick={() => {
                                setSelectedSlot(slot);
                              }}
                              className={`slot-box ${
                                selectedSlot == slot ? 'selectedSlot' : ''
                              }`}
                              key={`${ind}`}
                            >
                              <p>{slot.substring(11, 16)}</p>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div>
                      <Button
                        className={` ${
                          buttonClicked ? 'submitDisabled' : ''
                        } slot-submit`}
                        onClick={() => {
                          scheduleCallApi();
                        }}
                      >
                        {buttonClicked ? 'Loading...' : 'Submit'}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="box_main">
                  <div
                    className="header_top"
                    style={{ justifyContent: 'space-between' }}
                  >
                    <h3> Meetings List</h3>
                    {isUserAdmin() && (
                      <Button onClick={() => setCreateForm(true)}>
                        Create a meeting
                      </Button>
                    )}
                  </div>
                  <div className="middle_main">
                    {loading ? (
                      <Loader loading={loading} />
                    ) : (
                      <ReactTable
                        title="Subscription Orders"
                        tableColumns={
                          isUserAdmin() ? admin_columns : clinician_columns
                        }
                        tableData={data}
                        seeAll={seeAll}
                        totalPage={totalPage}
                        pageNo={currentPageNo}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OldOrdersMeetingList;
