import React from 'react';
import BlogListing from '../components/BlogManagement/BlogListing';

const BlogManagement = () => {
  return (
    <>
      <BlogListing />
    </>
  );
};

export default BlogManagement;
