import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import api from '../api/api';
import { PRODUCT_TYPES } from '../common/constants';
import editIcon from '../assets/images/pencil.svg';

const ViewProduct = () => {
  const { id } = useParams();
  const [productData, setProductData] = useState({});
  const [mainPhoto, setMainPhoto] = useState();
  useEffect(() => {
    if (id) {
      api
        .getProductById(id)
        .then((res) => {
          setProductData(res.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  useEffect(() => {
    if (productData?.photos?.length) {
      setMainPhoto(productData.photos[0]);
    }
  }, [productData]);
  const toggleMainPhoto = (photo) => {
    setMainPhoto(photo);
  };
  return (
    <div className="content_wrapper product_detials_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ol className="breadcrumb">
              <li>
                <Link title="Product Management" to="/product">
                  Product Management
                </Link>
              </li>
              <li>View Product Details</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              <div className="header_top">
                <h3>View Product Details</h3>
                <button type="button" className="btn btn-green  ms-auto">
                  <Link
                    to={`/addproduct?type=${productData.type}&id=${productData._id}`}
                    title="Edit Product"
                    className="d-flex align-items-center text-white"
                  >
                    {' '}
                    <img
                      src={editIcon}
                      width="24"
                      height="24"
                      alt="pencil"
                      className="me-2"
                    />
                    Edit Details
                  </Link>
                </button>
              </div>
            </div>
            <div className="middle_main">
              <div className="row">
                <div className="col-md-8 col-lg-8 col-xxl-4 col-xl-5 pe-md-5 line_cusotom">
                  <div>
                    <img
                      src={mainPhoto?.location}
                      alt={productData.name}
                      width={'450px'}
                      height={'200px'}
                    />
                  </div>
                  <div className="d-flex flex-wrap carousel-indicators custom_indicators">
                    {productData?.photos?.map((el) => {
                      return (
                        <div
                          style={{
                            border: `${
                              mainPhoto?.key === el?.key
                                ? '2px solid black'
                                : 'none'
                            }`,
                            cursor: 'pointer',
                          }}
                          onClick={() => toggleMainPhoto(el)}
                          key={el?.key}
                        >
                          <img
                            src={el?.location}
                            width={'80px'}
                            height={'80px'}
                            alt={productData?.name}
                            className="img-fluid m-1"
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-4">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>
                            <p className="des_2">Type</p>
                          </td>
                          <td>
                            <p className="des_4">
                              {PRODUCT_TYPES.find(
                                (el) => el.value === productData?.type,
                              )?.label || null}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="des_2">Category</p>
                          </td>
                          <td>
                            <p className="des_3">
                              {productData?.sub_categories?.length
                                ? productData?.sub_categories?.reduce(
                                    (prev, curr) => {
                                      if (!prev) return curr?.category?.name;
                                      else {
                                        if (
                                          prev
                                            .split(',')
                                            .includes(curr?.category?.name)
                                        ) {
                                          return prev;
                                        }
                                        return (
                                          prev + `, ${curr?.category?.name}`
                                        );
                                      }
                                    },
                                    '',
                                  )
                                : null}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="des_2">Sub-Category</p>
                          </td>
                          <td>
                            <p className="des_3">
                              {productData?.sub_categories?.length
                                ? productData?.sub_categories?.reduce(
                                    (prev, curr) => {
                                      if (!prev) return curr?.name;
                                      else {
                                        return prev + `, ${curr?.name}`;
                                      }
                                    },
                                    '',
                                  )
                                : null}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="des_2">Health Goals</p>
                          </td>
                          <td>
                            <p className="des_3">
                              {productData?.health_goal !== 'null'
                                ? productData?.health_goal?.title
                                : ''}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="des_2">Barcode</p>
                          </td>
                          <td>
                            <p className="des_3">
                              {productData?.barcode || null}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="des_2">Brand</p>
                          </td>
                          <td>
                            <p className="des_3">
                              {productData?.brand?.name || null}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="des_2">VAT Rate</p>
                          </td>
                          <td>
                            <p className="des_3">
                              {productData?.vat_rate || 0}%
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="des_2">SKU</p>
                          </td>
                          <td>
                            <p className="des_3">{productData?.sku || null}</p>
                          </td>
                        </tr>{' '}
                        <tr>
                          <td>
                            <p className="des_2">Stock Level</p>
                          </td>
                          <td>
                            <p className="des_3">
                              {productData?.stock?.map((stock) =>
                                stock.isPrimary ? stock.stock_level : '',
                              )}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xxl-8 col-xl-7">
                  <h3 className="des_title">{productData?.name || ''}</h3>
                  <div
                    className="des_1"
                    dangerouslySetInnerHTML={{
                      __html: productData.description,
                    }}
                  />
                  {/* {productData.type === 'medication' && (
                    <>
                      <h4 className="des_title_2">About</h4>
                      <p className="des_1">{productData?.about}</p>
                      <h4 className="des_title_2">How does it works?</h4>
                      <p className="des_1">{productData?.how_works}</p>
                    </>
                  )} */}
                  <h4 className="des_title_2">Related Keywords</h4>
                  {productData?.related_keyword?.length ? (
                    <div>
                      {productData?.related_keyword?.map((el) => (
                        <button
                          key={el?.id}
                          type="button"
                          className="btn btn-sky my-2 me-2"
                        >
                          {el?.name}
                        </button>
                      ))}
                    </div>
                  ) : (
                    '-'
                  )}{' '}
                  <hr />
                  {productData.type === 'product' && (
                    <>
                      <h4 className="des_title_2">Ingredients</h4>
                      {productData?.ingrediant?.length && (
                        <div>
                          {productData?.ingrediant?.map((el) => (
                            <button
                              key={el}
                              type="button"
                              className="btn btn-sky my-2 me-2"
                            >
                              {el}
                            </button>
                          ))}
                        </div>
                      )}
                      <hr />
                    </>
                  )}
                  <h4 className="des_title_2">Recommended Supplements</h4>
                  <p className="des_1">
                    {productData?.rec_supp_description || '-'}
                  </p>
                  <div className="col-md-8">
                    {productData?.recommended_product?.map((el, index) => {
                      return (
                        <div key={el?._id}>
                          <p className="des_4">{`${index + 1}.) ${
                            el?.name
                          }`}</p>
                        </div>
                      );
                    })}
                  </div>
                  <hr className="my-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
