import React, { useState } from 'react';
import { Formik, Form, FieldArray, Field, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import closexIcon from '../../../assets/images/close-x.svg';
import Input from '../../../common/FormComponents/Input';
import ReactSelect from '../../../common/FormComponents/ReactSelect';
import ImageInput from '../../../common/FormComponents/ImageInput';
import CustomFieldArray from '../../../common/FormComponents/CustomFieldArray';
import DeleteConfirmationModal from '../../../common/UI/CustomModal/deleteModal';
import CustomModal from '../../../common/UI/CustomModal';
import ImageProductPreviewModal from './ImageProductPreviewModal';
import CustomDropdown from '../../../common/UI/Dropdown';
import AsyncCreatableSelect from 'react-select/async-creatable';
import makeAnimated from 'react-select/animated';
import CustomAsyncCreatableReactSelect from '../../../common/FormComponents/AsyncCreatableReactSelect';
import { useEffect } from 'react';
import api from '../../../api/api';
import {
  faMinusCircle,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileInput from '../../../common/FormComponents/FileInput';
import { Button } from 'react-bootstrap';
import {
  DIMENSION,
  VARIANT_ATTRIBUTE_OPTIONS,
} from '../../../common/constants';
import TextEditor from '../../../common/TextEditor';
import TextError from '../../../common/FormComponents/TextError';
import { toastError, toastSuccess } from '../../../common/UI/Toasts';
import { getLabelValuePair } from '../../../utils/helpers';
import CustomReactSelect from '../../../common/FormComponents/ReactSelect';

const ProductDetails = (props) => {
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const [attributeValuesOptions, setAttributeValuesOptions] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  // useEffect(() => {
  //   // setting attributeValuesOptions state to prefill the values
  //   let attrValuesOptions = {};
  //   props.initialValues.variantAttributes.forEach((el) => {
  //     let options = props.variant_list[el?.value].values.map((elem) => {
  //       return {
  //         label: elem,
  //         value: elem,
  //       };
  //     });
  //     attrValuesOptions[el?.label] = {
  //       value: el?.value,
  //       options: options,
  //     };
  //   });
  //   setAttributeValuesOptions(attrValuesOptions);
  // }, []);

  // useEffect(() => {
  //   console.log('attribute', attributeValuesOptions);
  // }, [attributeValuesOptions]);

  // const deleteProductVariant = (id) => {
  //   api
  //     .deleteVariant(id)
  //     .then((res) => {
  //       toastSuccess('Deleted Product Variant Successfully');
  //     })
  //     .catch((err) => {
  //       console.log('Error While Deleting Variant', err);
  //     });
  // };

  // const onChangeOfAttributesDropdown = () => {};

  // const getOptionsListForAttribute = (attribute) => {
  //   let options = props.initialValues.variant_list[attribute].map((el) => {
  //     return {
  //       value: el,
  //       label: el,
  //     };
  //   });
  //   return options;
  // };

  const validateVariantList = (value, name) => {
    // attr options
    let error;
    if (!value?.length) {
      error = `One ${name} Attribute Value is Required`;
    }
    return error;
  };

  const validateVariantComboAttributes = (value, name) => {
    console.log(
      '🚀 ~ file: index.jsx:96 ~ validateVariantComboAttributes ~ value:',
      value,
      name,
    );
    //variant combo table options of variants only
    let error;
    if (!value) error = `Please select attribute value`;
    return error;
  };
  const validateVariantAttributes = (value, name) => {
    // define attr
    let error;
    if (!value.length) error = `Please select attribute`;
    return error;
  };

  const handleStrengthsDelete = (setFieldValue, values) => {
    setFieldValue('has_variant', false);
    setFieldValue('deletetable_variant', [
      ...values.strengths.map((s) => s._id).filter((s) => s),
    ]);
    setFieldValue('variantAttributes', []);
    setFieldValue('variant_list', {});
    setFieldValue('strengths', [
      {
        rrp_price: '',
        trade_price: '',
        margin: '',
        barcode: '',
        stock_level: '',
        sku: '',
        isPrimary: true,
        attributes: null,
        subscription_price: [],
      },
    ]);
    setIsModalOpen(false);
  };
  return (
    <>
      <Formik
        initialValues={props.initialValues}
        onSubmit={(values) => {
          props.onSubmit(values, false);
        }}
        validateOnChange={false}
        validationSchema={props.validationSchema}
      >
        {(formik) => {
          const { touched, errors, values, setFieldValue, setFieldError } =
            formik;

          const calculateMargin = (tradePrice, RRRPrice, vatRate, index) => {
            vatRate = vatRate?.value;
            if (tradePrice && RRRPrice && (vatRate || vatRate == '0')) {
              const calculatedMargin =
                (RRRPrice / (1 + vatRate / 100) - tradePrice) /
                (RRRPrice / (1 + vatRate / 100));

              // if (calculatedMargin) {
              // NOTE: commented bcoz when margin is 0, its value isn't getting changed
              formik.setFieldValue(
                `strengths[${index}].margin`,
                parseFloat(calculatedMargin).toFixed(2),
                false,
              );
              props.handleVatRateChanged(false);
              // }
            }
          };
          const handleVatRateChange = (event) => {
            for (let i = 0; i < formik.values.strengths.length; i++) {
              const currSize = formik.values.strengths[i];
              calculateMargin(
                currSize.trade_price,
                currSize.rrp_price,
                event,
                i,
              );
            }
          };
          return (
            <Form>
              <div className="middle_main_2">
                <div className="row">
                  <div className="col-md-12 d-flex align-items-center">
                    <h4 className="middle_title">Basic Details - </h4>
                    <h3 className="middle_title text-uppercase">
                      {' '}
                      {` ${formik.values.type}`}
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                    <Input
                      label="Product Name"
                      labelClassname="form-label"
                      name="name"
                      type="text"
                      className="form-control form-input"
                      placeholder="Product Name"
                      isRequired={true}
                    />
                  </div>
                  <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                    <Input
                      label="Show Product Name"
                      labelClassname="form-label"
                      name="showProductName"
                      type="text"
                      className="form-control form-input"
                      placeholder="Show Product Name"
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      {props.productType === 'medication' ? (
                        <div className="col-md-6 col-lg-6 col-xl-6">
                          <Input
                            style={{
                              marginTop: '9px',
                              width: '17px',
                              height: '17px',
                            }}
                            type="checkbox"
                            labelClassname="form-label"
                            name="isFridgeItem"
                            label="Fridge Item"
                            className="mx-2 form-input-check"
                          />
                        </div>
                      ) : null}
                      <div className="col-md-6 col-lg-6 col-xl-6">
                        <Input
                          style={{
                            marginTop: '9px',
                            width: '17px',
                            height: '17px',
                          }}
                          className="mx-2 form-input-check"
                          labelClassname="form-label"
                          label="Product Active"
                          type="checkbox"
                          name="status"
                        />
                      </div>
                      {props.productType === 'medication' ? (
                        <div className="col-md-6 col-lg-6 col-xl-6">
                          <Field
                            style={{
                              marginTop: '9px',
                              width: '17px',
                              height: '17px',
                            }}
                            id={'is_subscribable'}
                            className="mx-2 form-input-check"
                            type="checkbox"
                            name="is_subscribable"
                            onChange={(e) => {
                              setFieldValue(
                                'is_subscribable',
                                e.target.checked,
                              );
                              props.handleChange(
                                'is_subscribable',
                                e.target.checked,
                              );
                            }}
                          />
                          <label
                            htmlFor={'is_subscribable'}
                            className={'form-label'}
                          >
                            isSubscribable
                          </label>
                        </div>
                      ) : null}
                      {props.productType === 'medication' &&
                      values?.is_subscribable ? (
                        <div className="col-md-6 col-lg-6 col-xl-6">
                          <Field
                            style={{
                              marginTop: '9px',
                              width: '17px',
                              height: '17px',
                            }}
                            id={'only_subscribable'}
                            className="mx-2 form-input-check"
                            type="checkbox"
                            name="only_subscribable"
                            onChange={(e) => {
                              setFieldValue(
                                'only_subscribable',
                                e.target.checked,
                              );
                              props.handleChange(
                                'only_subscribable',
                                e.target.checked,
                              );
                            }}
                          />
                          <label
                            htmlFor={'only_subscribable'}
                            className={'form-label'}
                          >
                            Only Subscribable
                          </label>
                        </div>
                      ) : null}
                      {props.productType === 'medication' ? (
                        <div className="col-md-6 col-lg-6 col-xl-6">
                          <Field
                            style={{
                              marginTop: '9px',
                              width: '17px',
                              height: '17px',
                            }}
                            id={'has_landingpage'}
                            className="mx-2 form-input-check"
                            type="checkbox"
                            name="has_landingpage"
                            disabled={!props.productId}
                            onChange={(e) => {
                              setFieldValue(
                                'has_landingpage',
                                e.target.checked,
                              );
                              props.handleChange(
                                'has_landingpage',
                                e.target.checked,
                              );
                            }}
                          />
                          <label
                            htmlFor={'has_landingpage'}
                            className={'form-label'}
                          >
                            Google Landing Page
                          </label>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                    <CustomAsyncCreatableReactSelect
                      height={'45px'}
                      label="Brand"
                      labelClassname="form-label"
                      cacheOptions
                      name="brand"
                      placeholder={'Select Brand'}
                      loadOptions={(inputValue, callback) =>
                        props.debouncedLoadOptions(
                          inputValue,
                          callback,
                          'brand',
                        )
                      }
                      defaultOptions={props.brandOptions}
                      options={props.brandOptions}
                      isSearchable={true}
                      isClearable={false}
                      closeMenuOnSelect={true}
                      onChange={(e) => props.handleChange('brand', e)}
                      isRequired={true}
                    />
                  </div>
                  <div className="col-xl-4 col-md-6 col-lg-6 form-group ">
                    <div className="input-group">
                      <ReactSelect
                        onChangeAction={handleVatRateChange}
                        label="VAT Rate"
                        labelClassname="form-label"
                        name="vat_rate"
                        className="form-select"
                        options={props.vatRateOptions}
                        height={'45px'}
                        containerWidth="100%"
                        wrappedStyles={{ width: '100%' }}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                    <Input
                      label="SKU"
                      labelClassname="form-label"
                      name="sku"
                      type="text"
                      className="form-control form-input"
                      placeholder="Enter SKU"
                      isRequired={true}
                    />
                  </div>
                  <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                    <ReactSelect
                      isClearable={true}
                      label="Health Goal"
                      labelClassname="form-label"
                      name="health_goal"
                      placeholder="Select Health Goal"
                      className="form-select"
                      options={props.health_goalOptions}
                      height={'45px'}
                    />
                  </div>
                  <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                    <ReactSelect
                      isMulti={props.productType === 'product' ? true : false}
                      label="Category"
                      labelClassname="form-label"
                      name="category"
                      placeholder="Select Category"
                      className="form-select"
                      options={props.categoryOptions}
                      controlHeight={'45px'}
                      onChangeAction={(value) => {
                        if (props.productType === 'product') {
                          const categoryIds = value.reduce(
                            (prev, curr) =>
                              prev ? prev + ',' + curr.value : curr.value,
                            '',
                          );
                          if (formik.values.sub_category) {
                            let subCategoryValues =
                              formik.values.sub_category.reduce(
                                (prev, curr) => {
                                  if (categoryIds.includes(curr?.categoryId)) {
                                    prev.push(curr);
                                  }
                                  return prev;
                                },
                                [],
                              );
                            formik.setFieldValue(
                              'sub_category',
                              subCategoryValues,
                            );
                          }
                          props.getProductSubCategory(categoryIds);
                        } else {
                          formik.values.sub_category = null;
                          props.getProductSubCategory(
                            value?.value,
                            props.productType,
                          );
                        }
                      }}
                      isRequired={true}
                    />
                  </div>
                  <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                    <ReactSelect
                      isMulti={props.productType === 'product' ? true : false}
                      label="Sub Category"
                      labelClassname="form-label"
                      name="sub_category"
                      placeholder="Select Sub-Category "
                      className="form-select"
                      options={props.subCategoryOptions}
                      controlHeight={'45px'}
                      isRequired={true}
                    />
                  </div>

                  <div className="col-12 mt-1 mb-2">
                    <hr className="mt-1" />
                  </div>
                </div>

                {/* Variant Section */}
                <div className="row">
                  <div className="col-12 d-flex justify-content-between">
                    <div className="d-flex justify-content-between w-50">
                      <h4 className="middle_title w-50">Variants</h4>
                      <div className="w-50">
                        <Field
                          type="checkbox"
                          name="has_variant"
                          onChange={(e) => {
                            if (formik.values.has_variant) {
                              setIsModalOpen(true);
                            } else {
                              // delete default variant
                              formik.setFieldValue('deletetable_variant', [
                                ...formik.values.strengths
                                  .map((s) => s._id)
                                  .filter((s) => s),
                              ]);
                              setFieldValue('has_variant', e.target.checked);
                              setFieldValue('strengths', [
                                {
                                  rrp_price: '',
                                  trade_price: '',
                                  margin: '',
                                  barcode: '',
                                  stock_level: '',
                                  sku: '',
                                  isPrimary: true,
                                  attributes: null,
                                  subscription_price: [],
                                },
                              ]);
                            }
                          }}
                        />
                        <label className="mx-2 form-label">Has Variant</label>
                      </div>
                    </div>
                    <div className="fs-15">
                      Note: Check one of the variants to make it primary
                    </div>
                  </div>
                  {formik.values.has_variant ? (
                    <div className="row">
                      <div className="col-12 form-group d-flex align-items-center">
                        <label className="form-label mt-3">
                          Define Attributes :{' '}
                        </label>

                        <CustomReactSelect
                          validate={validateVariantAttributes}
                          placeholder={'Select Attributes...'}
                          className="ms-3"
                          name="variantAttributes"
                          containerWidth="500px"
                          options={VARIANT_ATTRIBUTE_OPTIONS}
                          onChange={(value, action) => {
                            setFieldValue('variantAttributes', value);
                            // adding attributes to variant_list object
                            value.forEach((el, index) => {
                              let tempObj = {
                                ...values.variant_list,
                                [el?.value]: {
                                  values: [],
                                },
                              };

                              setFieldValue('variant_list', tempObj);
                            });

                            // removing attributes options key value from state
                            if (action.action === 'remove-value') {
                              let tempObj = { ...values.variant_list };
                              delete tempObj[action.removedValue.label];
                              setFieldValue('variant_list', tempObj);

                              // Removing Attribute key if it is not included in variant_list object

                              let tempStrengths = [...values.strengths];
                              tempStrengths.forEach((strgth) => {
                                delete strgth?.attributes[
                                  action.removedValue.label
                                ];
                              });
                              setFieldValue('strengths', tempStrengths);
                            }
                            if (action.action === 'clear') {
                              // setAttributeValuesOptions({});
                              setFieldValue('variant_list', {});
                              setFieldValue('strengths', [
                                {
                                  rrp_price: '',
                                  trade_price: '',
                                  margin: '',
                                  barcode: '',
                                  stock_level: '',
                                  sku: '',
                                  isPrimary: true,
                                  attributes: null,
                                  subscription_price: [],
                                },
                              ]);
                            }
                          }}
                          isClearable={true}
                          border={'1 px solid #9aa5ad'}
                          isMulti={true}
                        />
                      </div>
                      <div className="col-12">
                        <div className="form-label mt-4">
                          Attribute Options :{' '}
                        </div>
                        <div className="row ms-3">
                          {formik.values.variantAttributes.map((el, index) => {
                            return (
                              <div className="col-4 mt-2" key={index}>
                                <CustomAsyncCreatableReactSelect
                                  selectType={'creatable'}
                                  height={''}
                                  isMulti={true}
                                  controlHeight={'45px'}
                                  containerWidth="350px"
                                  cacheOptions
                                  label={el?.label}
                                  labelClassname={'form-label mb-0'}
                                  name={`variant_list["${el?.value}"].values`}
                                  placeholder={'Enter and Search Options...'}
                                  // loadOptions={(inputValue, callback) =>
                                  //   props.debouncedLoadOptions(
                                  //     inputValue,
                                  //     callback,
                                  //     'keywords',
                                  //   )
                                  // }
                                  // defaultOptions={props.keywordOptions}
                                  // value={() =>
                                  //   getOptionsListForAttribute(el?.value)
                                  // }
                                  validate={(value) =>
                                    validateVariantList(value, el?.label)
                                  }
                                  isSearchable={true}
                                  isClearable={false}
                                  closeMenuOnSelect={true}
                                  placeholderColor={'grey'}
                                  onCreateOption={(createdValue) => {
                                    let option = {
                                      label: createdValue,
                                      value: createdValue,
                                    };
                                    // adding attibutes to varaint_list
                                    let tempVariantList = {
                                      ...values.variant_list,
                                      [el?.value]: {
                                        ...values.variant_list[el?.value],
                                        values: [
                                          ...values.variant_list[el?.value]
                                            .values,
                                          option,
                                        ],
                                      },
                                    };
                                    setFieldValue(
                                      'variant_list',
                                      tempVariantList,
                                    );
                                  }}
                                  onChange={(value) => {
                                    values.strengths.forEach(
                                      (elem, strengthInd) => {
                                        // check if there is an attribute object and also key named this attribute
                                        if (
                                          elem.attributes &&
                                          elem.attributes[el?.value]
                                        ) {
                                          // check if value array includes specific variant's attribute value
                                          if (
                                            !value.includes(
                                              elem.attributes[el?.value],
                                            )
                                          ) {
                                            setFieldValue(
                                              `strengths[${strengthInd}].attributes.${el?.value}`,
                                              null,
                                            );
                                          }
                                        }
                                      },
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {Object.keys(values.variant_list).length ? (
                        <div className="col-12">
                          <FieldArray name={`strengths`}>
                            {(subArrayHelpers) => {
                              return (
                                <>
                                  <div className="d-flex align-items-center m-2">
                                    <div className="form-label mt-4">
                                      Variant Combo :{' '}
                                    </div>
                                    <Button
                                      onClick={() => {
                                        subArrayHelpers.push({
                                          rrp_price: '',
                                          trade_price: '',
                                          margin: '',
                                          stock_level: '',
                                          barcode: '',
                                          sku: '',
                                          isPrimary: false,
                                          attributes: null,
                                        });
                                      }}
                                      className="p-2 h-100 mx-2"
                                    >
                                      Add Variant
                                    </Button>
                                  </div>
                                  {values.strengths.map((el, index) => {
                                    return (
                                      <div key={index}>
                                        {/* attributes section */}
                                        <div className="row">
                                          <div
                                            className="col-1 mt-2"
                                            style={{ width: '3%' }}
                                          >
                                            {index + 1}
                                          </div>
                                          {Object.keys(
                                            values.variant_list,
                                          )?.map((attr) => {
                                            return (
                                              <div
                                                className="col-2 mt-2"
                                                key={attr}
                                              >
                                                <CustomReactSelect
                                                  placeholder={'Search...'}
                                                  className="mx-0"
                                                  label={attr}
                                                  name={`strengths[${index}].attributes.${attr}`}
                                                  containerWidth="200px"
                                                  options={
                                                    values.variant_list[attr]
                                                      ?.values
                                                  }
                                                  isClearable={true}
                                                  validate={(value, name) =>
                                                    validateVariantComboAttributes(
                                                      value,
                                                      `strengths[${index}].attributes.${attr}`,
                                                    )
                                                  }
                                                />
                                              </div>
                                            );
                                          })}
                                        </div>
                                        {/* price section */}
                                        <table className="strengths_table mt-3">
                                          <thead>
                                            <th></th>
                                            <th>
                                              Trade Price
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </th>
                                            <th>
                                              RRP Price
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </th>
                                            <th>Margin</th>
                                            <th>
                                              Stock
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </th>
                                            <th>
                                              Barcode
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </th>
                                            <th>
                                              SKU
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </th>
                                            <th></th>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  name={`strengths[${index}].isPrimary`}
                                                  onChange={(e) => {
                                                    formik.setFieldValue(
                                                      'strengths',
                                                      props.handleChange(
                                                        'strengths',
                                                        e.target.checked,
                                                        formik.values.strengths,
                                                        index,
                                                        // subIndex,
                                                      ),
                                                      false,
                                                    );
                                                  }}
                                                  checked={
                                                    formik.values.strengths[
                                                      index
                                                    ].isPrimary
                                                  }
                                                />
                                              </td>
                                              {/* Trade Price */}
                                              <td>
                                                <Input
                                                  style={{
                                                    width: '100%',
                                                    minWidth: '80px',
                                                  }}
                                                  onChange={() => {
                                                    calculateMargin(
                                                      formik.values?.strengths[
                                                        index
                                                      ].trade_price,
                                                      formik.values?.strengths[
                                                        index
                                                      ].rrp_price,
                                                      formik.values.vat_rate,
                                                      index,
                                                      // subIndex,
                                                    );
                                                  }}
                                                  // label="Trade Price"
                                                  labelClassname="form-label"
                                                  name={`strengths[${index}].trade_price`}
                                                  type="text"
                                                  className=""
                                                  placeholder="Enter Trade Price"
                                                />
                                              </td>
                                              {/* rrp_price */}
                                              <td>
                                                <Input
                                                  style={{
                                                    width: '100%',
                                                    minWidth: '80px',
                                                  }}
                                                  onChange={() => {
                                                    calculateMargin(
                                                      formik.values?.strengths[
                                                        index
                                                      ].trade_price,
                                                      formik.values?.strengths[
                                                        index
                                                      ].rrp_price,
                                                      formik.values.vat_rate,
                                                      index,
                                                      // subIndex,
                                                    );
                                                  }}
                                                  name={`strengths[${index}].rrp_price`}
                                                  type="text"
                                                  className=""
                                                  placeholder="Enter RRP Price"
                                                />
                                              </td>
                                              {/* margin */}
                                              <td>
                                                <Input
                                                  style={{
                                                    width: '100%',
                                                    minWidth: '80px',
                                                  }}
                                                  disabled={true}
                                                  name={`strengths[${index}].margin`}
                                                  type="text"
                                                  className=""
                                                  placeholder="Enter Margin"
                                                />
                                              </td>
                                              {/* stock level */}
                                              <td>
                                                <Input
                                                  style={{
                                                    width: '100%',
                                                    minWidth: '80px',
                                                  }}
                                                  name={`strengths[${index}].stock_level`}
                                                  type="text"
                                                  placeholder="Enter Stock"
                                                  className=""
                                                />
                                              </td>
                                              {/* barcode */}
                                              <td>
                                                <Input
                                                  style={{
                                                    width: '100%',
                                                    minWidth: '80px',
                                                  }}
                                                  name={`strengths[${index}].barcode`}
                                                  type="text"
                                                  placeholder="Enter Barcode"
                                                  className=""
                                                />
                                              </td>{' '}
                                              <td>
                                                <Input
                                                  style={{
                                                    width: '100%',
                                                    minWidth: '50px',
                                                  }}
                                                  name={`strengths[${index}].sku`}
                                                  type="text"
                                                  placeholder="Enter SKU"
                                                  className=""
                                                />
                                              </td>
                                              <td>
                                                <FontAwesomeIcon
                                                  icon={faTrash}
                                                  color={'#088fff'}
                                                  className="m-2"
                                                  onClick={() => {
                                                    if (el?.isPrimary) {
                                                      toastError(
                                                        `You can't delete primary variant, If you want to delete this then selelect another variant as primary variant.`,
                                                      );
                                                      return;
                                                    }
                                                    if (el._id) {
                                                      formik.setFieldValue(
                                                        'deletetable_variant',
                                                        [
                                                          ...formik.values
                                                            .deletetable_variant,
                                                          el._id,
                                                        ],
                                                      );
                                                      subArrayHelpers.remove(
                                                        index,
                                                      );
                                                    } else {
                                                      subArrayHelpers.remove(
                                                        index,
                                                      );
                                                    }
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <hr />
                                      </div>
                                    );
                                  })}
                                </>
                              );
                            }}
                          </FieldArray>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div>
                      <table className="strengths_table mt-0">
                        <thead>
                          <th>
                            Trade Price<span className="text-danger">*</span>
                          </th>
                          <th>
                            RRP Price<span className="text-danger">*</span>
                          </th>
                          <th>Margin</th>
                          <th>
                            Stock<span className="text-danger">*</span>
                          </th>
                          <th>
                            Barcode<span className="text-danger">*</span>
                          </th>
                          <th>
                            SKU<span className="text-danger">*</span>
                          </th>
                        </thead>
                        <tbody>
                          <tr>
                            {/* Trade Price */}
                            <td>
                              <Input
                                style={{
                                  width: '100%',
                                  minWidth: '80px',
                                }}
                                onChange={() => {
                                  calculateMargin(
                                    formik.values?.strengths[0].trade_price,
                                    formik.values?.strengths[0].rrp_price,
                                    formik.values.vat_rate,
                                    0,
                                    // subIndex,
                                  );
                                }}
                                // label="Trade Price"
                                labelClassname="form-label"
                                name={`strengths[0].trade_price`}
                                type="text"
                                className=""
                                placeholder="Enter Trade Price"
                              />
                            </td>
                            {/* rrp_price */}
                            <td>
                              <Input
                                style={{
                                  width: '100%',
                                  minWidth: '80px',
                                }}
                                onChange={() => {
                                  calculateMargin(
                                    formik.values?.strengths[0].trade_price,
                                    formik.values?.strengths[0].rrp_price,
                                    formik.values.vat_rate,
                                    0,
                                    // subIndex,
                                  );
                                }}
                                name={`strengths[0].rrp_price`}
                                type="text"
                                className=""
                                placeholder="Enter RRP Price"
                              />
                            </td>
                            {/* margin */}
                            <td>
                              <Input
                                style={{
                                  width: '100%',
                                  minWidth: '80px',
                                }}
                                disabled={true}
                                name={`strengths[0].margin`}
                                type="text"
                                className=""
                                placeholder="Enter Margin"
                              />
                            </td>
                            {/* stock level */}
                            <td>
                              <Input
                                style={{
                                  width: '100%',
                                  minWidth: '80px',
                                }}
                                name={`strengths[0].stock_level`}
                                type="text"
                                placeholder="Enter Stock"
                                className=""
                              />
                            </td>
                            {/* barcode */}
                            <td>
                              <Input
                                style={{
                                  width: '100%',
                                  minWidth: '80px',
                                }}
                                name={`strengths[0].barcode`}
                                type="text"
                                placeholder="Enter Barcode"
                                className=""
                              />
                            </td>{' '}
                            <td>
                              <Input
                                style={{
                                  width: '100%',
                                  minWidth: '50px',
                                }}
                                name={`strengths[0].sku`}
                                type="text"
                                placeholder="Enter SKU"
                                className=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                    </div>
                  )}
                </div>
                {/* <div className="row">
                  <div className="col-12 form-group">
                    <FieldArray id={'strengths'} name={'strengths'}>
                      {(arrayHelpers) => {
                        return (
                          <>
                            <div
                              className="d-flex align-items-center
                                   middle_main banner_table p-0"
                            >
                              <label
                                className={'form-label'}
                                htmlFor={'strengths'}
                              >
                                Strength
                                <span className="text-danger">*</span>
                              </label>
                              <Button
                                onClick={() => {
                                  if (formik.values.strengths.length === 0) {
                                    arrayHelpers.push({
                                      strength: '',
                                      size_variants: [
                                        {
                                          size: '',
                                          rrp_price: '',
                                          trade_price: '',
                                          margin: '',
                                          stock_level: '',
                                          barcode: '',
                                          isPrimary: true,
                                        },
                                      ],
                                    });
                                  } else
                                    arrayHelpers.push({
                                      strength: '',
                                      size_variants: [
                                        {
                                          size: '',
                                          rrp_price: '',
                                          trade_price: '',
                                          margin: '',
                                          stock_level: '',
                                          barcode: '',
                                          isPrimary: false,
                                        },
                                      ],
                                    });
                                }}
                                className="p-2 mx-2"
                              >
                                Add Strength
                              </Button>
                            </div>
                            {formik.values?.strengths?.map((value, index) => {
                              return (
                                <React.Fragment key={`main${index}`}>
                                  <FieldArray
                                    name={`strengths[${index}].size_variants`}
                                  >
                                    {(subArrayHelpers) => {
                                      return (
                                        <>
                                          <div
                                            className="d-flex align-items-center mb-0"
                                            style={{
                                              justifyContent: 'space-between',
                                            }}
                                            key={index}
                                          >
                                            <div>
                                              <Field
                                                type="text"
                                                placeholder="Add Strength"
                                                id={`strengths.${index}`}
                                                name={`strengths.${index}.strength`}
                                                className="form-control form-input p-2 w-100"
                                              />
                                              {errors?.strengths?.length &&
                                              errors.strengths[index]
                                                ?.strength ? (
                                                <TextError>
                                                  {
                                                    errors.strengths[index]
                                                      .strength
                                                  }
                                                </TextError>
                                              ) : null}
                                            </div>
                                            <div className="d-flex align-items-center m-2">
                                              <Button
                                                onClick={() => {
                                                  subArrayHelpers.push({
                                                    size: '',
                                                    rrp_price: '',
                                                    trade_price: '',
                                                    margin: '',
                                                    stock_level: '',
                                                    barcode: '',
                                                    isPrimary: false,
                                                  });
                                                }}
                                                className="p-2 h-100 mx-2"
                                              >
                                                Add Size
                                              </Button>
                                            </div>
                                          </div>
                                          <div>
                                            <table className="strengths_table mt-0">
                                              <thead>
                                                <th></th>
                                                <th>Size</th>
                                                <th>Trade Price</th>
                                                <th>RRP Price</th>
                                                <th>Margin</th>
                                                <th>Stock</th>
                                                <th>Barcode</th>
                                                <th></th>
                                              </thead>
                                              <tbody>
                                                {formik.values.strengths[
                                                  index
                                                ]?.size_variants?.map(
                                                  (elem, subIndex) => {
                                                    return (
                                                      <tr
                                                        key={`sub${subIndex}`}
                                                      >
                                                        <td>
                                                          <input
                                                            type="checkbox"
                                                            name={`strengths[${index}].size_variants[${subIndex}].isPrimary`}
                                                            onChange={(e) => {
                                                              formik.setFieldValue(
                                                                'strengths',
                                                                props.handleChange(
                                                                  'strengths',
                                                                  e.target
                                                                    .checked,
                                                                  formik.values
                                                                    .strengths,
                                                                  index,
                                                                  subIndex,
                                                                ),
                                                                false,
                                                              );
                                                            }}
                                                            checked={
                                                              formik.values
                                                                .strengths[
                                                                index
                                                              ]?.size_variants[
                                                                subIndex
                                                              ].isPrimary
                                                            }
                                                          />
                                                        </td>
                                                        <td>
                                                          <Input
                                                            style={{
                                                              width: '100%',
                                                              minWidth: '80px',
                                                            }}
                                                            name={`strengths[${index}].size_variants[${subIndex}].size`}
                                                            type="text"
                                                            className="p-0"
                                                            placeholder="Enter Size"
                                                          />
                                                        </td>
                                                        <td>
                                                          <Input
                                                            style={{
                                                              width: '100%',
                                                              minWidth: '80px',
                                                            }}
                                                            onChange={() => {
                                                              calculateMargin(
                                                                formik.values
                                                                  ?.strengths[
                                                                  index
                                                                ].size_variants[
                                                                  subIndex
                                                                ].trade_price,
                                                                formik.values
                                                                  ?.strengths[
                                                                  index
                                                                ].size_variants[
                                                                  subIndex
                                                                ].rrp_price,
                                                                formik.values
                                                                  .vat_rate,
                                                                index,
                                                                subIndex,
                                                              );
                                                            }}
                                                            // label="Trade Price"
                                                            labelClassname="form-label"
                                                            name={`strengths[${index}].size_variants[${subIndex}].trade_price`}
                                                            type="text"
                                                            className=""
                                                            placeholder="Enter Trade Price"
                                                          />
                                                        </td>
                                                        <td>
                                                          <Input
                                                            style={{
                                                              width: '100%',
                                                              minWidth: '80px',
                                                            }}
                                                            onChange={() => {
                                                              calculateMargin(
                                                                formik.values
                                                                  ?.strengths[
                                                                  index
                                                                ].size_variants[
                                                                  subIndex
                                                                ].trade_price,
                                                                formik.values
                                                                  ?.strengths[
                                                                  index
                                                                ].size_variants[
                                                                  subIndex
                                                                ].rrp_price,
                                                                formik.values
                                                                  .vat_rate,
                                                                index,
                                                                subIndex,
                                                              );
                                                            }}
                                                            name={`strengths[${index}].size_variants[${subIndex}].rrp_price`}
                                                            type="text"
                                                            className=""
                                                            placeholder="Enter RRP Price"
                                                          />
                                                        </td>
                                                        <td>
                                                          <Input
                                                            style={{
                                                              width: '100%',
                                                              minWidth: '80px',
                                                            }}
                                                            disabled={true}
                                                            name={`strengths[${index}].size_variants[${subIndex}].margin`}
                                                            type="text"
                                                            className=""
                                                            placeholder="Enter Margin"
                                                          />
                                                        </td>
                                                        <td>
                                                          <Input
                                                            style={{
                                                              width: '100%',
                                                              minWidth: '80px',
                                                            }}
                                                            name={`strengths[${index}].size_variants[${subIndex}].stock_level`}
                                                            type="text"
                                                            placeholder="Enter Stock"
                                                            className=""
                                                          />
                                                        </td>
                                                        <td>
                                                          <Input
                                                            style={{
                                                              width: '100%',
                                                              minWidth: '80px',
                                                            }}
                                                            name={`strengths[${index}].size_variants[${subIndex}].barcode`}
                                                            type="text"
                                                            placeholder="Enter Barcode"
                                                            className=""
                                                          />
                                                        </td>{' '}
                                                        <td>
                                                          <FontAwesomeIcon
                                                            icon={faTrash}
                                                            color={'#088fff'}
                                                            className="m-2"
                                                            onClick={() => {
                                                              if (
                                                                formik.values
                                                                  .strengths[
                                                                  index
                                                                ].size_variants[
                                                                  subIndex
                                                                ]?._id
                                                              )
                                                                deleteProductVariant(
                                                                  formik.values
                                                                    .strengths[
                                                                    index
                                                                  ]
                                                                    .size_variants[
                                                                    subIndex
                                                                  ]?._id,
                                                                );
                                                              if (
                                                                formik.values
                                                                  .strengths[
                                                                  index
                                                                ].size_variants
                                                                  .length === 1
                                                              ) {
                                                                arrayHelpers.remove(
                                                                  index,
                                                                );
                                                              } else {
                                                                subArrayHelpers.remove(
                                                                  subIndex,
                                                                );
                                                              }
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                    );
                                                  },
                                                )}
                                              </tbody>
                                            </table>
                                            <hr />
                                          </div>
                                        </>
                                      );
                                    }}
                                  </FieldArray>
                                </React.Fragment>
                              );
                            })}
                          </>
                        );
                      }}
                    </FieldArray>
                    {errors?.strengths &&
                    typeof errors['strengths'] === 'string' ? (
                      <TextError>{errors.strengths}</TextError>
                    ) : null}
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-12 mt-1 mb24">
                    <hr />
                  </div>
                  <div className="col-12">
                    <h4 className="middle_title">
                      Product Images
                      <span className="text-danger">*</span>
                    </h4>
                  </div>
                </div>
                <ImageInput
                  showPrimaryTag={true}
                  label={`Upload Image (w x h: ${DIMENSION.product.width} x ${DIMENSION.product.height})`}
                  subLabel={'Select upto 5 images.'}
                  accept="image/png, image/svg, image/jpeg, image/jpg, image/PNG, image/SVG, image/JPEG, image/JPG, image/webp"
                  name={'photos'}
                  id={'photos'}
                  imagesLimit={5}
                  productId={props.productId}
                  imageDimention={DIMENSION.product}
                />
                {props.productType === 'medication' ? (
                  <div className="form-group">
                    <FileInput
                      labelClassname="form-label"
                      label="Information Label"
                      className={'form-control '}
                      type="file"
                      name="information_label"
                      accept={'application/pdf'}
                    />
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-lg-12 col-xl-8 form-group">
                    <label className="form-label">
                      Description
                      <span className="text-danger">*</span>
                    </label>
                    <TextEditor
                      name="description"
                      value={formik.values.description}
                      className={'position-relative'}
                      onEditorChange={(value) => {
                        formik.setFieldValue('description', value, true);
                      }}
                      editorHeight="30vh"
                    />
                    {touched['description'] && errors['description'] && (
                      <TextError>{errors['description']}</TextError>
                    )}
                  </div>
                  <div className="col-lg-12 col-xl-8 form-group">
                    <label className="form-label">
                      More Info
                      <span className="text-danger"></span>
                    </label>
                    <TextEditor
                      name="more_info"
                      value={formik.values.more_info}
                      className={'position-relative'}
                      onEditorChange={(value) => {
                        formik.setFieldValue('more_info', value, true);
                      }}
                      editorHeight="30vh"
                    />
                    {touched['more_info'] && errors['more_info'] && (
                      <TextError>{errors['more_info']}</TextError>
                    )}
                  </div>
                  {props.productType === 'product' ? (
                    <div className="col-lg-12 col-xl-8 form-group">
                      <CustomAsyncCreatableReactSelect
                        selectType="creatable"
                        isMulti
                        controlHeight={'45px'}
                        label="Ingredients"
                        labelClassname="form-label"
                        cacheOptions
                        name="ingredients"
                        isSearchable={true}
                        isClearable={false}
                        closeMenuOnSelect={true}
                        onChange={(e) => props.handleChange('ingredients', e)}
                      />
                    </div>
                  ) : null}
                  {/* {props.productType === 'medication' ? (
                    <div className="col-lg-12 col-xl-8 form-group">
                      <label className="form-label">
                        About
                        <span className="text-danger">*</span>
                      </label>
                      <TextEditor
                        name="about"
                        value={formik.values.about}
                        className={'position-relative'}
                        onEditorChange={(value) => {
                          formik.setFieldValue('about', value, true);
                        }}
                        editorHeight="30vh"
                      />
                      {touched['about'] && errors['about'] && (
                        <TextError>{errors['about']}</TextError>
                      )}
                    </div>
                  ) : null} */}
                  {/* {props.productType === 'medication' ? (
                    <div className="col-lg-12 col-xl-8 form-group">
                      <label className="form-label">
                        How does it work?
                        <span className="text-danger">*</span>
                      </label>
                      <TextEditor
                        name="how_works"
                        value={formik.values.how_works}
                        className={'position-relative'}
                        onEditorChange={(value) => {
                          formik.setFieldValue('how_works', value, true);
                        }}
                        editorHeight="30vh"
                      />
                      {touched['how_works'] && errors['how_works'] && (
                        <TextError>{errors['how_works']}</TextError>
                      )}
                    </div>
                  ) : 
                  null} */}
                  <div className="col-lg-12 col-xl-8">
                    <CustomAsyncCreatableReactSelect
                      height={''}
                      isMulti={true}
                      controlHeight={'45px'}
                      label="Related Keywords"
                      labelClassname="form-label"
                      cacheOptions
                      name="related_keyword"
                      placeholder={'Search & select Keywords'}
                      loadOptions={(inputValue, callback) =>
                        props.debouncedLoadOptions(
                          inputValue,
                          callback,
                          'keywords',
                        )
                      }
                      defaultOptions={props.keywordOptions}
                      isSearchable={true}
                      isClearable={false}
                      closeMenuOnSelect={true}
                      onChange={(e) => props.handleChange('related_keyword', e)}
                    />
                  </div>
                  {props.productType === 'medication' ? (
                    <div className="col-lg-12 col-xl-8 m-1 form-group">
                      <label className="form-label">Email Template</label>
                      <TextEditor
                        name="emailTemplate"
                        value={formik.values.emailTemplate}
                        className={'position-relative'}
                        onEditorChange={(value) => {
                          formik.setFieldValue('emailTemplate', value, true);
                        }}
                        editorHeight="25vh"
                      />
                      {touched['emailTemplate'] && errors['emailTemplate'] && (
                        <TextError>{errors['emailTemplate']}</TextError>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="footer_main">
                <button className="btn btn-primary me-2" type="submit">
                  Next
                </button>
              </div>
              <DeleteConfirmationModal
                module={'Variants'}
                name={'All'}
                handleToggle={() => setIsModalOpen(!isModalOpen)}
                cancel={() => setIsModalOpen(false)}
                show={isModalOpen}
                size="lg"
                handleDelete={() =>
                  handleStrengthsDelete(formik.setFieldValue, formik.values)
                }
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ProductDetails;
