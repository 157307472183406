import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import { CSVLink } from 'react-csv';
import services from '../api/api';
import moment from 'moment';
import { toastError, toastSuccess } from '../common/UI/Toasts';
import CustomDropdown from '../common/UI/Dropdown';
import DatePicker from 'react-datepicker';
import { Button } from 'react-bootstrap';

const DoctorReporting = () => {
  const columns = [
    {
      Header: 'Order Number',
      disableSortBy: true,
      disableFilters: true,
      accessor: 'order_number',
    },
    {
      Header: 'Patient Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div>
            {row?.original?.patient?.first_name}{' '}
            {row?.original?.patient?.last_name}
          </div>
        );
      },
    },
    {
      Header: 'Order Date',
      disableSortBy: true,
      disableFilters: true,
      accessor: (row) => `${moment(row?.createdAt).format('DD/MM/yyyy')}`,
    },

    {
      Header: 'Medications',
      disableSortBy: true,
      disableFilters: true,
      accessor: (row) => `${moment(row?.createdAt).format('DD/MM/yyyy')}`,
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.orderItemArray.map((el) => (
              <div key={el?._id}>{el?.product?.name}</div>
            ))}
          </div>
        );
      },
    },
    {
      Header: 'Order Total(Ex. VAT)',
      disableSortBy: true,
      accessor: 'medicationTotalRrpPrice',
    },
    {
      Header: 'Order Status',
      disableSortBy: true,
      accessor: 'current_order_status',
    },
    {
      Header: 'Doctor Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <div>{dates?.doctor}</div>;
      },
    },
    {
      Header: 'Payment to Doc.',
      disableSortBy: true,
      disableFilters: true,
      accessor: 'doctor_commission',
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const [doctorOptions, setdoctorOptions] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [selectedDates, setSelectedDates] = useState({
    start_date: '',
    end_date: '',
  });
  const [dates, setDates] = useState({});

  useEffect(() => {
    getDoctors();
  }, []);

  useEffect(() => {
    loadCSVdata(data);
  }, [data]);

  const getDoctors = () => {
    const params = { showall: true, role: 'doctor' };
    services
      .getUsers(params)
      .then((res) => {
        let doctors = res.data.data.docs.map((el) => {
          return {
            label: `${el?.first_name} ${el?.last_name}`,
            value: el?._id,
          };
        });
        setdoctorOptions(doctors);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const CSVheaders = [
    { label: 'Order Number', key: 'order_number' },
    { label: 'Patient Name', key: 'patient_name' },
    { label: 'Order Date', key: 'createdAt' },
    { label: 'Medications', key: 'medications' },
    { label: 'Order Total(Ex. VAT)', key: 'order_total_exVAT' },
    { label: 'Doctor Name', key: 'doctor_name' },
    { label: 'Payment to Doc.', key: 'payment' },
  ];

  const loadCSVdata = (orderdata) => {
    const modifyorderdata = orderdata?.length
      ? orderdata?.map((el) => {
          let itemsName = '';
          el?.orderItemArray?.forEach((elem, index) => {
            itemsName =
              itemsName +
              elem?.product?.name +
              `${index + 1 == el?.orderItemArray?.length ? '' : ', '}`;
          });
          return {
            order_number: el?.order_number,
            patient_name: `${el?.patient.first_name} ${el?.patient.last_name}`,
            createdAt: `${moment(el?.createdAt).format('DD/MM/yyyy')}`,
            medications: itemsName,
            order_total_exVAT: el?.medicationTotalRrpPrice,
            doctor_name: dates.doctor,
            payment: el?.doctor_commission,
          };
        })
      : [];
    setCsvData(modifyorderdata);
  };

  const handleFilterDataClick = () => {
    if (
      selectedDoctor &&
      selectedDates.start_date !== '' &&
      selectedDates.end_date !== ''
    ) {
      if (
        new Date(selectedDates.start_date) > new Date(selectedDates.end_date)
      ) {
        toastError(`Start date should be less than End date`);
      } else {
        let params = {
          doctor_id: selectedDoctor?.value,
          sdt: selectedDates?.start_date,
          edt: selectedDates?.end_date,
        };
        setLoading(true);
        services
          .getOrderAccountingData(params)
          .then((res) => {
            console.log('res', res);
            setData(res.data.data);
            setDates({
              start_date: selectedDates.start_date,
              end_date: selectedDates.end_date,
              doctor: selectedDoctor.label,
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    } else {
      !selectedDoctor
        ? toastError('Please Select Doctor')
        : toastError(
            `Please Select ${
              selectedDates.start_date == '' ? 'Start' : 'End'
            } Date`,
          );
    }
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div
                  className="header_top"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h3>Doctor Reporting</h3>
                </div>

                <div className="middle_main">
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label">Doctor</label>
                      <CustomDropdown
                        className=""
                        options={doctorOptions}
                        isSearchable={false}
                        height={'40px'}
                        placeholder={'Select Doctor'}
                        placeholderColor={'#9aa5ad'}
                        border={'1 px solid #9aa5ad'}
                        onChange={(value) => {
                          setSelectedDoctor(value);
                        }}
                        value={selectedDoctor}
                        isLoading={!doctorOptions.length}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">Start Date</label>
                      <DatePicker
                        dateFormat={'dd/MM/yyyy'}
                        placeholderText="Select Start Date"
                        selected={selectedDates.start_date}
                        onChange={(val) => {
                          setSelectedDates((prev) => {
                            return { ...prev, start_date: val };
                          });
                        }}
                        className="form-control form-input px-4"
                        name="start_date"
                        inputDateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        onDaySelect={() => {}} //when day is clicked
                        onBlur={() => console.log('blur')}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">End Date</label>
                      <DatePicker
                        dateFormat={'dd/MM/yyyy'}
                        placeholderText="Select End Date"
                        selected={selectedDates.end_date}
                        onChange={(val) => {
                          setSelectedDates((prev) => {
                            return { ...prev, end_date: val };
                          });
                        }}
                        className="form-control form-input px-4"
                        name="start_date"
                        inputDateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        onDaySelect={() => {}} //when day is clicked
                        onBlur={() => console.log('blur')}
                      />
                    </div>
                    <div className="col-md-3 mt-4 text-end">
                      <Button
                        className="me-4 order_btns p-3"
                        onClick={handleFilterDataClick}
                      >
                        Filter Data
                      </Button>
                      <Button
                        className="me-4 order_btns p-3"
                        disabled={!data.length}
                      >
                        <CSVLink
                          data={csvData}
                          filename={`orders_${moment(dates.start_date).format(
                            'YYYY_MM_DD',
                          )} to ${moment(dates.end_date).format(
                            'YYYY_MM_DD',
                          )}.csv`}
                          target="_blank"
                          headers={CSVheaders}
                          style={{ color: '#fff' }}
                        >
                          Export Orders
                        </CSVLink>
                      </Button>
                    </div>
                  </div>
                  {Object.keys(dates).length ? (
                    <div className="mt-4 fs-24">
                      {' '}
                      Order data of{' '}
                      <span className="fw-bold">{dates.doctor}</span> from{' '}
                      <span className="fw-bold">
                        {moment(dates.start_date).format('DD/MM/yyyy')}
                      </span>{' '}
                      to{' '}
                      <span className="fw-bold">
                        {moment(dates.end_date).format('DD/MM/yyyy')}
                      </span>
                    </div>
                  ) : null}
                  {loading ? (
                    <Loader loading={loading} />
                  ) : (
                    <ReactTable
                      title="Doctor's Orders"
                      tableColumns={columns}
                      tableData={data}
                      seeAll={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorReporting;
