import { Formik, Form } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import DatePickerField from '../../../common/FormComponents/DatePickerField';
import CustomInput from './../../../common/FormComponents/Input';
import CustomAsyncCreatableReactSelect from './../../../common/FormComponents/AsyncCreatableReactSelect';
import ReactSelect from '../../../common/FormComponents/ReactSelect';

const AddCouponcodeForm = ({
  selectedOptionValue,
  debouncedLoadOptions,
  validationSchema,
  initialValues,
  onSubmit,
  handleAddCouponcode,
  COUPON_TYPES,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(formik) => {
        formik.values.coupon = formik.values.coupon.toUpperCase();
        return (
          <Form>
            <div className="row">
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <CustomInput
                  label="Coupon Code Name"
                  labelClassname="form-label"
                  name="coupon"
                  id="coupon"
                  type="text"
                  className="form-control form-input"
                  placeholder="Coupon Code Name"
                  isRequired={true}
                  disabled={initialValues && initialValues.id ? true : false}
                />
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <CustomInput
                  label="Percentage Discount"
                  labelClassname="form-label"
                  name="cdiscount"
                  id="cdiscount"
                  type="number"
                  // min={0}
                  // max={100}
                  step="any"
                  className="form-control form-input"
                  placeholder="Percentage Discount"
                  isRequired={true}
                />
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <DatePickerField
                  label="Start Date"
                  labelClassname={'form-label'}
                  placeholder="Select start date "
                  className="form-control form-input px-4"
                  name="start_date"
                  inputDateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  maxDate={new Date(formik.values.end_date)}
                  onDaySelect={() => {}} //when day is clicked
                  onBlur={() => console.log('blur')}
                  isRequired={true}
                />
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <DatePickerField
                  label="End Date"
                  labelClassname={'form-label'}
                  placeholder="Select end date "
                  className="form-control form-input px-4"
                  name="end_date"
                  inputDateFormat="dd/MM/yyyy"
                  minDate={
                    formik.values.start_date
                      ? new Date(formik.values.start_date)
                      : new Date()
                  }
                  onDaySelect={() => {}} //when day is clicked
                  onBlur={() => console.log('blur')}
                  isRequired={true}
                />
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <ReactSelect
                  label="Select Coupon Type"
                  labelClassname="form-label"
                  name="ctype"
                  id="ctype"
                  placeholder="Select Coupon Type"
                  className="form-select"
                  options={COUPON_TYPES}
                  height={'40px'}
                  isRequired={true}
                  placeholderStyles={{
                    fontSize: 14,
                    color: '#9aa5ad',
                  }}
                />
              </div>
              {formik?.values?.ctype?.value === 'brand_promo_code' ? (
                <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                  <CustomAsyncCreatableReactSelect
                    height={'40px'}
                    selectType="async"
                    label={'Select Brand'}
                    labelClassname={'mb-3'}
                    className={'text-muted'}
                    cacheOptions
                    name="brand"
                    placeholder={'Search & Select Brand'}
                    loadOptions={(inputValue, callback) =>
                      debouncedLoadOptions(inputValue, callback, 'brand')
                    }
                    defaultOptions={selectedOptionValue}
                    options={selectedOptionValue}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                    isRequired={true}
                  />
                </div>
              ) : null}
            </div>
            <div className="footer_main">
              <Button
                type="button"
                variant="sky"
                className="my-2 me-2"
                onClick={() => handleAddCouponcode(false)}
              >
                Back
              </Button>
              <Button className="me-2" type="submit">
                {initialValues && initialValues.id ? 'Update' : 'Save'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddCouponcodeForm;
