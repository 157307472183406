import * as network from './network';

const getAllGPAddress = (params) => {
  return network.get(`address/gp?showall`, params, true);
};

const getGPAddressByName = (gpAdd, params) => {
  return network.get(
    `address/gp?search=${gpAdd.replace(/ /g, '+')}`,
    params,
    true,
  );
};

const updateGPAddress = (id, body) => {
  return network.put(`address/gp/${id}`, body, true);
};

const addGPAddress = (body) => {
  return network.post(`address/gp`, body, true);
};

const searchGPAddress = (params) => {
  return network.get('address/gp', params, true);
};

const deleteGPAddress = (id) => {
  return network.delet(`address/gp/${id}`, true);
};
const sendGPLetter = (body) => {
  return network.post(`address/gp/sendmail`, body, true);
};

const getOrdersWithGPs = (params) => {
  return network.get(`order/getDispatchedGpOrder`, params, true);
};

const updateGPMail = (orderId, payload) => {
  return network.put(`order/update-gp-mail/${orderId}`, payload, true);
};

const getGPAddressByOrg = (params) => {
  return network.get(`address/gp`, params);
};

export default {
  getAllGPAddress,
  updateGPAddress,
  addGPAddress,
  searchGPAddress,
  deleteGPAddress,
  sendGPLetter,
  getGPAddressByName,
  getOrdersWithGPs,
  updateGPMail,
  getGPAddressByOrg,
};
