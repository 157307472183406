import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { default as api, default as services } from '../../api/api';
import previewIcon from '../../assets/images/eye.svg';
import editIcon from '../../assets/images/pencil.svg';
import deleteIcon from '../../assets/images/trash.svg';
import DeleteConfirmationModal from '../../common/UI/CustomModal/deleteModal';
import Loader from '../../common/UI/Loader';
import CustomReactTable from '../../common/UI/ReactTable';
import SearchAndButtons from '../../common/UI/Search&Buttons';
import { toastError, toastSuccess } from '../../common/UI/Toasts';
import CustomToggle from '../../common/UI/Toggle';
import { getLabelValuePair } from '../../utils/helpers';
import BannerFormPage from './form';
import RearrangeBanner from './rearrangeBanner';
import CustomDropdown from '../../common/UI/Dropdown';
import { BANNER_TYPE } from '../../common/constants';
import { getFormDataFromObject } from '../../utils/helpers';
import ReactSwitch from 'react-switch';

const BannerSection = () => {
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [deleteBannerId, setDeleteBannerId] = useState(null);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [deleteBannerName, setDeleteBannerName] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setbuttonClicked] = useState('table');
  const [bannerEditInfo, setBannerEditInfo] = useState({});
  // Link product-brand
  const [selectedOptionValue, setSelectedOptionValue] = useState([]);
  // Banner type
  const [bannerType, setBannerType] = useState('');
  const bannerTypeFCapital = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1) + ' ' + 'Banner';
  };
  const columns = [
    {
      Header: 'Banner Image',
      width: 200,
      disableSortBy: true,
      disableFilters: true,
      Cell: (tableProps) => {
        return (
          <div>
            <img
              height="auto"
              width={200}
              src={tableProps?.row?.original?.banner_image?.location}
              alt="banner"
            />
          </div>
        );
      },
    },
    {
      Header: 'Title',
      accessor: 'title',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Linked Product/Brand/Category',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div>
            {row.original?.referal === 'products'
              ? `Product- ${row.original?.linkProductBrandData?.name}`
              : row.original?.referal === 'brands'
              ? `Brand- ${row.original.linkProductBrandData?.name}`
              : row.original?.referal === 'product_categories'
              ? `Category- ${row.original.linkProductBrandData?.name}`
              : null}
          </div>
        );
      },
    },
    {
      Header: 'Date Published',
      disableSortBy: true,
      disableFilters: true,
      accessor: (dataFormat) => {
        return moment(dataFormat?.publish_date).format('YYYY-MM-DD');
      },
    },
    {
      Header: 'Banner Location',
      accessor: 'location',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Banner Type',
      accessor: (bannerdata) => bannerTypeFCapital(bannerdata?.bannerType),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Banner Status',
      disableSortBy: true,
      disableFilters: true,
      Cell: (tableProps) => {
        return (
          <>
            <ReactSwitch
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={() => handleToggleChange(tableProps?.row.original?._id)}
              checked={tableProps?.row?.original?.status}
            />
          </>
        );
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span className="me-2" onClick={() => handleEdit(row)}>
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span className="me-2" onClick={() => handleDelete(row)}>
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
            <span onClick={() => handleView(row)}>
              <img src={previewIcon} alt="preview" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getBanners(currentPageNo);
  }, [currentPageNo, seeAll]);

  const onBannerButtonClick = () => {
    setbuttonClicked('addBanner');
    setBannerEditInfo({
      banner_id: '',
      title: '',
      linkProductBrandData: '',
      referal: '',
      banner_image: [],
      publish_date: '',
      bannerType: '',
      status: '',
    });
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  // Functions for api calls
  const getBanners = (pageNo) => {
    const params = seeAll
      ? { showall: true }
      : {
          perPage: 10,
          page: pageNo,
        };
    services
      .getAllBanner(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const getOnSearch = (value) => {
    const params = {
      search: value,
    };
    services
      .getBannersOnSearch(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const deleteBanner = () => {
    services
      .deleteBanner(deleteBannerId)
      .then(() => {
        getBanners(currentPageNo);
        setDeleteBannerId(null);
        toastSuccess('Category Deleted Successfully');
      })
      .catch((err) => {
        console.log('error', err);
        setDeleteBannerId(null);
      });
  };

  const onSearchDebounce = debounce(getOnSearch, 1000);

  const onBannerSearchChange = (value) => {
    onSearchDebounce(value);
  };

  const handleDelete = (row) => {
    setDeleteBannerName(row.original?.title);
    setDeleteBannerId(row.original?._id);
    setModalShow(true);
  };

  const handleEdit = (row) => {
    setbuttonClicked('editBanner');
    let bannerEditInformation = {
      banner_id: row.original?._id,
      title: row.original?.title,
      banner_image: [
        {
          ...row.original?.banner_image,
          PrevUrl: row.original?.banner_image?.location,
        },
      ],
      linkProductBrandData: {
        label: row.original?.linkProductBrandData?.name,
        value: row.original?.linkProductBrandData?._id,
      },
      referal: {
        label: row.original?.referal,
        value: row.original?.referal,
      },
      publish_date: new Date(row.original?.publish_date),
      location: {
        label: row.original?.location,
        value: row.original?.location,
      },
      status: row.original?.status,
      bannerType: {
        label: bannerTypeFCapital(row.original?.bannerType),
        value: row.original?.bannerType,
      },
    };
    setBannerEditInfo(bannerEditInformation);
  };

  const handleToggleChange = (id) => {
    const currentRecord = data.find((el) => el._id === id);
    let payload = {
      status: !currentRecord.status,
      location: currentRecord.location,
      bannerType: currentRecord.bannerType,
    };
    payload = getFormDataFromObject(payload);
    if (id) {
      services
        .updateBanner(id, payload)
        .then(() => {
          getBanners(currentPageNo);
          setLoading(false);
          toastSuccess('Banner Updated Successfully');
        })
        .catch((err) => {
          console.log(`err`, err);
          toastError(err.message);
        });
    }
  };

  const handleView = (row) => {
    setbuttonClicked('viewBanner');
    setBannerEditInfo({
      banner_id: row.original?._id,
      title: row.original?.title,
      linkProductBrandData: row.original?.linkProductBrandData?.name,
      publish_date: row.original?.publish_date,
      banner_image: [{ PrevUrl: row.original?.banner_image?.location }],
      location: {
        label: row.original?.location,
        value: row.original?.location,
      },
      status: row.original?.status,
    });
  };

  const deleteModalOnHide = () => {
    setModalShow(false);
    setDeleteBannerId(null);
  };

  const deleteModalOnDelete = () => {
    setModalShow(false);
    deleteBanner();
  };

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  const backLinkHandle = () => {
    setbuttonClicked('table');
    getBanners(currentPageNo);
  };

  const getAsyncOptions = (inputValue, linkedOption) => {
    if (linkedOption.value === 'brands') {
      return getAsyncBrands({ search: inputValue });
    } else if (linkedOption.value === 'products') {
      return getAsyncProducts({ name: inputValue });
    } else {
      return getAsyncCategories({ search: inputValue });
    }
  };

  const loadOptions = (inputValue, callback, linkedOption) => {
    getAsyncOptions(inputValue, linkedOption).then((results) =>
      callback(results),
    );
    return;
  };

  const debouncedLoadOptions = debounce(loadOptions, 1000);

  /// on search getting product and brand
  const getAsyncBrands = async (params) => {
    try {
      let brandParams = { perPage: 20, page: 1, ...params };
      const response = await api.getBrandsOnSearch(brandParams);
      let brandData = response?.data?.data;
      return getLabelValuePair(brandData, 'name', '_id');
    } catch (err) {
      console.log(err);
    }
  };

  const getAsyncProducts = async (params) => {
    try {
      let productParams = { perPage: 20, page: 1, admin: true, ...params };
      const response = await api.getProducts(productParams);
      let productData = response?.data?.data?.docs;
      return getLabelValuePair(productData, 'name', '_id');
    } catch (err) {
      console.log(err);
    }
  };

  const getAsyncCategories = async (params) => {
    try {
      let categoryParams = { perPage: 20, page: 1, admin: true, ...params };
      const response = await api.getAllCategory(categoryParams);
      let categoryData = response?.data?.data?.docs;
      return getLabelValuePair(categoryData, 'name', '_id');
    } catch (err) {
      console.log(err);
    }
  };

  const getAllProducts = () => {
    const params = { perPage: 20, page: 1, admin: true };
    api
      .getProducts(params)
      .then((res) => {
        const response = getLabelValuePair(res.data?.data?.docs, 'name', '_id');
        setSelectedOptionValue(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllBrand = () => {
    const params = { perPage: 20, page: 1 };
    api
      .getAllBrands(params)
      .then((res) => {
        const response = getLabelValuePair(res.data?.data?.docs, 'name', '_id');
        setSelectedOptionValue(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCategories = () => {
    const params = { perPage: 20, page: 1 };
    api
      .getAllCategory(params)
      .then((res) => {
        const response = getLabelValuePair(res.data?.data?.docs, 'name', '_id');
        setSelectedOptionValue(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProductOrBrandValue = (getSelectedField) => {
    getSelectedField.value === 'products' && getAllProducts();
    getSelectedField.value === 'brands' && getAllBrand();
    getSelectedField.value === 'categories' && getAllCategories();
  };

  const rearrangeBanner = () => {
    setbuttonClicked('rearrange');
  };

  const handleBannerType = (value) => {
    value.value === 'big' ? setBannerType('big') : setBannerType('small');
    rearrangeBanner();
  };
  return (
    <>
      {buttonClicked === 'table' ? (
        <>
          <div className="content_wrapper all_products_main">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="box_main">
                    <div className="header_top">
                      <h3>Banner Management</h3>
                    </div>
                    <div className="middle_main">
                      <SearchAndButtons
                        onSearchChange={onBannerSearchChange}
                        onButtonClick={onBannerButtonClick}
                        searchPlaceholder={'Search by banner name...'}
                        buttonName={'Add Banner'}
                        seeAllHandle={seeAllHandle}
                      />
                      <CustomDropdown
                        className="fs-16 mt-3"
                        placeholder="Rearrange Banner"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        options={BANNER_TYPE}
                        controlStyles={{
                          backgroundColor: '#088fff',
                          color: 'white',
                          height: '45px',
                        }}
                        showIndicator="none"
                        containerWidth="190px"
                        isClearable={false}
                        controlShouldRenderValue={false}
                        isSearchable={false}
                        placeholderStyles={{
                          textAlign: 'center',
                          fontSize: '16px',
                          color: 'white',
                        }}
                        onChange={handleBannerType}
                      />

                      <div className="row">
                        <div className="col-md-12 mb-0 mt-4">
                          {loading ? (
                            <Loader loading={loading} />
                          ) : (
                            <CustomReactTable
                              title="Banner"
                              tableColumns={columns}
                              tableData={data}
                              seeAll={seeAll}
                              nextHandle={nextHandle}
                              prevHandle={prevHandle}
                              totalPage={totalPage}
                              pageNo={currentPageNo}
                              handleDelete={handleDelete}
                            />
                          )}
                        </div>
                      </div>
                      <DeleteConfirmationModal
                        name={deleteBannerName}
                        module={'banner'}
                        show={modalShow}
                        handleToggle={deleteModalOnHide}
                        handleDelete={deleteModalOnDelete}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {buttonClicked === 'rearrange' ? (
            <RearrangeBanner
              backLinkHandle={backLinkHandle}
              bannerType={bannerType}
            />
          ) : (
            <BannerFormPage
              name={buttonClicked}
              backLinkHandle={backLinkHandle}
              bannerEditInfo={bannerEditInfo}
              debouncedLoadOptions={debouncedLoadOptions}
              handleChange={getProductOrBrandValue}
              selectedOptionValue={selectedOptionValue}
              tableRecords={data}
            />
          )}
        </>
      )}
    </>
  );
};

export default BannerSection;
