import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ReactTable from '../common/UI/ReactTable';
import DeleteConfirmationModal from '../common/UI/CustomModal/deleteModal';
import Loader from '../common/UI/Loader';
import { toastSuccess } from '../common/UI/Toasts';
import api from '../api/api';
import deleteIcon from '../assets/images/trash.svg';

const ContactManagement = () => {
  const columns = [
    {
      Header: 'Full Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.first_name} {row.original.last_name}
          </span>
        );
      },
    },
    {
      Header: 'Email',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <a href={`mailto:${row.original.email}`}>{row.original.email}</a>
        );
      },
    },
    {
      Header: 'Phone',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <a href={`tel:${row.original.phone}`}>{row.original.phone}</a>;
      },
    },
    {
      Header: 'Comment',
      accessor: 'comment',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Submitted Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format('DD-MM-YYYY');
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleDelete(row)}
            >
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [deleteContactId, setDeleteContactId] = useState(null);
  const [deleteContactName, setDeleteContactName] = useState(null);
  const [seeAll, setSeeAll] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getContacts(currentPageNo);
  }, [currentPageNo, seeAll]);

  // Api functions

  const getContacts = (pageNo) => {
    const params = seeAll
      ? { showall: true }
      : {
          perPage: 10,
          page: pageNo,
        };
    api
      .getAllContacts(params)
      .then((res) => {
        // console.log('res', res?.data?.data?.docs);
        setData(res?.data?.data?.docs);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const deleteContact = () => {
    api
      .deleteContact(deleteContactId)
      .then((res) => {
        toastSuccess('Contact Deleted Successfully');
        setDeleteContactId(null);
        setDeleteContactName(null);
        getContacts(currentPageNo);
      })
      .catch((err) => {
        console.log('error', err);
        setDeleteContactId(null);
        setDeleteContactName(null);
      });
  };

  const handleDelete = (row) => {
    setModalShow(true);
    setDeleteContactName(
      `${row.original.first_name} ${row.original.last_name}`,
    );
    setDeleteContactId(row.original._id);
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const deleteModalOnHide = () => {
    setModalShow(false);
    setDeleteContactId(null);
    setDeleteContactName(null);
  };

  const deleteModalOnDelete = () => {
    setModalShow(false);
    deleteContact();
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <h3>Contact Us</h3>
                </div>
                <div className="middle_main">
                  <div className="row">
                    <div className="col-md-6 form-group"></div>
                    <div className="col-md-6 d-flex align-items-end flex-column">
                      <div className="form-check mt-3 mx-3">
                        <input
                          className="form-check-input check_box"
                          type="checkbox"
                          id="flexCheckChecked"
                          defaultChecked={seeAll}
                          onChange={() => setSeeAll(!seeAll)}
                        />
                        <label
                          className="form-check-label fs-5 checkbox_label"
                          htmlFor="flexCheckChecked"
                        >
                          See All
                        </label>
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <Loader loading={loading} />
                  ) : (
                    <ReactTable
                      title="FeaturedProducts"
                      tableColumns={columns}
                      tableData={data}
                      seeAll={seeAll}
                      nextHandle={nextHandle}
                      prevHandle={prevHandle}
                      totalPage={totalPage}
                      pageNo={currentPageNo}
                      handleDelete={handleDelete}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        name={deleteContactName}
        module={'Contact'}
        show={modalShow}
        handleToggle={deleteModalOnHide}
        handleDelete={deleteModalOnDelete}
      />
    </>
  );
};

export default ContactManagement;
