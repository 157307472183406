import React, { useEffect, useState } from 'react';
import SearchAndButtons from '../../../common/UI/Search&Buttons';
import Loader from '../../../common/UI/Loader';
import ReactTable from '../../../common/UI/ReactTable';
import BlogDetailsModal from '../AddOrEditBlog/blogDetailsModal';
import ViewBlog from '../ViewBlog';
import BlogEditor from '../AddOrEditBlog/blogEditor';
import api from '../../../api/api';
import moment from 'moment';
import debounce from 'lodash.debounce';
import ReactSwitch from 'react-switch';

import editIcon from '../../../assets/images/pencil.svg';
import deleteIcon from '../../../assets/images/trash.svg';
import eyeIcon from '../../../assets/images/eye_gray.svg';
import DeleteConfirmationModal from '../../../common/UI/CustomModal/deleteModal';
import AuthorDetailsModal from '../AddOrEditBlog/authorDetailsModal';
import { DIMENSION, PRODUCT_STATUS_OPTIONS } from '../../../common/constants';
import { toastSuccess } from '../../../common/UI/Toasts';
import { getFormDataFromObject } from '../../../utils/helpers';

const BlogListing = () => {
  const columns = [
    {
      Header: 'Blog Title',
      accessor: 'title',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Category',
      accessor: 'category.name',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Subcategory',
      // because we are getting different variable name in response of getAll and getOnSearch
      accessor: ({ subcategory, sub_category }) =>
        subcategory
          ? `${subcategory?.name || ''}`
          : `${sub_category?.name || ''}`,
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Date Published',
      accessor: ({ status, published_date }) =>
        status ? moment(published_date).format('YYYY-MM-DD') : 'Pending',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Status',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="align-items-center">
            <ReactSwitch
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={() => handleStatusChange(row)}
              checked={row.original?.status}
            />
          </div>
        );
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span className="me-2" onClick={() => handleTableEditButton(row)}>
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span className="me-2" onClick={() => handleTableDeleteButton(row)}>
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
            <span onClick={() => handleTableEyeButton(row)}>
              <img src={eyeIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];

  const [show, setShow] = useState('Table');
  const [showAddBlogDetailsModal, setShowAddBlogDetailsModal] = useState(false);
  const [showAuthorDetailsModal, setShowAuthorDetailsModal] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [blogInfo, setBlogInfo] = useState({});
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    getBlogs(currentPageNo);
  }, [currentPageNo, seeAll]);

  // Functions for api calls
  const getBlogs = (pageNo) => {
    setLoading(true);
    const params = seeAll
      ? { showall: true }
      : {
          perPage: 10,
          page: pageNo,
        };
    api
      .getAllBlogs(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const getOnSearch = (value, pageNo) => {
    const params = { showall: true, search: value };

    api
      .getBlogsOnSearch(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const deleteBlogs = (blogId) => {
    api
      .deleteBlog(blogId)
      .then((res) => {
        toastSuccess('Blog Deleted Successfully');
        setBlogInfo({});
        getBlogs(currentPageNo);
      })
      .catch((err) => {
        console.log('error', err);
        setBlogInfo({});
      });
  };

  const onSearchDebounce = debounce(getOnSearch, 1000);

  const onBlogSearchChange = (value) => {
    onSearchDebounce(value, currentPageNo);
  };

  const nextPageHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevPageHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const handleTableEditButton = (row) => {
    setShow('Edit');
    const editRowInfo = row.original;
    setBlogInfo({
      ...editRowInfo,
      category: {
        label: editRowInfo.category?.name,
        value: editRowInfo.category?.name,
        categoryId: editRowInfo.category?._id,
      },
      subcategory: {
        label: editRowInfo.subcategory?.name,
        value: editRowInfo.subcategory?.name,
        subcategoryId: editRowInfo.subcategory?._id,
      },
      status: PRODUCT_STATUS_OPTIONS.find(
        (option) => option.actualValue == editRowInfo.status,
      ),
      photo: {
        PrevUrl: editRowInfo?.photo?.location || null,
        imgData: editRowInfo?.photo,
      },
      author_photo: {
        PrevUrl: editRowInfo?.author_photo?.location || null,
        imgData: editRowInfo?.author_photo,
      },
      metadata: editRowInfo?.meta_data?.data,
    });
  };

  const handleTableDeleteButton = (row) => {
    setDeleteModalShow(true);
    setBlogInfo(row.original);
  };

  const handleTableEyeButton = (row) => {
    setShow('View');
    setBlogInfo(row.original);
  };

  const onAddButtonClick = () => {
    setShowAddBlogDetailsModal(true);
  };

  const seeAllBlogs = () => {
    setSeeAll((prev) => !prev);
  };

  const onClickOfBackButton = () => {
    setShow('Table');
    setBlogInfo({});
    getBlogs(currentPageNo);
  };

  const toggleDeleteModal = () => {
    setDeleteModalShow(false);
    setBlogInfo({});
  };

  const toggleAddBlogModal = () => {
    setShowAddBlogDetailsModal(false);
    setShowAuthorDetailsModal(false);
    setBlogInfo({});
  };

  const handleBlogDetailsNext = (moveFurther) => {
    if (moveFurther) {
      setShowAuthorDetailsModal(true);
      setShowAddBlogDetailsModal(false);
    }
  };

  const handleAuthorModalPrev = () => {
    setShowAddBlogDetailsModal(true);
    setShowAuthorDetailsModal(false);
  };

  const handleAuthorModalNext = () => {
    setShow('Add');
    setShowAuthorDetailsModal(false);
  };

  const deleteModalAction = () => {
    setDeleteModalShow(false);
    deleteBlogs(blogInfo?._id);
  };

  const updateBlogInfo = (key, value) => {
    setBlogInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleStatusChange = (row) => {
    let payload = {
      status: !row?.original?.status,
    };
    payload = getFormDataFromObject(payload);
    api
      .updateBlog(row?.original?._id, payload)
      .then((res) => {
        toastSuccess('Blog Status Updated Successfully');
        getBlogs();
      })
      .catch((err) => {
        console.log('error', err);
      });
  };
  return (
    <>
      {show == 'Table' ? (
        <div className="content_wrapper all_products_main">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="box_main">
                  <div className="header_top">
                    <h3>Blog Management</h3>
                  </div>
                  <div className="middle_main">
                    <SearchAndButtons
                      onSearchChange={onBlogSearchChange}
                      onButtonClick={onAddButtonClick}
                      searchPlaceholder={'Search by blog title...'}
                      buttonName={'Add Blog'}
                      seeAllButton={true}
                      seeAllHandle={seeAllBlogs}
                    />
                    {loading ? (
                      <Loader loading={loading} />
                    ) : (
                      <ReactTable
                        title="Blogs"
                        tableColumns={columns}
                        tableData={data}
                        seeAll={seeAll}
                        nextHandle={nextPageHandle}
                        prevHandle={prevPageHandle}
                        totalPage={totalPage}
                        pageNo={currentPageNo}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : show == 'View' ? (
        <ViewBlog blogInfo={blogInfo} backLinkHandle={onClickOfBackButton} />
      ) : (
        <BlogEditor
          backLinkHandle={onClickOfBackButton}
          action={show}
          blogInfo={blogInfo}
          updateBlogInfo={updateBlogInfo}
        />
      )}
      {showAddBlogDetailsModal && (
        <BlogDetailsModal
          isOpenedInEditorScreen={false}
          show={showAddBlogDetailsModal}
          handleToggle={toggleAddBlogModal}
          handleNext={handleBlogDetailsNext}
          blogInfo={blogInfo}
          updateBlogInfo={updateBlogInfo}
          imageDimention={DIMENSION.blog}
        />
      )}
      {showAuthorDetailsModal && (
        <AuthorDetailsModal
          isOpenedInEditorScreen={false}
          show={showAuthorDetailsModal}
          handleToggle={toggleAddBlogModal}
          handlePrev={handleAuthorModalPrev}
          handleNext={handleAuthorModalNext}
          blogInfo={blogInfo}
          updateBlogInfo={updateBlogInfo}
        />
      )}
      {deleteModalShow && (
        <DeleteConfirmationModal
          name={blogInfo?.title}
          module={'Blog'}
          show={deleteModalShow}
          handleToggle={toggleDeleteModal}
          handleDelete={deleteModalAction}
        />
      )}
    </>
  );
};

export default BlogListing;
