import * as network from './network';

// Keyword Apis

const getProductByBrand = (id, params) => {
  return network.get(`brand/getProductByBrand/${id}`, params, true);
};
const getAllBrands = (params) => {
  return network.get(`brand`, params, true);
};

const getBrandsOnSearch = (params) => {
  return network.get(`brand/search`, params, true);
};

const addBrand = (payload) => {
  return network.post(`brand`, payload, true);
};

const updateBrand = (id, payload) => {
  return network.put(`brand/${id}`, payload, true);
};

const deleteBrand = (id) => {
  return network.delet(`brand/${id}`, true);
};

export default {
  getAllBrands,
  getBrandsOnSearch,
  getProductByBrand,
  addBrand,
  updateBrand,
  deleteBrand,
};
