import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import SearchAndButtons from '../common/UI/Search&Buttons';
import OrderTable from '../components/Orders/orderTable';
import services from '../api/api';
import { isUserAdmin, isUserDoctor } from '../utils/helpers';
import { orderSearchbarPlaceholder } from '../common/constants';

const ApprovedOrdersManagement = () => {
  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    getOrders(currentPageNo);
  }, [currentPageNo, seeAll, searchVal]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getOrders = (pageNo, value = null) => {
    setLoading(true);
    let params = seeAll
      ? { showall: true }
      : {
          perPage: 10,
          page: pageNo,
        };

    params = {
      ...params,
      doctor_id: userId,
      has_medicine: true,
      doctor_approved: true,
    };

    if (searchVal)
      params = {
        ...params,
        search: searchVal,
      };

    services
      .getAllOrders(params)
      .then((res) => {
        let data = res?.data?.data?.docs;
        if (isUserDoctor()) {
          data = data.filter((el) => el?.has_medicine);
        }
        setData(data);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const handleOrderSearch = (value) => {
    setSearchVal(value);
  };

  const onSearchDebounce = debounce(handleOrderSearch, 1000);

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <h3>Order Management</h3>
                </div>
                <div className="middle_main">
                  <SearchAndButtons
                    onSearchChange={onSearchDebounce}
                    searchPlaceholder={orderSearchbarPlaceholder}
                    buttonName={isUserAdmin() ? 'Export Orders' : null}
                    seeAllHandle={seeAllHandle}
                    loading={loading}
                  />
                  <OrderTable
                    loading={loading}
                    prevHandle={prevHandle}
                    nextHandle={nextHandle}
                    data={data}
                    seeAll={seeAll}
                    totalPage={totalPage}
                    currentPageNo={currentPageNo}
                    tableFor={'Approved'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovedOrdersManagement;
