import React, { useState, useEffect } from 'react';
import ReactTable from '../../common/UI/ReactTable';
import SearchAndButtons from '../../common/UI/Search&Buttons';
import services from '../../api/api';
import debounce from 'lodash.debounce';
import editIcon from '../../assets/images/pencil.svg';
import deleteIcon from '../../assets/images/trash.svg';
import leftArrow from '../../assets/images/left_arrow.svg';
import rightArrow from '../../assets/images/right_arrow.svg';
import DeleteConfirmationModal from '../../common/UI/CustomModal/deleteModal';
import { toastError, toastSuccess } from '../../common/UI/Toasts';
import AddAndEditModal from '../../common/UI/CustomModal/addAndEditModal';
import Loader from '../../common/UI/Loader';

const RelatedKeyword = () => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      disableSortBy: true,
    },
    {
      Header: 'Action',
      disableSortBy: true,
      width: 40,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span className="me-2" onClick={() => handleEdit(row)}>
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span onClick={() => handleDelete(row)}>
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteKeywordId, setDeleteKeywordId] = useState(null);
  const [keywordEditInfo, setKeywordEditInfo] = useState({});
  const [deleteKeywordName, setDeleteKeywordName] = useState();
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [addAndEditModalShow, setAddAndEditModalShow] = useState(false);
  const [manageAddUpdate, setManageAddUpdate] = useState();
  const [first, setfirst] = useState([]);
  const [second, setsecond] = useState([]);
  const [third, setthird] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getKeywords(currentPageNo);
  }, [currentPageNo, seeAll]);

  useEffect(() => {
    setfirst(data.slice(0, 10));
    setsecond(data.slice(10, 20));
    setthird(data.slice(20, 30));
  }, [data]);

  const toggleAddEditModal = (data) => {
    setAddAndEditModalShow(false);
    setKeywordEditInfo({});
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  // Functions for api calls
  const getKeywords = (pageNo) => {
    const params = {
      perPage: 30,
      page: pageNo,
    };
    services
      .getAllKeyword(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const getOnSearch = (value) => {
    const params = {
      search: value,
    };
    services
      .getKeywordsOnSearch(params)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const deleteKeywords = () => {
    services
      .deleteKeyword(deleteKeywordId)
      .then((res) => {
        toastSuccess('Keyword Deleted Successfully');
        setDeleteKeywordId(null);
        getKeywords(currentPageNo);
      })
      .catch((err) => {
        console.log('error', err);
        setDeleteKeywordId(null);
      });
  };

  const onSearchDebounce = debounce(getOnSearch, 1000);

  const onKeywordSearchChange = (value) => {
    onSearchDebounce(value);
  };

  const handleDelete = (row) => {
    setDeleteKeywordName(row.original.name);
    setDeleteModalShow(true);
    setDeleteKeywordId(row.original._id);
  };

  const handleEdit = (row) => {
    setAddAndEditModalShow(true);
    setManageAddUpdate('Edit');
    setKeywordEditInfo({
      id: row.original._id,
      name: row.original.name,
    });
  };

  const handleUpdate = () => {
    const payload = {
      name: keywordEditInfo?.name,
    };
    services
      .updateKeyword(keywordEditInfo.id, payload)
      .then((res) => {
        setAddAndEditModalShow(false);
        getKeywords(currentPageNo);
        setKeywordEditInfo({});
        toastSuccess('Keyword Updated Successfully');
      })
      .catch((err) => {
        toastError(err?.message);
        setAddAndEditModalShow(true);
        console.log('error', err);
      });
  };

  const handleAdd = () => {
    setAddAndEditModalShow((prev) => !prev);
    const payload = {
      name: keywordEditInfo.name,
    };
    services
      .addKeyword(payload)
      .then((res) => {
        setAddAndEditModalShow(false);
        getKeywords(currentPageNo);
        setKeywordEditInfo({});
        toastSuccess('Keyword Added Successfully');
      })
      .catch((err) => {
        toastError(err?.message);
        setAddAndEditModalShow(true);
        console.log('error', err);
      });
  };

  const deleteModalOnHide = () => {
    setDeleteModalShow(false);
    setDeleteKeywordId(null);
  };

  const deleteModalOnDelete = () => {
    setDeleteModalShow(false);
    deleteKeywords();
  };

  const handleAddButtonClick = () => {
    setAddAndEditModalShow(true);
    setManageAddUpdate('Add');
  };

  return (
    <>
      {addAndEditModalShow && (
        <AddAndEditModal
          show={addAndEditModalShow}
          handleToggle={toggleAddEditModal}
          handleAction={manageAddUpdate === 'Add' ? handleAdd : handleUpdate}
          action={manageAddUpdate}
          module={'Keyword'}
        >
          <label className="form-check-label mb-2 fs-6" htmlFor="name">
            Name<span className="text-danger">*</span>
          </label>
          <input
            className="form-control me-2 shadow-none"
            style={{
              border: '1px solid #e5e5e5',
              borderRadius: '28px',
              width: '430px',
              height: '51px',
            }}
            id="name"
            type="text"
            onChange={(e) =>
              setKeywordEditInfo({
                ...keywordEditInfo,
                name: e.target.value,
              })
            }
            value={keywordEditInfo.name}
          />
        </AddAndEditModal>
      )}

      <SearchAndButtons
        onSearchChange={onKeywordSearchChange}
        onButtonClick={handleAddButtonClick}
        searchPlaceholder={'Search by keyword name...'}
        buttonName={'Add Keyword'}
        seeAllButton={false}
      />
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div className="row">
          <div className="col-md-4 mb-0 mt-4">
            <ReactTable
              title="Keyword"
              tableColumns={columns}
              tableData={first}
              seeAll={true}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          </div>
          {second.length ? (
            <div className="col-md-4 mb-0 mt-4">
              <ReactTable
                title="Keyword"
                tableColumns={columns}
                tableData={second}
                seeAll={true}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
              />
            </div>
          ) : null}
          {third.length ? (
            <div className="col-md-4 mb-0 mt-4">
              <ReactTable
                title="Keyword"
                tableColumns={columns}
                tableData={third}
                seeAll={true}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
              />
            </div>
          ) : null}
        </div>
      )}

      <div className="row">
        <div className="pagination justify-content-center">
          <div className="pagination__inner">
            <div className="pagination__btn previous ">
              <span>
                <button
                  onClick={() => prevHandle()}
                  disabled={currentPageNo == 1 ? true : false}
                >
                  <img
                    src={leftArrow}
                    alt="left arrow"
                    width="21"
                    height="21"
                  />
                </button>
              </span>
            </div>
            <div>
              <strong>{currentPageNo}</strong> of <strong>{totalPage}</strong>
            </div>
            <div className="pagination__btn  next">
              <span>
                <button
                  onClick={() => nextHandle()}
                  disabled={currentPageNo == totalPage ? true : false}
                >
                  <img
                    src={rightArrow}
                    alt="right arrow"
                    width="21"
                    height="21"
                  />
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {deleteModalShow && (
        <DeleteConfirmationModal
          name={deleteKeywordName}
          module={'keyword'}
          show={deleteModalShow}
          handleToggle={deleteModalOnHide}
          handleDelete={deleteModalOnDelete}
        />
      )}
    </>
  );
};

export default RelatedKeyword;
