import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from 'react-bootstrap';

const QuestionOptionModal = (props) => {
  return (
    <Modal
      style={{ zIndex: '5000' }}
      show={props.show}
      onHide={props.toggle}
      size="lg"
    >
      <ModalTitle>
        <span className="m-3 mt-2">Options</span>
      </ModalTitle>
      <ModalBody>
        <div
          className="d-flex align-items-center"
          style={{ justifyContent: 'space-between' }}
        >
          <Button className="" onClick={props.addOptions}>
            Add
          </Button>
          <div className="form-check">
            <input
              type={'checkbox'}
              className=" form-check-input"
              id="isSingle"
              checked={props.isSingle}
              onChange={(e) => props.handleChange(false, e.target.checked)}
            />
            <label
              className="mx-1 fs-20 fw-bold form-check-label"
              htmlFor="isSingle"
            >
              Single Select
            </label>
          </div>
        </div>
        {props.options?.map((el, index) => {
          return (
            <div
              key={index}
              className="d-flex align-items-center"
              style={{ justifyContent: 'space-between' }}
            >
              <input
                className="form-input m-1 p-1 w-100"
                id="title"
                type="text"
                placeholder="Option"
                value={el || ''}
                onChange={(e) => {
                  props.handleChange(index, e.target.value, 'option');
                }}
              />
              <FontAwesomeIcon
                className="m-1"
                onClick={() => props.deleteOption(index)}
                icon={faTrash}
              />
            </div>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex">
          <Button onClick={props.toggle}>Close</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default QuestionOptionModal;
