import React, { useState, useEffect } from 'react';
import SearchAndButtons from '../../../common/UI/Search&Buttons';
import ReactTable from '../../../common/UI/ReactTable';
import api from '../../../api/api';
import debounce from 'lodash.debounce';
import editIcon from '../../../assets/images/pencil.svg';
import deleteIcon from '../../../assets/images/trash.svg';
import DeleteConfirmationModal from '../../../common/UI/CustomModal/deleteModal';
import { toastError, toastSuccess } from '../../../common/UI/Toasts';
import { PRODUCT_TYPES } from '../../../common/constants';
import Loader from '../../../common/UI/Loader';
import { getProductOptions } from '../../../utils/productHelper';
import { useNavigate } from 'react-router-dom';

const SubCategorySection = (props) => {
  const columns = [
    {
      Header: 'Sub Category',
      accessor: 'name',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Category Name',
      accessor: 'category_id.name',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Product Type',
      accessor: 'category_id.product_type',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Action',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span className="me-2" onClick={() => handleEdit(row)}>
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span onClick={() => handleDelete(row)}>
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [deleteSubcategoryId, setDeleteSubcategoryId] = useState(null);
  const [deleteSubategoryName, setDeleteSubategoryName] = useState();
  const [loading, setLoading] = useState(false);

  // -----Used for Pagination
  // const [currentPageNo, setCurrentPageNo] = useState(1);
  // const [totalPage, setTotalPage] = useState(1);
  // const [seeAll, setSeeAll] = useState(true);

  useEffect(() => {
    setLoading(true);
    getSubcategories();
  }, []);

  // PAGINATION Handle
  // const nextHandle = () => {
  //   setCurrentPageNo((prev) => prev + 1);
  // };

  // const prevHandle = () => {
  //   setCurrentPageNo((prev) => prev - 1);
  // };

  // Function for api calls
  const getSubcategories = () => {
    // const params = seeAll
    //   ? { showall: true }
    //   : {
    //       perPage: 10,
    //       page: pageNo,
    //     };

    const params = { showall: true };
    api
      .getAllSubcategory(params)
      .then((res) => {
        setData(res?.data?.data.docs);
        // setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const getOnSearch = (value) => {
    const params = {
      search: value,
    };
    api
      .getSubcategoriesOnSearch(params)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const deleteSubcategories = () => {
    api
      .deleteSubcategory(deleteSubcategoryId)
      .then((res) => {
        toastSuccess('Subcategory Deleted Successfully');
        setDeleteSubcategoryId(null);
        getSubcategories();
      })
      .catch((err) => {
        console.log('error', err);
        toastError(err?.message);
        setDeleteSubcategoryId(null);
      });
  };

  const onSearchDebounce = debounce(getOnSearch, 1000);

  const onSubCategorySearchChange = (value) => {
    onSearchDebounce(value);
  };

  const onSubCategoryButtonClick = () => {
    navigate('/addsubcategory');
  };

  const handleDelete = (row) => {
    setDeleteSubategoryName(row.original.name);
    setModalShow(true);
    setDeleteSubcategoryId(row.original._id);
  };

  const handleEdit = (row) => {
    navigate(`/addsubcategory?id=${row.original._id}`);
  };

  const deleteModalOnHide = () => {
    setModalShow(false);
    setDeleteSubcategoryId(null);
  };

  const deleteModalOnDelete = () => {
    setModalShow(false);
    deleteSubcategories();
  };

  // NOTE: code for seeAll checkbox handle
  // const seeAllHandle = () => {
  //   setSeeAll((prev) => !prev);
  // };

  return (
    <>
      <SearchAndButtons
        onSearchChange={onSubCategorySearchChange}
        onButtonClick={onSubCategoryButtonClick}
        searchPlaceholder={
          'Search by sub category, category and product type...'
        }
        buttonName={'Add Subcategory'}
        seeAllButton={false}
        // seeAllHandle={seeAllHandle}
      />

      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div className="row">
          <div className="col-md-12 mb-0 mt-4">
            <ReactTable
              title="Sub Category"
              tableColumns={columns}
              tableData={data}
              seeAll={true}
              // if client wants pagination
              // nextHandle={nextHandle}
              // prevHandle={prevHandle}
              // totalPage={totalPage}
              // pageNo={currentPageNo}
            />
          </div>
        </div>
      )}
      <DeleteConfirmationModal
        name={deleteSubategoryName}
        module={'subcategory'}
        show={modalShow}
        handleToggle={deleteModalOnHide}
        handleDelete={deleteModalOnDelete}
      />
    </>
  );
};

export default SubCategorySection;
