import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import services from '../api/api';
import { PRODUCT_TYPES } from '../common/constants';
import Loader from '../common/UI/Loader';
import CategoryFormPage from '../components/CategoryPage/CategorySection/form';
import { getAllCategory } from '../store/reducers/category';

const addCategory = () => {
  const { categoryArr, loading } = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const [categoryEditInfo, setCategoryEditInfo] = useState({});
  const [params] = useSearchParams();
  const id = params.get('id');

  useEffect(() => {
    if (id && !categoryArr) {
      dispatch(getAllCategory());
    }
    window.onbeforeunload = () => '';
  }, []);

  useEffect(() => {
    if (id && categoryArr?.length) {
      let category = categoryArr.find((el) => el?._id === id);
      const image =
        typeof category?.image == 'string'
          ? JSON.parse(category?.image)
          : category?.image;
      setCategoryEditInfo({
        ...category,
        prodType: PRODUCT_TYPES.find(
          (option) => option.value == category?.product_type,
        ),
        categoryName: category?.name,
        categoryId: category?._id,
        photo: {
          PrevUrl: image?.location || null,
          imgData: image,
        },
      });
    }
  }, [categoryArr]);

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <CategoryFormPage categoryEditInfo={categoryEditInfo} />
      )}
    </>
  );
};

export default addCategory;
