import React, { useEffect, useState } from 'react';

import CustomDropdown from '../../common/UI/Dropdown';
import AddAndEditModal from '../../common/UI/CustomModal/addAndEditModal';
import { defaultForm, pageOptions } from './const';
import { toastError } from '../../common/UI/Toasts';

const AddModal = ({ show, toggle, data }) => {
  const [form, setForm] = useState({ ...defaultForm });

  useEffect(() => {
    if (data) {
      setForm((prevData) => ({
        ...prevData,
        ...data,
        page: pageOptions.find((p) => p.value === data.pageUrl),
      }));
    }
  }, [data]);

  const handleChange = (name, value) => {
    setForm((prevData) => {
      let errObj = { ...prevData.errors };
      if (errObj[name]) {
        delete errObj[name];
      }
      return {
        ...prevData,
        [name]: value,
        errors: errObj,
      };
    });
  };
  const handleValidate = (name, value) => {
    let errObj = { ...form.errors };
    if (name === 'page' && (!value || value.value)) {
      errObj[name] = `${name} is required.`;
    }
    setForm((prevData) => ({
      ...prevData,
      errors: { ...errObj },
    }));
  };

  const handleSubmit = () => {
    if (!form.page) {
      setForm((prevData) => ({
        ...prevData,
        errors: { ...prevData.errors, page: 'page is required' },
      }));
    } else if (!form.description && !form.keyword && !form.title) {
      toastError('Please enter one value');
    } else {
      toggle(form);
    }
  };

  return (
    <AddAndEditModal
      show={show}
      handleToggle={() => toggle()}
      handleAction={() => handleSubmit()}
      action={data && data._id ? 'Edit' : 'Add'}
      module={'SEO Details'}
      disabled={Object.keys(form.errors).length}
    >
      <div>
        <label className="form-check-label mb-2 fs-6">
          Page<span className="text-danger">*</span>
        </label>
        <CustomDropdown
          placeholder="Page"
          options={pageOptions}
          border={'1 px solid #dee2e6'}
          height={'50px'}
          value={form.page}
          onChange={(val) => handleChange('page', val)}
        />
        {form.errors.page ? (
          <span className="text-danger fs-13 mx-2">{form.errors.page}</span>
        ) : null}
      </div>
      <div>
        <label className="form-check-label mb-2 fs-6">Title</label>
        <input
          className="form-control shadow-none border"
          name="title"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          onBlur={(e) => handleValidate(e.target.name, e.target.value)}
          value={form.title}
        />
        {form.errors.title ? (
          <span className="text-danger fs-13 mx-2">{form.errors.title}</span>
        ) : null}
      </div>
      <div>
        <label className="form-check-label mb-2 fs-6">Description</label>
        <input
          className="form-control shadow-none border"
          name="description"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          onBlur={(e) => handleValidate(e.target.name, e.target.value)}
          value={form.description}
        />
        {form.errors.description ? (
          <span className="text-danger fs-13 mx-2">
            {form.errors.description}
          </span>
        ) : null}
      </div>
      <div>
        <label className="form-check-label mb-2 fs-6">Keyword</label>
        <input
          className="form-control shadow-none border"
          name="keyword"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          onBlur={(e) => handleValidate(e.target.name, e.target.value)}
          value={form.keyword}
        />
        {form.errors.keyword ? (
          <span className="text-danger fs-13 mx-2">{form.errors.keyword}</span>
        ) : null}
      </div>
    </AddAndEditModal>
  );
};

export default AddModal;
