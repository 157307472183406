import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api/api';

const initialState = {
  orderData: null,
  loading: false,
  pharmacyRejectOrders: { isSet: false, count: 0 },
  lexisNexisRejectOrders: { isSet: false, count: 0 },
  pharmacistsAndDoctors: [],
};

export const getOrderById = createAsyncThunk('order/getOrderById', (id) => {
  return api.getOrderById(id).then((res) => {
    let orderData = { ...res?.data?.data[0] };
    return orderData;
  });
});
export const getPharmacyRejectedOrdersCount = createAsyncThunk(
  'order/getPharmacyRejectedOrdersCount',
  () => {
    return api.getCountForPharmacyRejectedOrders().then((res) => {
      return res?.data?.data?.count || 0;
    });
  },
);
export const getLexisNexisRejectedOrdersCount = createAsyncThunk(
  'order/getLexisNexisRejectedOrdersCount',
  () => {
    return api.getCountForLexisNexisRejectedOrders().then((res) => {
      return res?.data?.data?.count || 0;
    });
  },
);

export const getPharmacistsAndDoctors = createAsyncThunk(
  'order/getPharmacistsAndDoctors',
  () => {
    return api
      .getUsers({ showall: true, role: 'pharmacist,doctor,admin' })
      .then((res) => {
        return res?.data?.data?.docs || [];
      });
  },
);
const orderReducer = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    emptyOrderData: (state, action) => {
      state.orderData = null;
    },
  },
  extraReducers: {
    [getOrderById.pending]: (state) => {
      state.loading = true;
    },
    [getOrderById.rejected]: (state) => {
      state.loading = false;
    },
    [getOrderById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.orderData = payload;
    },
    [getPharmacyRejectedOrdersCount.fulfilled]: (state, { payload }) => {
      state.pharmacyRejectOrders = { isSet: true, count: payload };
    },
    [getPharmacistsAndDoctors.fulfilled]: (state, { payload }) => {
      state.pharmacistsAndDoctors = payload;
    },
    [getLexisNexisRejectedOrdersCount.fulfilled]: (state, { payload }) => {
      state.lexisNexisRejectOrders = { isSet: true, count: payload };
    },
  },
});
export default orderReducer.reducer;
export const { setOrderData, emptyOrderData } = orderReducer.actions;
