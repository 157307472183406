import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { Button } from 'react-bootstrap';
import ReactAsyncSelect from 'react-select/async';
import ReactTable from '../../common/UI/ReactTable';
import DeleteConfirmationModal from '../../common/UI/CustomModal/deleteModal';
import Loader from '../../common/UI/Loader';
import { toastSuccess, toastError } from '../../common/UI/Toasts';
import api from '../../api/api';
import editIcon from '../../assets/images/pencil.svg';
import deleteIcon from '../../assets/images/trash.svg';
import plusIcon from '../../assets/images/plus.svg';
import FeaturedProductForm from './editForm';
import { getProductOptions } from '../../utils/productHelper';

const FeaturedProductsManagement = () => {
  const columns = [
    {
      Header: 'Product Name',
      accessor: 'product.name',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'SKU',
      accessor: 'product.sku',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Brand',
      accessor: 'product.brand.name',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Stock',
      accessor: 'product.stock.stock_level',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'RRP',
      accessor: 'product.stock.rrp_price',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span
              className="me-2"
              style={{ cursor: 'pointer' }}
              onClick={() => handleEdit(row)}
            >
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleDelete(row)}
            >
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [deleteFeaturedProductId, setDeleteFeaturedProductId] = useState(null);
  const [deleteFeaturedProductName, setDeleteFeaturedProductName] =
    useState(null);
  const [isForm, setIsForm] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [featureProduct, setFeaturedProduct] = useState(null);
  const [productOptions, setProductOptions] = useState([]);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getFeaturedProducts(currentPageNo);
    getProducts();
  }, [currentPageNo, seeAll, isForm]);

  // Api functions
  const getProducts = () => {
    const params = {
      perPage: 10,
      page: 1,
      admin: true,
    };
    api
      .getProductLabels(params)
      .then((res) => {
        let productOptions = getProductOptions(res?.data?.data?.docs);
        setProductOptions(productOptions);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const loadOptions = (inputValue, callback, fieldName) => {
    getAsyncOptions(inputValue, fieldName).then((results) => callback(results));
    return;
  };

  const debouncedLoadOptions = debounce(loadOptions, 500);

  const getAsyncOptions = (inputValue, fieldName) => {
    if (fieldName === 'products') {
      return getAsyncProducts({ name: inputValue });
    }
  };

  const getAsyncProducts = async (params) => {
    try {
      params = {
        ...params,
        perPage: 10,
        page: 1,
      };
      const response = await api.getProductLabels(params);
      let productData = response?.data?.data?.docs;
      return getProductOptions(productData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFeatureProductSubmit = (pid, fid, description, stype) => {
    if (pid && description && stype) {
      if (fid && stype === 'update') {
        const payload = {
          product_id: pid,
          description: description,
        };
        api
          .updateFeatureProduct(fid, payload)
          .then((res) => {
            toastSuccess('Featured product updated sucessfully');
            setIsForm(false);
          })
          .catch((err) => {
            // console.log(err, 'err');
            toastError(err.message);
          });
      } else {
        const payload = {
          product_id: pid,
          description: description,
        };
        api
          .addFeatureProduct(payload)
          .then((res) => {
            toastSuccess('Featured product added sucessfully');
            setFeaturedProduct(null);
            setIsForm(false);
          })
          .catch((err) => {
            // console.log(err, 'err');
            toastError(err.message);
          });
      }
    } else {
      toastError('Please enter description');
    }
  };

  const getFeaturedProducts = (pageNo) => {
    const params = seeAll
      ? { showall: true }
      : {
          perPage: 10,
          page: pageNo,
        };
    api
      .getAllFeaturedProducts(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const deleteFeaturedPorducts = () => {
    api
      .deleteFeaturedProduct(deleteFeaturedProductId)
      .then((res) => {
        toastSuccess('Featured Product Deleted Successfully');
        setDeleteFeaturedProductId(null);
        setDeleteFeaturedProductName(null);
        getFeaturedProducts(currentPageNo);
      })
      .catch((err) => {
        console.log('error', err);
        setDeleteFeaturedProductId(null);
        setDeleteFeaturedProductName(null);
      });
  };

  const handleDelete = (row) => {
    console.log(row);
    setModalShow(true);
    setDeleteFeaturedProductName(row.original.product_id.name);
    setDeleteFeaturedProductId(row.original._id);
  };

  const handleEdit = (row) => {
    setFormData(row.original);
    setFeaturedProduct(null);
    setIsForm(true);
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const deleteModalOnHide = () => {
    setModalShow(false);
    setDeleteFeaturedProductId(null);
    setDeleteFeaturedProductName(null);
  };

  const deleteModalOnDelete = () => {
    setModalShow(false);
    deleteFeaturedPorducts();
  };

  return (
    <>
      {isForm ? (
        <FeaturedProductForm
          setIsForm={setIsForm}
          formData={formData}
          selectedProduct={featureProduct}
          handleFeatureProductSubmit={handleFeatureProductSubmit}
        />
      ) : (
        <>
          <div className="content_wrapper all_products_main">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="box_main">
                    <div className="header_top">
                      <h3>Featured Products</h3>
                    </div>
                    <div className="middle_main">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <ReactAsyncSelect
                            name="featured_product"
                            value={featureProduct}
                            placeholder={<div>Search & Select Product</div>}
                            loadOptions={(inputValue, callback) =>
                              debouncedLoadOptions(
                                inputValue,
                                callback,
                                'products',
                              )
                            }
                            defaultOptions={productOptions}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            onChange={(e) => {
                              setFeaturedProduct(e);
                              setFormData({});
                            }}
                            controlShouldRenderValue={true}
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-end flex-column">
                          <div style={{ paddingLeft: '1.5rem' }}>
                            <Button
                              type="submit"
                              className="btn-green d-flex align-items-center ms-auto"
                              onClick={() =>
                                featureProduct
                                  ? setIsForm(true)
                                  : toastError('Please select product')
                              }
                            >
                              <img
                                src={plusIcon}
                                width="24"
                                height="24"
                                alt="pencil"
                                className="me-2"
                              />
                              Add
                            </Button>
                          </div>
                          <div className="form-check mt-3 mx-3">
                            <input
                              className="form-check-input check_box"
                              type="checkbox"
                              id="flexCheckChecked"
                              defaultChecked={seeAll}
                              onChange={() => setSeeAll(!seeAll)}
                            />
                            <label
                              className="form-check-label fs-5 checkbox_label"
                              htmlFor="flexCheckChecked"
                            >
                              See All
                            </label>
                          </div>
                        </div>
                      </div>
                      {loading ? (
                        <Loader loading={loading} />
                      ) : (
                        <ReactTable
                          title="FeaturedProducts"
                          tableColumns={columns}
                          tableData={data}
                          seeAll={seeAll}
                          nextHandle={nextHandle}
                          prevHandle={prevHandle}
                          totalPage={totalPage}
                          pageNo={currentPageNo}
                          handleDelete={handleDelete}
                          handleEdit={handleEdit}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DeleteConfirmationModal
            name={deleteFeaturedProductName}
            module={'Featured Product'}
            show={modalShow}
            handleToggle={deleteModalOnHide}
            handleDelete={deleteModalOnDelete}
          />
        </>
      )}
    </>
  );
};

export default FeaturedProductsManagement;
