import React from 'react';
import GeneralHealth from '../components/GeneralHealthQuestions';

const GeneralHealthQuestions = () => {
  return (
    <>
      <GeneralHealth />
    </>
  );
};

export default GeneralHealthQuestions;
