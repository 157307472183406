import React from 'react';

const ImageProductPreviewModal = (props) => {
  return (
    <>
      <img
        src={props.imageFile}
        alt="viewProduct"
        width=""
        height=""
        className="img-fluid w-100"
      />
    </>
  );
};

export default ImageProductPreviewModal;
