import * as network from './network';

const getAllCouponcodes = (params) => {
  return network.get('coupon-code', params, true);
};

const deleteCouponcode = (id) => {
  return network.delet(`coupon-code/${id}`, true);
};

const addCouponcode = (body) => {
  return network.post('coupon-code', body, true);
};

const updateCouponcode = (id, body) => {
  return network.put(`coupon-code/${id}`, body, true);
};

const searchCouponcode = (params) => {
  return network.get('coupon-code/search', params, true);
};

export default {
  getAllCouponcodes,
  deleteCouponcode,
  addCouponcode,
  updateCouponcode,
  searchCouponcode,
};
