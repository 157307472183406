import React from 'react';
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import services from '../../api/api';
import {
  PRODUCT_OPTIONS,
  BANNER_TYPE,
  DIMENSION,
} from '../../common/constants';
import CustomAsyncCreatableReactSelect from '../../common/FormComponents/AsyncCreatableReactSelect';
import DatePickerField from '../../common/FormComponents/DatePickerField';
import ImageInput from '../../common/FormComponents/ImageInput';
import CustomInput from '../../common/FormComponents/Input';
import CustomReactSelect from '../../common/FormComponents/ReactSelect';
import { toastSuccess } from '../../common/UI/Toasts';
import { getErrorMessage, getFormDataFromObject } from '../../utils/helpers';
import moment from 'moment';

const validationSchema = Yup.object({
  title: Yup.string().required(getErrorMessage('select-required', 'Title')),
  banner_image: Yup.array()
    .nullable()
    .min(1, getErrorMessage('required', 'image file')),
  publish_date: Yup.date()
    .nullable()
    .required(getErrorMessage('select-required', 'publish date')),
  bannerType: Yup.object()
    .nullable()
    .required(getErrorMessage('select-required', 'option')),
});

const BannerFormPage = (props) => {
  const {
    name,
    backLinkHandle,
    bannerEditInfo,
    debouncedLoadOptions,
    handleChange,
    selectedOptionValue,
  } = props;
  console.log(
    '🚀 ~ file: form.js:43 ~ BannerFormPage ~ bannerEditInfo',
    bannerEditInfo,
  );

  const onSubmit = (values, onSubmitProps) => {
    console.log('🚀 ~ file: form.js:45 ~ onSubmit ~ values', values);
    let payload;
    if (values?.linkProductBrandData?.value && values?.referal?.value) {
      let referalValue;
      values?.referal?.value === 'categories'
        ? (referalValue = 'product_categories')
        : (referalValue = values?.referal?.value);
      payload = {
        title: values?.title,
        // banner_image: values?.banner_image[0],
        publish_date: values?.publish_date,
        linkProductBrandData: values?.linkProductBrandData?.value,
        referal: referalValue,
        bannerType: values?.bannerType?.value,
        status: values?.status,
      };
    } else {
      payload = {
        title: values?.title,
        // banner_image: values?.banner_image[0],
        publish_date: values?.publish_date,
        bannerType: values?.bannerType?.value,
        status: values?.status,
      };
    }
    console.log('payload', payload);

    // Update banner
    if (bannerEditInfo && bannerEditInfo.banner_id) {
      let editPayload = {
        ...payload,
      };

      // if user has uploaded new image
      if (
        values?.banner_image[0]?.imgData &&
        values?.banner_image[0]?.imgData instanceof File
      ) {
        editPayload.banner_image = values.banner_image[0].imgData;
      }
      // old image
      else {
        editPayload.old_banner_image = JSON.stringify(values.banner_image[0]);
      }
      console.log('edit payload ', editPayload);
      editPayload = getFormDataFromObject(editPayload);
      services
        .updateBanner(bannerEditInfo.banner_id, editPayload)
        .then(() => {
          backLinkHandle(name);
          toastSuccess('Banner Updated Successfully');
        })
        .catch((err) => {
          console.log('error', err);
          onSubmitProps.setFieldError('bannerName', err.message);
        });
    }
    // Add Banner
    else {
      payload.status = true;
      payload.banner_image = values?.banner_image[0]?.imgData;
      payload = getFormDataFromObject(payload);
      services
        .addBanner(payload)
        .then(() => {
          onSubmitProps.resetForm();
          toastSuccess('Banner Added Successfully');
        })
        .catch((err) => {
          console.log('error', err);
          onSubmitProps.setFieldError('bannerName', err.message);
        });
    }
  };
  return (
    <section className="content_wrapper product_add_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ol className="breadcrumb">
              <li>
                <Link
                  to={'/banner'}
                  title="Banner Management"
                  onClick={() => backLinkHandle(name)}
                >
                  Banner Management
                </Link>
              </li>
              {name === 'addBanner' && <li>Add Banner Details</li>}
              {name === 'editBanner' && <li>Edit Banner Details</li>}
              {name === 'viewBanner' && <li>View Details</li>}
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              <div className="header_top">
                <h3>
                  {name === 'viewBanner' ? 'View Details' : 'Banner Management'}
                </h3>
              </div>
            </div>
            {name !== 'viewBanner' ? (
              <Formik
                initialValues={{
                  title: bannerEditInfo?.title || '',
                  banner_image: bannerEditInfo?.banner_image || [],
                  status: bannerEditInfo ? bannerEditInfo?.status : false,
                  linkProductBrandData:
                    bannerEditInfo?.linkProductBrandData || null,
                  publish_date: bannerEditInfo?.publish_date || '',
                  referal: bannerEditInfo?.referal || null,
                  bannerType: bannerEditInfo?.bannerType || null,
                }}
                onSubmit={onSubmit}
                validateOnChange={false}
                validationSchema={validationSchema}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className="middle_main_2">
                        <div className="row mb-3">
                          <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                            <CustomInput
                              name="title"
                              id="title"
                              type="text"
                              label="Banner Title"
                              labelclassname="form-label"
                              placeholder="Banner 1"
                              className="form-control form-input mt-3"
                              value={formik.values.title}
                              isRequired
                            />
                          </div>
                          <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                            <CustomReactSelect
                              label="Link Product / Brand / Categories"
                              labelClassname={'mb-3'}
                              name="referal"
                              id="referal"
                              className="form-control"
                              placeholderColor="#9aa5ad"
                              height={'40px'}
                              placeholderMarginTop={'-5px'}
                              validationType="required"
                              errorFieldName="Product Type"
                              customSelectBorder={'1px solid #9aa5ad'}
                              options={PRODUCT_OPTIONS}
                              value={formik?.values?.referal}
                              onChangeAction={(selectedOption) => {
                                formik.values.linkProductBrandData = null;
                                handleChange(selectedOption);
                              }}
                            />
                          </div>
                          <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                            <CustomAsyncCreatableReactSelect
                              height={'40px'}
                              selectType="async"
                              label={`Options ${
                                formik.values?.referal?.value === undefined
                                  ? ''
                                  : `for ${formik.values?.referal?.value}`
                              }`}
                              labelClassname={'mb-3'}
                              className={'text-muted'}
                              cacheOptions
                              name="linkProductBrandData"
                              placeholder={'Select option'}
                              loadOptions={(inputValue, callback) =>
                                debouncedLoadOptions(
                                  inputValue,
                                  callback,
                                  formik.values.referal,
                                )
                              }
                              defaultOptions={selectedOptionValue}
                              options={selectedOptionValue}
                              isSearchable={true}
                              closeMenuOnSelect={true}
                            />
                          </div>
                          <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                            <CustomReactSelect
                              label="Banner Type"
                              labelClassname={'mb-3'}
                              name="bannerType"
                              id="bannerType"
                              className="form-control"
                              placeholderColor="#9aa5ad"
                              height={'40px'}
                              placeholderMarginTop={'-5px'}
                              validationType="required"
                              errorFieldName="Product Type"
                              customSelectBorder={'1px solid #9aa5ad'}
                              options={BANNER_TYPE}
                              value={formik.values?.bannerType}
                              isRequired
                            />
                          </div>
                          <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                            <DatePickerField
                              label="Publish Date"
                              labelClassname={'mb-3'}
                              placeholder="Select publish date "
                              className="form-control form-input px-4"
                              name="publish_date"
                              inputDateFormat="dd/MM/yyyy"
                              onDaySelect={() => {}} //when day is clicked
                              onBlur={() => console.log('blur')}
                              isRequired
                            />
                          </div>
                        </div>
                        <ImageInput
                          label={
                            formik.values?.bannerType?.value
                              ? formik.values?.bannerType?.value === 'big'
                                ? `Upload Image : (w x h :${DIMENSION.bigBanner.width}x${DIMENSION.bigBanner.height})`
                                : `Upload Image : (w x h :${DIMENSION.smallBanner.width}x${DIMENSION.smallBanner.height})`
                              : null
                          }
                          subLabel={'Select an image.'}
                          accept="image/png, image/svg, image/jpeg, image/jpg, image/PNG, image/SVG, image/JPEG, image/JPG, image/webp"
                          name={'banner_image'}
                          id={'banner_image'}
                          imagesLimit={1}
                          imageDimention={
                            formik.values?.bannerType?.value === 'big'
                              ? DIMENSION.bigBanner
                              : DIMENSION.smallBanner
                          }
                          isRequired
                        />
                        <div className="footer_main">
                          <Button
                            type="button"
                            variant="sky"
                            className="my-2 me-2"
                            onClick={() => backLinkHandle(name)}
                          >
                            Back
                          </Button>
                          <Button className="me-2" type="submit">
                            {bannerEditInfo && bannerEditInfo.banner_id
                              ? 'Update'
                              : 'Save'}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <div className="middle_main">
                <div className="row">
                  <div className="col-md-5 col-lg-6 col-sm-6">
                    <img
                      src={bannerEditInfo?.banner_image[0]?.PrevUrl}
                      alt={bannerEditInfo?.title}
                      className="slider_img"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="mt-4">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>
                              <p className="des_2">Banner Title</p>
                            </td>
                            <td>
                              <p className="des_3">{bannerEditInfo?.title}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="des_2">Link Prodcut/Brand</p>
                            </td>
                            <td>
                              <p className="des_3">
                                {bannerEditInfo?.linkProductBrandData}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="des_2">Published Date</p>
                            </td>
                            <td>
                              <p className="des_3">
                                {moment(bannerEditInfo?.publish_date).format(
                                  'DD/MM/YYYY',
                                )}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="footer_main">
                    <Button
                      type="button"
                      variant="sky"
                      className="my-2 me-2"
                      onClick={() => backLinkHandle(name)}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerFormPage;
