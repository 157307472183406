import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import api from '../../api/api';

const FeaturedProductForm = (props) => {
  const { setIsForm, selectedProduct, formData, handleFeatureProductSubmit } =
    props;
  const [form, setFormData] = useState({});
  const [description, setDescription] = useState(
    formData && formData.description ? formData.description : '',
  );
  const [error, setError] = useState('');
  const productId = selectedProduct
    ? selectedProduct.value
    : formData.product_id;
  useEffect(() => {
    api.getProductById(productId).then((res) => {
      const prodData = res?.data?.data || {};
      setFormData(prodData);
    });
  }, []);
  const stockData = (form?.stock || []).find((s) => s.isPrimary);
  return (
    <section className="content_wrapper product_add_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ol className="breadcrumb">
              <li>
                <Link
                  to={'#'}
                  title="Featured Product Management"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsForm(false);
                  }}
                >
                  Featured Product Management
                </Link>
              </li>
              <li>{form ? 'Edit' : 'Add'} Featured Product</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              <div className="header_top">
                <h3>{form ? 'Edit' : 'Add'} Featured Product</h3>
              </div>
              <div className="middle_main_2">
                <div className="row">
                  <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>
                            <p className="des_2">Product Name: </p>
                          </td>
                          <td>
                            <p className="des_3">
                              {form ? form?.name : form?.name}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="des_2">SKU: </p>
                          </td>
                          <td>
                            <p className="des_3">
                              {form ? form?.sku : form?.sku}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="des_2">Brand: </p>
                          </td>
                          <td>
                            <p className="des_3">
                              {form ? form?.brand?.name : form?.brand?.name}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>
                            <p className="des_2">Stock: </p>
                          </td>
                          <td>
                            <p className="des_3">{stockData?.stock_level}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="des_2">RRP: </p>
                          </td>
                          <td>
                            <p className="des_3">{stockData?.rrp_price}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-xl-8 form-group">
                    <label htmlFor="description" className="form-label">
                      Featured Product Description ( limit: 200 characters)
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      rows="5"
                      type="textarea"
                      className="form-control form-textarea"
                      placeholder="Write the description here..."
                      autoComplete="off"
                      value={description}
                      onChange={(e) => {
                        if (e.target.value.length > 200) {
                          setError('You cannot exceed 200 characters');
                          return;
                        }
                        setDescription(e.target.value);
                      }}
                    ></textarea>
                    <span>{`${description.length}/200`}</span>
                    <br />
                    {error ? (
                      <span className="text-danger fs-16">{error}</span>
                    ) : null}
                  </div>
                </div>
                <div className="footer_main">
                  <Button
                    type="button"
                    variant="sky"
                    className="my-2 me-2"
                    onClick={() => setIsForm(false)}
                  >
                    Back
                  </Button>
                  <Button
                    className="me-2"
                    type="button"
                    onClick={() =>
                      handleFeatureProductSubmit(
                        productId,
                        formData ? formData?._id : null,
                        description,
                        formData?._id ? 'update' : 'add',
                      )
                    }
                  >
                    {formData?._id ? 'Update' : 'Save'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedProductForm;
