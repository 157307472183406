import React from 'react';
import BrandPage from '../components/BrandPage';

const BrandManagement = () => {
  return (
    <>
      <BrandPage />
    </>
  );
};

export default BrandManagement;
