import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../common/UI/Loader';
import OrderProductPage from '../components/Orders/ViewOrderProduct';
import { getOrderById } from '../store/reducers/order';
import { isUserAdmin, isUserDoctor, isUserPharmacist } from '../utils/helpers';

const ViewOrderProduct = () => {
  const [isAccessible, setIsAccessible] = useState(false);
  const [stateLoading, setStateLoading] = useState(true);
  const { orderData, loading } = useSelector((state) => state.order);
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!orderData || (orderData && !Object.keys(orderData).length)) {
      dispatch(getOrderById(id));
    }
  }, []);
  useEffect(() => {
    if (isUserPharmacist()) {
      if (orderData) {
        const loggedInUser = localStorage.getItem('userId');
        if (
          isUserPharmacist() &&
          (!orderData?.pharmacist_id ||
            (orderData?.pharmacist_id &&
              orderData?.pharmacist_id !== loggedInUser))
        ) {
          setIsAccessible(false);
          setStateLoading(false);
        } else {
          setIsAccessible(true);
          setStateLoading(false);
        }
      }
    } else if (isUserAdmin() || isUserDoctor()) {
      setStateLoading(loading);
      setIsAccessible(true);
    }
  }, [orderData, loading]);
  return (
    <div className="content_wrapper all_products_main">
      <div className="container-fluid">
        {stateLoading ? (
          <Loader loading={stateLoading} />
        ) : isAccessible ? (
          <OrderProductPage />
        ) : (
          <h1 className="no_access">Sorry user, No page for you!</h1>
        )}
      </div>
    </div>
  );
};

export default ViewOrderProduct;
