import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FieldArray, Formik, Form } from 'formik';
import CustomAsyncCreatableReactSelect from '../../FormComponents/AsyncCreatableReactSelect';
import { METADATA_NAME_OPTIONS } from '../../constants';
import { toastError } from '../Toasts';

const SEOModal = (props) => {
  const { show, centered, handleToggle, onSubmit, initialValues, editId } =
    props;

  useEffect(() => {
    console.log('initialValues', initialValues);
  }, []);

  const processBeforeSubmission = (values) => {
    const nameValues = values.metadata
      .map((el) => el.name.value != '' && el.name.value)
      .filter((el) => el != undefined);
    const nameValuesSet = new Set(nameValues);
    if (nameValues.length !== nameValuesSet.size) {
      toastError('Name should be unique');
    } else {
      let metadataObj = {};
      values.metadata.map((el) => {
        if (el.name.value) {
          metadataObj[el.name.value] = el.content;
        }
      });
      onSubmit(metadataObj);
    }
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleToggle}
      centered={centered || true}
      className="custom_modal"
      style={{ zIndex: '9999' }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="text-black fw-bold fs-5">
            {editId ? 'Edit' : 'Add'} Meta Data Details
          </h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues || {}}
          onSubmit={processBeforeSubmission}
        >
          {(formik) => {
            return (
              <Form>
                <FieldArray id={'metadata'} name={'metadata'}>
                  {(arrayHelpers) => {
                    return (
                      <>
                        <div
                          className="d-flex align-items-center"
                          style={{
                            height: '41px',
                          }}
                        >
                          <label className="form-label mt-2" htmlFor="metadata">
                            Meta Data
                          </label>

                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            size={'2x'}
                            color={'#088fff'}
                            className="m-4"
                            onClick={() =>
                              arrayHelpers.push({
                                name: '',
                                content: '',
                              })
                            }
                          />
                        </div>
                        {formik.values.metadata?.map((value, index) => {
                          return (
                            <div
                              className="d-flex justify-content-between mt-2"
                              key={index}
                            >
                              <CustomAsyncCreatableReactSelect
                                selectType="creatable"
                                options={METADATA_NAME_OPTIONS}
                                height={'40px'}
                                isMulti={false}
                                controlHeight={'30px'}
                                containerWidth={'250px'}
                                label="Name"
                                labelClassname="form-label"
                                id={`metadata.${index}.name`}
                                name={`metadata.${index}.name`}
                                placeholder={'Search & select name'}
                                isSearchable={true}
                                isClearable={false}
                                closeMenuOnSelect={true}
                                value={value.name}
                              />{' '}
                              <div className="ms-2 w-50">
                                <label
                                  className="form-label"
                                  htmlFor="metadata"
                                >
                                  Content
                                </label>
                                <ReactTextareaAutosize
                                  id={`metadata.${index}.content`}
                                  name={`metadata.${index}.content`}
                                  type="text"
                                  className="form-control form_input"
                                  placeholder=""
                                  onChange={(e) => {
                                    console.log('in on change');
                                    formik.setFieldValue(
                                      `metadata.${index}.content`,
                                      e.target.value,
                                      false,
                                    );
                                  }}
                                  value={value.content}
                                  minRows={1}
                                />
                              </div>
                              <div className="mt-3">
                                <FontAwesomeIcon
                                  icon={faMinusCircle}
                                  size={'2x'}
                                  color={'#088fff'}
                                  className="m-4"
                                  onClick={() => arrayHelpers.remove(index, '')}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    );
                  }}
                </FieldArray>
                <div className="row">
                  <hr
                    style={{
                      border: '1px solid #9aa5ad',
                      width: '811px',
                    }}
                  />
                  <div className="col-12 d-flex justify-content-end">
                    <Button variant="sky" size="sm" onClick={handleToggle}>
                      Close
                    </Button>
                    <Button className="ms-2" type="submit" size="sm">
                      {editId ? 'Update' : 'Add'}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default SEOModal;
