import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isLoggedIn, getCurrentUserRole } from './utils/helpers';

const requireAuth = (ComposedComponent, allowedRoles = [], ...rest) => {
  const RequireAuth = (props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
      if (!isLoggedIn() && pathname !== '/login') {
        navigate('/login');
      }
    }, []);

    if (allowedRoles.includes(getCurrentUserRole())) {
      return <ComposedComponent {...props} {...rest} />;
    } else {
      return <h1 className="no_access">Sorry user, No page for you!</h1>;
    }
  };

  return RequireAuth();
};

export default requireAuth;
