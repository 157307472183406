import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import services from '../../api/api';
import {
  getItemStyle,
  getListStyleSelected,
  reorder,
} from '../../utils/productHelper';
import { toastSuccess } from '../../common/UI/Toasts';

const RearrangeBanner = (props) => {
  const { name, backLinkHandle, bannerType } = props;
  const [banners, updateBanners] = useState([]);
  const [isDragged, setIsDragged] = useState(false);

  const getBanners = () => {
    const params = {
      showall: true,
      bannerType: bannerType === 'big' ? 'big' : 'small',
      status: true,
    };
    services
      .getAllBanner(params)
      .then((res) => {
        updateBanners(res?.data?.data?.docs);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };
  useEffect(() => {
    getBanners();
  }, []);

  // When image drag
  const onDragEndImage = (result) => {
    setIsDragged(true);
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const photos = banners;
      let prodImagesReordered = reorder(
        photos,
        source.index,
        destination.index,
      );
      prodImagesReordered = prodImagesReordered.map((el, index) => {
        return {
          ...el,
          location: index + 1,
        };
      });
      updateBanners(prodImagesReordered);
    }
  };
  const bulkLocationUpdate = () => {
    const relocatedBannerData = { banners: banners };
    services
      .bannerRelocation(relocatedBannerData)
      .then((res) => {
        toastSuccess('Banners location Updated Successfully');
        backLinkHandle();
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  return (
    <>
      <section className="content_wrapper product_add_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <ol className="breadcrumb">
                <li>
                  <Link
                    to={'/banner'}
                    title="Banner Management"
                    onClick={() => backLinkHandle(name)}
                  >
                    Banner Management
                  </Link>
                </li>
                <li>Rearrange Banner</li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top d-flex justify-content-between">
                  <h3>
                    Arrange Position for
                    {`${
                      bannerType === 'bigBanner'
                        ? ' Big Banner'
                        : ' Small Banner'
                    }`}
                  </h3>
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!isDragged}
                    onClick={() => bulkLocationUpdate()}
                  >
                    Update Position
                  </button>
                </div>
              </div>
              <div className="middle_main_2 mt-3">
                <DragDropContext onDragEnd={onDragEndImage}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <div
                        className="row "
                        ref={provided.innerRef}
                        style={{
                          ...getListStyleSelected(snapshot.isDraggingOver),
                          padding: '0px',
                        }}
                      >
                        {banners.length
                          ? banners.map((elem, index) => {
                              return (
                                <div className="col-md-2 col-sm-6" key={index}>
                                  <Draggable
                                    isDragDisabled={false}
                                    key={elem._id}
                                    draggableId={`main${index}`}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style,
                                        )}
                                      >
                                        <div key={index} className="">
                                          <div className="position-relative">
                                            <img
                                              src={elem.banner_image?.location}
                                              alt="banner"
                                              style={{
                                                borderRadius: '16px',
                                                width: '100%',
                                                // height: '150px',
                                                objectFit: 'contain',
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                </div>
                              );
                            })
                          : null}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RearrangeBanner;
