import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, Modal, ModalBody, ModalHeader } from 'react-bootstrap';

import api from '../../../api/api';

import CustomInput from '../../../common/FormComponents/Input';
import CustomReactSelect from '../../../common/FormComponents/ReactSelect';
import { subQuestionIDAndKeys } from '../../../common/constants';
import { toastError } from '../../../common/UI/Toasts';
import { cloneDeep, debounce } from 'lodash';
import CustomAsyncCreatableReactSelect from '../../../common/UI/AsyncCreatableReactSelect';

const GPAddressModal = ({ show, data, toggle }) => {
  const [form, setForm] = useState({ OrganisationName: null, gpQuestions: [] });
  const [gpOptions, setGPOptions] = useState([]);

  useEffect(() => {
    const subQues = data?.question?.subQuestions;
    const selectedGp = subQues.find(
      (q) => q?.questionType?.value === 'gp_select',
    );
    setForm({
      selectedGpData: selectedGp,
      OrganisationName: selectedGp?.answer,
      gpQuestions: subQues.filter((q) => q._id !== selectedGp._id),
    });
    api.getAllGPAddress({ showall: true }).then((res) => {
      let gpList = res?.data?.data?.docs || [];
      gpList = gpList.map((g) => ({
        value: g._id,
        label: g.OrganisationName,
        original: g,
      }));
      setGPOptions(gpList);
    });
  }, []);

  const onSubmit = (values) => {
    if (!values.OrganisationName) {
      toastError('GP Name is required.');
      return;
    }
    let orgDetails = {
      ...values.selectedGpData,
      answer: {
        label: values.OrganisationName.label,
        value: values.OrganisationName.value,
        ...cloneDeep(values.OrganisationName.original),
      },
    };
    let payload = cloneDeep(data);
    payload.question.subQuestions = [
      orgDetails,
      ...cloneDeep(values.gpQuestions),
    ];
    toggle('update', payload);
  };

  const getAsyncOrganization = async (params) => {
    try {
      const response = await api.getGPAddressByOrg(params);
      let orgData = response?.data?.data.docs;
      return orgData?.map((el) => {
        return {
          label: el.OrganisationName,
          value: el._id,
          original: el,
          ...el,
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  const organizationOptions = (inputValue, callback) => {
    getAsyncOrganization({ search: inputValue }).then((results) =>
      callback(results),
    );
  };

  const debouncedLoadOptions = debounce(organizationOptions, 1000);

  return (
    <Modal show={show} onHide={() => toggle('modal-close')}>
      <ModalHeader closeButton>Edit GP Address</ModalHeader>
      <ModalBody>
        <div>
          <Formik
            key={gpOptions.length}
            initialValues={form}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form>
                  <span className="text-primary">
                    {' '}
                    This change will also be updated to gp database.
                  </span>
                  <div className="my-2">
                    <CustomAsyncCreatableReactSelect
                      label="GP Name"
                      labelClassname={'form-label mb-3'}
                      name="OrganisationName"
                      id="OrganisationName"
                      className=""
                      placeholderColor="#9aa5ad"
                      height={'50px'}
                      customSelectBorder={'1px solid #9aa5ad'}
                      defaultOptions={gpOptions}
                      loadOptions={(inputValue, callback) =>
                        debouncedLoadOptions(inputValue, callback)
                      }
                      isRequired={true}
                      isSearchable
                      onChange={(value) => {
                        let updatedValues = [];
                        formik.values.gpQuestions.map((que) => {
                          let gpKey = subQuestionIDAndKeys[que._id];
                          updatedValues.push({
                            ...que,
                            answer: value.original[gpKey] || '',
                          });
                        });
                        formik.setFieldValue('gpQuestions', updatedValues);
                      }}
                    />
                  </div>
                  {form.gpQuestions.map((question, index) => (
                    <div className="my-2" key={question._id}>
                      <CustomInput
                        name={`gpQuestions[${index}].answer`}
                        id={`gpQuestions[${index}].answer`}
                        type="text"
                        label={question.question}
                        labelClassname="form-label"
                        placeholder={question.question}
                        className="form-control form-input"
                      />
                    </div>
                  ))}

                  <div className="border-top mt-3 pt-2 d-flex justify-content-center align-items-center">
                    <Button
                      type="button"
                      variant="sky"
                      className="my-2 me-2"
                      onClick={() => toggle('modal-close')}
                    >
                      Back
                    </Button>
                    <Button
                      className="me-2"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default GPAddressModal;
