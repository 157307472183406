import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ReactTextareaAutosize from 'react-textarea-autosize';
import api from '../../../api/api';
import { toastError, toastSuccess } from '../../../common/UI/Toasts';
import { setOrderData } from '../../../store/reducers/order';

const InternalNotes = (props) => {
  const { orderData } = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const [note, setNote] = useState('');
  useEffect(() => {
    setNote(orderData?.internal_notes);
    console.log(props.previousNotes, 'note');
  }, [orderData]);

  const saveNotes = () => {
    const payload = { internal_notes: note };
    api
      .updateOrder(orderData?._id, payload)
      .then((res) => {
        toastSuccess('Internal note added successfully!!');
        dispatch(setOrderData({ ...orderData, internal_notes: note }));
      })
      .catch((err) => {
        toastError(err);
      });
  };
  const changeOrderNote = (e) => {
    setNote(e.target.value);
  };
  return (
    <div>
      <div className="header_top " style={{ justifyContent: 'space-between' }}>
        <h3>Prescribers Internal Record of Notes</h3>
      </div>
      <div
        className="d-flex mt-4 align-items-center mx-3"
        style={{ justifyContent: 'space-between' }}
      >
        <ReactTextareaAutosize
          onChange={changeOrderNote}
          value={note}
          minRows={3}
          placeholder="Internal notes"
          className=" col-md-8 form-group form-input p-2"
        />
        <Button
          className="order_btns"
          onClick={saveNotes}
          style={{ height: 'max-content' }}
        >
          Save
        </Button>
      </div>
      <div className="header_top " style={{ justifyContent: 'space-between' }}>
        <h3>Previous Prescribers Internal Notes</h3>
      </div>
      <div
        className="d-flex mt-4 align-items-center mx-3"
        style={{ justifyContent: 'space-between' }}
      >
        <table className="internal_notes_table">
          <th>Order Number</th>
          <th>Order Date</th>
          <th>Note</th>
          {props?.previousNotes?.map((el) => {
            if (el.internal_notes) {
              return (
                <tr className="internal_notes" key={el._id}>
                  <td className="notes_order_number">{el.order_number}</td>
                  <td>
                    {el.createdAt.substring(8, 10)}/
                    {el.createdAt.substring(5, 7)}/
                    {el.createdAt.substring(0, 4)}
                  </td>
                  <td className="notes_order_internal_notes">
                    {el.internal_notes}
                  </td>
                </tr>
              );
            }
          })}
        </table>
      </div>
    </div>
  );
};

export default InternalNotes;
