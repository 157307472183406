import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import debounce from 'lodash.debounce';
import { default as api, default as services } from '../../api/api';
import deleteIcon from '../../assets/images/trash.svg';
import CustomAsyncCreatableReactSelect from '../../common/FormComponents/AsyncCreatableReactSelect';
import DeleteConfirmationModal from '../../common/UI/CustomModal/deleteModal';
import Loader from '../../common/UI/Loader';
import CustomReactTable from '../../common/UI/ReactTable';
import { toastError, toastSuccess } from '../../common/UI/Toasts';
import { getLabelValuePair } from '../../utils/helpers';

const ScanStockSection = () => {
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [deleteStockId, setDeleteStockId] = useState(null);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [deleteStockName, setDeleteStockName] = useState();
  const [loading, setLoading] = useState(false);
  const [updatedPayload, setUpdatedPayload] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);

  const onEditChange = (
    trade_price,
    rrp_price,
    vatRate,
    id,
    stock_level,
    recievedStock,
    margin,
  ) => {
    let stockCheckCount = updatedPayload.findIndex((x) => x.id === id);
    if (stockCheckCount === -1) {
      setUpdatedPayload([
        ...updatedPayload,
        {
          id,
          trade_price,
          rrp_price,
          vatRate,
          stock_level,
          recievedStock,
          margin: calcultateMargin(trade_price, rrp_price, vatRate),
        },
      ]);
    } else {
      const existingStockData = updatedPayload;
      existingStockData[stockCheckCount] = {
        id,
        trade_price,
        rrp_price,
        vatRate,
        stock_level,
        recievedStock,
        margin: calcultateMargin(trade_price, rrp_price, vatRate),
      };
      setUpdatedPayload([...existingStockData]);
    }
  };

  const columns = [
    {
      Header: 'BRAND',
      accessor: (data) => {
        return data?.product?.brand?.name;
      },
      disableSortBy: true,
    },
    {
      Header: 'SKU',
      disableSortBy: true,
      accessor: (data) => {
        return data?.product?.sku;
      },
    },
    {
      Header: 'NAME',
      accessor: (productName) => {
        return productName?.product?.name;
      },
      disableSortBy: true,
    },
    {
      Header: 'Size',
      accessor: 'size',
      disableSortBy: true,
    },
    {
      Header: 'Strength',
      accessor: 'strength',
      disableSortBy: true,
    },
    {
      Header: 'TRADE PRICE',
      disableSortBy: true,
      Cell: (cellinfo) => {
        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning
            onKeyPress={(e) => {
              if (isNaN(String.fromCharCode(e.which))) e.preventDefault();
            }}
            onBlur={(e) => {
              let value = e.target.innerHTML ? Number(e.target.innerHTML) : 0;
              onEditChange(
                (cellinfo.row.original.trade_price = value),
                cellinfo.row.original.rrp_price,
                cellinfo.row.original?.product?.vat_rate,
                cellinfo.row.original?._id,
                cellinfo.row.original?.stock_level,
                updatedPayload.length
                  ? updatedPayload[0]?.recievedStock
                  : cellinfo.row.original?.recievedStock,
                cellinfo.row.original?.margin,
              );
            }}
            style={{ textAlign: 'left', border: '2px solid #6c757d' }}
          >
            {cellinfo.row.original.trade_price.toFixed(2)}
          </div>
        );
      },
    },
    {
      Header: 'RRP',
      disableSortBy: true,
      Cell: (cellinfo) => {
        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning
            onKeyPress={(e) => {
              if (isNaN(String.fromCharCode(e.which))) e.preventDefault();
            }}
            onBlur={(e) => {
              let value = e.target.innerHTML ? Number(e.target.innerHTML) : 0;
              onEditChange(
                cellinfo.row.original.trade_price,
                (cellinfo.row.original.rrp_price = value),
                cellinfo.row.original?.product?.vat_rate,
                cellinfo.row.original?._id,
                cellinfo.row.original?.stock_level,
                updatedPayload.length
                  ? updatedPayload[0]?.recievedStock
                  : cellinfo.row.original?.recievedStock,
                cellinfo.row.original?.margin,
              );
            }}
            style={{ textAlign: 'left', border: '2px solid #6c757d' }}
          >
            {cellinfo.row.original.rrp_price.toFixed(2)}
          </div>
        );
      },
    },
    {
      Header: 'Margin %',
      disableSortBy: true,
      Cell: (cellinfo) => {
        let updatedMarginRecord = updatedPayload.filter((el) => {
          if (el.id === cellinfo.row.original._id) {
            return el;
          }
        });
        return updatedMarginRecord.length
          ? (
              updatedMarginRecord.length && updatedMarginRecord[0]?.margin * 100
            ).toFixed(0) + '%'
          : (cellinfo.row.original?.margin * 100).toFixed(0) + '%';
      },
    },
    {
      Header: 'Barcode',
      accessor: 'barcode',
      disableSortBy: true,
    },
    {
      Header: 'Old Stock',
      accessor: 'stock_level',
      disableSortBy: true,
    },
    {
      Header: 'Received Stock', // default 1
      disableSortBy: true,
      Cell: (cellinfo) => {
        let updatedRecievedStock = updatedPayload.filter((el) => {
          if (el.id === cellinfo.row.original._id) {
            return el;
          }
        });
        return (
          <div
            style={{
              textAlign: 'left',
              border: '2px solid #6c757d',
              width: '73px',
              height: '50px',
            }}
          >
            <div className="d-flex mx-2 align-items-center">
              {updatedRecievedStock.length
                ? updatedRecievedStock[0]?.recievedStock
                : 1}
              <div>
                <button
                  className="mx-4 counter--arrow-inc"
                  onClick={() => {
                    onEditChange(
                      cellinfo.row.original.trade_price,
                      cellinfo.row.original.rrp_price,
                      cellinfo.row.original?.product?.vat_rate,
                      cellinfo.row.original?._id,
                      cellinfo.row.original?.stock_level,
                      updatedRecievedStock.length
                        ? updatedRecievedStock[0]?.recievedStock + 1
                        : 1,
                      cellinfo.row.original?.margin,
                    );
                  }}
                >
                  <span>Increase</span>
                </button>
                <button
                  className="mx-4 counter--arrow-dec"
                  onClick={() => {
                    if (
                      updatedPayload.length &&
                      updatedPayload[0]?.recievedStock < 1
                    ) {
                      return toastError('Stock value can not be negative');
                    }
                    onEditChange(
                      cellinfo.row.original.trade_price,
                      cellinfo.row.original.rrp_price,
                      cellinfo.row.original?.product?.vat_rate,
                      cellinfo.row.original?._id,
                      cellinfo.row.original?.stock_level,
                      updatedRecievedStock.length
                        ? updatedRecievedStock[0]?.recievedStock - 1
                        : 1,
                      cellinfo.row.original?.margin,
                    );
                  }}
                >
                  <span>Decrease</span>
                </button>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      Header: ' ',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span className="me-2" onClick={() => handleDelete(row)}>
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    setLoading(true);
    getStocks(currentPageNo);
  }, [currentPageNo, updateTable]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const deleteStock = () => {
    services
      .deleteStock(deleteStockId)
      .then(() => {
        getStocks(currentPageNo);
        setDeleteStockId(null);
        toastSuccess('Stock Deleted Successfully');
      })
      .catch((err) => {
        console.log('error', err);
        setDeleteStockId(null);
      });
  };

  const handleDelete = (row) => {
    setDeleteStockName(row.original?.product?.name);
    setDeleteStockId(row.original._id);
    setModalShow(true);
  };

  const deleteModalOnHide = () => {
    setModalShow(false);
    setDeleteStockId(null);
  };

  const deleteModalOnDelete = () => {
    setModalShow(false);
    deleteStock();
  };

  const getStocks = () => {
    const params = { showall: true, isScanned: true };
    services
      .getStocks(params)
      .then((res) => {
        const responseData = res?.data?.data?.docs.map((value) => {
          return { ...value, recievedStock: 1 };
        });
        setData(responseData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(`err`, err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getStocks();
  }, []);

  const handleBulkUpdate = () => {
    let bulkUploadPayload = data.map((record) => {
      return {
        id: record._id,
        trade_price: record.trade_price,
        rrp_price: record.rrp_price,
        vatRate: record.vatRate,
        stock_level: record.stock_level,
        recievedStock: record.recievedStock,
        margin: record.margin,
        isScanned: false,
      };
    });
    services
      .stockBulkUpdate(bulkUploadPayload)
      .then((res) => {
        setUpdateTable((e) => !e);
        setUpdatedPayload([]);
        toastSuccess('Updated Data successfully');
      })
      .catch((err) => {
        console.log('error', err);
        toastError(err.message);
      });
  };
  const calcultateMargin = (tradePrice, RRRPrice, vatRate) => {
    if (tradePrice !== '' && RRRPrice !== '' && vatRate !== '') {
      const calculatedMargin =
        (RRRPrice / (1 + vatRate / 100) - tradePrice) /
        (RRRPrice / 1 + vatRate / 100);
      if (calculatedMargin) {
        return parseFloat(calculatedMargin).toFixed(2);
      }
      return 0;
    }
  };
  // From Barcode product data get.
  const getAsyncOptions = (inputValue, linkedOption) => {
    return getAsyncGetFromBarcode({ search: inputValue });
  };
  const getAsyncGetFromBarcode = async (params) => {
    try {
      let { search } = params;
      const response = await api.getFromBarcode(search);
      let barcodeDataResponse = response?.data?.data?.docs;
      const [{ _id }] = barcodeDataResponse;
      // check no duplicate recods is added
      if (barcodeDataResponse.length) {
        data.map((el) => {
          if (_id === el?._id) {
            barcodeDataResponse = [];
            return toastError('No duplicate entry allowed');
          }
        });
        if (barcodeDataResponse.length) {
          services
            .updateStock(_id, { isScanned: true })
            .then((res) => {
              setData([...data, { ...res.data.data }]);
              getStocks(currentPageNo);
              toastSuccess('Stock Added successfully');
            })
            .catch((err) => {
              console.log('error', err);
              toastError(err.message);
            });
        }
      }
      return getLabelValuePair(barcodeDataResponse, 'barcode', '_id');
    } catch (err) {
      console.log(err);
    }
  };
  const loadOptions = (inputValue, callback, linkedOption) => {
    getAsyncOptions(inputValue, linkedOption).then((results) =>
      callback(results),
    );
    return;
  };

  const debouncedLoadOptions = debounce(loadOptions, 1000);

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="header_top">
                <h3>Scan Stock Input</h3>
              </div>
              <Formik
                initialValues={{
                  barcodeData: '',
                }}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className="middle_main_2">
                        <div className="row">
                          <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                            <CustomAsyncCreatableReactSelect
                              height={'58px'}
                              selectType="async"
                              labelClassname="form-label"
                              cacheOptions
                              name="linkProductBrandData"
                              placeholder={'Enter Barcode here'}
                              loadOptions={(inputValue, callback) =>
                                debouncedLoadOptions(
                                  inputValue,
                                  callback,
                                  formik.values.referal,
                                )
                              }
                              isSearchable={true}
                              closeMenuOnSelect={true}
                            />
                          </div>
                          <div className="col-xl-6 col-md-6 col-lg-6 d-flex align-items-center justify-content-end">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm mx-5"
                              onClick={handleBulkUpdate}
                              disabled={updatedPayload.length ? false : true}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              <div className="box_main">
                <div className="d-flex justify-content-between align-items-center px-5"></div>
                <div className="middle_main">
                  <div className="row">
                    <div className="col-md-12 mb-0 mt-0">
                      {loading ? (
                        <Loader loading={loading} />
                      ) : (
                        <CustomReactTable
                          tableColumns={columns}
                          tableData={data}
                          seeAll={true}
                          nextHandle={nextHandle}
                          prevHandle={prevHandle}
                          pageNo={currentPageNo}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <DeleteConfirmationModal
                name={deleteStockName}
                module={'Stock'}
                show={modalShow}
                handleToggle={deleteModalOnHide}
                handleDelete={deleteModalOnDelete}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScanStockSection;
