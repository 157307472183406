import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import requireAuth from '../requireAuth';
import AdminHome from '../containers';
import Login from '../containers/login';
import Layout from '../layout/index';
import ProductManagement from '../containers/productMangement';
import CategoryManagement from '../containers/categoryManagement';
import AddProduct from '../containers/addProduct';
import ViewProduct from '../containers/viewProduct';
import AdminDoctorManagement from '../containers/adminDoctorManagement';
import UserManagement from '../containers/userManagement';
import DuplicateUserManagement from '../containers/duplicateUserManagement';
import AddAdminDoctor from './../containers/addAdminDoctor';
import RelatedKeywordManagement from '../containers/relatedKeywordManagement';
import BannerManagement from '../containers/bannerManagement';
import BrandManagement from '../containers/brandManagement';
import StockExcelUploadManagement from '../containers/stockExcelUploadManagement';
import StockScanManagement from '../containers/stockScanManagement';
import ViewOrder from '../containers/viewOrder';
import ViewOrderProduct from '../containers/viewOrderProduct';
import BlogManagement from '../containers/blogManagement';
import FeaturedProducts from '../containers/featuredProducts';
import ContactManagement from '../containers/contactManagement';
import ProfileManagement from '../containers/profileManagement';
import GeneralHealthQuestion from '../containers/generalHealthQuestions';
import BrandPromotion from './../containers/brandPromotion';
import ProductPromotion from './../containers/productPromotion';
import Couponcode from '../containers/couponcodeManagement';
import SubcategoryManagement from '../containers/subcategoryManagement';
import addCategory from '../containers/addCategory';
import addSubcategory from '../containers/addSubcategory';
import HealthGoalManagement from '../containers/healthGoals';
import OfferTitle from '../containers/offerTitle';

// Order Management
import OrderManagement from '../containers/orderManagement';
import PendingOrdersManagement from '../containers/pendingOrders';
import DispatchedOrdersManagement from '../containers/dispatchedOrdersManagement';
import CancelledOrdersManagement from '../containers/cancelledOrdersManagement';
import PharmacistRejectedOrders from '../containers/pharmacistRejectedOrders';
import ApprovedOrdersManagement from '../containers/approvedOrders';
import AssignedOrdersManagement from '../containers/assignedOrdersManagement';
import LexisNexisReport from '../containers/lexisNexisReport';
import { useSelector, useDispatch } from 'react-redux';
import {
  getLexisNexisRejectedOrdersCount,
  getPharmacyRejectedOrdersCount,
} from '../store/reducers/order';
import { isLoggedIn, isUserAdmin } from '../utils/helpers';
import LexisNexisRejectedOrders from '../containers/lexisNexisRejectedOrders';
import GPAddress from '../containers/GPAddress';
import SubscriptionManagement from '../containers/subscriptionOrders';
import CancelledSubscriptionManagement from '../containers/subscriptionCancelledOrders';
import PausedSubscriptionManagement from '../containers/pausedSubscription';
import OrdersMeetingList from '../containers/ordersMeetingList';
import OldOrdersMeetingList from '../containers/oldOrdersMeetingList';
import MeetSlotTime from '../containers/meetSlotTime';
import PharmacistReporting from '../containers/pharmacistReporting';
import Accounting from '../containers/accounting';
import GoogleAds from '../containers/googleAds';
import SEOComponent from '../containers/SEO';
import DoctorReporting from '../containers/doctorReporting';
import ForntPageFont from '../containers/forntPageFont';
import SendGP from '../containers/sendGpLetter';
import DownloadGP from '../containers/downloadGpLetter';
import EditUser from '../containers/editUserData';
import MdsOrdersManagement from '../containers/mdsOrders';
import GoogleAdsCats from '../containers/googleAdsCat';

const RoutesComponent = () => {
  const Admin = (comp) => requireAuth(comp, ['admin']);
  const Doctor = (comp) => requireAuth(comp, ['doctor']);
  const Pharmacist = (comp) => requireAuth(comp, ['pharmacist']);
  const AdminAndDoctor = (comp) => requireAuth(comp, ['admin', 'doctor']);
  const All = (comp) => requireAuth(comp, ['doctor', 'admin', 'pharmacist']);
  const dispatch = useDispatch();
  const pharmacistRejectedOrders = useSelector(
    (state) => state.order.pharmacyRejectOrders,
  );
  const lexisNexisRejectedOrders = useSelector(
    (state) => state.order.lexisNexisRejectOrders,
  );
  useEffect(() => {
    if (isLoggedIn() && isUserAdmin()) {
      if (!pharmacistRejectedOrders.isSet)
        dispatch(getPharmacyRejectedOrdersCount());
      if (!lexisNexisRejectedOrders.isSet)
        dispatch(getLexisNexisRejectedOrdersCount());
    }
  }, []);
  return (
    <>
      <Layout>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="/" element={All(AdminHome)} />
          <Route path="product" element={Admin(ProductManagement)} />
          <Route path="featured-product" element={Admin(FeaturedProducts)} />
          <Route path="addproduct" element={Admin(AddProduct)} />
          <Route path="viewproduct/:id" element={Admin(ViewProduct)} />
          <Route path="brand-promotion" element={Admin(BrandPromotion)} />
          <Route path="product-promotion" element={Admin(ProductPromotion)} />
          <Route path="offer-title" element={Admin(OfferTitle)} />
          <Route path="couponcode" element={Admin(Couponcode)} />
          <Route path="admin-doctor" element={Admin(AdminDoctorManagement)} />
          <Route path="users" element={Admin(UserManagement)} />
          <Route path="user-edit/:id" element={Admin(EditUser)} />
          <Route
            path="duplicate-users"
            element={Admin(DuplicateUserManagement)}
          />
          <Route path="register-admin-doctor" element={Admin(AddAdminDoctor)} />
          <Route path="category" element={Admin(CategoryManagement)} />
          <Route path="addcategory" element={Admin(addCategory)} />
          <Route path="subcategory" element={Admin(SubcategoryManagement)} />
          <Route path="addsubcategory" element={Admin(addSubcategory)} />
          <Route path="keyword" element={Admin(RelatedKeywordManagement)} />
          <Route path="banner" element={Admin(BannerManagement)} />
          <Route path="brand" element={Admin(BrandManagement)} />
          <Route
            path="general-health-questions"
            element={Admin(GeneralHealthQuestion)}
          />
          <Route path="health-goals" element={Admin(HealthGoalManagement)} />
          <Route path="meds-google-ads" element={Admin(GoogleAds)} />
          <Route path="subcat-google-ads" element={Admin(GoogleAdsCats)} />
          <Route path="stock-scan" element={Admin(StockScanManagement)} />
          <Route path="blog" element={Admin(BlogManagement)} />
          <Route path="contact" element={Admin(ContactManagement)} />
          <Route path="user-profile" element={All(ProfileManagement)} />
          <Route
            path="stock-upload"
            element={Admin(StockExcelUploadManagement)}
          />
          <Route
            path="pharmacist-reporting"
            element={Admin(PharmacistReporting)}
          />
          <Route path="doctor-reporting" element={Admin(DoctorReporting)} />
          <Route path="accounting" element={Admin(Accounting)} />

          {/* Order Management Routes */}

          {/* For Admin
            - All Orders
            - Pending Orders
            - Dispatched Orders
            - Cancelled Orders
            - Pharmacist Rejected Orders 
          */}
          {/* For Doctor
            - All Orders
            - Pending Orders
            - Approved Orders
            - Completed Orders
          */}
          {/* For Pharmacist
            - Assigned Orders
            - Dispatched Orders
            - All Orders
          */}
          <Route path="orders" element={All(OrderManagement)} />
          <Route
            path="orders-pending"
            element={AdminAndDoctor(PendingOrdersManagement)}
          />
          <Route
            path="orders-dispatched"
            element={All(DispatchedOrdersManagement)}
          />
          <Route
            path="orders-cancelled"
            element={Admin(CancelledOrdersManagement)}
          />
          <Route
            path="mds-orders"
            element={AdminAndDoctor(MdsOrdersManagement)}
          />
          <Route
            path="orders-pharmacist-rejected"
            element={Admin(PharmacistRejectedOrders)}
          />
          <Route
            path="orders-lexisnexis-rejected"
            element={Admin(LexisNexisRejectedOrders)}
          />
          <Route
            path="orders-subscription"
            element={Admin(SubscriptionManagement)}
          />
          <Route
            path="orders-subscription-paused"
            element={Admin(PausedSubscriptionManagement)}
          />
          <Route
            path="orders-subscription-cancelled"
            element={Admin(CancelledSubscriptionManagement)}
          />
          <Route
            path="orders-meeting-list"
            element={AdminAndDoctor(OrdersMeetingList)}
          />
          <Route
            path="old-orders-meeting-list"
            element={AdminAndDoctor(OldOrdersMeetingList)}
          />
          <Route path="meet-slot-time" element={Admin(MeetSlotTime)} />
          <Route
            path="orders-approved"
            element={Doctor(ApprovedOrdersManagement)}
          />
          <Route
            path="orders-assigned"
            element={Pharmacist(AssignedOrdersManagement)}
          />
          <Route path="gp-letters" element={Admin(SendGP)} />
          <Route path="gp-letters-download" element={Admin(DownloadGP)} />
          <Route path="vieworder/:id" element={All(ViewOrder)} />
          <Route
            path="vieworder/:id/variant/:variantId"
            element={All(ViewOrderProduct)}
          />
          <Route
            path="vieworder/:id/lexisnexisreport/:userId"
            element={All(LexisNexisReport)}
          />

          <Route path="gp-address" element={Admin(GPAddress)} />
          <Route path="seo" element={Admin(SEOComponent)} />
          <Route path="front-page-html" element={Admin(ForntPageFont)} />
        </Routes>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default RoutesComponent;
