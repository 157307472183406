// page-meta
import * as network from './network';

const getAllPageSEO = (params) => {
  return network.get('page-meta', params, true);
};

const getPageSEOById = (id) => {
  return network.get(`page-meta/${id}`, true);
};

const addPageSEO = (body) => {
  return network.post('page-meta', body, true);
};

const updatePageSEOById = (id, body) => {
  return network.put(`page-meta/${id}`, body, true);
};

const getPageText = (params) => {
  return network.get('htmlContent', params, true);
};

const addPageText = (body) => {
  return network.post('htmlContent', body, true);
};

const deletePageText = (id) => {
  return network.delet(`htmlContent/${id}`, true);
};

export default {
  getAllPageSEO,
  getPageSEOById,
  addPageSEO,
  updatePageSEOById,
  getPageText,
  addPageText,
  deletePageText,
};
