import React from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';

const CustomModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.handleToggle}
      size={props.size || 'lg'}
      centered={props.centered || true}
      className={`custom_modal `}
    >
      {!props.noHeader && (
        <Modal.Header className="header_top_modal" closeButton>
          <Modal.Title>
            <h5 className="modal_title">{props?.title}</h5>
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body
        className={`modal_costom_body
        ${!props.noHeader && `p-0 mt-3 mb-3`}
         `}
      >
        {props?.children}
      </Modal.Body>
      {props?.footer && <ModalFooter>{props?.footer}</ModalFooter>}
    </Modal>
  );
};

CustomModal.defaultProps = {
  noHeader: false,
};

export default CustomModal;
