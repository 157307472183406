import * as network from './network';

// Banner Apis
const getOfferTitle = (params) => {
  return network.get(`offer`, params, true);
};

const addOfferTitle = (payload) => {
  return network.post(`offer`, payload, true);
};

const updateOfferTitle = (id, payload) => {
  return network.put(`offer/${id}`, payload, true);
};

export default {
  getOfferTitle,
  addOfferTitle,
  updateOfferTitle,
};
