import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Editor } from '@tinymce/tinymce-react';
import api from '../../../api/api';
import { getFormDataFromObject } from '../../../utils/helpers';

import BlogDetailsModal from './blogDetailsModal';
import AuthorDetailsModal from './authorDetailsModal';
import {
  toastError,
  toastSuccess,
  toastWarning,
} from '../../../common/UI/Toasts';
import SEOModal from '../../../common/UI/CustomModal/SEOModal';
import TextEditor from '../../../common/TextEditor';

const BlogEditor = (props) => {
  const { backLinkHandle, action, blogInfo, updateBlogInfo } = props;

  const [show, setShow] = useState({
    blogDetailsModal: false,
    authorDetailsModal: false,
    metaDataModal: false,
  });
  const [finalBlogInfo, setFinalBlogInfo] = useState(blogInfo);
  const [metaDataInitialValues, setMetaDataInitialValues] = useState({
    metadata: [
      {
        name: '',
        content: '',
      },
    ],
  });

  useEffect(() => {
    if (action == 'Edit' && finalBlogInfo?.metadata) {
      let metadata_arr = [];
      metadata_arr = Object.keys(finalBlogInfo?.metadata).map((key) => {
        return {
          name: { label: key, value: key },
          content: finalBlogInfo.metadata[key],
        };
      });
      setMetaDataInitialValues({ metadata: metadata_arr });
    }
  }, [show.metaDataModal]);

  const onSubmitOfMetaDataForm = (metaObj) => {
    updateFinalBlogInfo('metadata', metaObj);
    handleMetaDataToggle();
  };

  const updateFinalBlogInfo = (key, value) => {
    setFinalBlogInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // All Modal Handle Show
  const handleBlogModalShow = () => {
    setShow({
      blogDetailsModal: true,
      authorDetailsModal: false,
      metaDataModal: false,
    });
  };

  const handleMetaDataModalShow = () => {
    setShow({
      blogDetailsModal: false,
      authorDetailsModal: false,
      metaDataModal: true,
    });
  };

  const handleAuthorModalshow = () => {
    setShow({
      blogDetailsModal: false,
      authorDetailsModal: true,
      metaDataModal: false,
    });
  };

  // All Modal Toggle
  const handleMetaDataToggle = () => {
    setShow({
      blogDetailsModal: false,
      authorDetailsModal: false,
      metaDataModal: false,
    });
  };

  const toggleModals = (isAuthor) => {
    setShow({
      blogDetailsModal: false,
      authorDetailsModal: false,
    });
    isAuthor
      ? setFinalBlogInfo((prev) => ({
          ...prev,
          author: blogInfo.author,
          author_profession: blogInfo.author_profession,
          author_photo: blogInfo.author_photo,
        }))
      : setFinalBlogInfo((prev) => ({
          ...prev,
          title: blogInfo.title,
          category: blogInfo.category,
          subcategory: blogInfo.subcategory,
          status: blogInfo.status,
          photo: blogInfo.photo,
        }));
  };

  const handleBothModalEdit = (isAuthor, moveFurther) => {
    if (moveFurther) {
      setShow({
        blogDetailsModal: false,
        authorDetailsModal: false,
      });
      isAuthor
        ? ['author', 'author_profession', 'author_photo'].forEach((el) =>
            updateBlogInfo(el, finalBlogInfo[el]),
          )
        : ['title', 'category', 'subcategory', 'status'].forEach((el) =>
            updateBlogInfo(el, finalBlogInfo[el]),
          );
    }
  };

  const handleValidation = () => {
    if (
      !Object.keys(finalBlogInfo).includes('blogBody') ||
      finalBlogInfo.title == '' ||
      finalBlogInfo.blogBody == ''
    ) {
      if (
        !Object.keys(finalBlogInfo).includes('blogBody') ||
        finalBlogInfo.blogBody == ''
      ) {
        toastWarning('Please Add Content for Blog');
      }
      if (finalBlogInfo.title == '') {
        toastWarning('Please Add Title for Blog');
      }
    } else {
      action == 'Add' ? handleAdd() : handleEdit();
    }
  };

  const handleAdd = () => {
    let payload = {
      title: finalBlogInfo.title,
      blogBody: finalBlogInfo.blogBody || '',
      category: finalBlogInfo.category.categoryId,
      subcategory: finalBlogInfo.subcategory.subcategoryId,
      status: finalBlogInfo.status.actualValue,
      product_link: JSON.stringify(['62e0e6bc9ded7d3bc5a1a2a2']),
    };

    // adding only fields to payload which are filled by user
    ['author', 'author_profession'].forEach(
      (el) => finalBlogInfo[el] && (payload[el] = finalBlogInfo[el]),
    );

    ['photo', 'author_photo'].forEach(
      (el) => finalBlogInfo[el] && (payload[el] = finalBlogInfo[el].imgData),
    );

    ['metadata'].forEach(
      (el) =>
        finalBlogInfo[el] && (payload[el] = JSON.stringify(finalBlogInfo[el])),
    );

    payload = getFormDataFromObject(payload);

    api
      .addBlog(payload)
      .then((res) => {
        toastSuccess('Blog Added Successfully');
        backLinkHandle();
      })
      .catch((err) => {
        console.log('error', err);
        err.status == 400 && toastError('This title already exists');
      });
  };

  const handleEdit = () => {
    let photoValue =
      finalBlogInfo?.photo?.imgData instanceof File
        ? finalBlogInfo?.photo?.imgData
        : JSON.stringify(finalBlogInfo?.photo?.imgData);
    let authorPhoto =
      finalBlogInfo?.author_photo?.imgData instanceof File
        ? finalBlogInfo?.author_photo?.imgData
        : JSON.stringify(finalBlogInfo?.author_photo?.imgData);
    let payload = {
      title: finalBlogInfo.title,
      blogBody: finalBlogInfo.blogBody || '',
      category: finalBlogInfo.category.categoryId,
      subcategory: finalBlogInfo.subcategory.subcategoryId,
      status: finalBlogInfo.status.actualValue,
      photo: photoValue || '',
      metadata: JSON.stringify(finalBlogInfo?.metadata) || '',
      author_photo: authorPhoto || '',
      author: finalBlogInfo.author || '',
      author_profession: finalBlogInfo.author_profession || '',
      product_link: JSON.stringify(['62e0e6bc9ded7d3bc5a1a2a2']),
    };

    if (finalBlogInfo.meta_data?._id) {
      payload['metadata_id'] = finalBlogInfo.meta_data._id;
    }

    payload = getFormDataFromObject(payload);

    api
      .updateBlog(finalBlogInfo._id, payload)
      .then((res) => {
        toastSuccess('Blog Updated Successfully');
        backLinkHandle();
      })
      .catch((err) => {
        console.log('error', err);
        err.status == 400 && toastError('This title already exists');
      });
  };

  const onEditorChange = (value) => {
    updateFinalBlogInfo('blogBody', value);
  };

  return (
    <>
      <section className="content_wrapper product_add_main">
        <div className="container-fluid">
          <div className="row">
            <div className="d-flex col-md-12">
              <ol className="me-auto breadcrumb">
                <li>
                  <Link
                    to={'/blog'}
                    title="Product Management"
                    onClick={() => backLinkHandle()}
                  >
                    Blog Management
                  </Link>
                </li>
                <li>{action} Blog</li>
              </ol>
              <div className="p-2">
                <Button
                  variant="primary"
                  className="m-2 fw-bold"
                  onClick={handleValidation}
                >
                  {action}
                </Button>
                <Button
                  variant="sky"
                  className="m-2"
                  onClick={() => backLinkHandle()}
                >
                  Back
                </Button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="box_main d-flex">
                <div className="header_top me-auto p-2">
                  <h3 className="p-0">{action} Blog</h3>
                </div>
                <Button
                  className="blog-buttons p-2 m-2 fw-bold"
                  onClick={handleMetaDataModalShow}
                >
                  <FontAwesomeIcon className="me-1" icon={faPen} />
                  Meta data
                </Button>
                <Button
                  className="blog-buttons p-2 m-2 fw-bold"
                  onClick={handleBlogModalShow}
                >
                  <FontAwesomeIcon className="me-1" icon={faPen} />
                  Blog Details
                </Button>
                <Button
                  className="blog-buttons p-2 m-2 fw-bold"
                  onClick={handleAuthorModalshow}
                >
                  <FontAwesomeIcon className="me-1" icon={faPen} />
                  Author Details
                </Button>
              </div>
            </div>
          </div>
          <div className="middle_main_2">
            <div className="row d-flex box_main">
              <input
                className="h2 mt-2"
                id="title"
                type="text"
                placeholder="Title"
                value={finalBlogInfo?.title || ''}
                onChange={(e) => {
                  updateFinalBlogInfo('title', e.target.value);
                  updateBlogInfo('title', e.target.value);
                }}
              />
            </div>
            <div className="row mt-3">
              <TextEditor
                onEditorChange={onEditorChange}
                value={finalBlogInfo?.blogBody}
                editorHeight="70vh"
              />
            </div>
          </div>
        </div>
      </section>
      {show.blogDetailsModal && (
        <BlogDetailsModal
          isOpenedInEditorScreen={true}
          show={show.blogDetailsModal}
          handleToggle={() => toggleModals(false)}
          handleNext={(moveFurther) => handleBothModalEdit(false, moveFurther)}
          blogInfo={finalBlogInfo}
          updateBlogInfo={updateFinalBlogInfo}
        />
      )}
      {show.authorDetailsModal && (
        <AuthorDetailsModal
          isOpenedInEditorScreen={true}
          show={show.authorDetailsModal}
          handleToggle={() => toggleModals(true)}
          handleNext={(moveFurther) => handleBothModalEdit(true, moveFurther)}
          blogInfo={finalBlogInfo}
          updateBlogInfo={updateFinalBlogInfo}
        />
      )}
      {show.metaDataModal && (
        <SEOModal
          handleAction={() => console.log('action')}
          handleToggle={handleMetaDataToggle}
          show={show.metaDataModal}
          initialValues={metaDataInitialValues}
          onSubmit={onSubmitOfMetaDataForm}
          fieldName="metadata"
        />
      )}
    </>
  );
};

export default BlogEditor;
