import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import api from '../api/api';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import SearchAndButtons from '../common/UI/Search&Buttons';
import { toastError, toastSuccess } from '../common/UI/Toasts';
import { hasDecimalPlace } from '../utils/helpers';

const ProductPromotion = () => {
  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatedPayload, setUpdatedPayload] = useState([]);

  const columns = [
    {
      Header: 'Brand',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.brand?.name}</span>;
      },
    },
    {
      Header: 'SKU',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.sku}</span>;
      },
    },
    {
      Header: 'Product name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.name}</span>;
      },
    },
    {
      Header: 'Product price',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>£{row.original?.variant?.rrp_price.toFixed(2)}</span>;
      },
    },
    {
      Header: 'Percentage Discount (%)',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div
            contentEditable={true}
            suppressContentEditableWarning
            onKeyPress={function (e) {
              let character = String.fromCharCode(e.which);
              let newValue = e.target.innerHTML + character;
              if (isNaN(newValue) || hasDecimalPlace(newValue, 3))
                e.preventDefault();
            }}
            onBlur={(e) => {
              let value;
              if (
                Number(e.target.innerHTML) >= 0 &&
                Number(e.target.innerHTML) <= 100
              ) {
                value = Number(e.target.innerHTML);
              } else {
                if (Number(row.original.promotion)) {
                  value = Number(row.original.promotion);
                  toastError(
                    'Percentage discount should be less than or equal to 100',
                  );
                } else {
                  value = 0;
                }
              }
              onEditChange((row.original.promotion = value), row.original?._id);
            }}
            style={{ textAlign: 'left', border: '2px solid #6c757d' }}
          >
            {row.original.promotion.toFixed(2)}
          </div>
        );
      },
    },
  ];

  const onEditChange = (percentage, product_id) => {
    let isProductPromotionExists = updatedPayload.findIndex(
      (x) => x.product_id === product_id,
    );
    if (isProductPromotionExists === -1) {
      setUpdatedPayload([
        ...updatedPayload,
        {
          percentage,
          product_id,
        },
      ]);
    } else {
      const existingProductPromotionData = updatedPayload;
      existingProductPromotionData[isProductPromotionExists] = {
        percentage,
        product_id,
      };
      setUpdatedPayload([...existingProductPromotionData]);
    }
  };

  useEffect(() => {
    setLoading(true);
    getAllProductPromotions();
  }, [currentPageNo, seeAll]);

  const getAllProductPromotions = () => {
    const params = seeAll
      ? { showall: true }
      : { perPage: 10, page: currentPageNo };
    api
      .getAllProductPromotions(params)
      .then((res) => {
        setData(res.data?.data?.docs);
        setTotalPage(res.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const searchProductPromotion = (searchkey) => {
    if (searchkey) {
      const params = { showall: true, search: searchkey };
      api
        .searchProductPromotion(params)
        .then((res) => {
          setData(res.data?.data?.docs);
          setTotalPage(res.data?.data?.totalPages);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      getAllProductPromotions();
    }
  };

  const handleBulkPromotionUpdate = () => {
    if (updatedPayload && updatedPayload.length > 0) {
      api
        .updateProductPromotion({ productPromotion: updatedPayload })
        .then((res) => {
          currentPageNo == 1 ? getAllProductPromotions() : setCurrentPageNo(1);
          setUpdatedPayload([]);
          toastSuccess('Product promotion updated sucessfully');
        })
        .catch((err) => {
          console.log('Error: ', err);
        });
    } else {
      toastError('Please edit any product Percentage Discount');
    }
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  return (
    <div className="content_wrapper all_products_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              <div className="header_top">
                <h3>Product Promotion</h3>
              </div>
              <div className="middle_main">
                <SearchAndButtons
                  onSearchChange={searchProductPromotion}
                  onButtonClick={handleBulkPromotionUpdate}
                  searchPlaceholder={'Search by brand name or product name...'}
                  buttonName={'Bulk Promotion Update'}
                  seeAllHandle={seeAllHandle}
                />
                {loading ? (
                  <Loader loading={loading} />
                ) : (
                  <ReactTable
                    title="Product Promotion"
                    tableColumns={columns}
                    tableData={data}
                    seeAll={seeAll}
                    nextHandle={nextHandle}
                    prevHandle={prevHandle}
                    totalPage={totalPage}
                    pageNo={currentPageNo}
                  />
                )}
                <div className="footer_main" style={{ textAlign: 'right' }}>
                  <Button
                    type="button"
                    className="me-2"
                    onClick={handleBulkPromotionUpdate}
                  >
                    Bulk Promotion Update
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPromotion;
