import React, { useState, useEffect } from 'react';
import SearchAndButtons from '../../../common/UI/Search&Buttons';
import ReactTable from '../../../common/UI/ReactTable';
import services from '../../../api/api';
import debounce from 'lodash.debounce';
import editIcon from '../../../assets/images/pencil.svg';
import deleteIcon from '../../../assets/images/trash.svg';
import DeleteConfirmationModal from '../../../common/UI/CustomModal/deleteModal';
import {
  toastError,
  toastSuccess,
  toastWarning,
} from '../../../common/UI/Toasts';
import { PRODUCT_TYPES } from '../../../common/constants';
import Loader from '../../../common/UI/Loader';
import CustomToggle from '../../../common/UI/Toggle';
import ReactSwitch from 'react-switch';
import { useNavigate } from 'react-router-dom';

const CategorySection = (props) => {
  const columns = [
    {
      Header: 'Product Type',
      accessor: 'product_type',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Category',
      accessor: 'name',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Show At Main Page \n(Max. 5 per category)',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="align-items-center">
            <ReactSwitch
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={() => handleToggleChange(row)}
              checked={row.original?.homepageDisplay}
            />
          </div>
        );
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      width: 40,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span className="me-2" onClick={() => handleEdit(row)}>
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span onClick={() => handleDelete(row)}>
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);
  const [categoryEditInfo, setCategoryEditInfo] = useState({});
  const [deleteCategoryName, setDeleteCategoryName] = useState();
  const [loading, setLoading] = useState(false);
  const [activeMedCount, setActiveMedCount] = useState(0);
  const [activeProdCount, setActiveProdCount] = useState(0);
  // const [currentPageNo, setCurrentPageNo] = useState(1);
  // const [totalPage, setTotalPage] = useState(1);
  // const [seeAll, setSeeAll] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCategories();
  }, []);

  // For future need (Pagination handle)
  // const nextHandle = () => {
  //   setCurrentPageNo((prev) => prev + 1);
  // };

  // const prevHandle = () => {
  //   setCurrentPageNo((prev) => prev - 1);
  // };

  // Functions for api calls
  const getCategories = () => {
    // const params = seeAll
    //   ? { showall: true }
    //   : {
    //       perPage: 10,
    //       page: pageNo,
    //     };
    const params = { showall: true };
    services
      .getAllCategory(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
        settingCountOfCategories(res?.data?.data?.docs);
        // setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const getOnSearch = (value) => {
    const params = {
      search: value,
    };
    services
      .getCategoriesOnSearch(params)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const updateCategoryDisplayStatus = (payload) => {
    services
      .updateCategoryStatus(payload)
      .then((res) => {
        payload?.product_type == 'medication'
          ? setActiveMedCount((prev) => prev + 1)
          : setActiveProdCount((prev) => prev + 1);
        toastSuccess('Category Status Updated Successfully');
        getCategories();
      })
      .catch((err) => {
        toastError(err?.message);
        console.log('error', err);
      });
  };

  const deleteCategories = () => {
    services
      .deleteCategory(deleteCategoryId)
      .then((res) => {
        toastSuccess('Category Deleted Successfully');
        setDeleteCategoryId(null);
        getCategories();
      })
      .catch((err) => {
        console.log('error', err);
        setDeleteCategoryId(null);
      });
  };

  const onSearchDebounce = debounce(getOnSearch, 1000);

  const onCategorySearchChange = (value) => {
    onSearchDebounce(value);
  };

  const onCategoryButtonClick = () => {
    navigate('/addcategory');
  };

  const handleDelete = (row) => {
    setDeleteCategoryName(row.original.name);
    setModalShow(true);
    setDeleteCategoryId(row.original._id);
  };

  const handleEdit = (row) => {
    navigate(`/addcategory?id=${row.original._id}`);
  };

  const deleteModalOnHide = () => {
    setModalShow(false);
    setDeleteCategoryId(null);
  };

  const deleteModalOnDelete = () => {
    setModalShow(false);
    deleteCategories();
  };

  const settingCountOfCategories = (data) => {
    const medData = data.filter((el) => el?.product_type == 'medication');
    const prodData = data.filter((el) => el?.product_type == 'product');
    let medCount = 0;
    let prodCount = 0;
    medData.forEach((el) => {
      if (el?.homepageDisplay) {
        medCount = medCount + 1;
      }
    });
    prodData.forEach((el) => {
      if (el?.homepageDisplay) {
        prodCount = prodCount + 1;
      }
    });
    setActiveMedCount(medCount);
    setActiveProdCount(prodCount);
  };

  const handleDisableActionOfCategory = ({ original }) => {
    if (activeMedCount == 6 && original.product_type == 'medication')
      return true;
    else if (activeProdCount == 6 && original.product_type == 'product')
      return true;
    else return false;
  };

  const handleToggleChange = (row) => {
    const { original } = row;
    const payload = {
      id: original?._id,
      homepageDisplay: !original?.homepageDisplay,
      product_type: original?.product_type,
    };
    // for product
    if (original?.homepageDisplay) {
      updateCategoryDisplayStatus(payload);
    } else if (original.product_type == 'product' && activeProdCount < 6) {
      updateCategoryDisplayStatus(payload);
    } else if (original.product_type == 'medication' && activeMedCount < 6) {
      updateCategoryDisplayStatus(payload);
    } else {
      original.product_type == 'product'
        ? toastWarning('6 Product Categories are already added to Home Page')
        : toastWarning(
            '6 Medication Categories are already added to Home Page',
          );
    }
  };

  // for seeAll checkbox
  // const seeAllHandle = () => {
  //   setSeeAll((prev) => !prev);
  // };
  return (
    <>
      <SearchAndButtons
        onSearchChange={onCategorySearchChange}
        onButtonClick={onCategoryButtonClick}
        searchPlaceholder={'Search by category name...'}
        buttonName={'Add Category'}
        seeAllButton={false}
        // seeAllHandle={seeAllHandle}
      />
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div className="row">
          <div className="col-md-12 mb-0 mt-4">
            <ReactTable
              title="Category"
              tableColumns={columns}
              tableData={data}
              seeAll={true}
              // nextHandle={nextHandle}
              // prevHandle={prevHandle}
              // totalPage={totalPage}
              // pageNo={currentPageNo}
            />
          </div>
        </div>
      )}
      <DeleteConfirmationModal
        name={deleteCategoryName}
        module={'category'}
        show={modalShow}
        handleToggle={deleteModalOnHide}
        handleDelete={deleteModalOnDelete}
      />
    </>
  );
};

export default CategorySection;
