import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import api from '../../../api/api';
import {
  staticIDs,
  gpAddressSubQuestionIDAndKeys,
  gpEmailKey,
  gpNameKey,
} from '../../../common/constants';
import { toastError, toastSuccess } from '../../../common/UI/Toasts';
import { getOrderById } from '../../../store/reducers/order';
import {
  isUserAdmin,
  isUserDoctor,
  isUserPharmacist,
} from '../../../utils/helpers';
import GPEmailModal from './gpEmailModal';
import GPAddressModal from '../ViewOrderProduct/GPAddressModal';

const address_question_list = [
  'Address 1',
  'Address 2',
  'Address 3',
  'City',
  'Postcode',
];
const GPContact = () => {
  const dispatch = useDispatch();
  const { orderData } = useSelector((state) => state.order);
  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const [showGPEmailModal, setGPEmailModal] = useState(false);
  const [gpData, setGPData] = useState({
    name: '',
    address: '',
    email: '',
    gpAddresId: null,
  });

  const [gpAddressModal, setGPAddressModal] = useState({
    show: false,
    data: {},
    questionId: null,
    orderId: null,
  });

  useEffect(() => {
    // finding gp data from general health answers
    const GPData = orderData?.order_items?.general_ans.find(
      (a) => a.question_id === staticIDs.gp_question,
    );
    const answers = GPData?.question?.subQuestions || [];
    let gpAddressObj = {};
    address_question_list.map((fld) => {
      const val = answers.find(
        (ans) => fld === gpAddressSubQuestionIDAndKeys[ans._id],
      );

      // setting address line to show on the gp section and aslo adding data to gpData Object
      if (val) {
        const fldName = fld.replace(' ', '');
        gpAddressObj = {
          ...gpAddressObj,
          [fldName]: val.answer,
        };
        gpAddressObj.address = gpAddressObj.address
          ? gpAddressObj.address + (val.answer ? `, ${val.answer}` : '')
          : val.answer;
      }
    });
    const emailData = answers.find((ans) => ans._id === gpEmailKey);
    const nameData = answers.find((ans) => ans._id === gpNameKey);
    gpAddressObj.email = emailData ? emailData.answer : '';
    gpAddressObj.name = nameData ? nameData?.answer?.label : '';

    // check if user has added new gp (not in the database)
    gpAddressObj.gpAddresId = !nameData?.answer?.__isNew__
      ? nameData?.answer?.value
      : null;

    // check if gp letter is already sent or not
    // & if user has entered email or not
    // & gp added by user is stored in database
    if (
      !orderData.gp_letter &&
      nameData &&
      nameData.answer &&
      !nameData.__isNew__
    ) {
      // as user hasn't added any email for gp so we are getting email from db
      // api.getGPAddressByName(nameData.answer.label).then((addRes) => {
      // let list = addRes?.data?.data?.docs || [];
      // const selectedDefGP = list.find(
      //   (l) => l._id === gpAddressObj.gpAddresId,
      // );
      // if (selectedDefGP && selectedDefGP.email) {
      //   gpAddressObj.email = selectedDefGP.email;
      // }
      setGPData((prevData) => ({
        ...prevData,
        ...gpAddressObj,
        defaultGP: nameData || null,
        hasLetter: !!orderData.gp_letter,
        letterUrl: orderData.gp_letter ? orderData.gp_letter : null,
        letterSendTo: orderData.gp_mail ? orderData.gp_mail : null,
      }));
      // });
    } else {
      setGPData((prevData) => ({
        ...prevData,
        ...gpAddressObj,
        defaultGP: nameData || null,
        hasLetter: !!orderData.gp_letter,
        letterUrl: orderData.gp_letter ? orderData.gp_letter : null,
        letterSendTo: orderData.gp_mail ? orderData.gp_mail : null,
      }));
    }
    // !orderData.gp_letter && !gpAddressObj.email ? setBtnDisabled(true) : null;
  }, [orderData]);

  // const toggleGPEmailModal = (arg) => {
  //   // setting the email added by admin
  //   if (arg && arg.email) {
  //     setGPData((prevData) => ({
  //       ...prevData,
  //       email: arg.email,
  //     }));

  //     // this will update both answers and also database
  //     const payload = {
  //       gp_mail: arg?.email,
  //     };
  //     api
  //       .updateGPMail(orderData?._id, payload)
  //       .then((res) => {
  //         toastSuccess('Updated GP Mail Successfully');
  //       })
  //       .catch((err) => {
  //         console.log('err', err);
  //       });
  //   }
  //   setGPEmailModal((prev) => !prev);
  // };

  const toggleGPAddressModal = (type, data = null) => {
    if (type === 'modal-open') {
      const GPData = orderData?.order_items?.general_ans.find(
        (a) => a.question_id === staticIDs.gp_question,
      );
      setGPAddressModal((prev) => ({
        ...prev,
        show: true,
        data: GPData,
      }));
    } else if (type === 'modal-close') {
      setGPAddressModal((prev) => ({
        ...prev,
        show: false,
        data: null,
      }));
    } else if (type === 'update') {
      // api call
      const gpName = data.question.subQuestions.find(
        (el) => el?._id == gpNameKey,
      )?.answer.label;

      api
        .updateOrderGPAddress({
          orderId: orderData?._id,
          generalAnsGpObject: data,
          gpName,
        })
        .then((res) => {
          toastSuccess('GP Address updated.');
          dispatch(getOrderById(orderData?._id));
          setGPAddressModal((prev) => ({
            ...prev,
            show: false,
            data: null,
          }));
        });
    }
  };

  const sendGPLetter = () => {
    // api call here

    // check if we have gp email
    if (!orderData._id || !gpData.email) {
      toastError('Please add email to GP Address.');
      return;
    }
    setBtnDisabled(true);
    let payload = {
      email: gpData.email,
      order_id: orderData._id,
      gp_address_id: gpData.gpAddresId,
      gpObj: !gpData.gpAddresId
        ? {
            OrganisationName: gpData?.name,
            Address1: gpData?.Address1,
            Address2: gpData?.Address2,
            Address3: gpData?.Address3,
            City: gpData?.City,
            Postcode: gpData?.Postcode,
          }
        : null,
    };
    let apis = [api.sendGPLetter(payload)];
    if (gpData.gpAddresId && !gpData.defaultGP?.email) {
      let payload = { ...gpData.defaultGP, email: gpData.email };
      delete payload.label;
      delete payload.value;
      delete payload._id;
      payload._id = gpData.gpAddresId;
      apis.push(api.updateGPAddress(gpData.gpAddresId, payload));
    }
    Promise.all(apis)
      .then(([res, gpResponse]) => {
        console.log('gpResponse', gpResponse);
        if (res?.data?.status === 200) {
          setBtnDisabled(false);
          dispatch(getOrderById(orderData._id));
          toastSuccess('Letter Sent Successfully.');
        } else {
          console.log('response :::', res);
        }
      })
      .catch((err) => {
        setBtnDisabled(false);
        console.log(err);
      });
  };

  return (
    <div>
      <div className="header_top d-flex justify-content-between">
        <h3>Contact GP</h3>
        {isUserAdmin() && !gpData.hasLetter ? (
          <button
            className="btn btn-primary order_btns"
            onClick={() => toggleGPAddressModal('modal-open')}
          >
            Update GP
          </button>
        ) : null}
      </div>
      <div className="m-3 d-flex justify-content-between align-items-center">
        <div>
          <strong>GP Name :</strong>
          <span className="mx-2">{gpData.name}</span>
          <br />
          <strong>GP Address :</strong>
          <span className="mx-2">{gpData.address}</span>
          <br />
          <strong>Email :</strong>
          {gpData.email || gpData.letterSendTo ? (
            <span className="mx-2">{gpData.letterSendTo || gpData.email}</span>
          ) : isUserAdmin() ? (
            <span
              className="text-primary text-decoration-underline"
              role="button"
              tabIndex="1"
              onClick={() => toggleGPAddressModal('modal-open')}
            >
              Add GP Email
            </span>
          ) : (
            <span className="mx-2">-</span>
          )}
        </div>
        <div className="d-flex justify-content-end">
          {isUserAdmin() && !gpData.hasLetter ? (
            <button
              className="btn btn-primary"
              disabled={isBtnDisabled}
              onClick={() => sendGPLetter()}
            >
              Send GP Letter
            </button>
          ) : null}
          {(isUserAdmin() || isUserDoctor() || isUserPharmacist()) &&
          gpData.hasLetter ? (
            <a
              className="text-white text-decoration-none"
              href={gpData.letterUrl}
              download="GPLetter.pdf"
              target="blank"
            >
              <button className="btn btn-primary">Download GP Letter</button>
            </a>
          ) : null}
        </div>
      </div>
      {/* {showGPEmailModal ? (
        <GPEmailModal
          show={showGPEmailModal}
          toggle={toggleGPEmailModal}
          email={gpData.email}
        />
      ) : null} */}
      {gpAddressModal.show && (
        <GPAddressModal
          show={gpAddressModal.show}
          toggle={toggleGPAddressModal}
          data={gpAddressModal.data}
        />
      )}
    </div>
  );
};
export default GPContact;
