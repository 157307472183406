import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../TextError';

const CustomInput = (props) => {
  const {
    label,
    name,
    className,
    style,
    labelClassname,
    id,
    type,
    min,
    max,
    step,
    rows,
    cols,
    placeholder,
    disabled,
    onChange,
    isRequired,
    ...rest
  } = props;

  const textareaProps = {
    as: type,
    rows: rows,
    cols: cols,
  };

  const textareaFieldProps = type === 'textarea' ? { ...textareaProps } : null;

  const numberProps = {
    min: min,
    max: max,
    step: step,
  };

  const numberFieldProps = type === 'number' ? { ...numberProps } : null;
  return (
    <>
      {type !== 'checkbox' ? (
        <label htmlFor={id} className={labelClassname}>
          {label}
          {isRequired ? <span className="text-danger">*</span> : null}
        </label>
      ) : null}
      <Field
        onKeyUp={(e) => {
          onChange ? onChange(e) : null;
        }}
        name={name}
        id={id}
        {...textareaFieldProps}
        {...numberFieldProps}
        type={type}
        className={className}
        style={style}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete="off"
        {...rest}
      />
      {type === 'checkbox' ? (
        <label htmlFor={id} className={labelClassname}>
          {label}
        </label>
      ) : null}
      <ErrorMessage component={TextError} name={name} />
    </>
  );
};

export default CustomInput;
