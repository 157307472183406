import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import services from '../../api/api';
import { useNavigate } from 'react-router-dom';

const AddressCard = ({ address, userId }) => {
  const navigate = useNavigate();

  const makeAddressPrimary = () => {
    services
      .updateUserAddress(address._id, {
        address_line1: address.address_line1,
        address_line2: address.address_line2,
        city: address.city,
        country: address.country.value,
        is_primary: true,
        zip_code: address.zip_code,
        user_id: userId,
      })
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const deleteAddress = () => {
    services
      .deleteAddress(address._id)
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <div className={`address_card`}>
      <h2>
        {address.title} {address.is_primary ? <span>PRIMARY</span> : null}
      </h2>
      <p className={`mb-1 text_des`}>{address.address_line1}</p>
      {address.address_line2 ? (
        <p className={`mb-1 text_des`}>{address.address_line2}</p>
      ) : null}
      <p className={`mb-0 text_des`}>
        {address.city}, {address.zip_code}, {address.country}
      </p>
      {!address.is_primary && address.type == 'delivery' ? (
        <Button
          className="mt-2 p-2 m-1"
          onClick={() => {
            makeAddressPrimary(address);
          }}
        >
          Make Primary
        </Button>
      ) : (
        <></>
      )}
      <Button
        className="mt-2 p-2 m-1"
        onClick={() => {
          deleteAddress(address);
        }}
      >
        Delete Address
      </Button>
    </div>
  );
};

export default AddressCard;
