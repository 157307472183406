import * as network from './network';

const getAllGoal = (params) => {
  return network.get(`healthgoal`, params, true);
};

const getHealthGoalOnSearch = (params) => {
  return network.get(`healthgoal/search`, params, true);
};

const addGoal = (payload) => {
  return network.post(`healthgoal`, payload, true);
};

const updateGoal = (id, payload) => {
  return network.put(`healthgoal/${id}`, payload, true);
};

const deleteGoal = (id) => {
  return network.delet(`healthgoal/${id}`, true);
};

export default {
  getAllGoal,
  getHealthGoalOnSearch,
  addGoal,
  updateGoal,
  deleteGoal,
};
