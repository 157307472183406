import React, { useEffect, useRef, useState } from 'react';
import api from '../api/api';
import { toastError, toastSuccess } from '../common/UI/Toasts';

const OfferTitle = () => {
  const [title, setTitle] = useState('');
  const [id, steId] = useState();
  useEffect(() => {
    getTitle();
  }, []);
  const getTitle = () => {
    api
      .getOfferTitle()
      .then((res) => {
        if (res?.data?.data.length) {
          steId(res?.data?.data[0]?._id);
          setTitle(res?.data?.data[0]?.title);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const submitTitle = () => {
    const payload = {
      title: title,
    };
    if (id) {
      api
        .updateOfferTitle(id, payload)
        .then((res) => {
          setTitle(res?.data?.data[0]?.title);
          toastSuccess('Title Updated Successfully');
        })
        .catch((err) => {
          console.log(err);
          toastError(err.message);
        });
    } else {
      api
        .addOfferTitle(payload)
        .then((res) => {
          setTitle(res?.data?.data[0]?.title);
          toastSuccess('Title Added Successfully');
        })
        .catch((err) => {
          console.log(err);
          toastError(err.message);
        });
    }
  };
  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <h3>Offer titles</h3>
                </div>
                <div className="col-md-12">
                  <div className="mt-5 px-5">
                    <textarea
                      type="text"
                      rows="3"
                      value={title}
                      className="w-54"
                      style={{ minWidth: '500px' }}
                      placeholder="Enter your title text here"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg btn-block mx-5"
                    onClick={submitTitle}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferTitle;
