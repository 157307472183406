import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import Input from '../common/FormComponents/Input';
import services from '../api/api';
import { toastSuccess, toastError } from '../common/UI/Toasts';
import { getErrorMessage, getFormDataFromObject } from '../utils/helpers';

import viewIcon from '../assets/images/eye.svg';
import crossViewIcon from '../assets/images/eye-off.svg';
import profileImg from '../assets/images/profile-image.jpg';

const ProfileManagement = () => {
  const UID = localStorage.getItem('userId');
  const [showOPassword, setShowOPassword] = useState(false);
  const [showNPassword, setShowNPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [loadFormValues, setLoadFormValues] = useState(null);

  const initialFormValues = {
    fname: '',
    lname: '',
    email: '',
    phone: '',
    photo: '',
  };

  const validationSchema = Yup.object({
    fname: Yup.string().required(getErrorMessage('required', 'First name')),
    lname: Yup.string().required(getErrorMessage('required', 'Last name')),
    email: Yup.string()
      .email(getErrorMessage('valid', 'Email'))
      .required(getErrorMessage('required', 'Email')),

    phone: Yup.string()
      .matches(/^.{9,11}$/, getErrorMessage('phoneformat', 'Phone'))
      .required(getErrorMessage('required', 'Phone')),
  });

  const onSubmit = (values, onSubmitProps) => {
    const payload = {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      phone: values.phone,
    };

    if (values && values.id) {
      services
        .updateUserById(values.id, getFormDataFromObject(payload))
        .then((res) => {
          if (res) {
            toastSuccess('Profile Updated Successfully!!');
            onSubmitProps.setSubmitting(false);
          }
        })
        .catch((err) => {
          toastError(err.message);
          onSubmitProps.setSubmitting(false);
        });
    }
  };

  const initialPasswordValues = {
    opassword: '',
    npassword: '',
    cpassword: '',
  };

  const validationPasswordSchema = Yup.object({
    opassword: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        getErrorMessage('passstrength', 'Old Password'),
      )
      .required(getErrorMessage('required', 'Old Password')),
    npassword: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        getErrorMessage('passstrength', 'New Password'),
      )
      .required(getErrorMessage('required', 'New Password')),
    cpassword: Yup.string()
      .oneOf([Yup.ref('npassword', '')], getErrorMessage('confirm', 'Password'))
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        getErrorMessage('passstrength', 'Confirm Password'),
      )
      .required(getErrorMessage('required', 'Confirm password')),
  });

  const onPasswordSubmit = (values, onSubmitProps) => {
    const payload = {
      id: UID,
      currentPassword: values.opassword,
      newPassword: values.npassword,
    };

    if (UID) {
      onSubmitProps.setSubmitting(true);
      services
        .updatePasswordById(payload)
        .then((res) => {
          if (res) {
            toastSuccess('Password Updated Successfully!!');
            onSubmitProps.resetForm();
            onSubmitProps.setSubmitting(false);
          }
        })
        .catch((err) => {
          toastError(err.message);
          onSubmitProps.setSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (UID) {
      services
        .getUserById(UID)
        .then((res) => {
          setLoadFormValues({
            id: res?.data?.data?._id,
            fname: res?.data?.data?.first_name,
            lname: res?.data?.data?.last_name,
            email: res?.data?.data?.email,
            phone: res?.data?.data?.phone,
            photo: res?.data?.data?.photo?.location,
          });
        })
        .catch((err) => {
          toastError(err.message);
        });
    }
  }, []);

  const toggleShowOPassword = () => {
    setShowOPassword((pre) => !pre);
  };

  const toggleShowNPassword = () => {
    setShowNPassword((pre) => !pre);
  };

  const toggleShowCPassword = () => {
    setShowCPassword((pre) => !pre);
  };

  return (
    <section className="content_wrapper product_add_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              <div className="header_top">
                <h3>User Profile</h3>
              </div>
            </div>
          </div>
        </div>
        <Formik
          initialValues={loadFormValues || initialFormValues}
          onSubmit={onSubmit}
          validateOnChange={false}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {(formik) => {
            return (
              <Form>
                <div className="middle_main_2">
                  <div className="row">
                    <div className="col-xl-3 col-md-3 col-lg-3">
                      <div className="row">
                        <div
                          className="col-xl-12 col-md-12 col-lg-12"
                          style={{ textAlign: 'center' }}
                        >
                          <img
                            src={formik?.values?.photo || profileImg}
                            style={{
                              borderRadius: '50%',
                              width: '100px',
                              height: '100px',
                              objectFit: 'cover',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-9 col-md-9 col-lg-9">
                      <div className="row">
                        <div className="col-md-6 col-lg-6 form-group">
                          <Input
                            label="First Name"
                            labelClassname="form-label"
                            name="fname"
                            id="fname"
                            type="text"
                            className="form-control form-input"
                            placeholder="First Name"
                            isRequired
                          />
                        </div>
                        <div className="col-md-6 col-lg-6 form-group">
                          <Input
                            label="Last Name"
                            labelClassname="form-label"
                            name="lname"
                            id="lname"
                            type="text"
                            className="form-control form-input"
                            placeholder="Last Name"
                            isRequired
                          />
                        </div>
                        <div className="col-md-6 col-lg-6 form-group">
                          <Input
                            label="Email"
                            labelClassname="form-label"
                            name="email"
                            id="email"
                            type="text"
                            className="form-control form-input"
                            placeholder="Email Address"
                            isRequired
                          />
                        </div>
                        <div className="col-md-6 col-lg-6 form-group">
                          <Input
                            label="Phone Number"
                            labelClassname="form-label"
                            name="phone"
                            id="phone"
                            type="text"
                            className="form-control form-input"
                            placeholder="Phone Number"
                            onKeyPress={(e) =>
                              !/[0-9]/.test(e.key) && e.preventDefault()
                            }
                            isRequired
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer_main">
                  <Button className="me-2" type="submit">
                    Update Profile
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              <div className="header_top">
                <h3>Change Password</h3>
              </div>
            </div>
          </div>
        </div>
        <Formik
          initialValues={initialPasswordValues}
          onSubmit={onPasswordSubmit}
          validateOnChange={false}
          validationSchema={validationPasswordSchema}
          enableReinitialize={true}
        >
          {(formik) => {
            return (
              <Form>
                <div className="middle_main_2">
                  <div className="row">
                    <div
                      className={`col-xl-4 col-md-4 col-lg-4 form-group position-relative`}
                    >
                      <Input
                        id={'opassword'}
                        label={'Old Password'}
                        labelClassname="form-label"
                        className="form-control form-input"
                        type={showOPassword ? 'text' : 'password'}
                        name={'opassword'}
                        placeholder={'Old Password'}
                        isRequired
                      />
                      <span
                        onClick={toggleShowOPassword}
                        className={`position-absolute`}
                        style={{ right: '30px', top: '47px' }}
                      >
                        <img
                          src={showOPassword ? viewIcon : crossViewIcon}
                          alt="eye"
                          width="24"
                          height="24"
                        />
                      </span>
                    </div>
                    <div
                      className={`col-xl-4 col-md-4 col-lg-4 form-group position-relative`}
                    >
                      <Input
                        id={'npassword'}
                        label={'New Password'}
                        labelClassname="form-label"
                        className="form-control form-input"
                        type={showNPassword ? 'text' : 'password'}
                        name={'npassword'}
                        placeholder={'New Password'}
                        isRequired
                      />
                      <span
                        onClick={toggleShowNPassword}
                        className={`position-absolute`}
                        style={{ right: '30px', top: '47px' }}
                      >
                        <img
                          src={showNPassword ? viewIcon : crossViewIcon}
                          alt="eye"
                          width="24"
                          height="24"
                        />
                      </span>
                    </div>
                    <div
                      className={`col-xl-4 col-md-4 col-lg-4 form-group position-relative`}
                    >
                      <Input
                        id={'cpassword'}
                        label={'Confirm Password'}
                        labelClassname="form-label"
                        className="form-control form-input"
                        type={showCPassword ? 'text' : 'password'}
                        name={'cpassword'}
                        placeholder={'Confirm Password'}
                        isRequired
                      />
                      <span
                        onClick={toggleShowCPassword}
                        className={`position-absolute`}
                        style={{ right: '30px', top: '47px' }}
                      >
                        <img
                          src={showCPassword ? viewIcon : crossViewIcon}
                          alt="eye"
                          width="24"
                          height="24"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="footer_main">
                  <Button className="me-2" type="submit">
                    Update Password
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

export default ProfileManagement;
