import React from 'react';
import Products from '../components/Products';

const ProductManagement = () => {
  return (
    <>
      <Products />
    </>
  );
};

export default ProductManagement;
