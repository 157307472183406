import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import AddAndEditModal from '../../../common/UI/CustomModal/addAndEditModal';
import { toastError } from '../../../common/UI/Toasts';

const QtyAlterModal = (props) => {
  const { orderItems, actualOrderItems } = props;

  const errors = orderItems.map((el) => {
    const index = actualOrderItems.findIndex(
      (element) => element?.product_id == el?.product_id,
    );
    const actualQuantity =
      index != -1 ? actualOrderItems[index]?.quantity : el?.quantity;
    return {
      id: el?._id,
      quantity: actualQuantity,
      qtyBlankError: false,
      qtyIncreasedError: false,
    };
  });
  const [orderItemsClone, setOrderItemsClone] = useState(orderItems);
  const [validationError, setValidationError] = useState(true);
  const [Errors, setErrors] = useState(errors);

  useEffect(() => {
    let isThereAnyError = false;
    Errors.forEach((element) => {
      isThereAnyError = isThereAnyError || element?.qtyBlankError;
    });
    if (!isThereAnyError) {
      Errors.forEach((element) => {
        isThereAnyError = isThereAnyError || element?.qtyIncreasedError;
      });
    }
    setValidationError(isThereAnyError);
  }, [Errors]);

  const onChangeOfQuantity = (value, id) => {
    const index = orderItemsClone.findIndex((el) => el?._id === id);

    if (index != -1) {
      const tempItems = [...orderItemsClone];
      const item = {
        ...tempItems[index],
        quantity: value,
      };
      tempItems[index] = item;
      setOrderItemsClone(tempItems);
    }
  };

  const onChangeClick = () => {
    if (!validationError) {
      const products = orderItemsClone.map((el) => {
        return {
          orderedItem: el?._id,
          quantity: el?.quantity,
        };
      });
      props.onChangeAction(products);
    }
  };

  const validateValues = (value, id) => {
    const errorsArr = [...Errors];
    const index = errorsArr.findIndex((el) => el?.id == id);

    if (index != -1) {
      const errorObj = errorsArr[index];

      // Validation:1 if input block has no value
      value === ''
        ? (errorObj.qtyBlankError = true)
        : (errorObj.qtyBlankError = false);
      // Validation:2 if quantity is increased
      value != '' && value > errorObj.quantity
        ? (errorObj.qtyIncreasedError = true)
        : (errorObj.qtyIncreasedError = false);
      errorsArr[index] = errorObj;
      setErrors(errorsArr);
    }
  };

  return (
    <div>
      <AddAndEditModal
        action="Change"
        module="Order Quantity"
        handleToggle={props.toggle}
        show={props.show}
        handleAction={onChangeClick}
      >
        {orderItemsClone.map((el, index) => {
          return (
            <div key={el._id} className="row d-flex align-items-center">
              <div className="col-md-7 h5">{el?.product?.name} :</div>
              <div className="col-md-5 d-flex align-items-center justify-content-end">
                <input
                  className="form-input"
                  style={{ width: '35%' }}
                  type="text"
                  onKeyPress={(e) => {
                    !/[0-9]/.test(e.key) && e.preventDefault();
                  }}
                  value={el?.quantity}
                  onChange={(e) => onChangeOfQuantity(e.target.value, el._id)}
                  onBlur={(e) => validateValues(e.target.value, el._id)}
                />
                <FontAwesomeIcon
                  icon={faMinusCircle}
                  size={'2x'}
                  color={'#088fff'}
                  className="m-4"
                  onClick={() =>
                    // if quantity is 0 then don't change
                    {
                      el?.quantity != 0 &&
                        onChangeOfQuantity(el?.quantity * 1 - 1, el._id);
                    }
                  }
                />
              </div>
              {Errors[index]?.qtyBlankError ? (
                <div className="text-danger">Please enter quantity</div>
              ) : Errors[index]?.qtyIncreasedError ? (
                <div className="text-danger">
                  {`You can't change quantity more than ${Errors[index]?.quantity}`}{' '}
                </div>
              ) : null}
            </div>
          );
        })}
      </AddAndEditModal>
    </div>
  );
};

export default QtyAlterModal;
