import { Formik, Form } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import DatePickerField from '../../../common/FormComponents/DatePickerField';
import CustomInput from './../../../common/FormComponents/Input';
import CustomAsyncCreatableReactSelect from './../../../common/FormComponents/AsyncCreatableReactSelect';

const AddPromotionForm = ({
  selectedOptionValue,
  debouncedLoadOptions,
  validationSchema,
  initialValues,
  onSubmit,
  handleAddBrandPromotion,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(formik) => {
        return (
          <Form>
            <div className="row">
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <CustomAsyncCreatableReactSelect
                  height={'40px'}
                  selectType="async"
                  label={'Select Brand'}
                  labelClassname={'form-label'}
                  className={'text-muted'}
                  cacheOptions
                  name="brand"
                  placeholder={'Select Brand'}
                  loadOptions={(inputValue, callback) =>
                    debouncedLoadOptions(inputValue, callback, 'brand')
                  }
                  defaultOptions={selectedOptionValue}
                  options={selectedOptionValue}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  isRequired={true}
                  isDisabled={initialValues && initialValues.id ? true : false}
                  placeholderStyles={{
                    fontSize: 16,
                    color: '#9aa5ad',
                  }}
                />
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <CustomInput
                  label="Percentage Discount"
                  labelClassname="form-label"
                  name="pdiscount"
                  id="pdiscount"
                  type="number"
                  // min={0}
                  // max={100}
                  step="any"
                  className="form-control form-input"
                  placeholder="Percentage Discount"
                  isRequired={true}
                />
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <DatePickerField
                  label="Start Date"
                  labelClassname={'form-label'}
                  placeholder="Select start date "
                  className="form-control form-input px-4"
                  name="start_date"
                  inputDateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  maxDate={new Date(formik.values.end_date)}
                  onDaySelect={() => {}} //when day is clicked
                  onBlur={() => console.log('blur')}
                  isRequired={true}
                />
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <DatePickerField
                  label="End Date"
                  labelClassname={'form-label'}
                  placeholder="Select end date "
                  className="form-control form-input px-4"
                  name="end_date"
                  inputDateFormat="dd/MM/yyyy"
                  minDate={
                    formik.values.start_date
                      ? new Date(formik.values.start_date)
                      : new Date()
                  }
                  onDaySelect={() => {}} //when day is clicked
                  onBlur={() => console.log('blur')}
                  isRequired={true}
                />
              </div>
            </div>
            <div className="footer_main">
              <Button
                type="button"
                variant="sky"
                className="my-2 me-2"
                onClick={() => handleAddBrandPromotion(false)}
              >
                Back
              </Button>
              <Button className="me-2" type="submit">
                {initialValues && initialValues.id ? 'Update' : 'Save'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddPromotionForm;
