import * as network from './network';

const getAllContacts = (params) => {
  return network.get(`contact-us`, params, true);
};

const deleteContact = (id) => {
  return network.delet(`contact-us/${id}`, true);
};

export default {
  getAllContacts,
  deleteContact,
};
