import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

import blogImg from '../../../assets/images/blog_details.png';
import authorImg from '../../../assets/images/profile_img_2.png';

const ViewBlog = (props) => {
  const { blogInfo, backLinkHandle } = props;
  console.log('blogInfo', blogInfo);
  return (
    <section className="content_wrapper product_detials_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ol className="breadcrumb">
              <li>
                <Link
                  to={'/blog'}
                  title="Product Management"
                  onClick={() => backLinkHandle()}
                >
                  Blog Management
                </Link>
              </li>
              <li>View Blog</li>
            </ol>
          </div>
        </div>
        <div className="container container-full-width">
          <div className="col-md-12">
            <div className="box_main">
              <div className="header_top">
                <div className="col-md-6 col-sm-6">
                  <h3>{blogInfo.title}</h3>
                </div>
                {!blogInfo.author && blogInfo.status && (
                  <div className="col-md-6 col-sm-6">
                    <p className="mb-md-0 ms-auto mt-3 text-end des_5">
                      {moment(blogInfo?.published_date).format('MMMM DD, YYYY')}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="middle_main">
              {blogInfo?.author && (
                <div className="row">
                  <div className="col-md-6 col-sm-6 d-flex">
                    {blogInfo.author_photo && (
                      <span className="me-3">
                        <img
                          src={blogInfo?.author_photo?.location}
                          width="56"
                          height="56"
                          crossOrigin="true"
                        />
                      </span>
                    )}
                    <div className="mt-2">
                      <h4 className="mb-1 des_4">{blogInfo?.author}</h4>
                      <p className="des_5">{blogInfo?.author_profession}</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6">
                    <p className="mb-md-0 ms-auto mt-3 text-end des_5">
                      {moment(blogInfo?.published_date).format('MMMM DD, YYYY')}
                    </p>
                  </div>
                </div>
              )}
              {blogInfo.photo && (
                <div className="row mt-3">
                  <div className="pe-md-5 line_cusotom">
                    <img
                      src={blogInfo?.photo?.location}
                      alt="alter text"
                      className="slider_img ms-4"
                      style={{
                        width: '1190px',
                        height: '300px',
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="row mt-5">
                <div dangerouslySetInnerHTML={{ __html: blogInfo?.blogBody }} />
                <div className="mt-4">
                  <Button
                    type="button"
                    variant="sky"
                    className="my-2 me-2"
                    onClick={() => backLinkHandle()}
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewBlog;
