import React from 'react';
import StockScanSectionPage from '../components/Stock/ScanStockSection';

const StockManagement = () => {
  return (
    <>
      <StockScanSectionPage />
    </>
  );
};

export default StockManagement;
