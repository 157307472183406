import * as network from './network';

const getAllGenQuestions = (params) => {
  return network.get(`general-question`, params, true);
};

const submitGenQuestions = (params) => {
  return network.post(`general-question`, params, true);
};

const deleteGenQuestion = (id) => {
  return network.delet(`general-question/${id}`, true);
};

export default {
  getAllGenQuestions,
  submitGenQuestions,
  deleteGenQuestion,
};
