import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import TextEditor from '../../common/TextEditor';
import DeleteConfirmationModal from '../../common/UI/CustomModal/deleteModal';
import { toastSuccess } from '../../common/UI/Toasts';

const tab = {
  condition: 'condition',
  discover: 'discover',
};

const ForntPageFontComponent = () => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    condition: '',
    discover: '',
    errorMsg: '',
  });
  const [activeTab, setActiveTab] = useState(tab.condition);
  const [showDeleteModel, setDeleteModel] = useState(false);

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  const getData = () => {
    api.getPageText().then((res) => {
      const contentlist = res?.data?.data;
      let formObj = {
        condition: '',
        condition_id: null,
        discover: '',
        discover_id: null,
      };
      contentlist.map((c) => {
        if (c.type === tab.condition) {
          formObj.condition = c.title;
          formObj.condition_id = c._id;
        } else if (c.type === tab.discover) {
          formObj.discover = c.title;
          formObj.discover_id = c._id;
        }
      });
      setForm((prev) => {
        return {
          ...prev,
          ...formObj,
        };
      });
      setLoading(false);
    });
  };

  const onEditorChange = (name, value) => {
    setForm((prev) => {
      if (prev.errorMsg && value) prev.errorMsg = '';
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSave = (tabName) => {
    if (!form[tabName]) {
      setForm((prev) => ({
        ...prev,
        errorMsg: 'Please enter text.',
      }));
      return;
    }
    let payload = {
      type: tabName,
      title: form[tabName],
    };
    if (payload.type) {
      setLoading(true);
      api.addPageText(payload).then((res) => {
        toastSuccess('Html saved successfully.');
        getData();
        setLoading(false);
      });
    }
  };

  const handleDelete = () => {
    const id = form[`${activeTab}_id`];
    api.deletePageText(id).then((res) => {
      toastSuccess('deleted saved successfully.');
      getData();
      setDeleteModel(false);
      setLoading(false);
    });
  };

  const toggleDeleteModal = () => {
    setDeleteModel((prev) => !prev);
  };

  return (
    <div className="content_wrapper all_products_main">
      <div className="container-fluid">
        <div className="box_main">
          <div className="header_top p-3 d-flex justify-content-between">
            <h3>Front Page HTML Management</h3>
            <div>
              <button
                type="button"
                className="btn btn-secondary py-3 mx-2"
                onClick={() => toggleDeleteModal(activeTab)}
                disabled={loading}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-primary py-3"
                onClick={() => handleSave(tab[activeTab])}
                disabled={loading}
              >
                Save
              </button>
            </div>
          </div>
          <div className="middle_main my-3">
            <div className="d-flex justify-content-center">
              <div className="text-uppercase fs-16">
                <span
                  className={`cursor-pointer font-weight-bold ${
                    activeTab === tab.condition
                      ? 'text-primary'
                      : 'text-secondary'
                  }`}
                  onClick={() => setActiveTab(tab.condition)}
                >
                  {tab.condition}
                </span>
                <span className="mx-3 text-secondary">|</span>
                <span
                  className={`cursor-pointer font-weight-bold ${
                    activeTab === tab.discover
                      ? 'text-primary'
                      : 'text-secondary'
                  }`}
                  onClick={() => setActiveTab(tab.discover)}
                >
                  {tab.discover}
                </span>
              </div>
            </div>
            <div className="p-3">
              <TextEditor
                onEditorChange={(value, editor) =>
                  onEditorChange(activeTab, value)
                }
                value={form[activeTab] || ''}
                editorHeight="500px"
                content_css={[
                  // user site uses bootstrap
                  // adding bootstrap cdn to get heading element size
                  'https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.0.1/css/bootstrap.min.css',
                ]}
                forced_root_block="div"
                content_style={`body { font-family:Helvetica,Arial,sans-serif; font-size:14px; padding: 1.5rem }`}
              />
              {form.errorMsg && (
                <span className="text-danger fs-14">{form.errorMsg}</span>
              )}
            </div>
          </div>
          <DeleteConfirmationModal
            module="HTML"
            name=""
            handleToggle={() => toggleDeleteModal()}
            cancel={() => toggleDeleteModal()}
            show={showDeleteModel}
            size="lg"
            handleDelete={() => handleDelete()}
          />
        </div>
      </div>
    </div>
  );
};

export default ForntPageFontComponent;
