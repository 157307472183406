import * as network from './network';

const getStocks = (params) => {
  return network.get('stock', params, true);
};
const updateStock = (id, payload) => {
  return network.put(`stock/${id}`, payload, true);
};

const deleteStock = (id) => {
  return network.delet(`stock/${id}`, true);
};

const stockBulkUpdate = (params) => {
  return network.put(`stock/bulk-update`, params, true);
};
const getFromBarcode = (id) => {
  return network.get(`stock/barcode/${id}`, true);
};

// Bulk Update
const importStockFile = (payload) => {
  return network.post(`stock/import`, payload, true);
};

const getStocksOnSearch = (params) => {
  return network.get(`stock/search`, params, true);
};

export default {
  getStocks,
  updateStock,
  deleteStock,
  stockBulkUpdate,
  getFromBarcode,
  importStockFile,
  getStocksOnSearch,
};
