import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api/api';

const initialState = { categoryArr: null, loading: false };

export const getAllCategory = createAsyncThunk('order/getAllCategory', () => {
  const params = {
    showall: true,
  };
  return api.getAllCategory(params).then((res) => {
    let categoryArr = res?.data?.data?.docs;
    return categoryArr;
  });
});

const categoryReducer = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategoryArr: (state, action) => {
      state.categoryArr = action.payload;
    },
    emptyCategoryArr: (state, action) => {
      state.categoryArr = null;
    },
  },
  extraReducers: {
    [getAllCategory.pending]: (state) => {
      state.loading = true;
    },
    [getAllCategory.rejected]: (state) => {
      state.loading = false;
    },
    [getAllCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.categoryArr = payload;
    },
  },
});
export default categoryReducer.reducer;
export const { setCategoryArr, emptyCategoryArr } = categoryReducer.actions;
