export const defaultFilters = {
  perPage: 20,
};

export const defaultForm = {
  description: '',
  title: '',
  keyword: '',
  page: '',
  errors: {},
};

export const pageOptions = [
  { pageName: 'Home', label: 'Home', value: '/' },
  { pageName: 'Blogs', label: 'Blogs', value: '/blog' },
  {
    pageName: 'Terms & Conditions',
    label: 'Terms & Conditions',
    value: '/terms',
  },
  {
    pageName: 'Privacy Policy',
    label: 'Privacy Policy',
    value: '/policy',
  },
  {
    pageName: 'Return & Refund',
    label: 'Return & Refund',
    value: '/returns',
  },
  { pageName: 'Contact Us', label: 'Contact Us', value: '/contact' },
];
