import React from 'react';
import GoogeAdsCatsTable from '../components/googleAdsCats';

const GoogleAdsCats = () => {
  return (
    <>
      <GoogeAdsCatsTable />
    </>
  );
};

export default GoogleAdsCats;
