import React, { useEffect, useState } from 'react';
import CustomModal from '../../../common/UI/CustomModal';
import moment from 'moment';
import api from '../../../api/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const lexisNexisReportModal = () => {
  const [reportData, setReportData] = useState('');
  const navigate = useNavigate();

  const { id, userId } = useParams();
  console.log(userId, 'userrrr');
  useEffect(() => {
    api.getUserLexisNexisReport(userId).then((res) => {
      const report = res?.data?.data || '';
      setReportData(report);
      console.log(reportData);
    });
  }, []);
  return (
    <div className="content_wrapper all_products_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ol className="breadcrumb">
              <li>
                <p style={{ cursor: 'pointer' }} title="Product Management">
                  All Orders
                </p>
              </li>
              <li>
                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`/vieworder/${id}`);
                  }}
                  title="Product Management"
                >
                  View Order
                </p>
              </li>
              <li>View Lexis Nexis Report</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div
            className=""
            style={{ textAlign: '' }}
            dangerouslySetInnerHTML={{
              __html: reportData,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default lexisNexisReportModal;
