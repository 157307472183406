import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import api from '../../api/api';

import Loader from '../../common/UI/Loader';
import SearchAndButtons from '../../common/UI/Search&Buttons';
import ReactTable from '../../common/UI/ReactTable';
import editIcon from '../../assets/images/pencil.svg';
import { toastError, toastSuccess } from '../../common/UI/Toasts';

import AddModal from './addModal';
import { defaultFilters } from './const.js';

const SEOTable = () => {
  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [showForm, setForm] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const columns = [
    {
      Header: 'Page',
      accessor: 'name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <a
            href={`${process.env.REACT_APP_USER_APP_URL}${row?.original?.pageUrl}`}
            target="blank"
          >
            {process.env.REACT_APP_USER_APP_URL}
            {row?.original?.pageUrl}
          </a>
        );
      },
    },
    {
      Header: 'Title',
      accessor: 'title',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Key Word',
      accessor: 'keyword',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Action',
      accessor: '_id',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => (
        <div onClick={() => toggleForm(row.original, true)}>
          <img src={editIcon} alt="pencil" width="24" height="24" />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    getData(currentPageNo);
  }, [currentPageNo, seeAll]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  // Functions for api calls
  const getData = (pageNo) => {
    let params = {
      ...defaultFilters,
      page: pageNo,
    };
    setLoading(true);

    api
      .getAllPageSEO(params)
      .then((response) => {
        setData(response?.data?.data?.docs);
        setTotalPage(response?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(`===> :: err`, err);
        setLoading(false);
      });
  };

  const getFilteredProductData = (value) => {
    let params = seeAll
      ? {
          seeAll,
          ...defaultFilters,
          name: value, // searching for product name only
        }
      : {
          ...defaultFilters,
          page: currentPageNo,
          name: value, // searching for product name only
        };
    setLoading(true);

    api
      .getProducts(params)
      .then((response) => {
        setData(response?.data?.data?.docs);
        setTotalPage(response?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(`===> :: err`, err);
        setLoading(false);
      });
  };

  const onSearchDebounce = debounce(getFilteredProductData, 1000);
  const getOnSearch = (value) => {
    onSearchDebounce(value);
  };

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  const toggleForm = (data = null, isEdit = false) => {
    if (!isEdit && data) {
      // add api call
      let payload = {
        pageName: data.page.pageName,
        pageUrl: data.page.value,
        description: data.description || '',
        keyword: data.keyword || '',
        title: data.title || '',
      };
      if (data._id) {
        api
          .updatePageSEOById(data._id, payload)
          .then(() => {
            toastSuccess('SEO added successfully.');
            setForm((prev) => !prev);
            setSelectedData(null);
            getData();
          })
          .catch((err) => {
            console.log('err :::', err);
            toastError(err?.message || 'Something went wrong.');
          });
        return;
      }
      api
        .addPageSEO(payload)
        .then(() => {
          toastSuccess('SEO updated successfully.');
          setSelectedData(null);
          setForm((prev) => !prev);
          getData();
        })
        .catch((err) => {
          console.log('err :::', err);
          toastError(err?.message || 'Something went wrong.');
        });
    } else if (isEdit) {
      setSelectedData(data);
      setForm((prev) => !prev);
    } else {
      setForm((prev) => !prev);
      setSelectedData(null);
    }
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="box_main">
            <div className="header_top">
              <h3>Page SEO</h3>
            </div>
            <div className="middle_main">
              <SearchAndButtons
                showSearchBox={false}
                seeAllButton={false}
                // onSearchChange={getOnSearch}
                // searchPlaceholder={'Search by page...'}
                // seeAllHandle={seeAllHandle}
                buttonName="Add"
                onButtonClick={() => toggleForm()}
              />
              {loading ? (
                <Loader loading={loading} />
              ) : (
                <ReactTable
                  tableColumns={columns}
                  tableData={data}
                  seeAll={seeAll}
                  nextHandle={nextHandle}
                  prevHandle={prevHandle}
                  totalPage={totalPage}
                  pageNo={currentPageNo}
                  loading={loading}
                />
              )}
            </div>
            {showForm ? (
              <AddModal
                show={showForm}
                toggle={toggleForm}
                data={selectedData}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default SEOTable;
