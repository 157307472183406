/* eslint-disable no-nested-ternary */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltUp,
  faLongArrowAltDown,
} from '@fortawesome/free-solid-svg-icons';
import { FormGroup } from 'react-bootstrap';
import Select from 'react-select';
import CustomDropdown from '../Dropdown';
import { debounce } from 'lodash';

const renderSortingArrows = (column) => {
  return !column.disableSortBy ? (
    column.isSorted ? (
      column.isSortedDesc ? (
        <>
          <FontAwesomeIcon icon={faLongArrowAltDown} className="sort-arrow" />
          <FontAwesomeIcon
            icon={faLongArrowAltUp}
            className="sort-arrow--active"
          />
        </>
      ) : (
        <>
          <FontAwesomeIcon
            icon={faLongArrowAltUp}
            className="sort-arrow--active"
          />
          <FontAwesomeIcon icon={faLongArrowAltDown} className="sort-arrow" />
        </>
      )
    ) : (
      <>
        <FontAwesomeIcon icon={faLongArrowAltUp} className="sort-arrow" />
        <FontAwesomeIcon icon={faLongArrowAltDown} className="sort-arrow" />
      </>
    )
  ) : (
    ''
  );
};

const defaultColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  const handleChange = (e) => {
    setFilter(e);
  };
  const handleSelectDebounce = debounce(handleChange, 500);

  return (
    <FormGroup>
      <input
        // value={filterValue || ''}
        onChange={(e) => handleSelectDebounce(e.target.value)}
        placeholder={column?.placeholder || 'Search...'}
        style={{
          borderRadius: '32px',
          height: '40px',
          padding: '10px',
          border: 'none',
          width: '100%',
        }}
      />
    </FormGroup>
  );
};

const selectColumnFilter = ({ column }, { name, width }) => {
  const { filterValue, setFilter, options } = column;
  return (
    <CustomDropdown
      placeholder={column?.placeholder || 'Search...'}
      className="mx-0"
      value={filterValue}
      name={name}
      containerWidth={width}
      onChange={(value) => setFilter(value || undefined)}
      options={options}
      isClearable={true}
    />
  );
};
// uncomment this when needed
// const datetTimeColumnFilter = ({
//   column: {
//     filterValue,
//     setFilter,
//     showTimeSelectOnly,
//     showYearPicker,
//     showTimeSelect,
//     filterDateFormat,
//   },
// }) => {
//   return (
//     <FormGroup className="filter-calender">
//       <InputGroup>
//         <DatePicker
//           className="form-control min-widht-datepicker"
//           calendarClassName={showTimeSelect && 'react-custom-datepicker'}
//           isClearable
//           showMonthDropdown
//           showYearDropdown
//           yearDropdownItemNumber={10}
//           scrollableYearDropdown
//           closeOnScroll
//           showYearPicker={showYearPicker}
//           showTimeSelectOnly={showTimeSelectOnly}
//           showTimeSelect={showTimeSelectOnly ? true : showTimeSelect}
//           placeholderText="Select..."
//           injectTimes={[
//             convertUtcToLocalDate(
//               moment().hours(23).minutes(59).second(0).format(),
//             ),
//           ]}
//           dateFormat={
//             showYearPicker
//               ? 'yyyy'
//               : showTimeSelect
//               ? 'MM-dd-yyyy h:mm aa'
//               : showTimeSelectOnly
//               ? 'h:mm aa'
//               : 'MM-dd-yyyy'
//           }
//           selected={
//             filterValue
//               ? showTimeSelectOnly
//                 ? filterValue &&
//                   new Date(
//                     moment.utc().set({
//                       hours: filterValue.split(':')[0],
//                       minutes: filterValue.split(':')[1],
//                     }),
//                   )
//                 : showYearPicker
//                 ? convertYearToTimeStamp(filterValue)
//                 : convertUtcToLocalDate(filterValue)
//               : null
//           }
//           onChange={(value) => {
//             return value === null
//               ? setFilter(null)
//               : showTimeSelectOnly
//               ? setFilter(moment.utc(value).format('HH:mm') || null)
//               : showYearPicker
//               ? setFilter(moment(value).format('yyyy') || null)
//               : filterDateFormat
//               ? setFilter(moment.utc(value).format(filterDateFormat) || null)
//               : setFilter(moment.utc(value).format() || null);
//           }}
//         />
//       </InputGroup>
//     </FormGroup>
//   );
// };

const getFilterParams = (filters) => {
  return filters.reduce(
    (filter, current) => ({
      ...filter,
      [current.id]:
        typeof current.value === 'object' && current.value !== null
          ? current.value.value
          : typeof current.value === 'string'
          ? current.value.trim()
          : current.value,
    }),
    {},
  );
};

const getSortingParams = (sortBy) => ({
  sortBy: sortBy[0].id,
  sortOrder: sortBy[0].desc ? 'desc' : 'asc',
});
export {
  renderSortingArrows,
  defaultColumnFilter,
  selectColumnFilter,
  getFilterParams,
  getSortingParams,
};
