import React from 'react';
import CommonHeaderFooter from './commonHeaderFooter';
import plusBlueIcon from '../../../assets/images/plus-blue.svg';

const AuthorDetailsModal = (props) => {
  const {
    show,
    action,
    handleToggle,
    handlePrev,
    handleNext,
    blogInfo,
    updateBlogInfo,
    isOpenedInEditorScreen,
  } = props;
  return (
    <CommonHeaderFooter
      name={'Author'}
      action={action}
      show={show}
      centered={true}
      handleToggle={handleToggle}
      handleNext={handleNext}
      handlePrev={handlePrev}
      isOpenedInEditorScreen={isOpenedInEditorScreen}
    >
      <div className="product_add_main">
        <div
          className="middle_main_2"
          style={{
            borderBottom: 'none',
          }}
        >
          <div className="row">
            <div className="col-lg-6 form-group">
              <label className="form-label">Author Name</label>
              <input
                type="text"
                className="form-control form-input"
                placeholder="Enter Author Name"
                onChange={(e) => updateBlogInfo('author', e.target.value)}
                value={blogInfo?.author || ''}
              />
            </div>
            <div className="col-lg-6 form-group">
              <label className="form-label">Author Profession</label>
              <input
                type="text"
                className="form-control form-input"
                placeholder="Enter Author Name"
                onChange={(e) =>
                  updateBlogInfo('author_profession', e.target.value)
                }
                value={blogInfo?.author_profession || ''}
              />
            </div>
          </div>
          <div className="row">
            <label className="form-label">Upload Profile Photo</label>
            <div
              className="col-md-5 col-lg-3 col-xl-3 col-sm-5 mb-2"
              style={{ height: '10rem' }}
            >
              <span className="upload_text">
                <label style={{ cursor: 'pointer' }}>
                  <img
                    src={plusBlueIcon}
                    width="12"
                    height="12"
                    className="img-fluid custom_radius me-2"
                    alt="plus"
                  />
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    name="author_photo"
                    accept="image/*"
                    onChange={(e) => {
                      // URL.createObjectURL is giving TypeError without checking length
                      if (e.target.files.length !== 0) {
                        let imgData = {
                          PrevUrl: URL.createObjectURL(e.target.files[0]),
                          imgData: e.target.files[0],
                        };
                        updateBlogInfo('author_photo', imgData);
                      }
                    }}
                  />
                </label>
              </span>
            </div>
            {blogInfo?.author_photo?.PrevUrl && (
              <div
                className="col-md-5 col-lg-3 col-xl-3 col-sm-5 mb-2"
                style={{ height: '10rem' }}
              >
                <img
                  src={blogInfo.author_photo?.PrevUrl}
                  className="img-fluid custom_radius"
                  style={{ height: '100%' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </CommonHeaderFooter>
  );
};

export default AuthorDetailsModal;
