import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';

const CustomAsyncCreatableSelect = (props) => {
  const {
    selectType = 'creatableAsync',
    id,
    isSearchable,
    controlHeight,
    height,
    placeholderMarginTop,
    placeholderColor,
    maxMenuHeight,
    defaultValue,
    placeholder,
    options,
    menuWidth,
    containerWidth,
    onChange,
    className,
    name,
    value,
    onBlur,
    controlStyles,
    placeholderStyles,
    showIndicator,
    isClearable,
    components,
    cacheOptions,
    loadOptions,
    defaultOptions,
    //
    field,
    border,
    menuIsOpen,
    isMulti,
    ...rest
  } = props;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: '#dcefff',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      backgroundColor: state.isFocused
        ? '#dcefff'
        : state.isSelected
        ? '#51a5ec'
        : 'white',
      borderRadius: state.isSelected ? '5px' : '',
      color: '#021D33',
      padding: '9px',
    }),
    control: (provided) => ({
      ...provided,
      border: border || 'none',
      textAlign: 'left',
      fontSize: '18px',
      borderRadius: '32px',
      boxShadow: 'white',
      cursor: 'pointer',
      minHeight: controlHeight,
      height: height || provided.height,
      ...controlStyles,
    }),
    container: (provided) => ({
      ...provided,
      width: containerWidth,
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '10px',
      textAlign: 'left',
      fontSize: '16px',
      width: menuWidth || provided.width,
      marginTop: '12px',
      boxShadow:
        '0 0 0 1px hsl(0deg 0% 0% / 0%), 0 4px 11px hsl(0deg 0% 0% / 10%)',
    }),
    singleValue: (provided) => ({
      ...provided,
      marginLeft: '10px',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '10px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: placeholderColor,
      marginLeft: '10px',
      marginTop: placeholderMarginTop || provided.marginTop,
      ...placeholderStyles,
    }),
    input: (provided) => ({
      ...provided,
      marginLeft: '10px',
    }),
  };
  // creatable, async

  return selectType == 'creatable' ? (
    <CreatableSelect
      id={id}
      name={name}
      className={className}
      instanceId={id}
      maxMenuHeight={maxMenuHeight}
      defaultValue={defaultValue ? defaultValue : null}
      placeholder={placeholder}
      isSearchable={isSearchable}
      options={options}
      styles={customStyles}
      components={
        components
          ? components
          : {
              IndicatorSeparator: () => null,
            }
      }
      onFocus={rest.onFocus}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      isMulti={isMulti}
      {...rest}
      {...field}
    />
  ) : selectType == 'async' ? (
    <AsyncSelect
      id={id}
      name={name}
      className={className}
      instanceId={id}
      maxMenuHeight={maxMenuHeight}
      defaultValue={defaultValue ? defaultValue : null}
      placeholder={placeholder}
      isSearchable={isSearchable}
      options={options}
      styles={customStyles}
      components={
        components
          ? components
          : {
              IndicatorSeparator: () => null,
            }
      }
      onFocus={rest.onFocus}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      isMulti={isMulti}
      cacheOptions={cacheOptions || true}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      {...field}
      {...rest}
    />
  ) : (
    <AsyncCreatableSelect
      id={id}
      name={name}
      className={className}
      instanceId={id}
      maxMenuHeight={maxMenuHeight}
      defaultValue={defaultValue ? defaultValue : null}
      placeholder={placeholder}
      isSearchable={isSearchable}
      options={options}
      styles={customStyles}
      components={
        components
          ? components
          : {
              IndicatorSeparator: () => null,
            }
      }
      onFocus={rest.onFocus}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      isMulti={isMulti}
      cacheOptions={cacheOptions || true}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      {...field}
      {...rest}
    />
  );
};

export default CustomAsyncCreatableSelect;
