import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import CustomInput from '../common/FormComponents/Input';
import {
  getErrorMessage,
  login,
  isLoggedIn,
  isUserAdmin,
  isUserDoctor,
  isUserPharmacist,
} from '../utils/helpers';
import services from '../api/api';
import { toastError, toastSuccess } from '../common/UI/Toasts';
import min_logo from '../assets/images/Farmeci Logo 2x.png';
import eye from '../assets/images/eye.svg';
import eyeOff from '../assets/images/eye-off.svg';

const Login = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirectPath = localStorage.getItem('path');
  const [showPassword, setShowPassword] = useState(false);
  const id = searchParams.get('id');
  const token = searchParams.get('token');

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(redirectPath || '/');
    }
  }, []);

  useEffect(() => {
    if (id && token) {
      services
        .login({ id, token, two_factor_authentication: true })
        .then((res) => {
          setAccessToken(res?.data?.data);
        })
        .catch((err) => {
          toastError(err.message);
          console.log('err', err);
        });
    }
  }, [id, token]);

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(getErrorMessage('valid', 'Email'))
      .required(getErrorMessage('required', 'Email')),
    password: Yup.string().required(getErrorMessage('required', 'Password')),
  });

  const setAccessToken = (user) => {
    let fullname = `${user?.first_name} ${user?.last_name}`;
    login(
      user?.token,
      user?._id,
      fullname,
      user?.first_name,
      user?.photo?.location,
      user?.role,
    );
    toastSuccess('Logged In Successfully!!');
    if (isUserAdmin() || isUserDoctor()) {
      navigate('/orders-pending', { replace: true });
    } else if (isUserPharmacist()) {
      navigate('/orders-assigned', { replace: true });
    }
  };

  const onSubmit = (values, onSubmitProps) => {
    onSubmitProps.setSubmitting(true);
    const isAuthenticationRequired =
      process.env.REACT_APP_TWO_FACTOR_AUTHENTICATION === 'true';
    services
      .login({
        ...values,
        url: window.location.origin,
        two_factor_authentication: isAuthenticationRequired,
      })
      .then((res) => {
        if (isAuthenticationRequired) {
          if (res?.data?.data) {
            toastSuccess(res?.data?.message);
          }
        } else {
          setAccessToken(res?.data?.data);
        }

        onSubmitProps.setSubmitting(false);
        onSubmitProps.resetForm();
      })
      .catch((err) => {
        onSubmitProps.setSubmitting(false);
        toastError(err.message);
        console.log('err', err);
      });
  };

  return (
    <section className="login_main mt-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 mx-auto">
            <div className="middle_main">
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validateOnChange={false}
                validationSchema={validationSchema}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-12 text-center mb-3">
                          {/* <img
                            src={min_logo}
                            className="logo_2"
                            height="40"
                            alt="Logo"
                          /> */}
                        </div>
                        <div className="col-xl-12 form-group">
                          <CustomInput
                            label="Email Address"
                            labelClassname="form-label"
                            type="email"
                            name="email"
                            id="email"
                            className="form-control form-input"
                            placeholder="Email Address"
                          />
                        </div>
                        <div className="col-xl-12 form-group position-relative">
                          <CustomInput
                            name="password"
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            labelClassname="form-label"
                            placeholder="Password"
                            className="form-control form-input pe-5"
                          />
                          <span
                            onClick={toggleShowPassword}
                            className="right_img position-absolute"
                          >
                            {' '}
                            <img
                              src={showPassword ? eye : eyeOff}
                              alt="eye"
                              width="24"
                              height="24"
                            />
                          </span>
                        </div>
                        {/* <div className="col-xl-12 form-group text-end">
                          <Link to="/">Forgot Password?</Link>
                        </div> */}
                        <div className="text-center">
                          <button
                            className="btn btn-primary login_btn"
                            type="submit"
                            disabled={formik.isSubmitting}
                          >
                            Login
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
