import { ErrorMessage, Field } from 'formik';
import React from 'react';
import TextError from '../TextError';
import CustomAsyncCreatableSelect from '../../UI/AsyncCreatableDropdown';

const CustomAsyncCreatableReactSelect = (props) => {
  const {
    label,
    name,
    className,
    style,
    labelClassname,
    id,
    type,
    value,
    height,
    placeholderMarginTop,
    placeholder,
    placeholderColor,
    options,
    menuWidth,
    border,
    onChange,
    cacheOptions,
    defaultOptions,
    loadOptions,
    ...rest
  } = props;
  return (
    <div>
      <Field name={name} id={id} validate={rest.validate || true}>
        {(formik) => {
          const {
            field: { value, name },
            form: { setFieldValue, setTouched, touched, errors },
          } = formik;
          return (
            <div>
              <label className={labelClassname}>
                {label}
                {rest.isRequired ? (
                  <span className="text-danger">*</span>
                ) : null}
              </label>
              <CustomAsyncCreatableSelect
                className={className}
                id={id}
                onChange={(value) => {
                  setFieldValue(name, value, false);
                  onChange ? onChange(value) : null;
                }}
                isSearchable={false}
                height={height}
                placeholderMarginTop={placeholderMarginTop}
                placeholder={placeholder}
                placeholderColor={placeholderColor}
                value={value}
                menuWidth={menuWidth}
                onBlur={() => {
                  setTouched({ ...touched, [name]: true });
                }}
                border={border ? border : '1 px solid #9aa5ad'}
                cacheOptions={cacheOptions || true}
                defaultOptions={defaultOptions}
                loadOptions={loadOptions}
                options={options}
                {...rest}
              />
              {/* {touched[name] && errors[name] && (
                <TextError>{errors[name]}</TextError>
              )} */}
              <ErrorMessage component={TextError} name={name} />
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default CustomAsyncCreatableReactSelect;
