import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import SearchAndButtons from '../common/UI/Search&Buttons';
import OrderTable from '../components/Orders/orderTable';
import api from '../api/api';
import fridgeIcon from '../assets/images/fridge.svg';
import { isUserAdmin, isUserDoctor, isUserPharmacist } from '../utils/helpers';
import { orderSearchbarPlaceholder } from '../common/constants';

const OrderManagement = () => {
  const CSVheaders = [
    { label: 'Order Number', key: 'order_number' },
    { label: 'Customer Name', key: 'customer_name' },
    { label: 'Total Price', key: 'total_price' },
    { label: 'Total Items', key: 'total_items' },
    { label: 'Email Address', key: 'email' },
    { label: 'Delivery Service', key: 'delivery_service' },
    { label: 'Status', key: 'status' },
  ];
  const [csvData, setCsvData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState('');

  const userId = localStorage.getItem('userId');
  useEffect(() => {
    getOrders(currentPageNo);
  }, [currentPageNo, seeAll, searchVal]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getOrders = (pageNo, value = null) => {
    setLoading(true);
    let params = seeAll
      ? { showall: true }
      : {
          perPage: 10,
          page: pageNo,
        };
    if (isUserAdmin()) params = { ...params, sortFor: 'admin' };
    if (isUserDoctor())
      params = {
        ...params,
        sortFor: 'doctor',
        doctor_id: userId,
        has_medicine: true,
      };
    if (isUserPharmacist()) params = { ...params, pharmacist_id: userId };
    if (searchVal)
      params = {
        ...params,
        search: searchVal,
      };

    api
      .getAllOrders(params)
      .then((res) => {
        let data = res?.data?.data?.docs;
        setData(data);
        setTotalPage(res.data?.data?.totalPages);
        loadCSVdata(res.data?.data?.docs);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadCSVdata = (orderdata) => {
    const modifyorderdata = orderdata?.length
      ? orderdata?.map((el) => {
          return {
            order_number: el?.order_number,
            customer_name: `${el?.user?.first_name} ${el?.user?.last_name}`,
            email: el?.user?.email,
            total_price: el?.total,
            total_items: el?.total_items,
            status: el?.current_order_status,
            delivery_service: el?.delivery?.title,
          };
        })
      : [];

    setCsvData(modifyorderdata);
  };

  const handleOrderSearch = (value) => {
    setSearchVal(value);
  };

  const onSearchDebounce = debounce(handleOrderSearch, 1000);

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div
                  className="header_top d-flex"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h3>Order Management</h3>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {(isUserAdmin() || isUserPharmacist()) && (
                      <span style={{ fontSize: '14px', color: 'blue' }}>
                        Order contains medicine
                      </span>
                    )}
                    {(isUserAdmin() || isUserDoctor()) && (
                      <span style={{ fontSize: '14px', color: 'green' }}>
                        New message received
                      </span>
                    )}
                    {isUserAdmin() && (
                      <span style={{ fontSize: '14px', color: 'red' }}>
                        Rejected by doctor
                      </span>
                    )}
                    {(isUserAdmin() || isUserPharmacist()) && (
                      <span style={{ fontSize: '14px', color: 'black' }}>
                        <span className="ms-2">
                          <img src={fridgeIcon} width={20} height={20} />
                        </span>
                        Has Fridge Item
                      </span>
                    )}
                  </div>
                </div>
                <div className="middle_main">
                  <SearchAndButtons
                    onSearchChange={onSearchDebounce}
                    searchPlaceholder={orderSearchbarPlaceholder}
                    buttonName={
                      isUserAdmin() ? (
                        <CSVLink
                          data={csvData}
                          filename={`orders_${moment().format(
                            'YYYY_MM_DD_HH_mm_ss',
                          )}.xlsx`}
                          target="_blank"
                          headers={CSVheaders}
                          style={{ color: '#fff' }}
                        >
                          Export Orders
                        </CSVLink>
                      ) : null
                    }
                    seeAllHandle={seeAllHandle}
                    loading={loading}
                  />
                  <OrderTable
                    loading={loading}
                    prevHandle={prevHandle}
                    nextHandle={nextHandle}
                    data={data}
                    seeAll={seeAll}
                    totalPage={totalPage}
                    currentPageNo={currentPageNo}
                    tableFor={'All'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderManagement;
