import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import ReactTable from './../common/UI/ReactTable';
import Loader from './../common/UI/Loader';
import SearchAndButtons from './../common/UI/Search&Buttons';
import GPAddressForm from '../components/GPAddress';
import api from '../api/api';
import { getErrorMessage } from '../utils/helpers';
import { toastSuccess } from '../common/UI/Toasts';
import editIcon from '../assets/images/pencil.svg';
import deleteIcon from '../assets/images/trash.svg';
import DeleteConfirmationModal from '../common/UI/CustomModal/deleteModal';
import { toastError } from './../common/UI/Toasts/index';

const GPAddress = () => {
  const columns = [
    {
      Header: 'GP Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original.OrganisationName}</span>;
      },
    },
    {
      Header: 'Address 1',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original.Address1}</span>;
      },
    },
    {
      Header: 'City',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original.City}</span>;
      },
    },
    {
      Header: 'Email',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original.email}</span>;
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span
              className="me-2"
              style={{ cursor: 'pointer' }}
              onClick={() => handleEdit(row.original)}
            >
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleDelete(row.original)}
            >
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];
  const initialValues = {
    OrganisationName: '',
    Address1: '',
    Address2: '',
    Address3: '',
    City: '',
    Postcode: '',
    email: '',
  };

  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [loadInitialValues, setLoadInitialValues] = useState(null);
  const [delModelShow, setDelModelShow] = useState(false);
  const [deleteName, setDeleteName] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllAddress();
  }, [currentPageNo, seeAll, showForm]);

  const validationSchema = Yup.object({
    OrganisationName: Yup.string().required(
      getErrorMessage('required', 'GP Name'),
    ),
    Address1: Yup.string().required(getErrorMessage('required', 'Address1')),
    Address2: Yup.string(),
    Address3: Yup.string(),
    City: Yup.string(),
    Postcode: Yup.string().required(getErrorMessage('required', 'Postcode')),
    email: Yup.string().email(getErrorMessage('valid', 'Email')),
  });

  const onSubmit = (values, onSubmitProps) => {
    onSubmitProps.setSubmitting(true);
    if (loadInitialValues?._id) {
      api
        .updateGPAddress(loadInitialValues._id, { ...values })
        .then(() => {
          onSubmitProps.resetForm();
          onSubmitProps.setSubmitting(false);
          handleAddGPAddress(false);
          toastSuccess('GP Address Updated Successfully');
        })
        .catch((err) => {
          onSubmitProps.setSubmitting(false);
          console.log('error', err);
          toastError(err?.message);
        });
    } else {
      api
        .addGPAddress(values)
        .then(() => {
          onSubmitProps.resetForm();
          onSubmitProps.setSubmitting(false);
          handleAddGPAddress(false);
          toastSuccess('GP Address Added Successfully');
        })
        .catch((err) => {
          onSubmitProps.setSubmitting(false);
          console.log('error', err);
          toastError(err?.message);
        });
    }
  };

  const handleEdit = (data) => {
    handleAddGPAddress(true);
    setLoadInitialValues(data);
  };

  const handleAddGPAddress = (isShow) => {
    setLoadInitialValues(null);
    setShowForm(isShow);
  };

  const handleDelete = (data) => {
    setDelModelShow(true);
    setDeleteName(data?.OrganisationName);
    setDeleteId(data?._id);
  };

  const deleteModalOnHide = () => {
    setDelModelShow(false);
    setDeleteId(null);
    setDeleteName(null);
  };

  const deleteModalOnDelete = () => {
    api
      .deleteGPAddress(deleteId)
      .then(() => {
        toastSuccess('GP Address Deleted Successfully');
        setLoading(true);
        getAllAddress();
        setDeleteId(null);
        setDeleteName(null);
        setDelModelShow(false);
      })
      .catch((err) => {
        console.log('error', err);
        setDeleteId(null);
        setDeleteName(null);
      });
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getAllAddress = () => {
    const params = seeAll
      ? { showall: true }
      : { perPage: 10, page: currentPageNo };
    api
      .getAllGPAddress(params)
      .then((res) => {
        setData(res.data?.data?.docs);
        setTotalPage(res.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const searchGPAddress = (searchkey) => {
    if (searchkey) {
      const params = { showall: true, search: searchkey };
      api
        .searchGPAddress(params)
        .then((res) => {
          setData(res.data?.data?.docs);
          setTotalPage(res.data?.data?.totalPages);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      getAllAddress();
    }
  };

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  return (
    <div className="content_wrapper all_products_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              {showForm ? (
                <>
                  <div className="header_top">
                    <h3>
                      {loadInitialValues && loadInitialValues.id
                        ? 'Edit'
                        : 'Add'}{' '}
                      GP Address
                    </h3>
                  </div>
                  <div className="middle_main">
                    <GPAddressForm
                      initialValues={loadInitialValues || initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                      handleAddGPAddress={handleAddGPAddress}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="header_top">
                    <h3>GP Address</h3>
                  </div>
                  <div className="middle_main">
                    <SearchAndButtons
                      onSearchChange={searchGPAddress}
                      onButtonClick={() => handleAddGPAddress(true)}
                      searchPlaceholder={'Search by GP Name...'}
                      buttonName={'Add GP Address'}
                      seeAllHandle={seeAllHandle}
                    />
                    {loading ? (
                      <Loader loading={loading} />
                    ) : (
                      <ReactTable
                        title="GP Address"
                        tableColumns={columns}
                        tableData={data}
                        seeAll={seeAll}
                        nextHandle={nextHandle}
                        prevHandle={prevHandle}
                        totalPage={totalPage}
                        pageNo={currentPageNo}
                      />
                    )}
                  </div>
                  <DeleteConfirmationModal
                    name={deleteName}
                    module={'GP Address'}
                    show={delModelShow}
                    handleToggle={deleteModalOnHide}
                    handleDelete={deleteModalOnDelete}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GPAddress;
