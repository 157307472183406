import { Formik, Form } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import CustomInput from './../../common/FormComponents/Input';

const GPAddressForm = ({
  validationSchema,
  initialValues,
  onSubmit,
  handleAddGPAddress,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(formik) => {
        return (
          <Form>
            <div className="row">
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <CustomInput
                  label="GP Name"
                  labelClassname="form-label"
                  name="OrganisationName"
                  id="OrganisationName"
                  className="form-control form-input"
                  placeholder="GP Name"
                  isRequired={true}
                />
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <CustomInput
                  label="Address1"
                  labelClassname="form-label"
                  name="Address1"
                  id="Address1"
                  className="form-control form-input"
                  placeholder="Address1"
                  isRequired={true}
                />
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <CustomInput
                  label="Address2"
                  labelClassname="form-label"
                  name="Address2"
                  id="Address2"
                  className="form-control form-input"
                  placeholder="Address2"
                />
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <CustomInput
                  label="Address3"
                  labelClassname="form-label"
                  name="Address3"
                  id="Address3"
                  className="form-control form-input"
                  placeholder="Address3"
                />
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <CustomInput
                  label="City"
                  labelClassname="form-label"
                  name="City"
                  id="City"
                  className="form-control form-input"
                  placeholder="City"
                />
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <CustomInput
                  label="Postcode"
                  labelClassname="form-label"
                  name="Postcode"
                  id="Postcode"
                  className="form-control form-input"
                  placeholder="Postcode"
                  isRequired={true}
                />
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6 form-group">
                <CustomInput
                  label="Email"
                  labelClassname="form-label"
                  name="email"
                  id="email"
                  className="form-control form-input"
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="footer_main">
              <Button
                type="button"
                variant="sky"
                className="my-2 me-2"
                onClick={() => handleAddGPAddress(false)}
              >
                Back
              </Button>
              <Button className="me-2" type="submit">
                {initialValues && initialValues.id ? 'Update' : 'Save'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GPAddressForm;
