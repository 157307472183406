import React, { useEffect, useState } from 'react';
import CommonHeaderFooter from './commonHeaderFooter';
import CustomDropdown from '../../../common/UI/Dropdown';
import api from '../../../api/api';

import plusBlueIcon from '../../../assets/images/plus-blue.svg';
import { DIMENSION, PRODUCT_STATUS_OPTIONS } from '../../../common/constants';
import { imageDimentionValidator } from '../../../utils/helpers';

const BlogDetailsModal = (props) => {
  const {
    show,
    action,
    handleToggle,
    handleNext,
    blogInfo,
    updateBlogInfo,
    isOpenedInEditorScreen,
  } = props;
  const [errors, setErrors] = useState({});
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptons, setSubcategoryOptons] = useState([]);
  const [allSubcategories, setAllSubcategories] = useState([]);

  useEffect(() => {
    getCategories();
    getSubcategories();
    blogInfo?.category &&
      settingSubcategoryOptions(blogInfo?.category?.categoryId);
  }, []);

  const validateFields = (key, value) => {
    value
      ? setErrors((prev) => {
          const updated = { ...prev };
          delete updated[key];
          return updated;
        })
      : setErrors((prev) => ({
          ...prev,
          [key]: true,
        }));
  };

  const handleNextWithValidation = () => {
    const requiredFields = ['title', 'category', 'subcategory', 'status'];
    let errorCount = 0;
    requiredFields.map((el) => {
      console.log('bloginfo', el, !blogInfo[el]);
      // when key or value is not defined in blogInfo Object
      if (!blogInfo[el]) {
        console.log('skfhsd');
        errorCount += 1;
        setErrors((prev) => ({
          ...prev,
          [el]: true,
        }));
      }
    });
    return errorCount == 0 && Object.keys(errors).length == 0
      ? handleNext(true)
      : handleNext(false);
  };

  const getCategories = () => {
    const params = {
      showall: true,
    };
    api
      .getAllCategory(params)
      .then((res) => {
        setCategoryOptions(
          res?.data?.data?.docs.map((category) => {
            return {
              label: category.name,
              value: category.slug,
              categoryId: category._id,
            };
          }),
        );
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const getSubcategories = () => {
    const params = { showall: true };
    api
      .getAllSubcategory(params)
      .then((res) => {
        setAllSubcategories(
          res?.data?.data?.docs.map((subcategory) => {
            return {
              label: subcategory.name,
              value: subcategory.slug,
              subcategoryId: subcategory._id,
              categoryId: subcategory.category_id?._id,
            };
          }),
        );
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const settingSubcategoryOptions = (categoryId) => {
    const options = allSubcategories.filter(
      (subcategory) => subcategory.categoryId == categoryId,
    );
    setSubcategoryOptons(options);
  };

  return (
    <>
      <CommonHeaderFooter
        action={action}
        name={'Blog'}
        show={show}
        handleToggle={handleToggle}
        handleNext={handleNextWithValidation}
        isOpenedInEditorScreen={isOpenedInEditorScreen}
      >
        <div className="product_add_main">
          <div className="middle_main_2" style={{ borderBottom: 'none' }}>
            <div className="row">
              <div className="col-lg-12 form-group">
                <label className="form-label">Blog Title<span className="text-danger">*</span></label>
                <input
                  type="text"
                  name="title"
                  className="form-control form-input"
                  style={{
                    borderColor: '#d3d3d3',
                  }}
                  placeholder="Enter Blog Title"
                  onChange={(e) => updateBlogInfo('title', e.target.value)}
                  value={blogInfo?.title || ''}
                  onBlur={(e) => validateFields(e.target.name, e.target.value)}
                />
                {errors.title && (
                  <span className="text-danger d-block mt-1 ms-3">
                    Please Enter Blog Title
                  </span>
                )}
              </div>
              <div className="col-lg-6 form-group">
                <label className="form-label">Category<span className="text-danger">*</span></label>
                <CustomDropdown
                  name="category"
                  options={categoryOptions}
                  isSearchable={false}
                  height={'50px'}
                  placeholderMarginTop={'-5px'}
                  placeholder={'Select'}
                  placeholderColor={'#9aa5ad'}
                  value={blogInfo?.category}
                  border={'1 px solid #9aa5ad'}
                  onChange={(value) => {
                    updateBlogInfo('category', value);
                    validateFields('category', value);
                    updateBlogInfo('subcategory', null);
                    settingSubcategoryOptions(value.categoryId);
                  }}
                  onBlur={() => validateFields('category', blogInfo?.category)}
                />
                {errors.category && (
                  <span className="text-danger d-block mt-1 ms-3">
                    Please select category
                  </span>
                )}
              </div>
              <div className="col-lg-6 form-group">
                <label className="form-label">SubCategory<span className="text-danger">*</span></label>
                <CustomDropdown
                  id="subcategory"
                  name="subcategory"
                  options={subcategoryOptons}
                  isSearchable={false}
                  height={'50px'}
                  placeholder={'Select'}
                  placeholderColor={'#9aa5ad'}
                  value={blogInfo?.subcategory}
                  border={'1 px solid #9aa5ad'}
                  onChange={(value) => {
                    updateBlogInfo('subcategory', value);
                    validateFields('subcategory', value);
                  }}
                  onBlur={() =>
                    validateFields('subcategory', blogInfo?.subcategory)
                  }
                />
                {errors.subcategory && (
                  <span className="text-danger d-block mt-1 ms-3">
                    Please select subcategory
                  </span>
                )}
              </div>
              <div className="col-lg-6 form-group">
                <label className="form-label">Status<span className="text-danger">*</span></label>
                <CustomDropdown
                  name="status"
                  options={PRODUCT_STATUS_OPTIONS}
                  isSearchable={false}
                  height={'50px'}
                  placeholderMarginTop={'-5px'}
                  placeholder={'Select'}
                  placeholderColor={'#9aa5ad'}
                  value={blogInfo?.status}
                  border={'1 px solid #9aa5ad'}
                  onChange={(value) => {
                    updateBlogInfo('status', value);
                    validateFields('status', value);
                  }}
                  onBlur={() => validateFields('status', blogInfo?.status)}
                />
                {errors.status && (
                  <span className="text-danger d-block mt-1 ms-3">
                    Please select status
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <label className="form-label">
                Upload image ( w x h : {DIMENSION.blog?.width}x
                {DIMENSION.blog?.height})
              </label>
              <div
                className="col-md-5 col-lg-3 col-xl-3 col-sm-5 mb-2"
                style={{ height: '10rem' }}
              >
                <span className="upload_text">
                  <label style={{ cursor: 'pointer' }}>
                    <img
                      src={plusBlueIcon}
                      width="12"
                      height="12"
                      className="img-fluid custom_radius me-2"
                      alt="plus"
                    />
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={(e) => {
                        console.log('in on change blog');
                        // URL.createObjectURL is giving TypeError without checking length
                        if (e.target.files.length !== 0) {
                          let imgData = {
                            PrevUrl: URL.createObjectURL(e.target.files[0]),
                            imgData: e.target.files[0],
                          };
                          let img = new Image();

                          img.src = imgData.PrevUrl;
                          img.onload = function () {
                            let height = DIMENSION.blog?.height;
                            let width = DIMENSION.blog?.width;
                            const isImageDimention =
                              imageDimentionValidator.call(this, height, width);
                            if (isImageDimention) {
                              updateBlogInfo('photo', imgData);
                              setErrors((prev) => {
                                const updated = { ...prev };
                                delete updated['photo'];
                                return updated;
                              });
                            } else {
                              setErrors((prev) => ({
                                ...prev,
                                photo: true,
                              }));
                            }
                          };
                        }
                      }}
                    />
                  </label>
                </span>
                {errors.photo && (
                  <span
                    className="text-danger d-block m-2"
                    style={{ width: 'max-content' }}
                  >
                    Please select photo of size 1190x300
                  </span>
                )}
              </div>
              {blogInfo?.photo?.PrevUrl && (
                <div
                  className="col-md-5 col-lg-3 col-xl-3 col-sm-5 mb-2"
                  style={{ height: '10rem' }}
                >
                  <img
                    src={blogInfo.photo?.PrevUrl}
                    className="img-fluid custom_radius"
                    style={{ height: '100%' }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </CommonHeaderFooter>
    </>
  );
};

export default BlogDetailsModal;
