import React from 'react';
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import services from '../../api/api';
import { DIMENSION } from '../../common/constants';
import ImageInput from '../../common/FormComponents/ImageInput';
import CustomInput from '../../common/FormComponents/Input';
import { toastSuccess } from '../../common/UI/Toasts';
import { getErrorMessage, getFormDataFromObject } from '../../utils/helpers';

const validationSchema = Yup.object({
  title: Yup.string().required(getErrorMessage('select-required', 'Title')),
  image: Yup.array()
    .nullable()
    .min(1, getErrorMessage('required', 'image file')),
});

const HealthGoalFormPage = (props) => {
  const { name, backLinkHandle, goalEditInfo } = props;

  const onSubmit = (values, onSubmitProps) => {
    let payload = {
      title: values?.title,
    };

    if (goalEditInfo && goalEditInfo.goal_id) {
      let editPayload = {
        ...payload,
      };
      if (
        values?.image[0]?.imgData &&
        values?.image[0]?.imgData instanceof File
      ) {
        editPayload.image = values.image[0].imgData;
      }

      editPayload = getFormDataFromObject(editPayload);
      services
        .updateGoal(goalEditInfo.goal_id, editPayload)
        .then(() => {
          backLinkHandle(name);
          toastSuccess('Health Goal Updated Successfully');
        })
        .catch((err) => {
          console.log('error', err);
          onSubmitProps.setFieldError('title', err.message);
        });
    } else {
      payload.status = true;
      payload.image = values.image[0].imgData;
      payload = getFormDataFromObject(payload);
      services
        .addGoal(payload)
        .then(() => {
          onSubmitProps.resetForm();
          toastSuccess('Health Goal Added Successfully');
        })
        .catch((err) => {
          console.log('error', err);
          onSubmitProps.setFieldError('title', err.message);
        });
    }
  };

  return (
    <section className="content_wrapper product_add_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ol className="breadcrumb">
              <li>
                <Link
                  to={'/health-goals'}
                  title="Health Goal Management"
                  onClick={() => backLinkHandle(name)}
                >
                  Health Goal Management
                </Link>
              </li>
              {name === 'addGoal' && <li>Add Health Goal</li>}
              {name === 'editGoal' && <li>Edit Health Goal</li>}
              {name === 'viewGoal' && <li>View Details</li>}
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              <div className="header_top">
                <h3>
                  {name === 'viewGoal'
                    ? 'View Details'
                    : 'Health Goal Management'}
                </h3>
              </div>
            </div>
            {name !== 'viewGoal' ? (
              <Formik
                initialValues={{
                  title: goalEditInfo?.title || '',
                  image: goalEditInfo?.image || [],
                  status: goalEditInfo?.status || '',
                }}
                onSubmit={onSubmit}
                validateOnChange={false}
                validationSchema={validationSchema}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className="middle_main_2">
                        <div className="row mb-3">
                          <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                            <CustomInput
                              name="title"
                              id="title"
                              type="text"
                              label="Health Goal Title"
                              labelclassname="form-label"
                              placeholder=""
                              className="form-control form-input mt-3"
                              value={formik.values.title}
                              isRequired
                            />
                          </div>
                        </div>
                        <ImageInput
                          label={`Upload Image : (w x h :${DIMENSION.healthGoal.width}x${DIMENSION.healthGoal.height})`}
                          subLabel={'Select an image.'}
                          accept="image/png, image/svg, image/jpeg, image/jpg, image/PNG, image/SVG, image/JPEG, image/JPG, image/webp"
                          name={'image'}
                          id={'image'}
                          imagesLimit={1}
                          imageDimention={DIMENSION.healthGoal}
                          isRequired
                        />
                        <div className="footer_main">
                          <Button
                            type="button"
                            variant="sky"
                            className="my-2 me-2"
                            onClick={() => backLinkHandle(name)}
                          >
                            Back
                          </Button>
                          <Button className="me-2" type="submit">
                            {goalEditInfo && goalEditInfo.goal_id
                              ? 'Update'
                              : 'Save'}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <div className="middle_main">
                <div className="row">
                  <div style={{ width: '130px', marginTop: '20px' }}>
                    <img
                      src={goalEditInfo?.image[0]?.PrevUrl}
                      alt={goalEditInfo?.title}
                      className="slider_img"
                      height={100}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="mt-4">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>
                              <p className="des_2">Goal Title</p>
                            </td>
                            <td>
                              <p className="des_3">{goalEditInfo?.title}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="footer_main">
                    <Button
                      type="button"
                      variant="sky"
                      className="my-2 me-2"
                      onClick={() => backLinkHandle(name)}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HealthGoalFormPage;
