import React, { useState, useEffect } from 'react';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import { useNavigate } from 'react-router-dom';
import services from '../api/api';
import editIcon from '../assets/images/pencil.svg';
import { Link } from 'react-router-dom';
import CustomDropdown from '../common/UI/Dropdown';
import { Button } from 'react-bootstrap';

const MeetSlotTime = () => {
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [formActive, setFormActive] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [blockoutStartTime, setBlockoutStartTime] = useState();
  const [blockoutEndTime, setBlockoutEndTime] = useState();
  const [bannerEditInfo, setBannerEditInfo] = useState({});
  const [buttonClicked, setbuttonClicked] = useState('table');

  useEffect(() => {
    setLoading(true);
    services
      .getAllMeetingSlot()
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  }, []);

  const updateSlotTime = () => {
    const payload = {
      start_time: startTime.value,
      end_time: endTime.value,
      blocked_start_time: blockoutStartTime.value,
      blocked_end_time: blockoutEndTime.value,
      day: formActive.day,
    };
    services
      .updateSlotTime(payload, formActive.id)
      .then((res) => {
        console.log(res, 'res');
        setFormActive(false);
        navigate(0);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const openForm = (data) => {
    setFormActive({
      day: data.day,
      start_time: data.start_time,
      end_time: data.end_time,
      id: data._id,
    });
    setStartTime(data.start_time);
    setEndTime(data.end_time);
    setBlockoutStartTime(data.blocked_start_time);
    setBlockoutEndTime(data.blocked_end_time);
  };

  const columns = [
    {
      Header: 'Day',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.day}</span>;
      },
    },
    {
      Header: 'Start Time',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.start_time}</span>;
      },
    },
    {
      Header: 'End Time',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.end_time}</span>;
      },
    },
    {
      Header: 'Blockout Start Time',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.blocked_start_time}</span>;
      },
    },
    {
      Header: 'Blockout End Time',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.blocked_end_time}</span>;
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            onClick={() => {
              openForm(row?.original);
            }}
            style={{ cursor: 'pointer' }}
          >
            <img src={editIcon} alt="pencil" width="24" height="24" />
          </span>
        );
      },
    },
  ];
  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="row">
          <div className="col-md-12">
            {' '}
            <div className="container-fluid">
              {formActive ? (
                <>
                  <ol className="breadcrumb">
                    <li>
                      <Link
                        to={'/meet-slot-time'}
                        title="Slots Time"
                        onClick={() => setFormActive(false)}
                      >
                        Slots Time
                      </Link>
                    </li>

                    <li>Edit Slot Time</li>
                  </ol>
                  <div className="box_main">
                    <h6 className="available-text day-label">
                      Day: {formActive.day}
                    </h6>
                    <div className="row col-md-12 d-flex">
                      <h6 className="available-text time-field">Start Time</h6>
                      <CustomDropdown
                        className=""
                        options={[
                          { label: '6:00:00', value: '6:00:00' },
                          { label: '7:00:00', value: '7:00:00' },
                          { label: '8:00:00', value: '8:00:00' },
                          { label: '9:00:00', value: '9:00:00' },
                          { label: '10:00:00', value: '10:00:00' },
                          { label: '11:00:00', value: '11:00:00' },
                          { label: '12:00:00', value: '12:00:00' },
                          { label: '13:00:00', value: '13:00:00' },
                          { label: '14:00:00', value: '14:00:00' },
                          { label: '15:00:00', value: '15:00:00' },
                          { label: '16:00:00', value: '16:00:00' },
                          { label: '17:00:00', value: '17:00:00' },
                          { label: '18:00:00', value: '18:00:00' },
                          { label: '19:00:00', value: '19:00:00' },
                          { label: '20:00:00', value: '20:00:00' },
                          { label: '21:00:00', value: '21:00:00' },
                          { label: '22:00:00', value: '22:00:00' },
                          { label: '23:00:00', value: '23:00:00' },
                        ]}
                        height={'40px'}
                        placeholder={'Select'}
                        placeholderColor={'#9aa5ad'}
                        border={'1 px solid #9aa5ad'}
                        containerWidth={'175px'}
                        onChange={(value) => {
                          setStartTime(value);
                        }}
                        // value={meetOrder}
                      />
                    </div>
                    <div className="row col-md-12 d-flex">
                      <h6 className="available-text time-field">End Time</h6>
                      <CustomDropdown
                        className=""
                        options={[
                          { label: '6:00:00', value: '6:00:00' },
                          { label: '7:00:00', value: '7:00:00' },
                          { label: '8:00:00', value: '8:00:00' },
                          { label: '9:00:00', value: '9:00:00' },
                          { label: '10:00:00', value: '10:00:00' },
                          { label: '11:00:00', value: '11:00:00' },
                          { label: '12:00:00', value: '12:00:00' },
                          { label: '13:00:00', value: '13:00:00' },
                          { label: '14:00:00', value: '14:00:00' },
                          { label: '15:00:00', value: '15:00:00' },
                          { label: '16:00:00', value: '16:00:00' },
                          { label: '17:00:00', value: '17:00:00' },
                          { label: '18:00:00', value: '18:00:00' },
                          { label: '19:00:00', value: '19:00:00' },
                          { label: '20:00:00', value: '20:00:00' },
                          { label: '21:00:00', value: '21:00:00' },
                          { label: '22:00:00', value: '22:00:00' },
                          { label: '23:00:00', value: '23:00:00' },
                        ]}
                        height={'40px'}
                        placeholder={'Select'}
                        placeholderColor={'#9aa5ad'}
                        border={'1 px solid #9aa5ad'}
                        containerWidth={'175px'}
                        onChange={(value) => {
                          setEndTime(value);
                        }}
                      />
                    </div>
                    <div className="row col-md-12 d-flex">
                      <h6 className="available-text time-field">
                        Blockout Start Time
                      </h6>
                      <CustomDropdown
                        className=""
                        options={[
                          { label: '6:00:00', value: '6:00:00' },
                          { label: '7:00:00', value: '7:00:00' },
                          { label: '8:00:00', value: '8:00:00' },
                          { label: '9:00:00', value: '9:00:00' },
                          { label: '10:00:00', value: '10:00:00' },
                          { label: '11:00:00', value: '11:00:00' },
                          { label: '12:00:00', value: '12:00:00' },
                          { label: '13:00:00', value: '13:00:00' },
                          { label: '14:00:00', value: '14:00:00' },
                          { label: '15:00:00', value: '15:00:00' },
                          { label: '16:00:00', value: '16:00:00' },
                          { label: '17:00:00', value: '17:00:00' },
                          { label: '18:00:00', value: '18:00:00' },
                          { label: '19:00:00', value: '19:00:00' },
                          { label: '20:00:00', value: '20:00:00' },
                          { label: '21:00:00', value: '21:00:00' },
                          { label: '22:00:00', value: '22:00:00' },
                          { label: '23:00:00', value: '23:00:00' },
                        ]}
                        height={'40px'}
                        placeholder={'Select'}
                        placeholderColor={'#9aa5ad'}
                        border={'1 px solid #9aa5ad'}
                        containerWidth={'175px'}
                        onChange={(value) => {
                          setBlockoutStartTime(value);
                        }}
                      />
                    </div>
                    <div className="row col-md-12 d-flex">
                      <h6 className="available-text time-field">
                        Blockout End Time
                      </h6>
                      <CustomDropdown
                        className=""
                        options={[
                          { label: '6:00:00', value: '6:00:00' },
                          { label: '7:00:00', value: '7:00:00' },
                          { label: '8:00:00', value: '8:00:00' },
                          { label: '9:00:00', value: '9:00:00' },
                          { label: '10:00:00', value: '10:00:00' },
                          { label: '11:00:00', value: '11:00:00' },
                          { label: '12:00:00', value: '12:00:00' },
                          { label: '13:00:00', value: '13:00:00' },
                          { label: '14:00:00', value: '14:00:00' },
                          { label: '15:00:00', value: '15:00:00' },
                          { label: '16:00:00', value: '16:00:00' },
                          { label: '17:00:00', value: '17:00:00' },
                          { label: '18:00:00', value: '18:00:00' },
                          { label: '19:00:00', value: '19:00:00' },
                          { label: '20:00:00', value: '20:00:00' },
                          { label: '21:00:00', value: '21:00:00' },
                          { label: '22:00:00', value: '22:00:00' },
                          { label: '23:00:00', value: '23:00:00' },
                        ]}
                        height={'40px'}
                        placeholder={'Select'}
                        placeholderColor={'#9aa5ad'}
                        border={'1 px solid #9aa5ad'}
                        containerWidth={'175px'}
                        onChange={(value) => {
                          setBlockoutEndTime(value);
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        className="slot-submit"
                        onClick={() => {
                          updateSlotTime();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="box_main">
                  <div
                    className="header_top"
                    style={{ justifyContent: 'space-between' }}
                  >
                    <h3>Meet Slot Time</h3>
                  </div>
                  <div className="middle_main">
                    {loading ? (
                      <Loader loading={loading} />
                    ) : (
                      <ReactTable
                        title="Subscription Orders"
                        tableColumns={columns}
                        tableData={data}
                        seeAll={seeAll}
                        totalPage={totalPage}
                        pageNo={currentPageNo}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetSlotTime;
