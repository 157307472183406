import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import ReactAsyncSelect from 'react-select/async';
import ReactTextareaAutosize from 'react-textarea-autosize';
import productImg from '../../../assets/images/danilo-alvesd-small.png';
import deleteImg from '../../../assets/images/trash.svg';
import TextEditor from '../../../common/TextEditor';
import deleteIcon from '../../../assets/images/trash.svg';
import editIcon from '../../../assets/images/pencil.svg';
import { toastError } from '../../../common/UI/Toasts';

const RecommendedSupplements = (props) => {
  const {
    debouncedLoadOptions,
    productData,
    getLatestProducts,
    productOptions,
    handleChange,
    deleteRecSupp,
    deleteFAQAndTickPoints,
    handlePrevious,
    brandOptions,
    productType,
    handleSubmit,
    isSubmitDisabled,
  } = props && props;

  const [currentFAQObj, setCurrentFAQObj] = useState({
    question: '',
    answer: '',
  });

  const [currentTickPoint, setCurrentTickPoint] = useState({
    value: '',
  });

  useEffect(() => {
    getLatestProducts({ admin: true, perPage: 20, page: 1 });
  }, []);

  const setFAQEditValue = (index) => {
    setCurrentFAQObj({ ...productData?.faq[index], index: index });
  };

  const setTickPointsEditValue = (index) => {
    setCurrentTickPoint({
      value: productData?.tick_points[index],
      index: index,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 mt-2 mb-4">
          <hr />
        </div>
        <div className="col-12">
          <h4 className="middle_title">Recommended Supplements</h4>
        </div>

        <div className="col-lg-12 col-xl-8 form-group">
          <label className="form-label">Description</label>
          <textarea
            className="form-control form-textarea"
            rows="5"
            name="rec_supp_description"
            value={productData?.rec_supp_description}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            placeholder="Write the description here..."
          ></textarea>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4 col-md-3 col-lg-3 form-group">
          <label className="form-label">Brand</label>

          <ReactAsyncSelect
            name="brandForRecSupp"
            value={productData?.brandForRecSupp}
            placeholder={<div>Search For Brand</div>}
            loadOptions={(inputValue, callback) =>
              debouncedLoadOptions(inputValue, callback, 'brand')
            }
            defaultOptions={brandOptions}
            isSearchable={true}
            isClearable={true}
            closeMenuOnSelect={true}
            onChange={(e) => handleChange('brandForRecSupp', e)}
          />
        </div>
        <div className="col-xl-4 col-md-3 col-lg-3 form-group">
          <label className="form-label">Select upto 5 products</label>

          <ReactAsyncSelect
            name="recommended_product"
            classNamePrefix="custom-select"
            value={productData?.recommended_product}
            placeholder={<div>Search & Select Products</div>}
            loadOptions={(inputValue, callback) =>
              debouncedLoadOptions(inputValue, callback, 'products')
            }
            defaultOptions={productOptions}
            isSearchable={true}
            isClearable={false}
            isMulti={true}
            closeMenuOnSelect={true}
            onChange={(e) => handleChange('recommended_product', e)}
            controlShouldRenderValue={false}
          />
        </div>

        <div className="col-md-8">
          {productData?.recommended_product?.map((el) => {
            return (
              <div key={el?.value} className="add_box_main">
                {el?.product?.photos?.length ? (
                  <span>
                    {' '}
                    <img
                      src={el?.product?.photos[0]?.location}
                      width="64"
                      height="64"
                      className="img-fluid product_add_img"
                      alt="denilo"
                    />{' '}
                  </span>
                ) : null}
                <div>
                  <p className="des_one">{el?.product?.name}</p>
                </div>
                <span
                  onClick={() => deleteRecSupp(el?.value)}
                  className="ms-auto delete_icon"
                >
                  <img
                    src={deleteImg}
                    width="24"
                    height="24"
                    className="img-fluid "
                    alt="trash"
                  />
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-2">
          <h4 className="middle_title">
            Product Details (For Product Page on User Site)
          </h4>
        </div>
        <div className="col-12">
          <div className="d-flex align-items-center">
            <label className="form-label" htmlFor={'faq'}>
              Add FAQs:
            </label>
            <Button
              className="ms-4"
              onClick={() => {
                if (
                  currentFAQObj?.question !== '' &&
                  currentFAQObj?.answer !== ''
                ) {
                  handleChange('faq', currentFAQObj);
                  setCurrentFAQObj({
                    question: '',
                    answer: '',
                  });
                } else {
                  toastError('Question and Answer both are required.');
                }
              }}
            >
              Save Changes
            </Button>
          </div>
          <div className="ms-4">
            <div className="d-flex mt-3">
              <label
                className="form-label mb-0"
                style={{
                  fontSize: '0.8rem',
                }}
              >
                Question
              </label>
              <ReactTextareaAutosize
                type="text"
                className="form-control ms-3 w-50"
                placeholder=""
                onChange={(e) =>
                  setCurrentFAQObj((prev) => {
                    return {
                      ...prev,
                      question: e.target.value,
                    };
                  })
                }
                value={currentFAQObj?.question}
                minRows={1}
              />
            </div>
            <div className="d-flex mt-3">
              <label
                className="form-label mb-0"
                style={{
                  fontSize: '0.8rem',
                }}
              >
                Answer
              </label>
              <TextEditor
                className={'w-50 ms-4'}
                onEditorChange={(value) => {
                  setCurrentFAQObj((prev) => {
                    return {
                      ...prev,
                      answer: value,
                    };
                  });
                }}
                value={currentFAQObj?.answer}
                editorHeight="30vh"
              />
            </div>
          </div>
          {productData?.faq?.length
            ? productData?.faq?.map((el, index) => {
                return (
                  <>
                    {el?.question != '' ? (
                      <React.Fragment key={index}>
                        <div className="mb-3 w-100">
                          <div className="d-flex justify-content-between">
                            <label
                              className="form-label mb-0 fs-18 w-100"
                              style={{
                                wordWrap: 'break-word',
                              }}
                            >
                              Q) {el?.question}
                            </label>
                            <div className="d-flex">
                              <span onClick={() => setFAQEditValue(index)}>
                                <img
                                  src={editIcon}
                                  alt="pencil"
                                  width="20"
                                  height="20"
                                />
                              </span>
                              <span
                                onClick={() => {
                                  if (
                                    (currentFAQObj?.index ||
                                      currentFAQObj?.index === 0) &&
                                    currentFAQObj?.index === index
                                  )
                                    setCurrentFAQObj({
                                      question: '',
                                      answer: '',
                                    });
                                  deleteFAQAndTickPoints('faq', index);
                                }}
                              >
                                <img
                                  src={deleteIcon}
                                  alt="trash"
                                  width="20"
                                  height="20"
                                />
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              backgroundColor: '#d3d3d359',
                              borderRadius: '5px',
                              padding: '10px',
                              wordWrap: 'break-word',
                            }}
                            dangerouslySetInnerHTML={{
                              __html: el?.answer,
                            }}
                            className="w-100"
                          ></div>
                        </div>
                      </React.Fragment>
                    ) : null}
                  </>
                );
              })
            : null}
        </div>
        <div className="col-12 mt-2">
          <label
            className="form-label mb-0"
            style={{
              fontSize: '1rem',
            }}
          >
            Insert Tick Points:
          </label>
          <input
            type={'text'}
            className="form-input ms-2"
            value={currentTickPoint?.value}
            onChange={(e) =>
              setCurrentTickPoint((prev) => {
                return { ...prev, value: e.target.value };
              })
            }
          />
          <Button
            className="order_btns ms-2"
            onClick={() => {
              if (currentTickPoint?.value !== '') {
                handleChange('tick_points', currentTickPoint);
                setCurrentTickPoint({ value: '' });
              } else {
                toastError('Please add point');
              }
            }}
          >
            Add
          </Button>
          <div className="row mt-3 d-flex">
            {productData?.tick_points?.length
              ? productData?.tick_points?.map((el, index) => {
                  return (
                    <>
                      {el?.question != '' ? (
                        <React.Fragment key={index}>
                          <div
                            className="d-flex justify-content-between mb-3"
                            style={{
                              width: 'fit-content',
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: '#d3d3d359',
                                borderRadius: '5px',
                                padding: '10px',
                                wordWrap: 'break-word',
                              }}
                              onClick={() => setTickPointsEditValue(index)}
                              // className="w-100"
                            >
                              {el}
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-center ms-1"
                              style={{
                                width: '42px',
                                backgroundColor: '#d78c8c59',
                                borderRadius: '30px',
                              }}
                              onClick={() => {
                                if (
                                  (currentTickPoint?.index ||
                                    currentTickPoint?.index === 0) &&
                                  currentTickPoint?.index === index
                                )
                                  setCurrentTickPoint({
                                    value: '',
                                  });
                                deleteFAQAndTickPoints('tick_points', index);
                              }}
                            >
                              {/* <span
                                  onClick={() => setTickPointsEditValue(index)}
                                >
                                  <img
                                    src={editIcon}
                                    alt="pencil"
                                    width="20"
                                    height="20"
                                  />
                                </span> */}
                              <span>
                                <img
                                  src={deleteIcon}
                                  alt="trash"
                                  width="20"
                                  height="20"
                                />
                              </span>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                    </>
                  );
                })
              : null}
          </div>
        </div>
      </div>

      <div className="footer_main" style={{ justifyContent: 'space-between' }}>
        <div>
          <button
            onClick={() => handlePrevious()}
            type="button"
            className="btn btn-sky my-2 me-2"
          >
            Previous
          </button>
        </div>
        <button
          disabled={isSubmitDisabled}
          onClick={() => handleSubmit()}
          type="button"
          className={`btn ${
            productData.is_subscribable || productData.has_landingpage
              ? 'btn-primary'
              : 'btn-success'
          } my-2 me-2`}
        >
          {isSubmitDisabled && (
            <span className="submit-loader">
              <Spinner animation="border" size="sm" />
            </span>
          )}
          {productData.is_subscribable || productData.has_landingpage
            ? 'Next'
            : 'Submit'}
        </button>
      </div>
    </>
  );
};

export default RecommendedSupplements;
