import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactTextareaAutosize from 'react-textarea-autosize';
import services from '../../../api/api';
import { toastSuccess, toastError } from '../../../common/UI/Toasts';
import { useNavigate } from 'react-router-dom';

const SendText = (props) => {
  const navigate = useNavigate();
  const { orderData } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const [chatData, setChatData] = useState([]);
  const [messageContent, setMessageContent] = useState();

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (orderData?._id) getMessagesForThisOrder();
  }, [orderData]);

  const temp = [
    {
      message: 'What is your name?',
      user: 'Romil',
      date: '12/03/2022-12:35 AM',
      id: 'a',
    },
    {
      message: 'My name is XXX',
      user: 'Customer',
      date: '12/03/2022-01:35 AM',
      id: 'b',
    },
  ];

  const getMessagesForThisOrder = () => {
    services
      .getTextMessages(orderData?._id)
      .then((res) => {
        setChatData(res?.data?.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const sendText = () => {
    const payload = {
      order_id: orderData?._id,
      message: messageContent,
    };
    services
      .sendTextMessage(payload)
      .then((res) => {
        toastSuccess('Message Sent Successfully');
        setMessageContent('');
        navigate(0);
      })
      .catch((err) => {
        console.log('err', err);
        toastError(err.message, { autoClose: false });
      });
  };

  return (
    <div>
      <div
        className="header_top text_message"
        style={{ justifyContent: 'space-between' }}
      >
        {chatData.map((el) => {
          let dateAndTime = `${el?.createdAt.substring(
            0,
            10,
          )} ${el?.createdAt.substring(11, 16)}`;

          return (
            <div
              key={el._id}
              className="mb-2"
              style={{ padding: '6px', backgroundColor: '#ddddddbf' }}
            >
              <div
                className="d-flex fs-16"
                style={{
                  justifyContent: 'space-between',
                  fontSize: '14px',
                }}
              >
                <div>{el.sender_name}</div>
                <div>{dateAndTime}</div>
              </div>
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '4px',
                  borderRadius: '4px',
                  fontSize: '16px',
                }}
              >
                {el.message}
              </div>
            </div>
          );
        })}
        <h3>Send Text Message</h3>
      </div>

      <div
        className="d-flex mt-3 align-items-center mx-3"
        style={{ justifyContent: 'space-between' }}
      >
        <ReactTextareaAutosize
          placeholder="Type Message"
          className="mb-0 col-md-8 form-group form-input p-2"
          rows={1}
          style={{ resize: 'none' }}
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
        />
        <Button
          style={{ height: 'fit-content' }}
          disabled={messageContent && messageContent != '' ? false : true}
          onClick={sendText}
          className="order_btns"
        >
          Send
        </Button>
      </div>
    </div>
  );
};
export default SendText;
