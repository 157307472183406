import React from 'react';
import StockExcelUploadSectionPage from '../components/Stock/StockExcelUploadSection';

const StockManagement = () => {
  return (
    <>
      <StockExcelUploadSectionPage />
    </>
  );
};

export default StockManagement;
