import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = ({
  loading,
  loadingMessage,
  loaderClass,
  hideLoadingMessage,
  loaderContainerClass,
  loaderSize,
  loaderMarginTop,
  loaderMarginBottom,
}) => {
  return (
    <div className={`${loaderContainerClass}`}>
      {loading && (
        <Spinner
          className={`${loaderClass}`}
          animation="border"
          style={{
            marginTop: loaderMarginTop,
            color: '#088fff',
            width: loaderSize.width,
            height: loaderSize.height,
            borderBlock: '5px solid #0b71d7f8',
            fontFamily: 'dm_sansregular',
            marginBottom: hideLoadingMessage ? loaderMarginBottom : '0px',
          }}
        />
      )}
      {!hideLoadingMessage && (
        <h3
          className="loader--messge"
          style={{ marginBottom: loaderMarginBottom }}
        >
          {loadingMessage}
        </h3>
      )}
    </div>
  );
};

Loader.defaultProps = {
  loadingMessage: 'Fetching Data...',
  children: null,
  loaderContainerClass: 'text-center mb-0 mt-4',
  loaderClass: '',
  hideLoadingMessage: false,
  loaderSize: {
    width: '90px',
    height: '90px',
  },
  loaderMarginTop: '169px',
  loaderMarginBottom: '169px',
};

export default Loader;
