import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const CommonHeaderFooter = (props) => {
  const {
    show,
    name,
    centered,
    handleToggle,
    handlePrev,
    handleNext,
    children,
    isOpenedInEditorScreen,
  } = props;
  return (
    <Modal
      show={show}
      onHide={handleToggle}
      centered={centered || true}
      className="custom_modal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="text-black fw-bold fs-5">
            {isOpenedInEditorScreen ? 'Edit' : 'Add'} {name} Details
          </h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        {name == 'Author' && !isOpenedInEditorScreen && (
          <Button className="me-auto" size="sm" onClick={handlePrev}>
            Prev
          </Button>
        )}
        <Button variant="sky" size="sm" onClick={handleToggle}>
          Close
        </Button>
        <Button size="sm" onClick={handleNext}>
          {isOpenedInEditorScreen ? 'Edit' : 'Next'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CommonHeaderFooter;
