import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { FieldArray, Form, Formik } from 'formik';
import CustomInput from '../../../common/FormComponents/Input';
import CustomReactSelect from '../../../common/FormComponents/ReactSelect';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import {
  getErrorMessage,
  getFormDataFromObject,
  getLabelValuePair,
  imageDimentionValidator,
  regex,
  validateFileExtensions,
} from '../../../utils/helpers';
import { DIMENSION, PRODUCT_TYPES } from '../../../common/constants';
import api from '../../../api/api';
import { toastError, toastSuccess } from '../../../common/UI/Toasts';
import ReactTextareaAutosize from 'react-textarea-autosize';
import TextError from '../../../common/FormComponents/TextError';
import ReactAsyncSelect from 'react-select/async';
import { getProductOptions, reorder } from '../../../utils/productHelper';
import { debounce, orderBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ImageInput from '../../../common/FormComponents/ImageInput';
import TextEditor from '../../../common/TextEditor';
import CustomDropdown from '../../../common/UI/Dropdown';
import deleteIcon from '../../../assets/images/trash.svg';
import editIcon from '../../../assets/images/pencil.svg';
import plusBlueIcon from '../../../assets/images/plus-blue.svg';
import SpecificQuestions from '../../addProducts/SpecificQuestions';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { getAllSubcategory } from '../../../store/reducers/subcategory';
import QuestionOptionModal from '../../GeneralHealthQuestions/questionOptionModal';
import SEOModal from '../../../common/UI/CustomModal/SEOModal';

const validationSchema = Yup.object({
  prodType: Yup.object()
    .nullable()
    .required(getErrorMessage('required', 'Product Type')),
  categoryName: Yup.object()
    .nullable()
    .required(getErrorMessage('required', 'Category Name')),
  subcategoryName: Yup.string().required(
    getErrorMessage('required', 'Subcategory Name'),
    // .matches(regex('name'), getErrorMessage('valid', 'Name')),
  ),
  photo: Yup.object()
    .nullable()
    .required(getErrorMessage('required', 'Subcategory Image')),
  // faq: Yup.array().of(
  //   Yup.object().shape({
  //     question: Yup.string().required(getErrorMessage('required', 'Question')),
  //     answer: Yup.string().required(getErrorMessage('required', 'Answer')),
  //   }),
  // ),
});

const emptyQuestion = {
  question: '',
  questionType: { label: 'Input', value: 'input' },
  preferredAnswer: null,
  subQuestions: [],
  isAnswerDisabled: true,
  isHTML: false,
};
const SubcategoryFormPage = (props) => {
  const { subcategoryEditInfo } = props;

  const [params] = useSearchParams();
  const id = params.get('id');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productType, setProductType] = useState('product');
  const [showStepTwo, setShowStepTwo] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState();
  const [totalCategories, setTotalCategories] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [showMetaModel, setShowMetaModel] = useState(false);
  const [metaDataInitialValues, setMetaDataInitialValues] = useState({
    metadata: [
      {
        name: null,
        content: '',
      },
    ],
  });
  const [additionalFields, setAdditionalFields] = useState({
    description: subcategoryEditInfo?.description || '',
    about: subcategoryEditInfo?.about || '',
    symptoms: subcategoryEditInfo?.symptoms || '',
    diagnosis: subcategoryEditInfo?.diagnosis || '',
    treatment: subcategoryEditInfo?.treatment || '',
    prevention: subcategoryEditInfo?.prevention || '',
    alternatives: subcategoryEditInfo?.alternatives || '',
    recommended_description:
      subcategoryEditInfo?.recommended_supliments_desc &&
        subcategoryEditInfo?.recommended_supliments_desc != 'undefined'
        ? subcategoryEditInfo?.recommended_supliments_desc
        : '',
    faq: subcategoryEditInfo?.faq || [],
  });
  const [selectedFieldName, setSelectedFieldName] = useState();
  const [selectedFieldValue, setSelectedFieldValue] = useState('');
  const [questionModalLoading, setQuestionModalLoading] = useState(false);
  // specific Question
  const [showSelectQuestionModal, setShowSelectQuestionModal] = useState(false);
  const [previousQuestions, setPreviousQuestions] = useState([]);
  const [subCatProducts, setSubCatProducts] = useState([]);
  const [formData, setFormData] = useState({
    prodType: subcategoryEditInfo?.prodType?.value
      ? subcategoryEditInfo?.prodType
      : null || null,
    categoryName: subcategoryEditInfo?.categoryName?.value
      ? subcategoryEditInfo?.categoryName
      : null || null,
    subcategoryName: subcategoryEditInfo?.subcategoryName
      ? subcategoryEditInfo?.subcategoryName
      : '',
    photo: subcategoryEditInfo?.photo?.PrevUrl
      ? subcategoryEditInfo?.photo
      : null,
    recommended_product: subcategoryEditInfo?.recommendedProduct || [],

    question_answer: subcategoryEditInfo?.question_answer || [],
    isGPDetailsRequired: subcategoryEditInfo?.isGPDetailsRequired || false,
  });

  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [selectedQuestionsIndex, setSelectedQuestionsIndex] = useState(null);
  const [deleteIds, setDeleteIds] = useState([]);
  const [lpImage, setLpImage] = useState();
  const [currentFAQObj, setCurrentFAQObj] = useState({
    question: '',
    answer: '',
  });
  const [lpForm, setLpForm] = useState(false)
  const fieldOptions = [
    { label: 'Description', value: 'description' },
    { label: 'About', value: 'about' },
    { label: 'Symptoms', value: 'symptoms' },
    { label: 'Diagnosis', value: 'diagnosis' },
    { label: 'Treatment', value: 'treatment' },
    { label: 'Prevention', value: 'prevention' },
    { label: 'Alternatives', value: 'alternatives' },
    {
      label: 'Description For Recommended Suppliments',
      value: 'recommended_description',
    },
  ];
  useEffect(() => {
    if (id) {
      let formDataPayload = {
        prodType: subcategoryEditInfo?.prodType?.value
          ? subcategoryEditInfo?.prodType
          : null || null,
        isMeetRequired: subcategoryEditInfo?.isMeetRequired
          ? subcategoryEditInfo?.isMeetRequired
          : false,
        has_landingpage: subcategoryEditInfo?.has_landingpage
          ? subcategoryEditInfo?.has_landingpage
          : false,
        categoryName: subcategoryEditInfo?.categoryName?.value
          ? subcategoryEditInfo?.categoryName
          : null || null,
        subcategoryName: subcategoryEditInfo?.subcategoryName
          ? subcategoryEditInfo?.subcategoryName
          : '',
        shoparize_title: subcategoryEditInfo?.shoparize_title
          ? subcategoryEditInfo?.shoparize_title
          : '',
        shoparize_description: subcategoryEditInfo?.shoparize_description
          ? subcategoryEditInfo?.shoparize_description
          : '',
        shoparize_image: subcategoryEditInfo?.shoparize_image
          ? subcategoryEditInfo?.shoparize_image
          : null,
        photo: subcategoryEditInfo?.photo?.PrevUrl
          ? subcategoryEditInfo?.photo
          : null,
        recommended_product: subcategoryEditInfo?.recommendedProduct || [],
        faq: subcategoryEditInfo?.faq || [],
        question_answer: subcategoryEditInfo?.question?.length
          ? orderBy(subcategoryEditInfo.question, 'question.order', 'asc').map(
            (el) => {
              return { ...el.question, _id: el._id };
            },
          )
          : [],
        isGPDetailsRequired: subcategoryEditInfo?.isGPDetailsRequired || false,
      };
      setFormData(formDataPayload);
      if (subcategoryEditInfo?.has_landingpage) {
        setLpForm(true)
      }

      setLpImage(subcategoryEditInfo?.shoparize_image)

      let metadata_arr = [];
      if (subcategoryEditInfo?.metadata?.data) {
        metadata_arr = Object.keys(subcategoryEditInfo?.metadata?.data).map(
          (key) => {
            return {
              name: { label: key[0].toUpperCase() + key.slice(1), value: key },
              content: subcategoryEditInfo.metadata?.data[key],
            };
          },
        );
      }

      setMetaDataInitialValues({ metadata: metadata_arr });
      setAdditionalFields({
        description: subcategoryEditInfo?.description || '',
        about: subcategoryEditInfo?.about || '',
        symptoms: subcategoryEditInfo?.symptoms || '',
        diagnosis: subcategoryEditInfo?.diagnosis || '',
        treatment: subcategoryEditInfo?.treatment || '',
        prevention: subcategoryEditInfo?.prevention || '',
        alternatives: subcategoryEditInfo?.alternatives || '',
        recommended_description:
          subcategoryEditInfo?.recommended_supliments_desc &&
            subcategoryEditInfo?.recommended_supliments_desc != 'undefined'
            ? subcategoryEditInfo?.recommended_supliments_desc
            : '',
        faq: subcategoryEditInfo?.faq || [],
      });
    }
    setProductType(subcategoryEditInfo?.prodType?.value);
    getSubCatProducts();
  }, [subcategoryEditInfo]);

  useEffect(() => {
    getCategories();
    getAsyncProducts({});
    window.onbeforeunload = () => '';
  }, []);

  useEffect(() => {
    if (Object.keys(subcategoryEditInfo).length) {
      settingCategoryOptions(subcategoryEditInfo.prodType.value);
    }
  }, [totalCategories]);

  const handleMetaDataToggle = () => {
    setShowMetaModel(!showMetaModel);
  };

  const onSubmitOfMetaDataForm = (metaObj) => {
    let metadata_arr = [];
    metadata_arr = Object.keys(metaObj).map((key) => {
      return {
        name: { label: key, value: key },
        content: metaObj[key],
      };
    });
    setMetaDataInitialValues({ metadata: metadata_arr });
    setShowMetaModel(false);
  };

  const getCategories = () => {
    const params = {
      showall: true,
    };
    api
      .getAllCategory(params)
      .then((res) => {
        setTotalCategories(
          res?.data?.data?.docs.map((category) => {
            return {
              label: category.name,
              value: category.slug,
              productType: category.product_type,
              categoryId: category._id,
            };
          }),
        );
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const getAsyncProducts = async (params) => {
    try {
      let finalParams = {
        ...params,
        showall: true,
        // this will only give us the limited info of product
        recommended_product: true,
        admin: true,
      };
      const response = await api.getProducts(finalParams);
      let formData = response?.data?.data?.docs;
      if (!Object.keys(params).length) {
        let productOptionsForDropdown = getProductOptions(formData);
        setProductOptions(productOptionsForDropdown);
      }
      return getProductOptions(formData);
    } catch (err) {
      console.log(err);
    }
  };

  const loadOptions = (inputValue, callback) => {
    getAsyncProducts({ name: inputValue }).then((results) => callback(results));
    return;
  };

  const debouncedLoadOptions = debounce(loadOptions, 500);

  const onSubmit = (formikValues, onSubmitProps = null) => {
    let values = null;
    productType === 'product' ? (values = formikValues) : (values = formData);
    let recommendedProductsArr = values.recommended_product.map(
      (el) => el.value,
    );
    let photoValue = values.photo?.imgData
      ? values.photo.imgData instanceof File
        ? values.photo.imgData
        : null
      : null;

    let payload = {
      name: values.subcategoryName,
      category_id: values.categoryName?.categoryId,
      isMeetRequired: values.isMeetRequired,
      has_landingpage: values.has_landingpage,
      shoparize_title: values.shoparize_title,
      shoparize_description: values.shoparize_description,
      shoparize_image: lpImage,
      description: additionalFields?.description,
      about: additionalFields?.about,
      symptoms: additionalFields?.symptoms,
      diagnosis: additionalFields?.diagnosis,
      treatment: additionalFields?.treatment,
      prevention: additionalFields?.prevention,
      alternatives: additionalFields?.alternatives,
      recommended_product: JSON.stringify(recommendedProductsArr),
      recommended_supliments_desc: values.recommended_description,
      faq: JSON.stringify(additionalFields?.faq),
      isGPDetailsRequired: values.isGPDetailsRequired,
    };

    payload['metadata_id'] = payload?.meta_data?._id || '';

    delete payload.meta_data;
    let metadataObj = {};
    metaDataInitialValues &&
      metaDataInitialValues.metadata &&
      metaDataInitialValues.metadata.length > 0 &&
      metaDataInitialValues.metadata.forEach((el) => {
        if (el?.name?.value) {
          metadataObj[el.name.value] = el.content;
        }
      });
    if (Object.keys(metadataObj).length > 0) {
      payload['metadata'] = JSON.stringify(metadataObj);
    }
    if (photoValue) {
      payload['image'] = photoValue;
    }
    if (productType === 'medication') {
      formData?.question_answer?.length &&
        formData?.question_answer.forEach((el, index) => {
          if (el?.question && el?.questionType) {
            payload[`questions[${index}]`] = JSON.stringify({
              ...el,
              order: index,
            });
          }
        });
      if (deleteIds?.length) {
        payload['deleteQuestionIds'] = JSON.stringify(deleteIds);
      }
    }
    onSubmitProps && onSubmitProps.setSubmitting(true);
    payload = getFormDataFromObject(payload);

    Object.keys(subcategoryEditInfo).length
      ? api
        .updateSubcategory(subcategoryEditInfo.subcategoryId, payload)
        .then((res) => {
          onSubmitProps && onSubmitProps.setSubmitting(false);
          dispatch(getAllSubcategory());
          navigate('/subcategory');
          toastSuccess('Subcategory Updated Successfully');
        })
        .catch((err) => {
          onSubmitProps && onSubmitProps.setSubmitting(false);
          console.log('error', err);
        })
      : api
        .addSubcategory(payload)
        .then((res) => {
          onSubmitProps && onSubmitProps.setSubmitting(false);
          dispatch(getAllSubcategory());
          navigate('/subcategory');
          toastSuccess('Subcategories Added Successfully');
        })
        .catch((err) => {
          onSubmitProps && onSubmitProps.setSubmitting(false);
          console.log('error', err);
        });
  };

  const settingCategoryOptions = (productType) => {
    const options = totalCategories.filter(
      (category) => category.productType == productType,
    );
    setCategoryOptions(options);
  };

  const onEditorChange = (value) => {
    setSelectedFieldValue(value);
  };

  const onSaveChangesClick = () => {
    setAdditionalFields((prev) => {
      return {
        ...prev,
        [selectedFieldName]: selectedFieldValue,
      };
    });
  };

  const handleAdditionalFieldsClick = (fieldName) => {
    setSelectedFieldName(fieldName);
    setSelectedFieldValue(additionalFields[fieldName]);
  };

  //
  const handleChangeForMainQuestion = (name, value, index) => {
    const { question_answer } = formData;
    let questionsClone = cloneDeep(question_answer);
    questionsClone[index][name] = value;
    if (name === 'questionType') {
      if (value.value === 'boolean' || value.value === 'select') {
        questionsClone[index].isAddSubQuestionsDisabled = false;
        if (value.value === 'boolean') {
          questionsClone[index].isAnswerDisabled = false;
        }
      } else {
        questionsClone[index].isAnswerDisabled = true;
        questionsClone[index].isAddSubQuestionsDisabled = true;
        questionsClone[index].subQuestions = [];
        questionsClone[index].preferredAnswer = null;
      }
    } else if (
      name === 'preferredAnswer' &&
      (value.value !== 'yesCertainRestrict' ||
        value.value !== 'noCertainRestrict')
    ) {
      // case: when they first selected certain restrict options and then changed the type of ques to non-certain-restriction question
      questionsClone[index].restrictedMeds = null;
      if (!subCatProducts || !subCatProducts.length) {
        getSubCatProducts();
      }
    }
    setFormData((prev) => {
      return { ...prev, question_answer: questionsClone };
    });
  };

  const handleAddMainQuestion = () => {
    const { question_answer } = formData;
    let questionsClone = cloneDeep(question_answer);
    questionsClone.push(emptyQuestion);
    setFormData((prev) => {
      return { ...prev, question_answer: questionsClone };
    });
  };

  const handleAddSubQuestion = (index) => {
    const { question_answer } = formData;
    let questionsClone = cloneDeep(question_answer);
    questionsClone[index].subQuestions.push({
      question: '',
      answer: '',
      questionType: { label: 'Input', value: 'input' },
      selectedAnswer: null,
      isAnswerDisabled: true,
      isHTML: false,
    });
    setFormData((prev) => {
      return { ...prev, question_answer: questionsClone };
    });
  };
  const handleDeleteMainQuestion = (index) => {
    const { question_answer } = formData;
    let questionsClone = cloneDeep(question_answer);
    if (questionsClone[index]?._id) {
      setDeleteIds([...deleteIds, questionsClone[index]._id]);
    }
    questionsClone.splice(index, 1);
    setFormData((prev) => {
      return { ...prev, question_answer: questionsClone };
    });
  };
  const handleDeleteSubQuestion = (subIndex, index) => {
    const { question_answer } = formData;
    let questionsClone = cloneDeep(question_answer);
    let subQuestionClone = cloneDeep(questionsClone[index]?.subQuestions);
    subQuestionClone.splice(subIndex, 1);
    questionsClone[index].subQuestions = subQuestionClone;
    setFormData((prev) => {
      return { ...prev, question_answer: questionsClone };
    });
  };
  const handleChangeForSubQuestion = (name, value, subIndex, index) => {
    const { question_answer } = formData;
    let questionsClone = cloneDeep(question_answer);
    let subQuestionClone = cloneDeep(questionsClone[index]?.subQuestions);
    subQuestionClone[subIndex][name] = value;

    if (name === 'questionType' && value.value === 'boolean') {
      subQuestionClone[subIndex].isAnswerDisabled = false;
    } else if (name === 'questionType' && value.value !== 'boolean') {
      subQuestionClone[subIndex].isAnswerDisabled = true;
      subQuestionClone[subIndex].preferredAnswer = null;
    } else if (
      name === 'preferredAnswer' &&
      (value.value !== 'yesCertainRestrict' ||
        value.value !== 'noCertainRestrict')
    ) {
      // case: when they first selected certain restrict options and then changed the type of ques to non-certain-restriction question
      subQuestionClone[subIndex].restrictedMeds = null;
    }
    questionsClone[index].subQuestions = subQuestionClone;
    setFormData((prev) => {
      return { ...prev, question_answer: questionsClone };
    });
  };
  // Drag and Drop

  const onDragEndQuestion = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const { question_answer } = formData;
      let questionsReordered = reorder(
        question_answer,
        source.index,
        destination.index,
      );

      questionsReordered = questionsReordered.map((el, index) => {
        return {
          ...el,
          order: index + 1,
        };
      });
      setFormData((prev) => {
        return { ...prev, question_answer: questionsReordered };
      });
    }
  };
  const handleSelectQuestionModalToggle = () => {
    if (showSelectQuestionModal === false) {
      getQuestionAnswers();
    }
    setShowSelectQuestionModal((prev) => !prev);
  };
  const getQuestionAnswers = () => {
    setQuestionModalLoading(true);
    api
      .getQuestionAnswers()
      .then((res) => {
        let previousQuestionsClone = res?.data?.data;
        previousQuestionsClone = previousQuestionsClone.map((el) => {
          return { ...el, isDisabled: true, isChecked: false };
        });
        setQuestionModalLoading(false);
        setPreviousQuestions(previousQuestionsClone);
      })
      .catch((err) => {
        setQuestionModalLoading(false);
        console.log(err);
      });
  };
  const handlePrevNextClick = (values) => {
    if (values) setFormData(values);
    setShowStepTwo((prev) => !prev);
  };

  // select options modal methods
  const handleAddOption = () => {
    const { question_answer } = formData;
    let questionsClone = cloneDeep(question_answer);
    questionsClone[selectedQuestionsIndex]?.options
      ? questionsClone[selectedQuestionsIndex].options.push('')
      : (questionsClone[selectedQuestionsIndex].options = ['']);
    setFormData((prev) => {
      return { ...prev, question_answer: questionsClone };
    });
  };

  const handleChangeForOptions = (index, value) => {
    const { question_answer } = formData;
    let questionsClone = cloneDeep(question_answer);
    if (index === false) {
      // change for is Single
      questionsClone[selectedQuestionsIndex].isSingle = value;
    } else {
      questionsClone[selectedQuestionsIndex].options[index] = value;
    }
    setFormData((prev) => {
      return { ...prev, question_answer: questionsClone };
    });
  };

  const handleUploadLPImage = (imageData) => {
    api
      .uploadOrderImage(getFormDataFromObject({ photos: imageData.answer }))
      .then((res) => {
        setLpImage(res.data.data.photos[0].location);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteForOptions = (index) => {
    const { question_answer } = formData;
    let questionsClone = cloneDeep(question_answer);
    const options = questionsClone[selectedQuestionsIndex].options.filter(
      (el, i) => i !== index,
    );
    questionsClone[selectedQuestionsIndex].options = options;
    setFormData((prev) => {
      return { ...prev, question_answer: questionsClone };
    });
  };
  const getSubCatProducts = () => {
    if (!subcategoryEditInfo || !subcategoryEditInfo?._id) {
      // sub cat add, can't fetch products without id
      return;
    } else {
      api
        .getProductsBySubcategory(subcategoryEditInfo._id)
        .then((res) => {
          const prodOptions = getLabelValuePair(res.data.data);
          setSubCatProducts(prodOptions);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const setFAQEditValue = (index) => {
    setCurrentFAQObj({
      ...additionalFields?.faq[index],
      index: index,
    });
  };

  const handleFaqChange = () => {
    if (
      currentFAQObj &&
      currentFAQObj?.question != '' &&
      currentFAQObj?.answer != ''
    ) {
      let faqClone = cloneDeep(additionalFields?.faq);
      if (currentFAQObj?.index || currentFAQObj?.index == 0) {
        faqClone[currentFAQObj?.index] = {
          question: currentFAQObj?.question,
          answer: currentFAQObj?.answer,
        };
      } else {
        faqClone.push(currentFAQObj);
      }
      setAdditionalFields((prev) => {
        return {
          ...prev,
          faq: faqClone,
        };
      });
      setCurrentFAQObj({
        question: '',
        answer: '',
      });
    } else {
      toastError('Question and Answer both are required fields.');
    }
  };

  const handleFaqDelete = (index) => {
    let faqClone = cloneDeep(additionalFields?.faq);

    faqClone.splice(index, 1);
    if (currentFAQObj?.index === index) {
      setCurrentFAQObj({
        question: '',
        answer: '',
      });
    }
    setAdditionalFields((prev) => {
      return {
        ...prev,
        faq: faqClone,
      };
    });
  };

  return (
    <section className="content_wrapper product_add_main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ol className="breadcrumb">
              <li>
                <Link to={'/subcategory'} title="Product Management">
                  Category Management
                </Link>
              </li>
              <li>
                {id ? 'Edit' : 'Add'}
                Subcategory
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="box_main">
              <div className="header_top">
                <h3>
                  {id ? 'Edit ' : 'Add '}
                  Subcategory
                </h3>
                <Button
                  className="p-2 m-2 fw-bold"
                  onClick={() => setShowMetaModel(true)}
                >
                  {id ? 'Edit' : 'Add'} Meta data
                </Button>
              </div>
            </div>
          </div>
        </div>
        {!showStepTwo ? (
          <Formik
            key={formData?.prodType}
            initialValues={formData}
            onSubmit={
              productType === 'product' ? onSubmit : handlePrevNextClick
            }
            validateOnChange={false}
            validationSchema={validationSchema}
          >
            {(formik) => {
              const { values, errors, touched } = formik;
              const { handleChange, setFieldValue, setTouched } = formik;
              return (
                <Form>
                  <div className="middle_main_2">
                    <div className="row">
                      <div className="row">
                        <div className="col-xl-2 col-md-6 col-lg-6 form-group">
                          <CustomReactSelect
                            label="Product Type"
                            labelClassname={'form-label mb-2'}
                            name="prodType"
                            id="prodType"
                            className="form-control"
                            placeholderColor="#9aa5ad"
                            height={'50px'}
                            placeholderMarginTop={'-5px'}
                            validationType="required"
                            errorFieldName="Product Type"
                            customSelectBorder={'1px solid #9aa5ad'}
                            options={PRODUCT_TYPES}
                            wrappedStyles={{
                              width: '100%',
                            }}
                            onChangeAction={(value) => {
                              formik.setFieldValue('categoryName', null);
                              settingCategoryOptions(value.value);
                              setProductType(value?.value);
                            }}
                            isRequired={true}
                          />
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6 form-group">
                          <CustomReactSelect
                            label="Category Name"
                            labelClassname={'form-label mb-2'}
                            name="categoryName"
                            id="categoryName"
                            className="form-control w-100"
                            placeholderColor="#9aa5ad"
                            height={'50px'}
                            placeholderMarginTop={'-5px'}
                            validationType="required"
                            errorFieldName="Category Name"
                            customSelectBorder={'1px solid #9aa5ad'}
                            options={categoryOptions}
                            wrappedStyles={{
                              width: '100%',
                            }}
                            isRequired={true}
                          />
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6 form-group ms-2">
                          <CustomInput
                            name="subcategoryName"
                            id="subcategoryName"
                            label="Subcategory Name"
                            labelClassname="form-label mb-2"
                            className="form-control form-input w-100"
                            value={values.subcategoryName}
                            style={{
                              height: '3rem',
                            }}
                            isRequired={true}
                          />
                        </div>
                        <div className="col-md-2 col-lg-6 col-xl-3">
                          <CustomInput
                            style={{
                              marginTop: '40px',
                              width: '17px',
                              height: '17px',
                            }}
                            className="mx-2 form-input-check"
                            labelClassname="form-label"
                            label="Clinician Call"
                            type="checkbox"
                            name="isMeetRequired"
                            checked={values.isMeetRequired}
                          />

                          <CustomInput
                            style={{
                              marginTop: '40px',
                              width: '17px',
                              height: '17px',
                            }}
                            className="mx-2 form-input-check"
                            labelClassname="form-label"
                            label="Landing Page"
                            type="checkbox"
                            name="has_landingpage"
                            checked={values.has_landingpage}
                            onClick={() => setLpForm(!lpForm)}
                          />
                        </div>
                      </div>
                      {lpForm &&
                        <>
                          <div className="row mt-3">
                            <div className="d-flex align-items-center">
                              <div className="col-xl-3 col-md-6 col-lg-6 form-group ms-2">
                                <CustomInput
                                  name="shoparize_title"
                                  id="shoparize_title"
                                  label="Landing Page Title"
                                  labelClassname="form-label mb-2"
                                  className="form-control form-input w-100"
                                  value={values.shoparize_title}
                                  style={{
                                    height: '3rem',
                                  }}
                                  isRequired={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-xl-8 form-group">
                              <label className="form-label">
                                Landing Page Description
                                <span className="text-danger">*</span>
                              </label>
                              <TextEditor
                                name="shoparize_description"
                                value={formik.values.shoparize_description}
                                className={'position-relative'}
                                onEditorChange={(value) => {
                                  formik.setFieldValue(
                                    'shoparize_description',
                                    value,
                                    true,
                                  );
                                }}
                                editorHeight="30vh"
                              />
                              {formik.touched['shoparize_description'] &&
                                formik.errors['shoparize_description'] && (
                                  <TextError>
                                    {formik.errors['shoparize_description']}
                                  </TextError>
                                )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <h4 className="middle_title">
                                Landing Page Image
                                <span className="text-danger ml-1">*</span>
                              </h4>
                              <div className="d-flex">
                                <div
                                  className="col-2 mb-2"
                                  style={{ height: '10rem', width: '11rem' }}
                                >
                                  <span className="upload_text">
                                    <label style={{ cursor: 'pointer' }}>
                                      <img
                                        src={plusBlueIcon}
                                        width="12"
                                        height="12"
                                        className="img-fluid custom_radius me-2"
                                        alt="plus"
                                      />
                                      <input
                                        type="file"
                                        accept="image/png, image/svg, image/jpeg, image/jpg, image/PNG, image/SVG, image/JPEG, image/JPG, image/webp, image/heic"
                                        style={{ width: '300px', display: 'none' }}
                                        onChange={(e) => {
                                          if (validateFileExtensions(e.target.files[0])) {
                                            handleUploadLPImage({
                                              answer: e.target.files[0],
                                              prevUrl: URL.createObjectURL(e.target.files[0]),
                                            });
                                          }
                                        }}
                                      />
                                    </label>
                                  </span>
                                </div>
                                {lpImage && (
                                  <div className="d-flex">
                                    <div className="position-relative hover_effect_main">
                                      <img
                                        src={lpImage}
                                        className="img-fluid custom_radius"
                                        style={{ height: '100%', width: '12rem' }}
                                      />
                                      <div className="hover_effect_child">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setLpImage(null);
                                          }}
                                        >
                                          <img
                                            src={deleteIcon}
                                            width="24"
                                            height="18"
                                            alt="trash"
                                            className="mx-2"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                            </div>
                          </div>
                        </>
                      }
                      <div className="col-12 mt-1 mb24">
                        <hr />
                      </div>
                      <div className="row">
                        <label className="form-label mb-2">
                          Additional Fields
                        </label>
                        <div className="d-flex">
                          <div
                            style={{
                              width: '20%',
                            }}
                          >
                            <CustomDropdown
                              options={fieldOptions}
                              isSearchable={false}
                              height={'50px'}
                              maxMenuHeight={'325px'}
                              className={'me-3'}
                              containerWidth={'95%'}
                              placeholderMarginTop={'-5px'}
                              placeholder={'Select'}
                              placeholderColor={'#9aa5ad'}
                              value={fieldOptions.find(
                                (el) => el.value == selectedFieldName,
                              )}
                              border={'1 px solid #9aa5ad'}
                              onChange={(value) => {
                                let fieldName = value?.value;
                                setSelectedFieldName(fieldName);
                                setSelectedFieldValue(
                                  additionalFields[fieldName],
                                );
                              }}
                            />
                          </div>

                          <TextEditor
                            className={'w-50'}
                            onEditorChange={onEditorChange}
                            value={selectedFieldValue}
                            editorHeight="30vh"
                          />
                          <div className="ms-3">
                            <Button
                              className="fw-bold"
                              onClick={onSaveChangesClick}
                            >
                              Save Changes
                            </Button>
                          </div>
                        </div>
                      </div>
                      {productType !== 'product' && (
                        <div className="row mx-1">
                          <div className="form-check mt-3">
                            <input
                              className="form-check-input check_box mt-2"
                              type="checkbox"
                              id="flexCheckChecked"
                              onChange={(e) => {
                                setFieldValue(
                                  'isGPDetailsRequired',
                                  e.target.checked,
                                );
                              }}
                              checked={values.isGPDetailsRequired}
                            />

                            <label
                              className="form-check-label fs-5 checkbox_label"
                              htmlFor="flexCheckChecked"
                            >
                              is required to provide GP details in general
                              health questions?
                            </label>
                          </div>
                        </div>
                      )}
                      <div className="row mt-4">
                        {fieldOptions.map((el, index) => {
                          let fieldName = el?.value;
                          return (
                            <React.Fragment key={index}>
                              {additionalFields[fieldName] ? (
                                <div className="col-xl-5 col-md-5 mb-3">
                                  <label
                                    className="form-label mb-0 fs-18"
                                    htmlFor="description"
                                  >
                                    {el?.label}
                                  </label>
                                  <div
                                    style={{
                                      backgroundColor: '#d3d3d359',
                                      borderRadius: '5px',
                                      width: '90%',
                                      padding: '10px',
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: additionalFields[fieldName],
                                    }}
                                    onClick={() =>
                                      handleAdditionalFieldsClick(fieldName)
                                    }
                                  ></div>
                                </div>
                              ) : null}
                            </React.Fragment>
                          );
                        })}
                      </div>

                      <div className="row">
                        <div className="col-xl-4 col-md-3 col-lg-3 form-group">
                          <label className="form-label">
                            Select upto 6 products
                          </label>

                          <ReactAsyncSelect
                            name="recommended_product"
                            value={values.recommended_product}
                            placeholder={<div>Search & Select Products</div>}
                            loadOptions={(inputValue, callback) =>
                              debouncedLoadOptions(inputValue, callback)
                            }
                            defaultOptions={productOptions}
                            isSearchable={true}
                            isClearable={false}
                            isMulti={true}
                            closeMenuOnSelect={true}
                            onChange={(e) => {
                              setFieldValue('recommended_product', e);
                            }}
                            controlShouldRenderValue={true}
                          />
                        </div>
                      </div>
                      <div className="row d-flex">
                        <label className="form-label">
                          Upload Photo<span className="text-danger">*</span> ( w
                          x h : {DIMENSION.subCategory.width} x{' '}
                          {DIMENSION.subCategory.height})
                        </label>
                        <div className="d-flex">
                          <div
                            className="col-2 mb-2"
                            style={{ height: '10rem', width: '11rem' }}
                          >
                            <span className="upload_text">
                              <label style={{ cursor: 'pointer' }}>
                                <img
                                  src={plusBlueIcon}
                                  width="12"
                                  height="12"
                                  className="img-fluid custom_radius me-2"
                                  alt="plus"
                                />
                                <input
                                  style={{ display: 'none' }}
                                  type="file"
                                  name="photo"
                                  accept="image/*"
                                  onChange={(e) => {
                                    if (
                                      validateFileExtensions(e.target.files[0])
                                    ) {
                                      // URL.createObjectURL is giving TypeError without checking length
                                      if (e.target.files.length !== 0) {
                                        let imgData = {
                                          PrevUrl: URL.createObjectURL(
                                            e.target.files[0],
                                          ),
                                          imgData: e.target.files[0],
                                        };
                                        let img = new Image();

                                        img.src = imgData.PrevUrl;
                                        img.onload = function () {
                                          const isImageDimention =
                                            imageDimentionValidator.call(
                                              this,
                                              DIMENSION.subCategory.height,
                                              DIMENSION.subCategory.width,
                                            );
                                          if (isImageDimention) {
                                            setFieldValue('photo', imgData);
                                          } else {
                                            toastError(
                                              `Please select photo of size ${DIMENSION.subCategory.width} x ${DIMENSION.subCategory.height}`,
                                            );
                                          }
                                        };
                                      }
                                    } else {
                                      toastError('Please select image file');
                                    }
                                  }}
                                  onBlur={() => {
                                    setTouched({ ...touched, photo: true });
                                  }}
                                />
                              </label>
                            </span>
                          </div>
                          {values?.photo?.PrevUrl && (
                            <div className="d-flex">
                              <div className="position-relative hover_effect_main">
                                <img
                                  src={values?.photo?.PrevUrl}
                                  className="img-fluid custom_radius"
                                  style={{ height: '100%', width: '12rem' }}
                                />
                                <div className="hover_effect_child">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setFieldValue('photo', null);
                                    }}
                                  >
                                    <img
                                      src={deleteIcon}
                                      width="24"
                                      height="18"
                                      alt="trash"
                                      className="mx-2"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        {touched['photo'] && errors['photo'] && (
                          <TextError>{errors['photo']}</TextError>
                        )}
                      </div>
                      <div className="row mt-3">
                        <div className="d-flex align-items-center">
                          <label className="form-label" htmlFor={'faq'}>
                            Add FAQs :
                          </label>
                          <Button className="ms-4" onClick={handleFaqChange}>
                            Save Changes
                          </Button>
                        </div>
                        <div className="ms-4">
                          <div className="d-flex mt-3">
                            <label
                              className="form-label mb-0"
                              style={{
                                fontSize: '0.8rem',
                              }}
                            >
                              Question
                            </label>
                            <ReactTextareaAutosize
                              type="text"
                              className="form-control ms-3 w-50"
                              placeholder=""
                              onChange={(e) =>
                                setCurrentFAQObj((prev) => {
                                  return {
                                    ...prev,
                                    question: e.target.value,
                                  };
                                })
                              }
                              value={currentFAQObj?.question}
                              minRows={1}
                            />
                          </div>
                          <div className="d-flex mt-3">
                            <label
                              className="form-label mb-0"
                              style={{
                                fontSize: '0.8rem',
                              }}
                            >
                              Answer
                            </label>
                            <TextEditor
                              className={'w-50 ms-4'}
                              onEditorChange={(value) => {
                                setCurrentFAQObj((prev) => {
                                  return {
                                    ...prev,
                                    answer: value,
                                  };
                                });
                              }}
                              value={currentFAQObj?.answer}
                              editorHeight="30vh"
                            />
                          </div>
                        </div>
                        {additionalFields?.faq?.length
                          ? additionalFields?.faq?.map((el, index) => {
                            return (
                              <>
                                {el?.question != '' ? (
                                  <React.Fragment key={index}>
                                    <div className="mb-3 w-100">
                                      <div className="d-flex justify-content-between">
                                        <label
                                          className="form-label mb-0 fs-18 w-100"
                                          style={{
                                            wordWrap: 'break-word',
                                          }}
                                        >
                                          Q) {el?.question}
                                        </label>
                                        <div className="d-flex">
                                          <span
                                            onClick={() =>
                                              setFAQEditValue(index)
                                            }
                                          >
                                            <img
                                              src={editIcon}
                                              alt="pencil"
                                              width="20"
                                              height="20"
                                            />
                                          </span>
                                          <span
                                            onClick={() =>
                                              handleFaqDelete(index)
                                            }
                                          >
                                            <img
                                              src={deleteIcon}
                                              alt="trash"
                                              width="20"
                                              height="20"
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          backgroundColor: '#d3d3d359',
                                          borderRadius: '5px',
                                          padding: '10px',
                                          wordWrap: 'break-word',
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: el?.answer,
                                        }}
                                        className="w-100"
                                      ></div>
                                    </div>
                                  </React.Fragment>
                                ) : null}
                              </>
                            );
                          })
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="footer_main">
                    <Button
                      type="button"
                      variant="sky"
                      className="my-2 me-2"
                      onClick={() => navigate('/subcategory')}
                    >
                      Back
                    </Button>
                    <Button className="me-2" type="submit">
                      {productType === 'product' ? 'Submit' : 'Next'}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <SpecificQuestions
            questionsFor={'subcategory'}
            subCatProducts={subCatProducts}
            getSubCatProducts={getSubCatProducts}
            handleAddSubQuestion={handleAddSubQuestion}
            handleAddMainQuestion={handleAddMainQuestion}
            handleChange={handleChangeForMainQuestion}
            questionsData={formData.question_answer}
            handleDeleteMainQuestion={handleDeleteMainQuestion}
            handleDeleteSubQuestion={handleDeleteSubQuestion}
            handleChangeForSubQuestion={handleChangeForSubQuestion}
            onDragEndQuestion={onDragEndQuestion}
            handleSelectQuestionModalToggle={handleSelectQuestionModalToggle}
            getQuestionAnswers={getQuestionAnswers}
            previousQuestions={previousQuestions}
            handlePrevious={handlePrevNextClick}
            handleSubmit={onSubmit}
            setSelectedQuestionsIndex={setSelectedQuestionsIndex}
            toggleShowOptionsModal={() => setShowOptionsModal((prev) => !prev)}
            subcategoryId={subcategoryEditInfo?._id || null}
            key={2}
          />
        )}
        {showOptionsModal ? (
          <QuestionOptionModal
            handleChange={handleChangeForOptions}
            show={showOptionsModal}
            toggle={() => {
              if (showOptionsModal) {
                // modal close
                setShowOptionsModal(false);
                setSelectedQuestionsIndex(null);
              } else {
                // modal open
                setShowOptionsModal(true);
              }
            }}
            isSingle={
              formData.question_answer[selectedQuestionsIndex]?.isSingle ||
              false
            }
            addOptions={handleAddOption}
            deleteOption={handleDeleteForOptions}
            options={formData.question_answer[selectedQuestionsIndex]?.options}
          />
        ) : null}
      </div>
      {showMetaModel && (
        <SEOModal
          handleAction={() => console.log('action')}
          handleToggle={handleMetaDataToggle}
          show={showMetaModel}
          initialValues={metaDataInitialValues}
          onSubmit={onSubmitOfMetaDataForm}
          fieldName="metadata"
          editId={id}
        />
      )}
    </section>
  );
};

export default SubcategoryFormPage;
