import * as network from './network';

const getProducts = (params) => {
  return network.get('product', params, true);
};

const getProductLabels = (params) => {
  return network.get('product/labels', params, true);
};

const getProductById = (id, params) => {
  return network.get(`product/id/${id}`, params, true);
};
const deleteProduct = (id) => {
  return network.delet('product/' + id);
};

const getProductCategories = (params) => {
  return network.get('category/all', params, true);
};

const getProductSubCategories = (params) => {
  return network.get(`sub-category/category`, params, true);
};
const postProduct = (body, isFileUpload) => {
  return network.post('product', body, true, isFileUpload);
};
const putProduct = (id, body, isFileUpload) => {
  return network.put('product/' + id, body, true, isFileUpload);
};
const getQuestionAnswers = () => {
  return network.get('question-answer');
};
const putQuestionAnswer = (id, body) => {
  return network.put(`question-answer/${id}`, body);
};
const productImagesUpload = (body) => {
  return network.post('product/imageupload', body);
};
const importBulkProducts = (params) => {
  return network.post(`product/import`, params, true, true);
};
const getProductImportTemplate = (type) => {
  return network.get(`product/download-template/${type}`, {}, true, 'blob');
};

const deleteVariant = (id) => {
  return network.put(`stock/${id}`, { is_deleted: true });
};

const uploadImage = (payload) => {
  return network.post('product/imageUpload', payload);
};

export default {
  getProducts,
  getProductById,
  getProductCategories,
  getProductSubCategories,
  postProduct,
  putProduct,
  getQuestionAnswers,
  putQuestionAnswer,
  deleteProduct,
  productImagesUpload,
  importBulkProducts,
  getProductImportTemplate,
  deleteVariant,
  getProductLabels,
  uploadImage,
};
