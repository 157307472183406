import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { default as api, default as services } from '../../api/api';
import previewIcon from '../../assets/images/eye.svg';
import editIcon from '../../assets/images/pencil.svg';
import deleteIcon from '../../assets/images/trash.svg';
import DeleteConfirmationModal from '../../common/UI/CustomModal/deleteModal';
import Loader from '../../common/UI/Loader';
import CustomReactTable from '../../common/UI/ReactTable';
import SearchAndButtons from '../../common/UI/Search&Buttons';
import { toastError, toastSuccess } from '../../common/UI/Toasts';
import HealthGoalFormPage from './form';
import { getFormDataFromObject } from '../../utils/helpers';
import ReactSwitch from 'react-switch';

const HealthGoal = () => {
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [deleteGoalId, setDeleteGoalId] = useState(null);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [deleteGoalName, setDeleteGoalName] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setbuttonClicked] = useState('table');
  const [goalEditInfo, setGoalEditInfo] = useState({});

  const columns = [
    {
      Header: 'Image',
      disableSortBy: true,
      disableFilters: true,
      Cell: (tableProps) => {
        return (
          <div>
            <img
              height="auto"
              width={100}
              src={tableProps?.row?.original?.image?.location}
              alt="goal"
            />
          </div>
        );
      },
    },
    {
      Header: 'Title',
      accessor: 'title',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Items in goal',
      accessor: 'numOfProducts',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Status',
      disableSortBy: true,
      disableFilters: true,
      Cell: (tableProps) => {
        return (
          <>
            <ReactSwitch
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={() => handleToggleChange(tableProps?.row.original?._id)}
              checked={tableProps?.row?.original?.status}
            />
          </>
        );
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span className="me-2" onClick={() => handleEdit(row)}>
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span className="me-2" onClick={() => handleDelete(row)}>
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
            <span onClick={() => handleView(row)}>
              <img src={previewIcon} alt="preview" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getAllGoal(currentPageNo);
  }, [currentPageNo, seeAll]);

  const onGoalButtonClick = () => {
    setbuttonClicked('addGoal');
    setGoalEditInfo({
      goal_id: '',
      title: '',
      image: [],
      status: '',
    });
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  // Functions for api calls
  const getAllGoal = (pageNo) => {
    const params = seeAll
      ? { showall: true }
      : {
          perPage: 10,
          page: pageNo,
        };
    services
      .getAllGoal(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const getOnSearch = (value) => {
    const params = {
      search: value,
    };
    services
      .getAllGoal(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };
  const onSearchDebounce = debounce(getOnSearch, 1000);
  const onGoalSearchChange = (value) => {
    onSearchDebounce(value);
  };

  const deleteGoal = () => {
    services
      .deleteGoal(deleteGoalId)
      .then(() => {
        getAllGoal(currentPageNo);
        setDeleteGoalId(null);
        toastSuccess('Goal Deleted Successfully');
      })
      .catch((err) => {
        console.log('error', err);
        setDeleteGoalId(null);
      });
  };

  const handleDelete = (row) => {
    setDeleteGoalName(row.original?.title);
    setDeleteGoalId(row.original?._id);
    setModalShow(true);
  };

  const handleEdit = (row) => {
    setbuttonClicked('editGoal');
    let goalEditInformation = {
      goal_id: row.original?._id,
      title: row.original?.title,
      image: [
        {
          ...row.original?.image,
          PrevUrl: row.original?.image?.location,
        },
      ],
      status: row.original?.status,
    };
    setGoalEditInfo(goalEditInformation);
  };

  const handleToggleChange = (id) => {
    const currentRecord = data.find((el) => el._id === id);
    let payload = {
      status: !currentRecord.status,
    };
    payload = getFormDataFromObject(payload);
    if (id) {
      services
        .updateGoal(id, payload)
        .then(() => {
          getAllGoal(currentPageNo);
          setLoading(false);
          toastSuccess('Status Updated Successfully');
        })
        .catch((err) => {
          console.log(`err`, err);
          toastError(err.message);
        });
    }
  };

  const handleView = (row) => {
    setbuttonClicked('viewGoal');
    setGoalEditInfo({
      goal_id: row.original?._id,
      title: row.original?.title,
      image: [{ PrevUrl: row.original?.image?.location }],
      status: row.original?.status,
    });
  };

  const deleteModalOnHide = () => {
    setModalShow(false);
    setDeleteGoalId(null);
  };

  const deleteModalOnDelete = () => {
    setModalShow(false);
    deleteGoal();
  };

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  const backLinkHandle = () => {
    setbuttonClicked('table');
    getAllGoal(currentPageNo);
  };

  return (
    <>
      {buttonClicked === 'table' ? (
        <>
          <div className="content_wrapper all_products_main">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="box_main">
                    <div className="header_top">
                      <h3>Health Goal Management</h3>
                    </div>
                    <div className="middle_main">
                      <SearchAndButtons
                        onSearchChange={onGoalSearchChange}
                        onButtonClick={onGoalButtonClick}
                        searchPlaceholder={'Search by goal name...'}
                        buttonName={'Add Goal'}
                        seeAllHandle={seeAllHandle}
                      />
                      <div className="row">
                        <div className="col-md-12 mb-0 mt-4">
                          {loading ? (
                            <Loader loading={loading} />
                          ) : (
                            <CustomReactTable
                              title="Banner"
                              tableColumns={columns}
                              tableData={data}
                              seeAll={seeAll}
                              nextHandle={nextHandle}
                              prevHandle={prevHandle}
                              totalPage={totalPage}
                              pageNo={currentPageNo}
                              handleDelete={handleDelete}
                            />
                          )}
                        </div>
                      </div>
                      <DeleteConfirmationModal
                        name={deleteGoalName}
                        module={'goal'}
                        show={modalShow}
                        handleToggle={deleteModalOnHide}
                        handleDelete={deleteModalOnDelete}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <HealthGoalFormPage
            name={buttonClicked}
            backLinkHandle={backLinkHandle}
            goalEditInfo={goalEditInfo}
            tableRecords={data}
          />
        </>
      )}
    </>
  );
};

export default HealthGoal;
