import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import services from '../../api/api';
import Loader from '../../common/UI/Loader';
import CustomReactTable from '../../common/UI/ReactTable';
import SearchAndButtons from '../../common/UI/Search&Buttons';
import { toastError, toastSuccess } from '../../common/UI/Toasts';
import CustomDropdown from '../../common/UI/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import {
  getFormDataFromObject,
  validateFileExtensions,
} from '../../utils/helpers';

const StockExcelUploadSection = () => {
  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [medicationData, setMedicationData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [medFilteredData, setMedFilteredData] = useState(null);
  const [prodFilteredData, setProdFilteredData] = useState(null);
  const [impFile, setImpFile] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const columns = [
    {
      Header: 'Brand',
      accessor: (data) => {
        return data?.product?.brand?.name;
      },
      disableSortBy: true,
    },
    {
      Header: 'Name',
      accessor: 'product.name',
      disableSortBy: true,
    },
    {
      Header: 'Type',
      accessor: ({ product }) => {
        return (
          product?.type.charAt(0).toUpperCase() + product?.type.slice(1) || null
        );
      },
      disableSortBy: true,
    },
    {
      Header: 'SKU',
      disableSortBy: true,
      accessor: 'product.sku',
    },
    {
      Header: 'Size',
      accessor: 'size',
      disableSortBy: true,
    },
    {
      Header: 'Strength',
      accessor: 'strength',
      disableSortBy: true,
    },
    {
      Header: 'Trade Price',
      disableSortBy: true,
      Cell: ({ row }) => {
        return <span>£{row.original.trade_price.toFixed(2)}</span>;
      },
    },
    {
      Header: 'RRP',
      disableSortBy: true,
      Cell: ({ row }) => {
        return <span>£{row.original.rrp_price.toFixed(2)}</span>;
      },
    },

    {
      Header: 'Stock',
      accessor: 'stock_level',
      disableSortBy: true,
    },
  ];

  const Excelheaders = [
    { label: 'StockId', key: 'stock_id' },
    { label: 'Brand', key: 'brand' },
    { label: 'Product Name', key: 'product_name' },
    { label: 'Product Type', key: 'product_type' },
    { label: 'SKU', key: 'sku' },
    { label: 'Size', key: 'size' },
    { label: 'Strength', key: 'strength' },
    { label: 'Trade Price', key: 'trade_price' },
    { label: 'RRP', key: 'rrp' },
    { label: 'Stock', key: 'stock' },
    { label: 'Received Stock', key: 'received_stock' },
  ];
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    // For excel data (because of all data need)
    getExcelData();
  }, []);

  useEffect(() => {
    setLoading(true);
    // For table (because of pagination)
    getStocks(currentPageNo);
  }, [currentPageNo, seeAll]);

  const getExcelData = () => {
    const params = {
      showall: true,
    };
    services
      .getStocks(params)
      .then((response) => {
        const data = response?.data?.data?.docs;

        const medication = data.filter(
          (el) => el?.product?.type == 'medication',
        );

        const product = data.filter((el) => el?.product?.type == 'product');

        setMedicationData(loadExcelData(medication));
        setProductData(loadExcelData(product));
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  };

  const getStocks = (pageNo) => {
    const params = seeAll
      ? {
          showall: true,
        }
      : {
          perPage: 20,
          page: pageNo,
        };
    services
      .getStocks(params)
      .then((response) => {
        setData(response?.data?.data?.docs);
        setTotalPage(response?.data?.data?.totalPages);
        setLoading(false);
        // Setting Brand options
        let brand = response?.data?.data?.docs.map((el) => {
          return {
            label: el?.product?.brand?.name,
            value: el?.product?.brand?._id,
          };
        });
        let uniqueBrandId = [];
        brand = brand.filter((el) => {
          const isDuplicate = uniqueBrandId.includes(el.value);

          if (!isDuplicate) {
            uniqueBrandId.push(el.value);
            return true;
          }
          return false;
        });
        setBrandOptions(brand);
      })
      .catch((err) => {
        console.log(`err`, err);
        setLoading(false);
      });
  };

  const updateStocks = () => {
    let payload = {
      importfile: impFile,
    };
    setIsButtonDisabled(true);
    payload = getFormDataFromObject(payload);

    services
      .importStockFile(payload)
      .then((res) => {
        toastSuccess('Stocks Updated Successfully');
        getStocks(currentPageNo);
        getExcelData();
        setImpFile(null);
        setIsButtonDisabled(false);
      })
      .catch((err) => {
        toastError('Opps, Something went wrong');
      });
  };

  const getOnSearch = (value) => {
    const params = {
      search: value,
    };
    services
      .getStocksOnSearch(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };
  const onSearchDebounce = debounce(getOnSearch, 1000);

  const onStockSearchChange = (value) => {
    onSearchDebounce(value);
  };

  const loadExcelData = (data) => {
    const exceldata =
      data?.length > 0
        ? data.map((el) => {
            return {
              stock_id: el._id,
              brand: el.product?.brand?.name,
              product_name: el?.product?.name,
              product_type: el?.product?.type,
              sku: el?.product?.sku,
              size: el?.size,
              strength: el?.strength,
              trade_price: el?.trade_price,
              rrp: el?.rrp_price,
              stock: el?.stock_level,
              received_stock: 0,
            };
          })
        : null;
    return exceldata ? exceldata : [];
  };

  const filterData = (value) => {
    if (value) {
      // setting table data
      let filteredData = data.filter(
        (el) => el?.product?.brand?.name === value?.label,
      );
      setFilteredData(filteredData);

      // setting template data
      const medData = medicationData.filter((el) => el?.brand == value?.label);
      const prodData = productData.filter((el) => el?.brand == value?.label);
      setMedFilteredData(medData);
      setProdFilteredData(prodData);
    } else {
      setFilteredData(null);
      setMedFilteredData(null);
      setProdFilteredData(null);
    }
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <h3>Bulk Excel Upload</h3>
                  <div className="d-flex ms-auto">
                    <CustomDropdown
                      className="mt-2 me-2"
                      placeholder="Filter by brand"
                      options={brandOptions}
                      containerWidth="190px"
                      height="52px"
                      isClearable={true}
                      isSearchable={false}
                      border={'1 px solid #9aa5ad'}
                      onChange={filterData}
                    />
                    <Button
                      className="me-2 mt-1"
                      style={{
                        width: '12rem',
                        height: '4rem',
                      }}
                    >
                      <CSVLink
                        data={
                          medFilteredData ? medFilteredData : medicationData
                        }
                        filename={`MedicationStock_${new Date().toLocaleString()}.xlsx`}
                        target="_blank"
                        headers={Excelheaders}
                        style={{ color: '#fff' }}
                      >
                        <div className="fw-bold">
                          <FontAwesomeIcon icon={faDownload} className="mx-2" />
                          Template For Medication
                        </div>
                      </CSVLink>
                    </Button>
                    <Button
                      className="mt-1"
                      style={{
                        width: '12rem',
                        height: '4rem',
                      }}
                    >
                      <CSVLink
                        data={prodFilteredData ? prodFilteredData : productData}
                        filename={`ProductStock_${new Date().toLocaleString()}.xlsx`}
                        target="_blank"
                        headers={Excelheaders}
                        style={{ color: '#fff' }}
                      >
                        <div className="fw-bold">
                          <FontAwesomeIcon icon={faDownload} className="mx-2" />
                          Template For Products
                        </div>
                      </CSVLink>
                    </Button>

                    <div className="d-flex">
                      <label
                        className="custom_file_upload p-2 text-center mt-2 mx-2 d-flex align-items-center justify-content-center fs-16"
                        style={{
                          borderRadius: '32px',
                          width: '150px',
                          height: '52px',
                        }}
                      >
                        Choose Template
                        <input
                          className="mt-2"
                          type="file"
                          style={{ width: '300px', display: 'none' }}
                          accept=".xlsx"
                          onChange={(event) => {
                            if (
                              validateFileExtensions(event.target.files[0], [
                                'xls',
                                'xlsx',
                              ])
                            ) {
                              setImpFile(event.target.files[0]);
                              setIsButtonDisabled(false);
                            } else {
                              toastError('Please choose xls or xlsx file');
                            }
                          }}
                        />
                      </label>
                      <div
                        className="form-check-label checkbox_label mt-2 fs-16"
                        style={{ width: '200px', wordBreak: 'break-all' }}
                      >
                        <label className="fw-bold">Template Name :</label>
                        <h6>{impFile ? impFile?.name : 'No file chosen'}</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="middle_main">
                  <SearchAndButtons
                    onSearchChange={onStockSearchChange}
                    searchPlaceholder={'Search by product or brand name...'}
                    seeAllHandle={seeAllHandle}
                    buttonName={
                      <>
                        <FontAwesomeIcon icon={faUpload} className="mx-2" />
                        Import Template
                      </>
                    }
                    isButtonDisabled={isButtonDisabled}
                    onButtonClick={updateStocks}
                  ></SearchAndButtons>
                  <div className="row">
                    <div className="col-md-12 mb-0 mt-4">
                      {loading ? (
                        <Loader loading={loading} />
                      ) : (
                        <CustomReactTable
                          tableColumns={columns}
                          tableData={filteredData || data}
                          seeAll={seeAll}
                          nextHandle={nextHandle}
                          prevHandle={prevHandle}
                          totalPage={totalPage}
                          pageNo={currentPageNo}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockExcelUploadSection;
