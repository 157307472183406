import * as network from './network';

const getAllBlogs = (params) => {
  return network.get(`blog`, params, true);
};

const addBlog = (payload) => {
  return network.post(`blog`, payload, true);
};

const updateBlog = (id, payload) => {
  return network.put(`blog/${id}`, payload, true);
};

const getBlogsOnSearch = (params) => {
  return network.get(`blog/search`, params, true);
};

const deleteBlog = (id) => {
  return network.delet(`blog/${id}`, true);
};

export default {
  getAllBlogs,
  addBlog,
  updateBlog,
  getBlogsOnSearch,
  deleteBlog,
};
