import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const AddAndEditModal = (props) => {
  const {
    size,
    show,
    centered,
    action,
    module,
    handleToggle,
    handleAction,
    children,
    disabled,
  } = props;
  return (
    <Modal
      size={size}
      show={show}
      onHide={handleToggle}
      centered={centered || true}
      className="custom_modal"
      style={{ zIndex: '3000' }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="text-black fw-bold fs-5">
            {action} {module}
          </h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="sky" size="sm" onClick={handleToggle}>
          Close
        </Button>
        {action ? (
          <Button size="sm" disabled={disabled} onClick={handleAction}>
            {action}
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

export default AddAndEditModal;
