import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './header';

const Layout = (props) => {
  const { pathname } = useLocation();
  const [isSidenavToggled, setIsSidenavToggled] = useState(false);
  const noLayoutRoutes = ['/login', '/forgot-password', '/reset-password'];

  useEffect(() => {
    if (!noLayoutRoutes.includes(pathname)) {
      localStorage.setItem('path', pathname);
    }
  }, [pathname]);

  const handleSidenavToggle = () => {
    setIsSidenavToggled((prev) => !prev);
  };
  return (
    <>
      {noLayoutRoutes.includes(pathname) ? (
        <>{props.children}</>
      ) : (
        <div
          className={`topper_main ${
            isSidenavToggled ? 'sidenav_toggled' : null
          }`}
        >
          <Header
            isSidenavToggled={isSidenavToggled}
            handleSidenavToggle={handleSidenavToggle}
          />
          {props.children}
        </div>
      )}
    </>
  );
};

export default Layout;
