import React from 'react';
import HealthGoals from '../components/HealthGoals';
const HealthGoalsManagement = () => {
  return (
    <>
      <HealthGoals />
    </>
  );
};

export default HealthGoalsManagement;
