import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

import SearchAndButtons from '../../common/UI/Search&Buttons';
import ReactTable from '../../common/UI/ReactTable';
import AddAndEditModal from '../../common/UI/CustomModal/addAndEditModal';
import DeleteConfirmationModal from '../../common/UI/CustomModal/deleteModal';
import Loader from '../../common/UI/Loader';
import services from '../../api/api';

import editIcon from '../../assets/images/pencil.svg';
import deleteIcon from '../../assets/images/trash.svg';
import leftArrow from '../../assets/images/left_arrow.svg';
import rightArrow from '../../assets/images/right_arrow.svg';
import { toastSuccess, toastError } from '../../common/UI/Toasts';

const BrandPage = () => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      disableSortBy: true,
      disableFilters: true,
      width: 200,
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      width: 80,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span className="me-2" onClick={() => handleEdit(row)}>
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span onClick={() => handleDelete(row)}>
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [modalOpenedFor, setModalOpenedFor] = useState();
  const [addAndEditModalShow, setAddAndEditModalShow] = useState(false);
  const [brandValueForModal, setBrandValueForModal] = useState('');
  const [idForBrandEdit, setIdForBrandEdit] = useState();
  const [deleteBrandName, setDeleteBrandName] = useState();
  const [deleteBrandId, setDeleteBrandId] = useState();
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);

  useEffect(() => {
    setFirst(data.slice(0, 10));
    setSecond(data.slice(10, 20));
    setThird(data.slice(20, 30));
  }, [data]);

  useEffect(() => {
    setLoading(true);
    getBrands(currentPageNo);
  }, [currentPageNo]);

  // Api functions
  const getBrands = (pageNo) => {
    const params = {
      perPage: 30,
      page: pageNo,
    };
    services
      .getAllBrands(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const getOnSearch = (value) => {
    const params = {
      search: value,
    };
    services
      .getBrandsOnSearch(params)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const deleteBrands = () => {
    services
      .deleteBrand(deleteBrandId)
      .then((res) => {
        toastSuccess('Brand Deleted Successfully');
        setDeleteBrandId(null);
        setDeleteBrandName(null);
        getBrands(currentPageNo);
      })
      .catch((err) => {
        console.log('error', err);
        toastError(err.message);
        setDeleteBrandId(null);
        setDeleteBrandName(null);
      });
  };

  const onSearchDebounce = debounce(getOnSearch, 1000);

  const onBrandSearchChange = (value) => {
    onSearchDebounce(value);
  };

  const handleAddButtonClick = () => {
    setAddAndEditModalShow(true);
    setModalOpenedFor('Add');
  };

  const handleEdit = (row) => {
    setAddAndEditModalShow(true);
    setModalOpenedFor('Edit');
    setBrandValueForModal(row.original.name);
    setIdForBrandEdit(row.original._id);
    console.log('edit');
  };

  const handleDelete = (row) => {
    setDeleteBrandId(row.original._id);
    setDeleteBrandName(row.original.name);
    setDeleteModalShow(true);
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const toggleAddEditModal = () => {
    setAddAndEditModalShow(false);
    setBrandValueForModal('');
  };

  const addAndEditModalAction = () => {
    const payload = {
      name: brandValueForModal,
    };
    modalOpenedFor == 'Add'
      ? services
          .addBrand(payload)
          .then((res) => {
            toastSuccess('Brand Added Successfully');
            getBrands(currentPageNo);
            setBrandValueForModal('');
            setAddAndEditModalShow(false);
          })
          .catch((err) => {
            console.log('error', err);
            toastError(err.message);
          })
      : services
          .updateBrand(idForBrandEdit, payload)
          .then((res) => {
            toastSuccess('Brand Name Updated Successfully');
            setBrandValueForModal('');
            setIdForBrandEdit(null);
            getBrands(currentPageNo);
            setAddAndEditModalShow(false);
          })
          .catch((err) => {
            console.log('error', err);
            toastError(err.message);
          });
  };

  const toggleDeleteModal = () => {
    setDeleteModalShow(false);
    setDeleteBrandId(null);
    setDeleteBrandName(null);
  };

  const deleteModalAction = () => {
    setDeleteModalShow(false);
    deleteBrands();
  };

  return (
    <>
      {addAndEditModalShow && (
        <AddAndEditModal
          show={addAndEditModalShow}
          handleToggle={toggleAddEditModal}
          handleAction={addAndEditModalAction}
          action={modalOpenedFor}
          module={'Brand'}
        >
          <label className="form-check-label mb-2 fs-6" htmlFor="name">
            Name<span className="text-danger">*</span>
          </label>
          <input
            className="form-control me-2 shadow-none"
            style={{
              border: '1px solid #e5e5e5',
              borderRadius: '28px',
              width: '430px',
              height: '51px',
            }}
            id="name"
            type="text"
            onChange={(e) => setBrandValueForModal(e.target.value)}
            value={brandValueForModal}
          />
        </AddAndEditModal>
      )}

      {deleteModalShow && (
        <DeleteConfirmationModal
          name={deleteBrandName}
          module={'Brand'}
          show={deleteModalShow}
          handleToggle={toggleDeleteModal}
          handleDelete={deleteModalAction}
        />
      )}
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <h3>Brand Management</h3>
                </div>
                <div className="middle_main">
                  <SearchAndButtons
                    onSearchChange={onBrandSearchChange}
                    onButtonClick={handleAddButtonClick}
                    searchPlaceholder={'Search by brand name...'}
                    buttonName={'Add Brand'}
                    seeAllButton={false}
                  />
                  {loading ? (
                    <Loader loading={loading} />
                  ) : (
                    <div className="row">
                      <div className="col-md-4 mb-0 mt-4">
                        <ReactTable
                          tableColumns={columns}
                          tableData={first}
                          seeAll={true}
                        />
                      </div>
                      {data.length > 10 && (
                        <div className="col-md-4 mb-0 mt-4">
                          <ReactTable
                            tableColumns={columns}
                            tableData={second}
                            seeAll={true}
                          />
                        </div>
                      )}
                      {data.length > 20 && (
                        <div className="col-md-4 mb-0 mt-4">
                          <ReactTable
                            tableColumns={columns}
                            tableData={third}
                            seeAll={true}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="row">
                    <div className="pagination justify-content-center">
                      <div className="pagination__inner">
                        <div className="pagination__btn previous ">
                          <span>
                            <button
                              onClick={() => prevHandle()}
                              disabled={currentPageNo == 1 ? true : false}
                            >
                              <img
                                src={leftArrow}
                                alt="left arrow"
                                width="21"
                                height="21"
                              />
                            </button>
                          </span>
                        </div>
                        <div>
                          <strong>{currentPageNo}</strong> of{' '}
                          <strong>{totalPage}</strong>
                        </div>
                        <div className="pagination__btn  next">
                          <span>
                            <button
                              onClick={() => nextHandle()}
                              disabled={
                                currentPageNo == totalPage ? true : false
                              }
                            >
                              <img
                                src={rightArrow}
                                alt="right arrow"
                                width="21"
                                height="21"
                              />
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandPage;
