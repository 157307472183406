import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api/api';

const initialState = { subcategoryArr: null, loading: false };

export const getAllSubcategory = createAsyncThunk(
  'order/getAllSubcategory',
  () => {
    const params = {
      showall: true,
    };
    return api.getAllSubcategory(params).then((res) => {
      let subcategoryArr = res?.data?.data?.docs;
      return subcategoryArr;
    });
  },
);

const subcategoryReducer = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setSubcategoryArr: (state, action) => {
      state.subcategoryArr = action.payload;
    },
    emptySubcategoryArr: (state, action) => {
      state.subcategoryArr = null;
    },
  },
  extraReducers: {
    [getAllSubcategory.pending]: (state) => {
      state.loading = true;
    },
    [getAllSubcategory.rejected]: (state) => {
      state.loading = false;
    },
    [getAllSubcategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.subcategoryArr = payload;
    },
  },
});
export default subcategoryReducer.reducer;
export const { setSubcategoryArr, emptySubcategoryArr } =
  subcategoryReducer.actions;
